export const NextPaginationIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 16.8073C20 17.224 19.9074 17.6407 19.7222 18.0574C19.537 18.4741 19.2824 18.7982 18.9583 19.0297L5.27778 32.7118C4.72222 33.36 4.00463 33.6841 3.125 33.6841C2.24537 33.6841 1.50463 33.3831 0.902778 32.7812C0.300926 32.1793 -9.86649e-08 31.4269 -1.38131e-07 30.524C-1.77597e-07 29.6211 0.300926 28.8687 0.902778 28.2668L12.3611 16.8073L0.902777 5.34768C0.300925 4.74576 -1.29681e-06 4.01652 -1.33425e-06 3.15994C-1.37169e-06 2.30337 0.300925 1.55097 0.902776 0.902752C1.50463 0.300835 2.25694 -0.000125984 3.15972 -0.000126023C4.0625 -0.000126063 4.81481 0.300835 5.41667 0.902752L18.9583 14.5153C19.2824 14.7931 19.537 15.1404 19.7222 15.5571C19.9074 15.9738 20 16.3905 20 16.8073Z" fill="#5B2FF6" />
        </svg>
    )
}

export const PreviousPaginationIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-7.3771e-07 16.8768C-7.19495e-07 16.4601 0.0925915 16.0434 0.277776 15.6267C0.462961 15.21 0.717592 14.8859 1.04167 14.6544L14.7222 0.972327C15.2778 0.324109 15.9954 -1.75048e-07 16.875 -1.36598e-07C17.7546 -9.81483e-08 18.4954 0.300958 19.0972 0.902876C19.6991 1.50479 20 2.25719 20 3.16006C20 4.06294 19.6991 4.81534 19.0972 5.41725L7.63889 16.8768L19.0972 28.3364C19.6991 28.9383 20 29.6676 20 30.5241C20 31.3807 19.6991 32.1331 19.0972 32.7813C18.4954 33.3832 17.7431 33.6842 16.8403 33.6842C15.9375 33.6842 15.1852 33.3832 14.5833 32.7813L1.04167 19.1687C0.717591 18.8909 0.46296 18.5437 0.277776 18.127C0.0925915 17.7103 -7.55925e-07 17.2935 -7.3771e-07 16.8768Z" fill="#5B2FF6" />
        </svg>
    )
}