import React from 'react';
import { IProposalCapacity } from '../types/interfaces/offer.interface';

interface IProps {
    proposalsCapacity: IProposalCapacity[]
}

/**
 * Renders a table displaying the capacity information of proposals.
 *
 * @param {IProps} props - The component props.
 * @param {IProposalCapacity[]} props.proposalsCapacity - The array of proposal capacity objects.
 * @return {ReactElement} The rendered table component.
 */
const ProposalCapacityTable: React.FC<IProps> = ({ proposalsCapacity }) => {

    return (
        <div className='flex flex-col border-2 border-background2 rounded-md w-full'>

            <div className='grid grid-cols-4 gap-2 px-2 py-1 font-medium text-sm text-mediumGray'>
                <p className='truncate'>#</p>
                <p className='truncate'>Tot</p>
                <p className='truncate'>Disponibili</p>
                <p className='truncate'>Prenotati</p>
            </div>

            {proposalsCapacity
                .map((proposalInfo: IProposalCapacity) => {
                    const freeQuantity = proposalInfo.totCapacity - proposalInfo.currentCapacity < 0 ? 0 : proposalInfo.totCapacity - proposalInfo.currentCapacity;
                    const quantityPercent = proposalInfo.totCapacity > 0 ? freeQuantity / proposalInfo.totCapacity * 100 : 100;
                    return { ...proposalInfo, quantityPercent, freeQuantity };
                })
                .sort((a, b) => (!!a.totCapacity || !!b.totCapacity) ? a.quantityPercent - b.quantityPercent : b.currentCapacity - a.currentCapacity)
                .map((proposalInfo, index) => {
                    let bgColor = 'bg-white';

                    if (proposalInfo.quantityPercent > 50) {
                        bgColor = 'bg-white';
                    } else if (proposalInfo.quantityPercent > 15) {
                        bgColor = 'bg-warning/20';
                    } else {
                        bgColor = 'bg-error/20';
                    }

                    if (proposalInfo?.currentCapacity === 0) return null;

                    return <div key={index} className={`grid grid-cols-4 gap-2 px-2 py-1 text-sm text-text font-medium capitalize border-t-2 border-background2 ${bgColor}`}>
                        <p className='truncate'>{proposalInfo?.proposal?.name ?? 'Da Definire'}</p>
                        <p className='truncate'>{proposalInfo.totCapacity > 0 ? proposalInfo.totCapacity : 'N/D'}</p>
                        <p className='truncate'>{proposalInfo.totCapacity > 0 ? proposalInfo.freeQuantity : 'N/D'}</p>
                        <p className='truncate'>{proposalInfo.currentCapacity}</p>
                    </div>
                })
            }
        </div>)
}

export default ProposalCapacityTable