import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputForm from '../../components/InputForm';
import CustomButton from '../../components/CustomButton';
import Modal from '../../components/Modal';
import { apiHandler, postHandler, putHandler } from '../../services/apiHandler';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';

const VerifyAccount = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { showErrorModal } = useModalDispatcher();

    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [countdown]);

    useEffect(() => {
        if (searchParams.has('id')) {
            setUserId(searchParams.get('id')!);
        }
        if (searchParams.has('email')) {
            setEmail(searchParams.get('email')!);
        }
    }, [searchParams]);

    const handleVerify = async () => {
        setErrors({});
        setIsLoading(true);

        if (userId.length <= 0 && !emailRegex.test(email)) {
            setErrors({ email: true })
            return;
        }
        if (otp.length <= 0) {
            setErrors({ otp: true })
            return;
        }

        try {
            const response = await apiHandler.post<postHandler | any>('/user/register/verify', { userId: userId, otp: otp.trim(), email: email });
            if (response.accessToken) {
                setIsModalOpen(true);
            }
            setIsLoading(false);
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
            setIsLoading(false);
        }
    };

    const handleResendOtp = async () => {
        setErrors({});
        if (!emailRegex.test(email)) {
            setErrors({ email: true })
            return;
        }

        try {
            setCountdown(60);
            await apiHandler.put<putHandler | any>('/user/register/resend-otp', { email: email.trim() });
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
            setCountdown(0);
        }
    };

    return (
        <div className='h-screen w-full flex justify-center items-center bg-gradient-to-t lg:bg-gradient-to-r to-primary from-primaryLight'>
            <div className="w-11/12 lg:w-1/2 flex flex-col justify-between overflow-hidden bg-white rounded-xl p-4 gap-2">

                <p className="text-2xl font-bold text-text">
                    Verifica account
                </p>

                <div className='flex gap-8 items-end'>

                    <InputForm
                        fullWidth
                        isDisabled={true}
                        backgroundColor='bg-background'
                        label='Email'
                        value={email}
                        error={errors.email}
                        errorMessage='Inserire una email valida'
                        setValue={(value) => setEmail(value)}
                    />
                    <CustomButton disabled={countdown !== 0} label={`Richiedi codice ${(countdown !== 0 ? countdown : '')}`} color='bg-primary' className="uppercase" onClickHandler={handleResendOtp} />
                </div>

                <InputForm
                    backgroundColor='bg-background'
                    label='Codice'
                    customStyle='uppercase'
                    maxLenght={6}
                    value={otp}
                    error={errors.otp}
                    errorMessage={'Inserire un codice'}
                    setValue={(value: string) => setOtp(value.toUpperCase())}
                />

                <div className='flex justify-between items-center'>
                    <CustomButton label='Indietro' color='bg-white' textColor='text-darkGray' className="border-2 border-darkGray mt-10 px-8 py-4 uppercase" onClickHandler={() => navigate(-1)} />
                    <CustomButton disabled={isLoading} label='Invia' color='bg-primary' className="mt-10 px-8 py-4 uppercase" onClickHandler={handleVerify} />
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={() => navigate('/', { replace: true })}>
                <p className='text-center font-medium text-text'>Utente verificato con successo</p>
                <CustomButton label='Vai al login' color='bg-success' className="mt-10 px-8 py-4 uppercase" onClickHandler={() => navigate('/', { replace: true })} />
            </Modal>
        </div>
    )
}

export default VerifyAccount