import React, { useState } from 'react'
import { CopyIcon } from '../utils/icons/CopyIcon'

interface IProps {
    link: string;
    padding?: number;
}

const CopyToClipboard: React.FC<IProps> = ({ link, padding = 0 }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        setCopied(false);

        navigator.clipboard.writeText(link)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Errore durante la copia del testo nella clipboard:', error);
            });
    };

    return (
        <>
            <div onClick={copyToClipboard} className={`p-${padding}`}>
                <CopyIcon />
            </div>
            <div className={`transition-opacity ${copied ? 'opacity-100' : 'opacity-0'} cursor-default duration-300 ease-in-out absolute bottom-full -translate-x-1/2 left-1/2 mb-2 px-2 py-1 bg-white text-text font-medium rounded shadow`}>
                Copiato
            </div>
        </>
    )
}

export default CopyToClipboard