import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { bookingState, IBookingState } from "./bookingState";

export const BookingSlice: Slice<IBookingState> = createSlice({
    name: "booking",
    initialState: bookingState,
    reducers: {
        addFilter: (state, action: PayloadAction<{ field: string; value: string[] }>) => {
            const { field, value } = action.payload;
            state.filters[field] = value;
        },
        removeFilter: (state, action) => {
            const key = action.payload;
            const updatedFilters = { ...state.filters };
            delete updatedFilters[key];
            state.filters = updatedFilters;
        },
        updateFilters(state, action) {
            state.filters = action.payload;
        },
        clearFilters(state) {
            state.filters = {};
        },
        changeSelectedDate: (state, action: PayloadAction<string>) => {
            state.globalDate = action.payload;
        },
        replacePaginationData: (state, action: PayloadAction<{ field: keyof typeof state.paginationData; value: number }>) => {
            const { field, value } = action.payload;
            state.paginationData[field] = value;
        }
    }
});

export const { addFilter, removeFilter, updateFilters, clearFilters, changeSelectedDate, replacePaginationData } = BookingSlice.actions;
export default BookingSlice.reducer;