import React, { useState, useRef, useEffect } from 'react';
import InputForm from './InputForm';

interface IProps {
    setValue: (...args: any) => void
}

const PhoneInput: React.FC<IProps> = ({ setValue }) => {
    const [phoneNumber, setPhoneNumber] = useState('+39 ');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            // Posiziona il cursore alla fine del prefisso +39
            inputRef.current.setSelectionRange(phoneNumber.length, phoneNumber.length);
        }
    }, [phoneNumber]);

    const handleInputChange = (inputValue: string) => {
        // Impedisci la modifica del prefisso +39
        if (inputValue.startsWith('+39 ')) {
            setPhoneNumber(inputValue);
            setValue(inputValue);
        } else if (!inputValue.startsWith('+39')) {
            setPhoneNumber('+39 ' + inputValue);
            setValue('+39 ' + inputValue);
        }
    };

    return (
        <InputForm
            inputRef={inputRef}
            name='phoneNumber'
            label='Telefono *'
            backgroundColor='bg-white'
            placeholder='Es. 333 1234567'
            type='text'
            value={phoneNumber}
            setValue={handleInputChange}
        />
    );
};

export default PhoneInput;
