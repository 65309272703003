import { useEffect, useState } from 'react';
import { IOffer, IOfferCapacity, IProposalCapacity } from '../../../types/interfaces/offer.interface';
import { useAppSelector } from '../../../store/store';
import { IBooking, IProposalItem, PartialIBooking } from '../../../types/interfaces/booking.interface';
import { EmptyStarIcon, FullStarIcon } from '../../../utils/icons/StarIcon';
import InputForm from '../../../components/InputForm';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { CalendarIcon } from '../../../utils/icons/CalendarIcon';
import moment from 'moment';
import Calendar from '../../../components/Calendar';
import SelectForm from '../../../components/SelectForm';
import { DeleteBucketIcon } from '../../../utils/icons/DeleteBucketIcon';
import RadioButton from '../../../components/RadioButton';
import { cakeTypeOptions, mapCakeType } from '../../../types/enum/cakeType';
import { IProposal } from '../../../types/interfaces/proposal.interface';
import CustomButton from '../../../components/CustomButton';
import { useLoaderData, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BookingRecap from '../../../components/BookingRecap';
import Modal from '../../../components/Modal';
import { useModalDispatcher } from '../../../hooks/useModalDispatcher';
import { HttpMethod, apiService } from '../../../services/apiService';
import UserReliability, { IUserReliability } from '../../../components/UserReliability';
import RecurringEventScheduler from '../../../components/RecurringEventScheduler';
import useFetch from '../../../hooks/useFetch';
import { IStructure } from '../../../types/interfaces/structure.interface';
import { IUser } from '../../../types/interfaces/auth.interface';
import { IEvent } from '../../../types/interfaces/event.interface';
import { EventProposalOptions } from '../../../types/enum/eventProposalsOptions.enum';
import ProposalCapacityTable from '../../../components/ProposalCapacityTable';
import { AutoBookingType } from '../../../types/enum/autoBookingType.enum';

export interface IBookingManage {
    name?: string;
    surname: string;
    email?: string,
    phoneNumber: string;
    bookingDate: string;
    isImportant?: boolean;
    age?: number;
    bookedOffers: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>[]
};

const BookingCreate = () => {
    // Get response for Update
    const response = useLoaderData() as IBooking<IStructure, IOffer, IUser, IProposal, string, PartialIBooking<{ offer: IOffer, proposals: IProposal }>, IBooking>;

    const offers: IOffer[] = useAppSelector(state => state.offer.offers);
    const { isComponentVisible: isCalendarOpen, setIsComponentVisible: setIsCalendarOpen } = useComponentVisible(false);
    const [searchParams] = useSearchParams();
    const { showErrorModal } = useModalDispatcher();
    const navigate = useNavigate();
    const params = useParams();

    const [booking, setBooking] = useState<IBookingManage>({ bookingDate: searchParams.get('bookingDate') } as IBookingManage);
    const [currentBookedOffer, setCurrentBookedOffer] = useState<PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }> | undefined>({} as PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>);
    const [multipleDates, setMultipleDates] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean | { index: number; status: boolean } | undefined }>({});
    const [isModalOpen, setIsModalOpen] = useState<{ type: 'multiBookings' | 'checkName' | 'userReliability' | 'confirm' | null, state: boolean, data?: any }>({ type: null, state: false });

    useEffect(() => {
        if (response && params?.id) {
            const bookedOffers = [response, ...response.sharedBookingsNew ?? []] as any
            const book: IBookingManage = {
                surname: response.surname,
                name: response.name,
                phoneNumber: response.phoneNumber,
                email: response.email,
                bookingDate: response.bookingDate,
                age: response.age,
                isImportant: response.isImportant,
                bookedOffers
            }

            setBooking(book);
            setCurrentBookedOffer(response as any);
        } else {
            setBooking({ bookingDate: searchParams.get('bookingDate'), name: '', surname: '', phoneNumber: '', email: '', isImportant: false, age: 0 } as IBookingManage);
            setCurrentBookedOffer({} as any);
        }
    }, [params]);

    const { data: dailyEvents } = useFetch<IEvent<string, IProposal, IOffer>[]>(
        () => apiService<IEvent[] & { errorCode: string }>(HttpMethod.GET, `/event/list`, undefined, { date: booking.bookingDate }),
        undefined,
        [booking.bookingDate],
        !!booking.bookingDate
    );

    const { data: offersCapacity } = useFetch<IOfferCapacity[]>(
        () => Promise.all(offers.map(async (offer) => {
            return await apiService(HttpMethod.GET, '/offer/disponibility', undefined, { offerId: offer._id, date: booking.bookingDate })
        })),
        undefined,
        [offers, booking.bookingDate],
        offers.length > 0 && !!booking.bookingDate
    );

    const { data: proposalsCapacity } = useFetch<IProposalCapacity[]>(
        () => apiService(HttpMethod.GET, '/offer/proposal/disponibility', undefined, { offersId: offers.map(offer => offer._id), date: booking.bookingDate }),
        undefined,
        [offers, booking.bookingDate],
        offers.length > 0 && !!booking.bookingDate
    );

    const handleManageBooking = <T extends keyof IBookingManage>(key: T, value: IBookingManage[T]) => {
        setBooking(prev => ({ ...prev, [key]: value }));
    };

    /**
     * Updates a specific property of a booked offer in the list of booked offers.
     *
     * @template T - The key of the property to update.
     * @param key - The key of the property to update.
     * @param value - The new value of the property.
     * @param index - The index of the offer in the list.
     * @return void
     */
    const updateBookedOffer = <T extends keyof PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>>(key: T, value: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>[T], index: number) => {
        setBooking(prev => {
            const newBookedOffers = [...prev.bookedOffers ?? []];
            if (newBookedOffers[index]) {
                (newBookedOffers[index] as any)[key] = value;
            }
            return { ...prev, bookedOffers: newBookedOffers };
        });
    };

    /**
     * Manages the list of booked offers.
     *
     * @param offer - The offer to add to the list.
     * @param index - The index of the offer to remove from the list.
     * @return void
     */
    const manageBookedOffers = (offer: IOffer | undefined, index?: number) => {
        setBooking(prev => {
            const newBookedOffers = [...prev.bookedOffers ?? []];
            const offerExists = newBookedOffers.some(item => item.offer._id === offer?._id);

            if (offerExists) {
                return prev;
            }

            if (offer) {
                setCurrentBookedOffer({ offer, proposals: [] } as any);
                newBookedOffers.push({ offer, proposals: [] } as any);
            } else if (index !== undefined) {
                newBookedOffers.splice(index, 1);
                setTimeout(() => setCurrentBookedOffer(newBookedOffers[0] ?? undefined), 0);
            }

            return { ...prev, bookedOffers: newBookedOffers };
        });
    };

    const proposalOnClick = (isSelected: IProposalItem<IProposal<string, string, string>>[] | undefined, proposal: IProposal<string, string, string>, bookedOffer: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>, index: number) => {
        if (!!isSelected?.length) {
            updateBookedOffer('proposals', bookedOffer.proposals?.filter((prop: IProposalItem<IProposal<string, string, string>>) => prop.proposal._id !== proposal._id), index);
        } else {
            const newProposal = {
                proposal: proposal,
                quantity: 1
            };
            updateBookedOffer('proposals', [...bookedOffer.proposals ?? [], newProposal], index);
        }
    };

    const handleQuantityChange = (newQuantity: number | null, proposalId: string, bookedOffer: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>, index: number) => {
        if (newQuantity !== null && newQuantity >= 0) {
            const updatedProposals = (bookedOffer.proposals ?? []).map(prop =>
                prop.proposal._id === proposalId ? { ...prop, quantity: newQuantity } : prop
            );
            updateBookedOffer('proposals', updatedProposals, index);
        }
    };

    const validateBooking = () => {
        const errors: { [key: string]: boolean | { index: number; status: boolean } | undefined } = {};

        const requiredFields: (keyof IBookingManage)[] = ['surname', 'phoneNumber'];

        for (const key of requiredFields) {
            const value = booking[key];
            errors[key] = value === undefined || value === null || value === 0 || value.toString().trim() === '';
        }

        if (booking.bookedOffers?.length !== 0 && !!booking.bookedOffers?.length) {
            for (let index = 0; index < booking?.bookedOffers?.length; index++) {
                const bookedOffer = booking.bookedOffers[index];
                if (!bookedOffer.people) {
                    errors['people'] = { index, status: true };
                    setCurrentBookedOffer(booking.bookedOffers[index]);
                    break;
                }
            }
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (booking.email && !emailRegex.test(booking.email)) {
            errors['email'] = true;
        }

        setFieldErrors(errors);
        return errors;
    };

    const handleCheckName = async () => {
        try {
            setIsLoading(true);

            const errors = validateBooking();

            if (Object.values(errors).some((value) => value)) {
                setIsLoading(false);
                return;
            }

            const response = await apiService<IBooking & { errorCode: string }>(HttpMethod.GET, '/booking/check-booking-name', undefined, { name: booking.name, surname: booking.surname, date: booking.bookingDate, id: params.id });

            if (!!response.errorCode) {
                throw response;
            }

            if (response) {
                setIsModalOpen({ type: 'checkName', state: true, data: response })
                setIsLoading(false);
            } else {
                handleCheckReliability();
            }
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleCheckReliability = async () => {
        try {
            setIsLoading(true);

            // Check user reliability
            const response = await apiService<IUserReliability & { errorCode: string }>(HttpMethod.GET, '/booking/user-reliability', undefined, { name: booking.name, surname: booking.surname, phoneNumber: booking.phoneNumber });

            if (!!response.errorCode) {
                throw response;
            }

            if (!response.isReliable) {
                setIsModalOpen({ type: 'userReliability', state: true, data: response })
            } else {
                setIsModalOpen({ type: 'confirm', state: true })
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleActionBooking = async () => {
        try {
            setIsLoading(true);

            const body = { booking, dates: multipleDates };

            let response;

            if (params.id) {
                // Update
                response = await apiService<IBooking[] & { errorCode: string }>(HttpMethod.PUT, '/booking/update/' + params.id, body);
            } else {
                // Create
                response = await apiService<IBooking[] & { errorCode: string }>(HttpMethod.POST, '/booking/create', body);
            }

            if (!!response.errorCode) {
                throw response;
            }

            navigate('/dashboard/bookings/details/' + response[0]._id + '?bookingDate=' + response[0].bookingDate + '&refetch', { replace: true });

            setIsModalOpen({ type: null, state: false });
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const RenderBookedOffer = (bookedOffer: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>, index: number) => {
        const dailyEvent = dailyEvents?.find(dailyEvent => dailyEvent.offer?._id === bookedOffer.offer._id);
        let proposalsToRender: IProposal[] = [];

        switch (dailyEvent?.proposalOption) {
            case EventProposalOptions.COMBINED:
                proposalsToRender = (bookedOffer?.offer?.proposals ?? []).concat(dailyEvent?.proposals ?? []);
                break;
            case EventProposalOptions.CUSTOM:
                proposalsToRender = dailyEvent?.proposals ?? [];
                break;
            case EventProposalOptions.SERVICE:
                proposalsToRender = bookedOffer?.offer?.proposals ?? [];
                break;
            default:
                proposalsToRender = bookedOffer?.offer?.proposals ?? [];
                break;
        };

        return (
            <div key={index} className='flex flex-col bg-white w-full rounded-md shadow-md'>
                <div onClick={() => setCurrentBookedOffer(bookedOffer)} className={`flex w-full relative h-8 bg-primary text-base font-medium text-text_white justify-center items-center ${bookedOffer.offer._id === currentBookedOffer?.offer._id ? 'rounded-t-md' : 'rounded-md cursor-pointer'}`}>
                    <p>{bookedOffer.offer.name}</p>

                    {bookedOffer.offer._id === currentBookedOffer?.offer._id &&
                        <div className='absolute right-2 cursor-pointer' onClick={() => manageBookedOffers(undefined, index)}>
                            <DeleteBucketIcon height={20} width={20} />
                        </div>
                    }
                </div>

                {bookedOffer.offer._id === currentBookedOffer?.offer._id &&
                    <>
                        {!!offersCapacity && offersCapacity.find(offercapacity => offercapacity.offer._id === bookedOffer.offer._id)?.badPercent! >= 50 &&
                            <p className='text-sm font-semibold text-error text-center mt-1'>
                                Servizio al {offersCapacity.find(offersCapacity => offersCapacity.offer._id === bookedOffer.offer._id)?.badPercent}%
                            </p>
                        }

                        <div className='flex flex-col gap-4 p-4 pt-2'>
                            <div className='flex items-start justify-between gap-2 w-full'>

                                {/* N PERSONE */}
                                <InputForm fullWidth type='number' customStyle='w-full h-8' label='Persone' value={booking.bookedOffers[index]?.people} placeholder='00' setValue={(e) => updateBookedOffer('people', Number(e), index)} error={(typeof fieldErrors.people === 'object' && fieldErrors.people && fieldErrors.people.index === index && fieldErrors.people.status)} errorMessage='Campo obbligatorio.' />


                                {/* TURNO / SHIFT */}
                                {bookedOffer.offer.shifts.length > 0
                                    ? <SelectForm options={bookedOffer.offer.shifts.map(shift => ({ label: shift, value: shift }))} selectedOption={booking.bookedOffers[index]?.shift} setSelectedOption={(e) => updateBookedOffer('shift', e.value, index)} containerClassName='w-full' arrow={false} title='Turno' />
                                    : <InputForm fullWidth customStyle='w-full h-8' label='Turno' value={booking.bookedOffers[index]?.shift} placeholder='00:00' setValue={(e) => updateBookedOffer('shift', e, index)} />
                                }
                            </div>

                            {/* PERSONE VARIABILI */}
                            <RadioButton checked={booking.bookedOffers[index]?.isPeopleVariable} onChange={() => updateBookedOffer('isPeopleVariable', !booking.bookedOffers[index]?.isPeopleVariable, index)} label='Persone variabili' />

                            {bookedOffer.offer.options?.isEntertainment && <div className='grid grid-cols-3 items-center gap-2'>

                                {/* BAMBINI */}
                                <RadioButton label='Bambini' classname={`${(booking.bookedOffers[index]?.child ? 'col-span-1' : 'col-span-3')}`} checked={!!booking.bookedOffers[index]?.child} onChange={() => updateBookedOffer('child', !booking.bookedOffers[index]?.child, index)} />

                                {/* SEGGIOLINI */}
                                {booking.bookedOffers[index]?.child && <>
                                    <p className='text-xs text-right font-medium text-darkGray'>Seggiolini?</p>
                                    <InputForm fullWidth containerClassName={'col-span-1'} type='number' value={booking.bookedOffers[index]?.childSeats} setValue={(e) => updateBookedOffer('childSeats', Number(e), index)} placeholder='00' paddingY={1} />
                                </>}
                            </div>}

                            {/* TABELLA INFO PROPOSTE */}
                            {proposalsCapacity && <ProposalCapacityTable proposalsCapacity={proposalsCapacity.filter(proposalCapacity => proposalCapacity.offer._id === currentBookedOffer?.offer._id)} />}

                            {/* LISTA DELLE PROPOSTE */}
                            <div className='flex flex-col gap-1 select-none'>

                                <div className='grid grid-cols-3'>
                                    <p className="font-semibold text-text text-lg col-span-2">Proposte</p>
                                    <p className="font-semibold text-text text-lg">Quantità</p>
                                </div>

                                {proposalsToRender?.map((proposal, proposalIndex) => {
                                    const isSelected = bookedOffer?.proposals?.filter(prop => prop.proposal._id === proposal._id);

                                    return <div key={proposalIndex} className='grid grid-cols-3'>
                                        {/* PROPOSTA */}
                                        <RadioButton
                                            label={proposal?.name}
                                            classname='col-span-2'
                                            checked={!!isSelected?.length}
                                            onChange={() => proposalOnClick(isSelected, proposal, bookedOffer, index)}
                                        />

                                        {/* QUANTITA PROPOSTA */}
                                        {(!!isSelected?.length) &&
                                            <InputForm
                                                fullWidth
                                                containerClassName={'col-span-1'}
                                                type='number'
                                                value={bookedOffer.proposals?.find(prop => prop.proposal._id === proposal._id)?.quantity}
                                                setValue={(e) => handleQuantityChange(Number(e), proposal._id!, bookedOffer, index)}
                                                placeholder='00'
                                                paddingY={1}
                                                isDisabled={!isSelected}
                                            />
                                        }
                                    </div>
                                })}

                                {/* PROPOSTA DEFAULT (DA DEFINIRE) */}
                                <div className='grid grid-cols-3'>
                                    <RadioButton label='Da definire' classname='col-span-2'
                                        checked={booking.bookedOffers[index]?.proposals?.filter(prop => !prop.proposal.deleted).length === 0}
                                        onChange={() => updateBookedOffer('proposals', [], index)} />
                                </div>
                            </div>

                            {/* MESSAGGIO WARNING SOLO SE PROPOSTE ANCORA NON AGGIORNATE - PRENOTAZIONE CON PROPOSTE ELIMINATE ASSEGNATE */}
                            {bookedOffer.proposals.find(prop => prop.proposal.deleted) && bookedOffer.proposals.filter(prop => !prop.proposal.deleted).length === 0 &&
                                <p className="text-xs md:text-sm font-medium text-warning text-center">
                                    Questa prenotazione ha proposte eliminate.<br />Ricontrolla e assegnane delle nuove se necessario!
                                </p>
                            }

                            {/* NOTE */}
                            <InputForm paddingY={1} label='Note' placeholder='Scrivi una nota' value={booking.bookedOffers[index]?.notes} setValue={(value) => updateBookedOffer('notes', value, index)} multiline switchBtn isSwitchActive={!!booking.bookedOffers[index]?.notes} />

                            {/* TORTA */}
                            <SelectForm title='Torta' options={cakeTypeOptions} selectedOption={mapCakeType(booking.bookedOffers[index]?.cakeType)} setSelectedOption={(value) => updateBookedOffer('cakeType', value.value, index)} />

                            {/* NOTE TORTA */}
                            {(booking.bookedOffers[index]?.cakeType !== 2) && <InputForm paddingY={1} placeholder='Note torta' value={booking.bookedOffers[index]?.cakeNote} setValue={(value) => updateBookedOffer('cakeNote', value, index)} multilineRows={3} multiline />}
                        </div>
                    </>
                }
            </div>
        )
    };

    return (
        <>
            <div className='flex flex-col gap-4'>
                <div className='flex items-center justify-between'>
                    <p className='text-primary text-2xl font-bold md:whitespace-nowrap w-full truncate'>Nuova prenotazione</p>
                    {/* PRENOTAZIONE IMPORTANTE */}
                    <div onClick={() => handleManageBooking('isImportant', !booking.isImportant)} title={booking.isImportant ? 'Importante' : 'Non importante'} className='cursor-pointer'>
                        {booking.isImportant ? <FullStarIcon /> : <EmptyStarIcon />}
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-x-4 justify-between w-full'>
                    {/* COGNOME */}
                    <InputForm paddingY={1} label='Cognome' placeholder='Cognome' value={booking.surname} setValue={(value) => handleManageBooking('surname', value)} error={!!fieldErrors.surname} errorMessage='Campo obbligatorio.' customStyle='shadow' />
                    {/* NOME */}
                    <InputForm paddingY={1} label='Nome' placeholder='Nome' value={booking.name} setValue={(value) => handleManageBooking('name', value)} customStyle='shadow' />
                </div>

                <div className='grid grid-cols-2 gap-x-4 justify-between w-full'>
                    {/* TELEFONO */}
                    <InputForm paddingY={1} label='Telefono' placeholder='+39' value={booking.phoneNumber} setValue={(value) => handleManageBooking('phoneNumber', value)} error={!!fieldErrors.phoneNumber} errorMessage='Campo obbligatorio.' customStyle='shadow' />
                    {/* ETA */}
                    <InputForm type={'number'} paddingY={1} label='Etá' placeholder='00' value={booking.age} setValue={(value) => handleManageBooking('age', value)} customStyle='shadow' />
                </div>

                {/* EMAIL */}
                <InputForm paddingY={1} label='Email' placeholder='mariorossi@gmail.com' value={booking.email} setValue={(value) => handleManageBooking('email', value)} error={!!fieldErrors.email} errorMessage='Inserisci una email valida' customStyle='shadow' />

                {/* DATA DELLA PRENOTAZIONE */}
                <div className='flex w-full flex-col gap-2'>
                    <p className="font-semibold text-text text-lg">Data</p>
                    <div className='flex items-center w-full gap-2 justify-between cursor-default select-none'>
                        <div className='h-7 w-full bg-primary text-text_white flex justify-center items-center font-medium text-xs rounded'>{moment(booking.bookingDate).format('D')}</div>
                        <div className='h-7 w-full bg-primary text-text_white flex justify-center items-center font-medium text-xs rounded'>{moment(booking.bookingDate).format('MM')}</div>
                        <div className='h-7 w-full bg-primary text-text_white flex justify-center items-center font-medium text-xs rounded'>{moment(booking.bookingDate).format('YYYY')}</div>
                        <div className='cursor-pointer' onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
                            <CalendarIcon />
                        </div>
                    </div>

                    {isCalendarOpen &&
                        <Calendar
                            selectedDate={booking.bookingDate}
                            handlePickDate={() => {
                                setIsCalendarOpen(false);
                                setMultipleDates([]);
                            }}
                            setSelectedDate={(date) => handleManageBooking('bookingDate', date)}
                            minDate={moment().utc(true).toISOString()}
                        />}
                </div>


                {/* SERVIZI */}
                <div className='flex w-full flex-col gap-4'>

                    {/* FORM PER LA SCELTA DEL SERVIZIO DA AGGIUNGERE */}
                    <SelectForm
                        title='Servizi'
                        options={offers
                            .filter(offer => (offer.isEnabled))
                            .map((offer: IOffer) => { return { label: offer?.name, value: offer } })
                        }
                        selectedOption={undefined}
                        setSelectedOption={(option) => manageBookedOffers(option.value)}
                        arrow={false}
                        addIcon
                    />

                    {/* CARD DEI SERVIZI SCELTI */}
                    {booking?.bookedOffers?.length > 0 && booking?.bookedOffers?.map((booking, index) => RenderBookedOffer(booking, index))}
                </div>
            </div>

            <div className='flex flex-col gap-2'>
                {!booking?.bookedOffers?.length
                    ? <p className="text-darkGray sm:text-sm md:text-xxs font-medium text-center">Scegli almeno un servizio</p>
                    : !params?.id && <CustomButton padding='p-1.5' label={`Prenotazioni multiple ${(multipleDates.length > 0) ? multipleDates.length : ''}`} color='bg-primary' onClickHandler={() => setIsModalOpen({ type: 'multiBookings', state: true })} />
                }

                {Object.values(fieldErrors).some((value) => value) && <p className='text-error text-sm text-center font-medium'>Errore, controlla i campi.</p>}

                <div className='flex flex-col lg:flex-row justify-between mt-2 gap-2'>
                    <CustomButton
                        label='Annulla'
                        color='bg-white'
                        textColor='text-darkGray'
                        className='border-2 border-darkGray'
                        onClickHandler={() => {
                            if (params.id) {
                                navigate('/dashboard/bookings/details/' + params.id + '?bookingDate=' + booking.bookingDate, { replace: true });
                            } else {
                                navigate('/dashboard/bookings?bookingDate=' + searchParams.get('bookingDate'), { replace: true });
                            }
                        }}
                    />
                    <CustomButton
                        disabled={!booking?.bookedOffers?.length || isLoading}
                        color='bg-primary'
                        label='Conferma'
                        onClickHandler={handleCheckName}
                    />
                </div>
            </div>

            <Modal isOpen={isModalOpen.state} onClose={() => setIsModalOpen({ type: null, state: false })} classNameChildren='w-full h-full'>
                {(isModalOpen.type === 'checkName') && <div className='flex flex-col gap-4 p-4'>
                    <p className='text-text font-medium text-center'>Prenotazione esistente per <strong className='capitalize'>{isModalOpen?.data?.name} {isModalOpen?.data?.surname}</strong></p>
                    <p className='text-sm text-darkGray font-medium whitespace-pre-line text-center'>
                        {isModalOpen?.data?.bookingRequest?.autoBookingType !== AutoBookingType.AUTOMATIC
                            ? "Decidi se modificare la prenotazione esistente o cambiare il nome a quella corrente."
                            : "La prenotazione con questo nome è stata creata online e non può essere modificata.\nPer favore, scegli un nome diverso per la tua nuova prenotazione per evitare confusione."
                        }
                    </p>
                    <div className={`flex gap-2 justify-between`}>
                        {isModalOpen?.data?.bookingRequest?.autoBookingType !== AutoBookingType.AUTOMATIC &&
                            <CustomButton color='bg-info' className='capitalize' label={"Modifica esistente"} onClickHandler={() => navigate('/dashboard/bookings/details/' + isModalOpen?.data?._id + '?bookingDate=' + searchParams.get('bookingDate'), { replace: true })} />
                        }
                        <CustomButton color='bg-primary' label='Cambia corrente' fullWidth={isModalOpen?.data?.bookingRequest?.autoBookingType !== AutoBookingType.AUTOMATIC ? false : true} onClickHandler={() => setIsModalOpen({ type: null, state: false })} />
                    </div>
                </div>}

                {(isModalOpen.type === 'multiBookings') && <RecurringEventScheduler onSchedule={setMultipleDates} startingDate={booking.bookingDate} onClose={() => setIsModalOpen({ type: null, state: false })} />}
                {(isModalOpen.type === 'userReliability') && <UserReliability data={isModalOpen.data} onConfirm={handleActionBooking} onCancel={() => setIsModalOpen({ type: null, state: false, data: undefined })} />}
                {(isModalOpen.type === 'confirm') && <BookingRecap booking={booking} onClose={() => setIsModalOpen({ type: null, state: false })} onAction={handleActionBooking} isLoading={isLoading} />}
            </Modal>
        </>
    )
}

export default BookingCreate;