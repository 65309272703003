import React from 'react'
import CustomButton from './CustomButton'
import Text from './Text'
import moment from 'moment'
import { mapCakeType } from '../types/enum/cakeType'
import { IBookingManage } from '../views/booking/booking/BookingCreate'
import { useParams } from 'react-router-dom'

interface IProps {
    booking: IBookingManage,
    onClose: () => void,
    onAction: () => void,
    isLoading: boolean
}

const BookingRecap: React.FC<IProps> = ({ booking, onClose, onAction, isLoading }) => {
    const params = useParams();

    return (
        <div className='flex flex-col gap-6 p-4 w-full'>
            <p className='text-text font-medium text-2xl'>Prenotazione <strong>{booking.name + ' ' + booking.surname}</strong></p>
            <div className='flex flex-col gap-2'>
                <Text title='Data' label={moment(booking.bookingDate).format('ddd DD MMM YYYY')} />
                <Text title='Telefono' label={booking.phoneNumber} />
                {booking.email && <Text title='Email' label={booking.email} className='normal-case' />}
            </div>

            <div className='w-full flex flex-col gap-2'>
                <p className='font-semibold text-text text-left text-base'>Servizi:</p>
                <div className='grid grid-cols-1 gap-2'>
                    {booking?.bookedOffers?.map((bookedOffer, index) => {
                        return <div key={index} className='bg-background p-2 shadow rounded-md gap-2 flex flex-col w-full'>
                            <div className='flex justify-between items-baseline gap-2'>
                                <p className='font-semibold text-xl whitespace-nowrap'>{bookedOffer.offer.name}, <span className='font-normal text-sm'>{bookedOffer.shift ?? 'No turno'}</span></p>
                                <p className='text-sm font-medium text-right'>x {bookedOffer.people}</p>
                            </div>

                            {!!mapCakeType(bookedOffer.cakeType) && <Text title={mapCakeType(bookedOffer.cakeType)} label={bookedOffer.cakeNote ?? 'Nessuna nota sulla torta.'} />}

                            <div className='w-full border-b border-darkGray' />

                            <div>
                                <p className='font-semibold text-text'>Proposte</p>
                                {!!bookedOffer?.proposals?.length
                                    ? bookedOffer?.proposals
                                        .filter(proposal => !proposal.proposal.deleted)
                                        .map((proposal, index) => {
                                            return <div key={index} className='flex justify-between items-baseline'>
                                                <p className='text-sm font-medium text-text flex gap-2 items-baseline'>{proposal.proposal.name}</p>
                                                <p className='text-sm font-medium text-text'>x {proposal.quantity}</p>
                                            </div>
                                        })
                                    : <p className='font-semibold text-text py-4 text-sm text-center'>Da definire</p>
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>

            <div className='flex gap-2 justify-between'>
                <CustomButton label='Annulla' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={onClose} />
                <CustomButton disabled={isLoading} label={params.id ? 'Modifica' : 'Crea'} color='bg-primary' onClickHandler={onAction} />
            </div>
        </div>)
}

export default BookingRecap