import React, { useState } from 'react';
import moment from 'moment';
import InputForm from './InputForm';
import SelectForm from './SelectForm';
import RadioButton from './RadioButton';
import Calendar from './Calendar';
import CustomButton from './CustomButton';

interface RecurringEventSchedulerProps {
    onSchedule: (dates: string[]) => void;
    startingDate: string;
    onClose?: () => void;
    warningMessage?: string;
}

interface RecurringEventSchedulerState {
    startDate: string;
    recurrenceType: 'Giorno/i' | 'Settimana/e';
    recurrenceCount: number;
    selectedDays: number[];
    endCondition: 'specificDate' | 'occurrences';
    endDate: string;
    totalOccurrences: number;
}

const RecurringEventScheduler: React.FC<RecurringEventSchedulerProps> = ({ onSchedule, startingDate, onClose, warningMessage }) => {
    const weekdays = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>();
    const [state, setState] = useState<RecurringEventSchedulerState>({
        startDate: startingDate,
        recurrenceType: 'Settimana/e',
        recurrenceCount: 1,
        selectedDays: [],
        endCondition: 'occurrences',
        endDate: startingDate,
        totalOccurrences: 5,
    });

    const handleSchedule = () => {
        setFieldErrors({});

        // Validate the state before scheduling
        if (state.recurrenceCount < 1) {
            setFieldErrors({ 'recurrenceCount': true });
            return;
        }

        if (state.recurrenceType === 'Settimana/e' && state.selectedDays.length < 1) {
            setFieldErrors({ 'selectedDay': true });
            return;
        }

        if (state.endCondition === 'occurrences' && state.totalOccurrences < 1) {
            setFieldErrors({ 'totalOccurrences': true });
            return;
        }

        if (state.endCondition === 'specificDate' && moment(state.endDate).isSameOrBefore(moment(startingDate))) {
            setFieldErrors({ 'endDate': true });
            return;
        }

        let scheduledDates: string[] = [];
        if (state.endCondition === 'occurrences') {
            if (state.recurrenceType === 'Giorno/i') {
                scheduledDates = calculateDailyOccurrences();
            } else if (state.recurrenceType === 'Settimana/e') {
                scheduledDates = calculateWeeklyOccurrences();
            }
        } else if (state.endCondition === 'specificDate') {
            if (state.recurrenceType === 'Giorno/i') {
                scheduledDates = scheduleDailyEventsUntilSpecificDate();
            } else if (state.recurrenceType === 'Settimana/e') {
                scheduledDates = scheduleWeeklyEventsUntilSpecificDate();
            }
        }

        const filteredDates = scheduledDates.filter(date => !moment(date).isSame(startingDate, 'days'))

        onSchedule(filteredDates);
        onClose && onClose();
    };

    const calculateDailyOccurrences = () => {
        const { startDate, recurrenceCount, totalOccurrences } = state;
        const scheduledDates = [];
        let currentDate = moment(startDate);

        for (let i = 0; i < totalOccurrences; i++) {
            scheduledDates.push(currentDate.toISOString());
            currentDate.add(recurrenceCount, 'day');
        }

        return scheduledDates;
    };


    const scheduleDailyEventsUntilSpecificDate = () => {
        const { startDate, recurrenceCount, endDate } = state;
        const scheduledDates = [];
        let currentDate = moment(startDate);

        while (currentDate.isSameOrBefore(endDate, 'day')) {
            scheduledDates.push(currentDate.toISOString());
            currentDate.add(recurrenceCount, 'day');
        }

        return scheduledDates;
    };

    const calculateWeeklyOccurrences = () => {
        const { startDate, recurrenceCount, selectedDays, totalOccurrences } = state;
        const startDateObj = moment(startDate);
        const occurrences = [];
        let currentDate = moment(startDateObj);
        let count = 0;

        while (count < totalOccurrences) {
            for (let day = 0; day < 7; day++) {
                if (selectedDays.includes(currentDate.isoWeekday() % 7)) {
                    occurrences.push(currentDate.toISOString());
                    count++;
                }
                if (count >= totalOccurrences) {
                    break;
                }
                currentDate.add(1, 'day');
            }
            currentDate.add((recurrenceCount - 1), 'week');
        }
        return occurrences;
    };


    const scheduleWeeklyEventsUntilSpecificDate = () => {
        const { startDate, recurrenceCount, selectedDays, endDate } = state;
        const startDateObj = moment(startDate);
        const endDateObj = moment(endDate);
        const scheduledEvents = [];
        let currentDate = moment(startDateObj);

        const selectedDaysSet = new Set(selectedDays);

        while (currentDate.isSameOrBefore(endDateObj, 'day')) {
            for (let day = 0; day < 7; day++) {
                if (selectedDaysSet.has(currentDate.isoWeekday() % 7)) {
                    scheduledEvents.push(currentDate.toISOString());
                }
                currentDate.add(1, 'day');
            }

            currentDate.add(recurrenceCount - 1, 'week');
        }
        return scheduledEvents;
    };

    return (
        <div className="flex flex-col gap-2 bg-white select-none p-4">
            <p className="text-xl font-semibold">Ricorrenza personalizzata</p>

            {warningMessage && <p className='text-warning text-xs font-medium text-center'>{warningMessage}</p>}

            <p className='text-text font-medium items-center flex gap-2'>
                Data inizio:
                <span className='text-sm'>{moment(startingDate).format('dddd DD MMMM YYYY')}</span>
            </p>

            <div className='flex items-center gap-4'>
                <p className='text-text font-medium whitespace-nowrap'>Ripeti ogni</p>
                <InputForm type='number' min={1} customStyle='w-full' value={state.recurrenceCount} setValue={(e) => setState({ ...state, recurrenceCount: e })} backgroundColor='bg-background' />
                <SelectForm className='w-full' containerClassName='w-full' options={[{ label: 'Giorno/i', value: 'Giorno/i' }, { label: 'Settimana/e', value: 'Settimana/e' }]} selectedOption={state.recurrenceType} setSelectedOption={(e) => setState({ ...state, recurrenceType: e.value as 'Giorno/i' | 'Settimana/e' })} />
            </div>

            {(fieldErrors?.recurrenceCount) && <p className="text-red-500 text-xxs font-medium">Selezionare almeno una ripetizione</p>}

            {state.recurrenceType === 'Settimana/e' && (
                <div className='flex flex-col gap-1'>
                    <p className="text-text font-medium">Si ripete il:</p>
                    {(fieldErrors?.selectedDay) && <p className="text-red-500 text-xxs font-medium">Selezionare almeno un giorno della settimana</p>}
                    {weekdays.map((day: string, index: number) => {
                        return <RadioButton key={index} checked={state.selectedDays.includes(index)} label={day} onChange={() =>
                            setState({
                                ...state,
                                selectedDays: state.selectedDays.includes(index)
                                    ? state.selectedDays.filter((day) => day !== index)
                                    : [...state.selectedDays, index]
                            })} />
                    })}
                </div>
            )}

            <p className="text-text font-medium">Fine:</p>
            {(fieldErrors?.endDate) && <p className="text-red-500 text-xxs font-medium">Inserire una data successiva a quella di inizio</p>}
            {(fieldErrors?.totalOccurrences) && <p className="text-red-500 text-xxs font-medium">Impossibile inserire occorrenze inferiori ad 1</p>}
            <div className='flex flex-col gap-4'>
                <div className='flex items-center justify-between'>
                    <RadioButton label='Dopo' checked={state.endCondition === 'occurrences'} onChange={() => setState({ ...state, endCondition: 'occurrences' })} />
                    <div className='flex items-center justify-end gap-2 w-1/2'>
                        <InputForm type='number' min={1} customStyle='w-full text-center' value={state.totalOccurrences} setValue={(e) => setState({ ...state, totalOccurrences: e })} isDisabled={state.endCondition !== 'occurrences'} />
                        <p className='text-text font-medium text-sm'>occorrenze</p>
                    </div>
                </div>

                <div className='flex items-start justify-between'>
                    <RadioButton label='Data' checked={state.endCondition === 'specificDate'} onChange={() => setState({ ...state, endCondition: 'specificDate' })} />
                    <p className='h-8 w-1/2 whitespace-nowrap px-2 bg-primary text-text_white flex justify-center items-center font-medium text-xs rounded'>{moment(state.endDate).format('dddd DD MMMM YYYY')}</p>
                </div>
                {(state.endCondition === 'specificDate') &&
                    <div className='w-full bg-background p-1 rounded-md mb-4'>
                        <Calendar selectedDate={state.endDate} setSelectedDate={(e) => setState({ ...state, endDate: e })} minDate={startingDate} />
                    </div>
                }
            </div>

            <div className='flex items-center gap-4 justify-between'>
                <CustomButton label='Elimina date' color='bg-background' textColor='text-secondary' onClickHandler={() => {
                    onSchedule([])
                    setFieldErrors({})
                }} />
                <CustomButton label='Fine' color='bg-primary' onClickHandler={handleSchedule} />
            </div>
        </div>
    );
};

export default RecurringEventScheduler;
