import React, { useEffect, useRef } from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import useFetch from '../../../../hooks/useFetch';
import { IEvent } from '../../../../types/interfaces/event.interface';
import { apiService, HttpMethod } from '../../../../services/apiService';
import { useHorizontalScroll } from '../../../../hooks/useHorizontalScroll';
import { EventProposalOptions } from '../../../../types/enum/eventProposalsOptions.enum';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';
import MenuProposalAccordion from '../details/details_pages/MenuProposalAccordion';

interface IProps {
    newBookingRequest: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    handleNext: (fields: (keyof IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>)[], value: any, step: number) => void
};

const Step4Update: React.FC<IProps> = ({ newBookingRequest, handleNext }) => {
    const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    useHorizontalScroll(containerRef);


    const { data: events } = useFetch<{ offerEvent: IEvent<string, IProposal> | undefined, dailyEvent: IEvent<string, IProposal> | undefined }>(
        () => apiService(HttpMethod.POST, `/event/public/get/dailyEvent`, { date: newBookingRequest.date, structureId: newBookingRequest.structure, offer: newBookingRequest.offer._id }),
        undefined,
        [newBookingRequest.date],
        !!newBookingRequest.date && !!newBookingRequest.structure && !!newBookingRequest.offer
    );

    // const proposals = !!events?.offerEvent?.proposals?.length ? events?.offerEvent?.proposals : newBookingRequest.offer.autoBookingOptions?.proposals;
    let proposals: IProposal[] = [];

    switch (events?.offerEvent?.proposalOption) {
        case EventProposalOptions.COMBINED:
            proposals = (newBookingRequest.offer.autoBookingOptions?.proposals ?? [] as any).concat(events?.offerEvent?.proposals ?? []);
            break;
        case EventProposalOptions.CUSTOM:
            proposals = events?.offerEvent?.proposals ?? [];
            break;
        case EventProposalOptions.SERVICE:
            proposals = newBookingRequest.offer.autoBookingOptions?.proposals ?? [] as any;
            break;
        default:
            proposals = newBookingRequest.offer.autoBookingOptions?.proposals ?? [] as any;
            break;
    };

    useEffect(() => {
        const offerPoposals: string[] = newBookingRequest.offer.autoBookingOptions?.proposals.map((proposal) => (proposal as IProposal)._id) as string[];
        const selectedIndex = offerPoposals.indexOf(newBookingRequest.proposal._id!);

        if (selectedIndex !== -1 && buttonRefs.current[selectedIndex] && !!buttonRefs) {
            setTimeout(() => {
                buttonRefs?.current[selectedIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }, 1000);
        }
    }, [newBookingRequest.proposal]);

    return (
        <div ref={containerRef} className='flex w-full overflow-x-auto hidden-scrollbar px-4 gap-2'>
            {proposals?.map((proposal, index) =>
                <button
                    key={index}
                    ref={(el) => (buttonRefs.current[index] = el)}
                    onClick={() => handleNext(["proposal"], proposal, 4)}
                    className={`${(proposal._id === newBookingRequest.proposal._id) ? 'border-2 border-primary text-primary' : 'text-text'} bg-white px-4 py-2 rounded-md shadow text-left flex flex-col justify-start h-36 aspect-video`}
                >
                    <p className='text-xl font-semibold capitalize'>{proposal.name}</p>
                    <p className='text-sm font-medium line-clamp-5'>{proposal.description}</p>
                </button>
            )}
        </div>
    )
}

export default Step4Update
