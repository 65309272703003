import { IOffer, IRoom } from "../../types/interfaces/offer.interface";
import { ITimeTable } from "../../types/interfaces/timeTable.interface";

export interface IOfferState {
  offers: IOffer[];
  manageOffer: {
    info: {
      name: string;
      description: string;
      capacity: number;
      shifts: string[];
      positions: string[];
      rooms: IRoom[];
      isMultiple: boolean;
      isForEach: boolean;
      isEntertainment: boolean;
      isAgeSensitive: boolean;
      timetable: ITimeTable[];
    };
    isCreate: boolean;
    activeStep: number;
    offerSession: string;
  };
}

export const offerState: IOfferState = {
  offers: [],
  manageOffer: {
    info: {
      name: "",
      description: "",
      capacity: 0,
      shifts: [],
      positions: [],
      rooms: [],
      isMultiple: false,
      isForEach: false,
      isEntertainment: false,
      isAgeSensitive: false,
      timetable: [
        {
          day: "lunedi",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        },
        {
          day: "martedi",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        },
        {
          day: "mercoledi",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        },
        {
          day: "giovedi",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        },
        {
          day: "venerdi",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        },
        {
          day: "sabato",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        },
        {
          day: "domenica",
          openingAt: "08:00",
          closedAt: "18:00",
          isAvailable: true,
        }
      ]
    },
    isCreate: false,
    activeStep: 0,
    offerSession: "",
  },
};
