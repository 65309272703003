import React from 'react'

interface IProps {
    badPerc?: number;
    goodPerc: number;
}

const ProgressBar: React.FC<IProps> = ({ goodPerc, badPerc }) => {
    return (
        <div className="relative w-full h-2 bg-background2 rounded-xl shadow mt-6">
            <div className={`absolute h-full ${!!badPerc ? "bg-error" : "bg-success"} flex items-center justify-center rounded-xl`} style={{ width: `${badPerc ?? goodPerc}%` }} />
            {badPerc
                ? <div className="absolute bg-white px-2 py-1 shadow-md rounded-md border border-background2 -translate-x-1/2 -translate-y-1/2 bottom-0" style={{ left: `${badPerc}%` }} >
                    <p className='text-xxs font-medium text-text whitespace-nowrap'>{badPerc.toFixed(1)} %</p>
                </div>
                : <div className="absolute bg-white px-2 py-1 shadow-md rounded-md border border-background2 -translate-x-1/2 -translate-y-1/2 bottom-0" style={{ left: `${goodPerc}%` }} >
                    <p className='text-xxs font-medium text-text whitespace-nowrap'>{goodPerc.toFixed(1)} %</p>
                </div>}
        </div>
    )
}

export default ProgressBar