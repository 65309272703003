import { createSlice, Slice } from "@reduxjs/toolkit";
import { notificationState } from "./notificationState";
import { INotificationState } from "./notificationState";

export const NotificationSlice: Slice<INotificationState> = createSlice({
    name: "notification",
    initialState: notificationState,
    reducers: {
      replaceNotifications: (state, action) => {
        state.notifications = action.payload;
      },
      addNotifications: (state, action) => {
          // handle array of notifications or single notifications
          if (Array.isArray(action.payload.notifications)) {
              state.notifications.push(...action.payload.notifications);
          } else {
              state.notifications.push(action.payload.notifications);
          }
      }  
    }
});

export const { replaceNotifications, addNotifications } = NotificationSlice.actions;
export default NotificationSlice.reducer;
