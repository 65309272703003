export const AscendingSortIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.40369 -7.3771e-07C8.61204 -7.19495e-07 8.8204 0.0462954 9.02876 0.138888C9.23711 0.23148 9.39917 0.358795 9.51492 0.520832L16.3559 7.36111C16.68 7.63889 16.8421 7.99769 16.8421 8.4375C16.8421 8.87731 16.6916 9.24769 16.3907 9.54861C16.0897 9.84954 15.7135 10 15.2621 10C14.8106 10 14.4344 9.84954 14.1335 9.54861L8.40369 3.81944L2.6739 9.54861C2.37294 9.84954 2.00832 10 1.58003 10C1.15174 10 0.775545 9.84954 0.451437 9.54861C0.150478 9.24768 -1.80869e-06 8.87153 -1.76923e-06 8.42014C-1.72977e-06 7.96875 0.150479 7.59259 0.451437 7.29167L7.25773 0.520832C7.39663 0.358795 7.57026 0.23148 7.77862 0.138888C7.98698 0.0462953 8.19533 -7.55925e-07 8.40369 -7.3771e-07Z" fill="#5B2FF6" />
            <path d="M8.43841 30C8.23006 30 8.0217 29.9537 7.81335 29.8611C7.60499 29.7685 7.44294 29.6412 7.32718 29.4792L0.486164 22.6389C0.162055 22.3611 0 22.0023 0 21.5625C0 21.1227 0.150479 20.7523 0.451438 20.4514C0.752396 20.1505 1.12859 20 1.58003 20C2.03147 20 2.40767 20.1505 2.70863 20.4514L8.43841 26.1806L14.1682 20.4514C14.4692 20.1505 14.8338 20 15.2621 20C15.6904 20 16.0666 20.1505 16.3907 20.4514C16.6916 20.7523 16.8421 21.1285 16.8421 21.5799C16.8421 22.0313 16.6916 22.4074 16.3907 22.7083L9.58437 29.4792C9.44547 29.6412 9.27184 29.7685 9.06348 29.8611C8.85513 29.9537 8.64677 30 8.43841 30Z" fill="#5B2FF6" fillOpacity="0.25" />
        </svg>
    );
};

export const DescendingSortIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.40369 -7.3771e-07C8.61204 -7.19495e-07 8.8204 0.0462954 9.02876 0.138888C9.23711 0.23148 9.39917 0.358795 9.51492 0.520832L16.3559 7.36111C16.68 7.63889 16.8421 7.99769 16.8421 8.4375C16.8421 8.87731 16.6916 9.24769 16.3907 9.54861C16.0897 9.84954 15.7135 10 15.2621 10C14.8106 10 14.4344 9.84954 14.1335 9.54861L8.40369 3.81944L2.6739 9.54861C2.37294 9.84954 2.00832 10 1.58003 10C1.15174 10 0.775545 9.84954 0.451437 9.54861C0.150478 9.24768 -1.80869e-06 8.87153 -1.76923e-06 8.42014C-1.72977e-06 7.96875 0.150479 7.59259 0.451437 7.29167L7.25773 0.520832C7.39663 0.358795 7.57026 0.23148 7.77862 0.138888C7.98698 0.0462953 8.19533 -7.55925e-07 8.40369 -7.3771e-07Z" fill="#5B2FF6" fillOpacity="0.25" />
            <path d="M8.43841 30C8.23006 30 8.0217 29.9537 7.81335 29.8611C7.60499 29.7685 7.44294 29.6412 7.32718 29.4792L0.486164 22.6389C0.162055 22.3611 0 22.0023 0 21.5625C0 21.1227 0.150479 20.7523 0.451438 20.4514C0.752396 20.1505 1.12859 20 1.58003 20C2.03147 20 2.40767 20.1505 2.70863 20.4514L8.43841 26.1806L14.1682 20.4514C14.4692 20.1505 14.8338 20 15.2621 20C15.6904 20 16.0666 20.1505 16.3907 20.4514C16.6916 20.7523 16.8421 21.1285 16.8421 21.5799C16.8421 22.0313 16.6916 22.4074 16.3907 22.7083L9.58437 29.4792C9.44547 29.6412 9.27184 29.7685 9.06348 29.8611C8.85513 29.9537 8.64677 30 8.43841 30Z" fill="#5B2FF6" />
        </svg>
    );
};

export const DefaultSortIcon = () => {
    return (

        <svg width="17" height="17" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.40369 -7.3771e-07C8.61204 -7.19495e-07 8.8204 0.0462954 9.02876 0.138888C9.23711 0.23148 9.39917 0.358795 9.51492 0.520832L16.3559 7.36111C16.68 7.63889 16.8421 7.99769 16.8421 8.4375C16.8421 8.87731 16.6916 9.24769 16.3907 9.54861C16.0897 9.84954 15.7135 10 15.2621 10C14.8106 10 14.4344 9.84954 14.1335 9.54861L8.40369 3.81944L2.6739 9.54861C2.37294 9.84954 2.00832 10 1.58003 10C1.15174 10 0.775545 9.84954 0.451437 9.54861C0.150478 9.24768 -1.80869e-06 8.87153 -1.76923e-06 8.42014C-1.72977e-06 7.96875 0.150479 7.59259 0.451437 7.29167L7.25773 0.520832C7.39663 0.358795 7.57026 0.23148 7.77862 0.138888C7.98698 0.0462953 8.19533 -7.55925e-07 8.40369 -7.3771e-07Z" fill="#5B2FF6" fillOpacity="0.25" />
            <path d="M8.43841 30C8.23006 30 8.0217 29.9537 7.81335 29.8611C7.60499 29.7685 7.44294 29.6412 7.32718 29.4792L0.486164 22.6389C0.162055 22.3611 0 22.0023 0 21.5625C0 21.1227 0.150479 20.7523 0.451438 20.4514C0.752396 20.1505 1.12859 20 1.58003 20C2.03147 20 2.40767 20.1505 2.70863 20.4514L8.43841 26.1806L14.1682 20.4514C14.4692 20.1505 14.8338 20 15.2621 20C15.6904 20 16.0666 20.1505 16.3907 20.4514C16.6916 20.7523 16.8421 21.1285 16.8421 21.5799C16.8421 22.0313 16.6916 22.4074 16.3907 22.7083L9.58437 29.4792C9.44547 29.6412 9.27184 29.7685 9.06348 29.8611C8.85513 29.9537 8.64677 30 8.43841 30Z" fill="#5B2FF6" fillOpacity="0.25" />
        </svg>
    )
}