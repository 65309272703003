import { useEffect, useState } from 'react'
import CustomButton from '../../components/CustomButton'
import InputForm from '../../components/InputForm';
import { IEvent } from '../../types/interfaces/event.interface';
import useComponentVisible from '../../hooks/useComponentVisible';
import { CalendarIcon } from '../../utils/icons/CalendarIcon';
import moment from 'moment';
import { Form, useActionData, useLoaderData, useNavigate, useNavigation, useParams, useSearchParams, useSubmit } from 'react-router-dom';
import { IOffer } from '../../types/interfaces/offer.interface';
import RadioButton from '../../components/RadioButton';
import { AddIcon } from '../../utils/icons/AddIcon';
import { IProposal } from '../../types/interfaces/proposal.interface';
import Modal from '../../components/Modal';
import RecurringEventScheduler from '../../components/RecurringEventScheduler';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import { EventProposalOptions } from '../../types/enum/eventProposalsOptions.enum';
import { CrossIcon } from '../../utils/icons/CrossIcon';
import { colorPalette } from '../../types/enum/colorPalette';
import ManageProposal from '../offer/proposals/ManageProposal';
import PageTitle from '../../components/PageTitle';
import DatePicker from '../../components/DatePicker';

const EventCreate = () => {
    const { offers, fetchedEvent } = useLoaderData() as { offers: IOffer[], fetchedEvent?: { event: IEvent<string, IProposal, string>, eventsGrouped: IEvent<string, IProposal, string>[] } };
    const response = useActionData() as any;
    const params = useParams();
    const submit = useSubmit();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { showErrorModal } = useModalDispatcher();
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { isComponentVisible: isProposalOpen, setIsComponentVisible: setIsProposalOpen } = useComponentVisible(false);
    const { isComponentVisible: isMultipleDateOpen, setIsComponentVisible: setIsMultipleDateOpen } = useComponentVisible(false);

    const [event, setEvent] = useState(fetchedEvent?.event ?? { date: moment(searchParams.get("date")).toDate(), proposalOption: EventProposalOptions.SERVICE } as IEvent<string, IProposal, string>);
    const [proposal, setProposal] = useState<IProposal | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [multipleDates, setMultipleDates] = useState<string[]>(fetchedEvent?.eventsGrouped.map(event => moment(event.date).toISOString()) ?? []);

    useEffect(() => {
        if (!!response?.errorCode) {
            showErrorModal(response.status, response.errorCode, response.message);
        }
    }, [response]);

    const handleManageEvent = <K extends keyof IEvent>(field: K, value: IEvent[K] | string) => {
        if (field === "date") {
            searchParams.set("date", moment(value).toISOString());
            setSearchParams(searchParams);

            setMultipleDates([]);
        };

        setEvent(prevState => {
            if (field === "proposalOption" && value === EventProposalOptions.SERVICE) {
                const { proposals, ...newState } = prevState;
                return { ...newState, [field]: value };
            };

            return { ...prevState, [field]: value };
        });
    };

    const handleAddProposal = (input: IProposal) => {
        setEvent(prevState => ({ ...prevState, 'proposals': [...event.proposals || [], { name: input.name, description: input.description, price: Number(input.price), capacity: Number(input.capacity) } as IProposal] }));

        setProposal(undefined);
        setIsProposalOpen(false);
    };

    const handleUpdateProposal = (input: IProposal) => {
        setEvent(prevState => {
            let index = undefined;
            if (!!input._id) {
                index = prevState.proposals?.findIndex(p => p._id === input._id);
            } else {
                index = prevState.proposals?.findIndex(p => p.name === proposal?.name);
            }

            if (index !== undefined && index !== -1 && prevState.proposals) {
                const updatedProposals = [...prevState.proposals];

                const element = { name: input.name, description: input.description, price: Number(input.price), capacity: Number(input.capacity) } as IProposal;

                if (input._id !== undefined) { element["_id"] = input._id }

                updatedProposals[index] = element as IProposal;

                return { ...prevState, proposals: updatedProposals };
            }

            return { ...prevState };
        });

        setProposal(undefined);
        setIsProposalOpen(false);
    };

    const handleRemoveProposal = () => {
        setEvent(prevState => ({ ...prevState, 'proposals': prevState.proposals?.filter(p => p.name !== proposal?.name) }));

        setProposal(undefined);
        setIsProposalOpen(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setErrorMessage("");
        const formData = event;

        if (!formData.name) {
            setErrorMessage("Inserisci un nome.");
            return;
        } else if (!formData.info) {
            setErrorMessage("Inserisci le Informazioni da mostrare ai Tuoi Clienti.");
            return;
        } else if (!!formData.offer && formData.proposalOption !== EventProposalOptions.SERVICE && !formData.proposals) {
            setErrorMessage("Per favore, inserisci almeno una proposta personalizzata oppure seleziona l'opzione per usare le proposte predefinite del servizio.");
            return;
        }

        const formElement = e.target;
        const form = new FormData(formElement);
        form.append("event", JSON.stringify(formData));
        form.append("dates", JSON.stringify(multipleDates));
        form.append("proposals", JSON.stringify(formData.proposals));

        submit(form, { method: params.id ? "PUT" : "POST" });
    };

    return (
        <div className='w-full flex-1 flex flex-col p-4 gap-12 container m-auto'>

            <PageTitle title='Evento' />

            <div className='flex flex-col gap-6 h-full'>
                {/* Name */}
                <InputForm backgroundColor='bg-white' label='Nome Evento' value={event.name} setValue={(e) => handleManageEvent("name", e)} />

                {/* Description */}
                <InputForm backgroundColor='bg-white' multiline multilineRows={4} label="Note Interne" value={event.description} setValue={(e) => handleManageEvent("description", e)} />

                {/* Info */}
                <InputForm backgroundColor='bg-white' multiline multilineRows={4} label='Informazioni per i Clienti' value={event.info} setValue={(e) => handleManageEvent("info", e)} />

                {/* Event date */}
                <DatePicker
                    isOpen={isComponentVisible}
                    setIsOpen={setIsComponentVisible}
                    onDatePick={(date) => handleManageEvent("date", date)}
                    selectedDate={event.date}
                />

                {/* Offers connected to the event */}
                <div className='flex flex-col gap-2'>
                    <p className="font-semibold text-text text-lg">Su quale Servizio sarà attivo l'Evento?</p>
                    <div>

                        {offers.map((offer, index) => <RadioButton key={index} checked={event.offer === offer._id} label={offer.name} onChange={() => handleManageEvent("offer", offer._id!)} />)}

                        <RadioButton checked={!event.offer} label="Tutto il giorno" onChange={() => setEvent(prevState => {
                            const { offer, proposals, ...newState } = prevState;
                            return { ...newState, proposalOption: EventProposalOptions.SERVICE };
                        })} />
                    </div>
                </div>

                {!!event.offer && <div className='flex flex-col gap-2'>
                    <p className="font-semibold text-text text-lg">Come desideri configurare le tue proposte?</p>
                    <div>
                        <RadioButton checked={event.proposalOption === EventProposalOptions.SERVICE} label='Usa le proposte predefinite del servizio' onChange={() => handleManageEvent('proposalOption', EventProposalOptions.SERVICE)} />
                        <RadioButton checked={event.proposalOption === EventProposalOptions.CUSTOM} label='Crea delle nuove proposte personalizzate' onChange={() => handleManageEvent('proposalOption', EventProposalOptions.CUSTOM)} />
                        <RadioButton checked={event.proposalOption === EventProposalOptions.COMBINED} label='Usa le proposte predefinite e aggiungi le tue proposte personalizzate' onChange={() => handleManageEvent('proposalOption', EventProposalOptions.COMBINED)} />
                    </div>
                </div>}

                {/* Proposals connected to the event */}
                {!!event.offer && event.proposalOption !== EventProposalOptions.SERVICE && <div className='flex flex-col gap-2'>
                    <div className='flex justify-between items-center'>
                        <p className="font-semibold text-text text-lg">Crea le Tue Proposte Personalizzate</p>
                        <button type='button' className='bg-text py-1 px-3 rounded-lg shadow' onClick={() => setIsProposalOpen(true)}>
                            <AddIcon />
                        </button>
                    </div>

                    {!!event.proposals?.length &&
                        <div className='bg-white rounded-md shadow flex flex-col'>
                            <div className='grid grid-cols-3 gap-1 text-base font-semibold text-text border-b border-mediumGray p-2'>
                                <p>Nome</p>
                                <p className='text-center'>Descrizione</p>
                                <p className='text-right'>Prezzo</p>
                            </div>
                            {event.proposals?.map((proposal, index) => (
                                <div key={index} className='grid grid-cols-3 gap-1 text-sm text-text cursor-pointer hover:bg-primaryLight p-2' onClick={() => {
                                    setProposal(proposal);
                                    setIsProposalOpen(true);
                                }}>
                                    <p>{proposal.name}</p>
                                    <p className='text-center line-clamp-2'>{proposal.description}</p>
                                    <p className='text-right'>{proposal.price.toFixed(2)} €</p>
                                </div>
                            ))}
                        </div>
                    }
                </div>}

                {/* Schedular Events section */}
                <div className='flex flex-col gap-2 mb-10'>
                    <div className='flex justify-between items-center'>
                        <p className='font-semibold text-text text-lg'>Programmazione dell'Evento</p>
                        <button type='button' className='bg-text py-1 px-3 rounded-lg shadow' onClick={() => setIsMultipleDateOpen(true)}>
                            <AddIcon />
                        </button>
                    </div>

                    <p className='text-xs text-darkGray text-center'>
                        Aggiungi le date in cui vuoi che il tuo evento si ripeta nel tempo. Clicca sul pulsante sopra per selezionare nuovi giorni!
                    </p>

                    {!!multipleDates.length && <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
                        {multipleDates?.map((date, index) => (
                            <div key={index} className='flex items-center justify-between gap-2 p-2 bg-white border border-mediumGray rounded-md'>
                                <CalendarIcon />
                                <p className='text-sm font-medium text-darkGray'>{moment(date).format('dddd DD MMMM YYYY')}</p>
                                <button type='button' onClick={() => setMultipleDates(prev => prev.filter(d => d !== date))}>
                                    <CrossIcon fillColor={colorPalette.Error} height='24' width='24' />
                                </button>
                            </div>
                        ))}
                        <button type='button' onClick={() => setMultipleDates([])} className='flex justify-center items-center gap-2 p-2 bg-white shadow rounded-md text-darkGray hover:text-error'>
                            <p className='text-sm font-semibold'>Rimuovi tutte le date</p>
                        </button>
                    </div>}
                </div>


                {!!errorMessage && <p className='text-error text-sm font-medium text-center'>{errorMessage}</p>}

                <Form method='POST' className='flex flex-col-reverse md:flex-row md:justify-between gap-2' onSubmit={handleSubmit}>
                    <CustomButton
                        type='button'
                        disabled={navigation.state !== 'idle'}
                        label='Annulla'
                        color='bg-white'
                        textColor='text-darkGray'
                        className='border-2 border-darkGray'
                        onClickHandler={() => navigate(-1)}
                    />
                    <CustomButton
                        type='submit'
                        disabled={navigation.state !== 'idle'}
                        label={params.id ? 'Modifica' : 'Crea'}
                        color='bg-primary'
                    />
                </Form>
            </div>

            {/* Modal to create multiple events */}
            <Modal isOpen={isMultipleDateOpen} onClose={() => setIsMultipleDateOpen(false)}>
                <p className='text-warning text-xs font-medium text-center'></p>
                <RecurringEventScheduler onSchedule={setMultipleDates} startingDate={moment(event.date).toISOString()} onClose={() => setIsComponentVisible(false)} warningMessage="Se ci sono date con un evento già assegnato, l'evento verrà sovrascritto." />
            </Modal>

            {/* Modal to create a new Proposal connected to this event */}
            <ManageProposal
                isOpen={isProposalOpen}
                setIsOpen={() => {
                    setIsProposalOpen(false);
                    setProposal(undefined);
                }}
                onCreateAction={handleAddProposal}
                onUpdateAction={handleUpdateProposal}
                onDeleteAction={handleRemoveProposal}
                element={proposal as any}
            />
        </div>
    )
}

export default EventCreate