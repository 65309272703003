import moment from "moment";
import { IBooking, PartialIBooking } from "../../types/interfaces/booking.interface";
import { IProposal } from "../../types/interfaces/proposal.interface";
import { IOffer } from "../../types/interfaces/offer.interface";

export interface IBookingState {
    filters: Record<string, string[] | number[] | boolean[]>;
    globalDate: string;
    paginationData: { pageSize: number, currentPage: number };
};

export const bookingState: IBookingState = {
    filters: {},
    globalDate: moment().utc(true).startOf('day').toISOString(),
    paginationData: {
        pageSize: 15,
        currentPage: 1
    }
};