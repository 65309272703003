import React, { useState } from 'react';
import InputForm from '../../../components/InputForm';
import RadioButton from '../../../components/RadioButton';
import { updateOfferTimetable } from '../../../store/offer/offerSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ITimeTable } from '../../../types/interfaces/timeTable.interface';
import { longStringErrorMessage, numberErrorMessage, shortStringErrorMessage, timetableErrorMessage } from './stepValidations';
import { alphanumericSort } from '../../../utils/alphanumericSort';
import { useModalDispatcher } from '../../../hooks/useModalDispatcher';
import { apiHandler, putHandler } from '../../../services/apiHandler';
import { IRoom, ITable } from '../../../types/interfaces/offer.interface';
import { AddIcon } from '../../../utils/icons/AddIcon';
import { DeleteBucketIcon } from '../../../utils/icons/DeleteBucketIcon';
import Modal from '../../../components/Modal';
import useComponentVisible from '../../../hooks/useComponentVisible';
import CustomButton from '../../../components/CustomButton';

const fields = {
    name: 'name',
    description: 'description',
    capacity: 'capacity',
    shifts: 'shifts',
    positions: 'positions',
    isForEach: 'isForEach',
    isMultiple: 'isMultiple',
    isAgeSensitive: 'isAgeSensitive',
    isEntertainment: 'isEntertainment',
    rooms: 'rooms'
};


interface IProps {
    handleUpdateOffer: (value: any, field: string) => void;
    infoErrors: {
        name: boolean;
        description: boolean;
        capacity: boolean;
        timetable: {
            0: boolean;
            1: boolean;
            2: boolean;
            3: boolean;
            4: boolean;
            5: boolean;
            6: boolean;
            7: boolean;
        }
    }
}

const InformationStep: React.FC<IProps> = ({ handleUpdateOffer, infoErrors }) => {

    const dispatch = useAppDispatch();
    const { info } = useAppSelector(state => state.offer.manageOffer);
    const { showErrorModal } = useModalDispatcher();
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible();

    const [isLoading, setIsLoading] = useState(false);
    const [inputRoom, setInputRoom] = useState<string>('');
    const [renameRoom, setRenameRoom] = useState<string>('');
    const [inputTable, setInputTable] = useState<string>('');
    const [selectedRoom, setSelectedRoom] = useState<number>(0)
    const [shift, setShift] = useState<string>('');
    const [tableError, setTableError] = useState(false);
    const [roomError, setRoomError] = useState(false);
    const [renameError, setRenameError] = useState(false);
    const [shiftError, setShiftError] = useState(false);

    const handleUpdateTimeTable = (value: any, field: string, index: number) => {
        // clone timetable array and update it with new new value 
        const temp = [...info?.timetable];
        const valueToSet = { ...temp[index], [field]: value };
        temp[index] = valueToSet;

        // update offer timeTable
        dispatch(updateOfferTimetable({
            newTimetable: temp
        }));
    };

    const handleRemoveShift = async (deleteShift?: string) => {
        try {
            setIsLoading(true);
            await apiHandler.put<putHandler>('booking/update/clean-bookings-shift/' + deleteShift);

            const updatedShifts = info.shifts.filter((shift: string) => shift !== deleteShift);
            handleUpdateOffer(updatedShifts, fields.shifts);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    }

    const handleAddRoom = () => {
        if (!inputRoom.trim().length) {
            return
        }

        const roomExist = info.rooms.some((room: IRoom) => room.name.trim().toLowerCase() === inputRoom.trim().toLowerCase());

        if (!roomExist) {
            handleUpdateOffer([...info.rooms, { name: inputRoom.trim(), tables: [] }], fields.rooms);
            setInputRoom('');
            setSelectedRoom(info.rooms.length);
            setRoomError(false);
        } else {
            setRoomError(true);
        }
    };

    const handleAddTable = () => {
        const tableExist = info.rooms.some((room: IRoom) =>
            room.tables.some((table: ITable) =>
                table.number.toLowerCase() === inputTable.toLowerCase()
            )
        );

        if (!tableExist) {
            const updatedRooms = info.rooms.map((room: IRoom, index: number) => {
                if (index === selectedRoom) {
                    return {
                        ...room,
                        tables: [
                            ...room.tables,
                            { number: inputTable, capacity: 0 }
                        ]
                    };
                }
                return room;
            });

            handleUpdateOffer(updatedRooms, fields.rooms);
            setInputTable('');
            setTableError(false);
        } else {
            setTableError(true);
        }
    };

    const handleRemoveTable = async (tableToRemove: string) => {
        try {
            setIsLoading(true);

            if (!!info.rooms[selectedRoom]._id) {
                await apiHandler.put<putHandler>(`booking/update/clean-bookings-pst`, { roomId: info.rooms[selectedRoom]._id, pst: tableToRemove });
            }

            const updatedRooms = info.rooms.map((room: IRoom, index: number) => {
                if (index === selectedRoom) {
                    const updatedTables = room.tables.filter((table: ITable) => table.number.toLowerCase() !== tableToRemove.toLowerCase());

                    return {
                        ...room,
                        tables: updatedTables
                    };
                }
                return room;
            });

            handleUpdateOffer(updatedRooms, fields.rooms);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleRemoveRoom = async (roomId: string) => {
        try {
            setIsLoading(true);

            if (!!roomId) {
                await apiHandler.put<putHandler>(`booking/update/clean-bookings-pst`, { roomId });
            }

            const updatedRooms = info.rooms.filter((room: IRoom) => room.name.trim() !== info.rooms[selectedRoom].name.trim());

            handleUpdateOffer(updatedRooms, fields.rooms);
            setSelectedRoom(selectedRoom - 1);
            setIsComponentVisible(false);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleRenameRoom = async () => {
        const roomExist = info.rooms.some((room: IRoom) => room.name.trim().toLowerCase() === renameRoom.trim().toLowerCase());

        if (!roomExist) {
            const updatedInfo = { ...info };
            const updatedRooms = [...updatedInfo.rooms];
            updatedRooms[selectedRoom] = {
                ...updatedRooms[selectedRoom],
                name: renameRoom
            };
            updatedInfo.rooms = updatedRooms;
            handleUpdateOffer(updatedInfo.rooms, fields.rooms);
            setRenameRoom('');
            setRenameError(false);
        } else {
            setRenameError(true);
        }
    };


    const handleAddShift = () => {
        const isShiftInList = info.shifts.some((el: string) => el.toLowerCase() === shift.toLowerCase());
        if (isShiftInList) {
            setShiftError(true);
        } else {
            setShiftError(false);
            handleUpdateOffer([...info.shifts, shift], fields.shifts);
            setShift('');
        }
    };

    return (
        <div className='flex flex-col gap-y-8'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-8'>
                <div className='flex flex-col gap-y-6'>
                    <InputForm
                        type='text'
                        label='Nome servizio *'
                        placeholder='Nome servizio'
                        error={infoErrors.name}
                        errorMessage={shortStringErrorMessage}
                        value={info?.name}
                        setValue={(e: string) => handleUpdateOffer(e, fields.name)} />
                    <InputForm
                        fullWidth={false}
                        customStyle="w-60"
                        type='number'
                        error={infoErrors.capacity}
                        errorMessage={numberErrorMessage}
                        placeholder='00'
                        label='Persone max'
                        value={info?.capacity}
                        setValue={(e: string) => {
                            const value = parseInt(e);
                            handleUpdateOffer(value, fields.capacity);
                        }} />
                    <InputForm
                        fullHeight
                        type='text'
                        label='Descrizione servizio *'
                        placeholder='Descrizione servizio'
                        value={info?.description}
                        error={infoErrors.description}
                        errorMessage={longStringErrorMessage}
                        setValue={(e: string) => handleUpdateOffer(e, fields.description)} multiline />
                </div>

                <div className='flex flex-col gap-y-2'>
                    <div className='grid grid-cols-4 font-semibold text-text text-lg'>
                        <p className='col-span-2'>Giorno</p>
                        <p className='col-span-1'>Apertura</p>
                        <p className='col-span-1'>Chiusura</p>
                    </div>

                    {info.timetable.map((timetable: ITimeTable, index: number) => {
                        return (
                            <div key={index} className='grid grid-cols-4 text-text'>
                                <RadioButton classname='col-span-2 text-xs lg:text-md' label={timetable.day} checked={timetable.isAvailable} onChange={() => {
                                    const value = !timetable.isAvailable;
                                    handleUpdateTimeTable(value, 'isAvailable', index)
                                }} />
                                <InputForm
                                    type='text'
                                    error={Object.values(infoErrors.timetable)[index]}
                                    errorMessage={timetableErrorMessage}
                                    placeholder='00:00'
                                    fullWidth={false}
                                    customStyle="w-16 lg:w-24 text-center text-xs lg:text:md"
                                    value={timetable.openingAt}
                                    setValue={(e: string) => {
                                        handleUpdateTimeTable(e, 'openingAt', index);
                                    }} />
                                <InputForm
                                    type='text'
                                    error={Object.values(infoErrors.timetable)[index]}
                                    errorMessage={timetableErrorMessage}
                                    placeholder="00:00"
                                    fullWidth={false}
                                    customStyle="w-16 lg:w-24 text-center text-xs lg:text:md"
                                    value={timetable.closedAt}
                                    setValue={(e: string) => {
                                        handleUpdateTimeTable(e, 'closedAt', index);
                                    }} />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex gap-1 justify-between items-center'>
                    <p className='font-semibold text-text text-lg'>Turni</p>
                    <div className='flex gap-1 items-start'>
                        <InputForm
                            type='text'
                            placeholder={`Inserisci turno ${info?.name}`}
                            fullWidth={false}
                            error={shiftError}
                            errorMessage='Turno già presente nel servizio'
                            customStyle="text-center text-xs lg:text:md"
                            value={shift}
                            setValue={(e) => setShift(e)}
                            onSearchSubmit={handleAddShift}
                        />
                        <div onClick={handleAddRoom} className='flex items-center justify-center h-8 aspect-square rounded-md cursor-pointer shadow bg-text relative'>
                            <AddIcon />
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap gap-2'>
                    {alphanumericSort(info.shifts).map((shift: string, index: number) => {
                        return <p key={index} title='Elimina' onClick={() => !isLoading && handleRemoveShift(shift)} className='py-1 px-4 bg-background rounded flex items-center justify-center text-sm font-medium hover:border-error hover:border-2 cursor-pointer text-text shadow'>{shift}</p>
                    })}
                </div>
            </div>

            <div className='flex flex-col gap-2'>
                <div className='flex gap-1 justify-between items-center'>
                    <p className='font-semibold text-text text-lg'>Sale</p>

                    <div className='flex gap-1 items-start'>
                        <InputForm
                            type='text'
                            placeholder={`Inserisci nuova sala`}
                            fullWidth={false}
                            error={roomError}
                            errorMessage='Sala già presente nel servizio.'
                            customStyle="text-center text-xs h-8"
                            value={inputRoom}
                            setValue={(e) => setInputRoom(e)}
                            onSearchSubmit={handleAddRoom}
                        />

                        <div onClick={handleAddRoom} className='flex items-center justify-center h-8 aspect-square rounded-md cursor-pointer shadow bg-text relative'>
                            <AddIcon />
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap gap-2'>
                    {info?.rooms?.map((room: IRoom, index: number) => {
                        return <button key={index} onClick={() => setSelectedRoom(index)} className={`rounded-md flex items-center ${selectedRoom === index ? 'bg-primary text-text_white pl-4 py-2' : 'bg-background px-4 py-2'}`}>
                            {selectedRoom === index
                                ? <>
                                    <InputForm
                                        type='text'
                                        error={renameError}
                                        backgroundColor='bg-primary'
                                        errorMessage='Sala già esistente.'
                                        customStyle="text- text-sm border-b-2 px-0 border-white rounded-none text-white"
                                        paddingY={1}
                                        value={renameRoom || room.name}
                                        setValue={(e) => setRenameRoom(e)}
                                        onSearchSubmit={handleRenameRoom}
                                    />
                                    <div onClick={() => setIsComponentVisible(true)}>
                                        <DeleteBucketIcon />
                                    </div>
                                </>
                                : <p className='text-sm font-medium'>{room.name}</p>
                            }
                        </button>
                    })}
                </div>
            </div>

            {!!info.rooms.length &&
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-1 justify-between items-center'>
                        <p className='font-semibold text-text text-lg'>Tavoli {info?.rooms[selectedRoom]?.name}</p>

                        <div className='flex gap-1 items-start'>
                            <InputForm
                                type='text'
                                placeholder={`Nuovo tavolo`}
                                fullWidth={false}
                                error={tableError}
                                errorMessage='Tavolo già presente.'
                                customStyle="text-center text-xs lg:text:md"
                                value={inputTable}
                                setValue={(e) => setInputTable(e)}
                                onSearchSubmit={handleAddTable}
                            />

                            <div onClick={handleAddTable} className='flex items-center justify-center h-8 aspect-square rounded-md cursor-pointer shadow bg-text relative'>
                                <AddIcon />
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-wrap gap-2'>
                        {!!info.rooms[selectedRoom].tables.length
                            ? [...info.rooms[selectedRoom].tables].sort((a: any, b: any) => Number(a.number) - Number(b.number)).map((table: ITable, index: number) => {
                                return (
                                    <button
                                        key={index} title='Elimina'
                                        onClick={() => handleRemoveTable(table.number)}
                                        className='py-1 px-4 bg-background rounded flex items-center justify-center text-sm font-medium hover:border-error hover:border-2 cursor-pointer text-text shadow'
                                    >
                                        {table.number}
                                    </button>
                                );
                            })
                            : <p className='text-xs font-medium text-darkGray text-center m-auto'>Nessun tavolo inserito</p>
                        }
                    </div>

                </div>
            }

            <div className='flex flex-col gap-2'>
                <p className='font-semibold text-text text-lg'>Tipologia di servizio</p>
                {/*<RadioButton label='Prezzo da calcolare a persona' checked={info?.isForEach} onChange={() => handleUpdateOffer(!info?.isForEach, fields.isForEach)} />*/}
                <RadioButton label='Consenti selezioni multiple per opzioni di servizio' checked={info?.isMultiple} onChange={() => handleUpdateOffer(!info?.isMultiple, fields.isMultiple)} />
                <RadioButton label='Servizio di ristorazione' checked={info?.isEntertainment} onChange={() => handleUpdateOffer(!info?.isEntertainment, fields.isEntertainment)} />
            </div>

            <Modal isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)}>
                <div className='flex flex-col gap-4'>
                    <p className='font-semibold text-text text-lg'>Vuoi davvero eliminare {info?.rooms[selectedRoom]?.name}?</p>
                    <p className='text-xs font-medium text-center'>Tutte le prenotazioni già collegate a questa sala verrano scollegate!</p>
                    <div className='flex justify-between gap-2'>
                        <CustomButton color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' label='Annulla' onClickHandler={() => setIsComponentVisible(false)} />
                        <CustomButton color='bg-error' label='Elimina' onClickHandler={() => !isLoading && handleRemoveRoom(info.rooms[selectedRoom]._id)} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default InformationStep;
