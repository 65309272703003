import moment from "moment";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import { IBookingRequest } from "../../../../types/interfaces/bookingRequest.interface";
import { IOffer } from "../../../../types/interfaces/offer.interface";
import { IStructure } from "../../../../types/interfaces/structure.interface";
import { ArrowRight } from "../../../../utils/icons/ArrowIcon";
import { CalendarIcon } from "../../../../utils/icons/CalendarIcon";
import Calendar from "../../../../components/Calendar";
import { IProposal } from "../../../../types/interfaces/proposal.interface";
import DatePicker from "../../../../components/DatePicker";
import { IAutoBookingOption } from "../../../../types/interfaces/autoBookingOptions.interface";

interface IProps {
    newBookingRequest: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    handleNext: (fields: (keyof IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>)[], value: any, step: number) => void
};

const Step2Update: React.FC<IProps> = ({ newBookingRequest, handleNext }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const getDisabledDays = (): number[] => {
        const disabledDays = newBookingRequest.offer.timetable.map(day => {
            if (!day.isAvailable) {
                return moment(day.day, "dddd").day()
            } else {
                return -1
            }
        })

        return disabledDays
    };

    return (
        <div className='flex flex-col px-4 gap-4'>

            <DatePicker
                isOpen={isComponentVisible}
                setIsOpen={setIsComponentVisible}
                selectedDate={newBookingRequest.date}
                onDatePick={(date) => handleNext(["date"], date, 3)}
                disabledDays={getDisabledDays()}
                minDate={moment().utc(true).toISOString()}
                maxDate={moment(newBookingRequest.offer.autoBookingOptions?.dateLimit).utc(true).toISOString()}
            />
            {/* 
            <div ref={ref} className={`flex items-center bg-white justify-between relative ${isComponentVisible ? 'rounded-t-lg' : ' rounded-lg'}`}>
                <div className='flex items-center gap-4 pl-4'>
                    <div className={`ease-in-out duration-300 transition-all ${isComponentVisible ? 'rotate-90' : ''}`}><ArrowRight /></div>
                    <p className='font-semibold capitalize text-primary text-xl'>{moment(newBookingRequest.date).format('dddd')}</p>
                </div>

                <p className='font-semibold text-text'>{moment(newBookingRequest.date).format('DD/MM/YYYY')}</p>

                <div onClick={() => setIsComponentVisible(!isComponentVisible)} className=' bg-primary aspect-square rounded-lg p-1'>
                    <CalendarIcon color='#FFF' size='48' />
                </div>
                {isComponentVisible &&
                    <div className="absolute z-50 top-full right-0 translate rounded-b-lg shadow-md w-full flex justify-center items-center bg-white overflow-auto pt-1 p-4">
                        <Calendar
                            fullWidth
                            selectedDate={moment(newBookingRequest.date).utc(true).toISOString()}
                            handlePickDate={() => setIsComponentVisible(false)}
                            setSelectedDate={(date) => handleNext(["date"], date, 3)}
                            minDate={moment().utc(true).toISOString()}
                            maxDate={moment(newBookingRequest.offer.autoBookingOptions?.dateLimit).utc(true).toISOString()}
                            disabledDays={getDisabledDays()}
                        />
                    </div>
                }
            </div> */}
        </div>
    )
}

export default Step2Update