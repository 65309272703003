import React, { useState } from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import Calendar from '../../../../components/Calendar';
import moment from 'moment';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import useFetch from '../../../../hooks/useFetch';
import { IEvent } from '../../../../types/interfaces/event.interface';
import CustomButton from '../../../../components/CustomButton';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import { apiService, HttpMethod } from '../../../../services/apiService';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';

interface Step2Props {
    data: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    setData: React.Dispatch<React.SetStateAction<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>>;
    nextStep: () => void;
    prevStep: () => void;
}

const Step2Create: React.FC<Step2Props> = ({ data, setData, nextStep, prevStep }) => {
    const [selectedDate, setSelectedDate] = useState(moment().utc(true).toISOString());

    const { data: events, loading } = useFetch<{ offerEvent: IEvent | undefined, dailyEvent: IEvent | undefined }>(
        () => apiService(HttpMethod.POST, `/event/public/get/dailyEvent`, { date: selectedDate, structureId: data.structure, offer: data.offer._id }),
        undefined,
        [selectedDate],
        !!selectedDate && !!data.structure && !!data.offer
    );

    const handleNext = () => {
        if (moment(selectedDate).isBefore(moment().utc(true), 'day')) {
            return;
        }

        setData({ ...data, date: moment(selectedDate).toDate() });
        nextStep();
    };

    const getDisabledDays = (): number[] => {
        const disabledDays = data.offer.timetable.map(day => {
            if (!day.isAvailable) {
                return moment(day.day, "dddd").day()
            } else {
                return -1
            }
        })

        return disabledDays
    };

    return (
        <>
            <div className='flex flex-col gap-2'>
                <p className='font-bold text-text text-2xl'>Scegli il Giorno Perfetto</p>
                <p className='text-darkGray font-semibold md:text-center'>
                    Seleziona il giorno che ti ispira di più e preparati a vivere un'esperienza unica con noi.
                </p>
            </div>

            <div>
                <div className={`bg-white -mx-8 px-8 py-4 md:max-w-md md:mx-auto ${(!!events?.offerEvent && !loading) ? 'md:rounded-t-md' : 'md:rounded-md'}`}>
                    <Calendar
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        minDate={moment().utc(true).toISOString()}
                        maxDate={moment(data.offer.autoBookingOptions?.dateLimit).utc(true).toISOString()}
                        disabledDays={getDisabledDays()}
                    />
                </div>

                {!!events?.offerEvent || !!events?.dailyEvent
                    ? <div className={`bg-primary -mx-8 px-8 py-4 gap-2 flex flex-col md:max-w-md md:mx-auto md:rounded-b-md relative ${loading && 'bg-opacity-50'}`}>
                        <p className="text-2xl font-bold text-white">{events?.offerEvent?.name}</p>
                        <p className="text-sm text-justify text-mediumGray">{events?.offerEvent?.info}</p>

                        <p className="text-2xl font-bold text-white">{events?.dailyEvent?.name}</p>
                        <p className="text-sm text-justify text-mediumGray">{events?.dailyEvent?.info}</p>

                        {loading && <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100'>
                            <LoadingIndicator icon color='text-primary' />
                        </div>}
                    </div>
                    : null
                }
            </div>

            <div className='flex flex-col md:flex-row-reverse md:justify-between md:items-end gap-2 h-full justify-end'>
                <CustomButton type='button' label="Avanti" onClickHandler={handleNext} color='bg-primary' textColor='text-text_white' />
                <CustomButton type='button' label="Indietro" onClickHandler={prevStep} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />
            </div>
        </>
    );
};

export default Step2Create;
