import { useEffect, useRef } from "react";
import useFetch from "../../../../hooks/useFetch";
import { apiHandler } from "../../../../services/apiHandler";
import { IBookingRequest } from "../../../../types/interfaces/bookingRequest.interface";
import { IOffer } from "../../../../types/interfaces/offer.interface";
import { IStructure } from "../../../../types/interfaces/structure.interface";
import { IProposal } from "../../../../types/interfaces/proposal.interface";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import { IAutoBookingOption } from "../../../../types/interfaces/autoBookingOptions.interface";

interface IProps {
    newBookingRequest: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    handleNext: (fields: (keyof IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>)[], value: any, step: number) => void
};

const Step3Update: React.FC<IProps> = ({ newBookingRequest, handleNext }) => {

    const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    useHorizontalScroll(containerRef);


    const { data: response } = useFetch<{ occupiedSeats: number }>(
        () => apiHandler.post(`/booking-request/get/occupied-seats`, {
            shift: newBookingRequest.shift ?? newBookingRequest.shift,
            date: newBookingRequest.date ?? newBookingRequest.date,
            structureId: newBookingRequest.structure._id,
            offerId: newBookingRequest.offer._id
        }),
        undefined,
        [],
        !!newBookingRequest.date
    );

    let availableSeats = 0;
    if (!!newBookingRequest?.offer?.autoBookingOptions?.nBookingsAvailable && response?.occupiedSeats !== undefined) {
        availableSeats = newBookingRequest?.offer?.autoBookingOptions?.nBookingsAvailable - response?.occupiedSeats;
    }

    const maxPeople = newBookingRequest?.offer?.autoBookingOptions?.maxPeoplePerBooking ?? 12;
    const numPeopleOptions = Array.from({ length: maxPeople }, (_, index) => index + 1);

    useEffect(() => {
        const selectedIndex = numPeopleOptions.indexOf(newBookingRequest?.people);
        if (selectedIndex !== -1 && buttonRefs.current[selectedIndex] && !!buttonRefs) {
            setTimeout(() => {
                buttonRefs?.current[selectedIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }, 500);
        }
    }, [newBookingRequest?.people]);

    const renderPeopleBtn = () => {
        const peopleButtons = numPeopleOptions.map((numPeople, index) => (
            <button
                key={numPeople}
                ref={(el) => (buttonRefs.current[index] = el)}
                disabled={availableSeats < numPeople}
                onClick={() => handleNext(["people"], numPeople, 4)}
                className={`aspect-video h-12 font-semibold text-lg hover:border-2 hover:border-primary hover:text-primary rounded-md shadow disabled:opacity-40 bg-white ${newBookingRequest?.people === numPeople ? 'border-2 border-primary text-primary' : 'text-darkGray'}`}
            >
                {numPeople}
            </button>
        ));

        return (
            <div ref={containerRef} className='flex w-full overflow-x-auto hidden-scrollbar px-4 gap-2'>
                {peopleButtons}
            </div>
        );
    };

    return (
        renderPeopleBtn()
    )
}

export default Step3Update