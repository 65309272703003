import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { eventState } from "./eventState";
import { IEvent } from "../../types/interfaces/event.interface";

export const EventSlice: Slice<any> = createSlice({
    name: "event",
    initialState: eventState,
    reducers: {
        replaceDailyEvent: (state, action: PayloadAction<IEvent>) => {
            state.dailyEvent = action.payload;
        }
    }
});

export const {
    replaceDailyEvent
} = EventSlice.actions;

export default EventSlice.reducer;