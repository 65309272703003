import React from 'react'
import { GoBackIcon } from '../utils/icons/GoBackIcon'
import { useNavigate } from 'react-router-dom';

interface IProps {
    goBackBtn?: boolean;
    title: string;
    rightBtn?: JSX.Element;
}

/**
 * Renders a page title component.
 *
 * @param {IProps} props - The props object containing the title, goBackBtn, and rightBtn.
 * @return {JSX.Element} The rendered page title component.
 */
const PageTitle: React.FC<IProps> = ({ title, goBackBtn = true, rightBtn }) => {
    const navigate = useNavigate();

    return (
        <div className='flex justify-between items-center w-full'>
            <div className="flex gap-4 items-center relative">

                {goBackBtn &&
                    <div onClick={() => navigate(-1)} className='cursor-pointer border-2 bg-white shadow rounded-lg flex items-center justify-center p-2 w-fit aspect-square'>
                        <GoBackIcon />
                    </div>}

                <p className='text-3xl md:text-4xl font-bold text-text'>{title}</p>

            </div>

            {rightBtn && rightBtn}

        </div>
    )
}

export default PageTitle