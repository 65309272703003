import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import { LogoutIcon } from '../../utils/icons/LogoutIcon';
import CustomButton from '../../components/CustomButton';
import useCookie from '../../hooks/useCookie';
import { useAppSelector } from '../../store/store';
import { useEffect } from 'react';
import { apiHandler, postHandler } from '../../services/apiHandler';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';

export const StructureLayout = () => {
    const { showErrorModal } = useModalDispatcher();
    const { cookieData, removeCookie, setCookie } = useCookie({ userData: 'user_cookie' });
    const { selectedStructure } = useAppSelector(state => state.auth);

    useEffect(() => {
        if (cookieData.userData) {
            checkUserToken();
        }

        const interval = setInterval(() => {
            if (cookieData.userData) {
                checkUserToken();
            }
        }, 900000); // Check every 15 minutes 

        return () => {
            clearInterval(interval);
        };
    }, []);

    const checkUserToken = async () => {
        try {
            const response = await apiHandler.post<postHandler | any>('/user/check-token', { isUserCookie: true });

            if (response.newJwt) {
                setCookie('userData', response.newJwt, response.exp);
            }

            if (!response.isTokenValid) {
                removeCookie('userData');
            }

        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
            removeCookie('userData');
        }
    };

    if (selectedStructure) {
        return <Navigate to="/dashboard/home" />;
    }

    if (!cookieData.userData) {
        return <Navigate to="/auth" />;
    }

    return (
        <div className="flex flex-col w-full h-screen" >
            <Navbar burgerMenu={false} navContent={<StructureLayoutNavbar cookieData={cookieData} handleRemoveCookie={removeCookie} />} />
            <Outlet />
        </div>)
}

interface IProps {
    cookieData: Record<string, any>;
    handleRemoveCookie: (name: string, options?: any) => void;

}

const StructureLayoutNavbar: React.FC<IProps> = ({ cookieData, handleRemoveCookie }) => {
    return <div className="flex w-full justify-between items-center">
        <p className='text-xl font-bold '>{cookieData?.userData?.name} {cookieData?.userData?.surname}</p>
        <div className='flex items-center gap-6'>
            {/* <NotificationButton /> */}
            {/* <CustomButton leftIcon={<SupportIcon />} color='bg-secondary' label='Supporto' /> */}
            <CustomButton onClickHandler={() => handleRemoveCookie('userData')} leftIcon={<LogoutIcon />} color='bg-secondary' label='Logout' />
        </div>
    </div>
}
