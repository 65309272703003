import React from 'react';
import { BOOKING_STATUS, bookingStatusColorMapping, bookingStatusOptions, bookingStatusTextColorMapping } from '../types/enum/bookingStatus';
import { colorPalette } from '../types/enum/colorPalette';

interface BookingStatusSliderProps {
    bookingStatus: BOOKING_STATUS;
}

const BookingStatusSlider: React.FC<BookingStatusSliderProps> = ({ bookingStatus }) => {
    const isRejectedOrCanceled = bookingStatus === BOOKING_STATUS.REJECTED || bookingStatus === BOOKING_STATUS.NOSHOW || bookingStatus === BOOKING_STATUS.CANCELLED;

    let sliderStyle: React.CSSProperties = {};

    switch (bookingStatus) {
        case BOOKING_STATUS.PENDING:
            sliderStyle.width = '5%';
            break;
        case BOOKING_STATUS.ACCEPTED:
            sliderStyle.width = '50%';
            break;
        case BOOKING_STATUS.SUCCESS:
            sliderStyle.width = '100%';
            break;
        case BOOKING_STATUS.REJECTED:
            sliderStyle.width = '100%';
            break;
        case BOOKING_STATUS.NOSHOW:
            sliderStyle.width = '100%';
            break;
        case BOOKING_STATUS.CANCELLED:
            sliderStyle.width = '100%';
            break;
        default:
            sliderStyle.width = '10%';
            sliderStyle.backgroundColor = colorPalette.Primary;
            break;
    };

    return (
        <div className='flex flex-col gap-2 w-full'>
            <p className="font-semibold text-text text-lg">Stato Prenotazione</p>
            <div className='relative w-full h-2 bg-background rounded-full'>
                <div className={`absolute w-full h-2 rounded-full ${bookingStatusColorMapping[bookingStatus]}`} style={sliderStyle}>
                </div>
            </div>
            {isRejectedOrCanceled
                ? <div className='flex justify-center'>
                    <p className={`text-sm font-semibold ${bookingStatusTextColorMapping[bookingStatus]}`}>
                        {bookingStatusOptions.find(option => option.value === bookingStatus)?.label}
                    </p>
                </div>
                : <div className='flex justify-between gap-2'>
                    {bookingStatusOptions
                        .filter(option => option.value !== BOOKING_STATUS.DRAW && option.value !== BOOKING_STATUS.REJECTED && option.value !== BOOKING_STATUS.CANCELLED && option.value !== BOOKING_STATUS.NOSHOW)
                        .map(status => (
                            <p className={`${(bookingStatus === status.value) ? bookingStatusTextColorMapping[bookingStatus] : 'text-text'} font-medium text-sm truncate`} key={status.value}>
                                {status.label}
                            </p>
                        ))}
                </div>
            }
        </div>
    );
};

export default BookingStatusSlider;
