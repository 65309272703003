import { useEffect } from 'react';
import { removeModal } from '../store/UI/uiSlice';
import { useAppDispatch, useAppSelector } from '../store/store';
import { getModalColor } from '../types/enum/apiResponseSeverity';

const ApiResponseModal = () => {
    const dispatch = useAppDispatch();

    const modalState = useAppSelector((state) => state.ui.modalState);
    const { isOpen, message, severity } = modalState;

    const handleCloseModal = () => {
        dispatch(removeModal({}));
    };

    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                handleCloseModal();
            }, 3500);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isOpen]);

    const capitalize = (paragraph: string) => {
        return paragraph.charAt(0).toUpperCase() + paragraph.slice(1);
    }

    return (
        <div className={`fixed bottom-4 left-1/2 transform max-md:-translate-x-1/2 w-full md:max-w-md md:left-auto md:right-4 px-4 z-50 transition-transform duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
            <div className={`${getModalColor(severity)} bg-opacity-90 text-opacity-100 rounded-md shadow-lg`}>
                <div className="flex flex-col py-2 px-4 gap-2">
                    <p className="text-text text-sm font-semibold">{capitalize(message)}</p>
                </div>
            </div>
        </div>
    );
};

export default ApiResponseModal;
