import { Form, Link, useLoaderData, useNavigate, useParams, useSubmit } from "react-router-dom";
import { IEvent } from "../../types/interfaces/event.interface";
import PageTamplate from "../../components/PageTamplate";
import Text from "../../components/Text";
import moment from "moment";
import { IOffer } from "../../types/interfaces/offer.interface";
import { IProposal } from "../../types/interfaces/proposal.interface";
import { DeleteBucketIcon } from "../../utils/icons/DeleteBucketIcon";
import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { AddIcon } from "../../utils/icons/AddIcon";
import PageTitle from "../../components/PageTitle";

const DailyEvents = () => {
    const response = useLoaderData() as IEvent<string, IProposal, IOffer>[];
    const params = useParams();
    const submit = useSubmit();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState<{ state: boolean, id?: string }>({ state: false });

    const handleDeleteEvent = (e: any, event: IEvent<string, IProposal, IOffer>) => {
        e.preventDefault();

        const formElement = e.target;
        const form = new FormData(formElement);
        form.append("event", JSON.stringify(event));

        submit(form, { method: "PUT" });
    };

    const navigateToEventUpdate = (id: string, e: any) => {
        e.stopPropagation();
        navigate('/dashboard/events/update/' + id);
    }

    return (
        <PageTamplate>
            <PageTitle title={moment(params.date).format('ddd DD MMMM')} />

            {!response.length
                ? <div className="p-4 md:py-8 bg-white rounded-lg shadow-md m-auto">
                    <p className="text-center text-lg font-semibold text-gray-700">Non ci sono eventi programmati per questa data.</p>
                    <p className="text-center text-gray-600 mt-2">Perché non ne crei uno nuovo? Basta un attimo per organizzare qualcosa di speciale!</p>
                </div>
                : <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {response.map((event, index) => (
                        isOpen.state && isOpen.id === event._id
                            ? <div key={index} className="bg-white rounded-md shadow p-4 gap-4 flex flex-col items-center justify-between">
                                <p className='text-text text-center text-xl font-semibold'>Sei sicuro di voler eliminare questo evento?</p>
                                <p className='text-text text-center'>Una volta eliminato, non potrai recuperarlo, ma avrai sempre la possibilità di crearne uno nuovo.</p>
                                <Form method="PUT" className="flex flex-col justify-between w-full gap-y-2" onSubmit={(e) => handleDeleteEvent(e, event)}>
                                    <CustomButton type="button" label="Annulla" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => setIsOpen({ state: false })} />
                                    <CustomButton type="submit" label="Elimina" color='bg-error' />
                                </Form>
                            </div>
                            : <div onClick={(e) => navigateToEventUpdate(event._id!, e)} key={index} className="bg-white rounded-md shadow p-4 gap-2 flex flex-col cursor-pointer">

                                <div className="flex justify-between">
                                    <p className="text-2xl font-semibold text-primary">{event.name}</p>
                                    <button type="button" onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen({ state: true, id: event._id });
                                    }}>
                                        <DeleteBucketIcon />
                                    </button>
                                </div>

                                <Text title="Servizio" label={event.offer?.name} gap={0} />
                                <Text title="Note Interne" label={event.description} gap={0} />
                                <Text title="Informazioni per i Clienti" label={event.info} gap={0} />

                                {!!event.proposals?.length &&
                                    <>
                                        <div className="border-b border-darkGray" />

                                        <p className="text-xl font-semibold">{event.proposals?.length > 1 ? 'Proposte' : 'Proposta'}</p>

                                        <div className='grid grid-cols-3 gap-1 text-base font-medium text-darkGray border-b border-mediumGray'>
                                            <p>Nome</p>
                                            <p className='text-center'>Descrizione</p>
                                            <p className='text-right'>Prezzo</p>
                                        </div>

                                        {event.proposals?.map((proposal, index) => (
                                            <div key={index} className='grid grid-cols-3 gap-1 text-sm text-darkGray'>
                                                <p>{proposal.name}</p>
                                                <p className='text-center line-clamp-2'>{proposal.description}</p>
                                                <p className='text-right'>{proposal.price.toFixed(2)} €</p>
                                            </div>
                                        ))}
                                    </>}
                            </div>
                    ))}
                </div>
            }

            <div className='absolute right-2 bottom-2 flex'>
                <Link to={'/dashboard/events/create?date=' + params.date} className='bg-text p-2 rounded-full shadow'>
                    <AddIcon />
                </Link>
            </div>
        </PageTamplate>
    )
}

export default DailyEvents