import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { offerState } from "./offerState";
import { IOffer } from "../../types/interfaces/offer.interface";

export const OfferSlice: Slice<any> = createSlice({
  name: "offer",
  initialState: offerState,
  reducers: {
    replaceOffers: (state, action: PayloadAction<IOffer[]>) => {
      state.offers = action.payload;
    },

    replaceInfo: (state, action: PayloadAction<any>) => {
      state.manageOffer.info = action.payload;
    },

    replaceOfferManage: (state, action: PayloadAction<any>) => {
      state.manageOffer = action.payload;
    },

    updateOfferTimetable: (state, action: PayloadAction<any>) => {
      state.manageOffer.info.timetable = action.payload.newTimetable;
    },

    resetOfferManage: (state, action: PayloadAction<any>) => {
      state.manageOffer = offerState.manageOffer;
    },

    handleOfferStepNavigation: (state, action: PayloadAction<number>) => {
      state.manageOffer.activeStep = action.payload;
    },

    replaceManageOfferSession: (state, action: PayloadAction<any>) => {
      state.manageOffer.offerSession = action.payload;
    },

    replaceCurrentOffer: (state, action: PayloadAction<IOffer>) => {
      state.manageOffer.currentOffer = action.payload;
    }
  }
});

export const {
  replaceInfo,
  replaceOfferManage,
  updateOfferTimetable,
  resetOfferManage,
  replaceOffers,
  handleOfferStepNavigation,
  replaceManageOfferSession,
  replaceCurrentOffer
} = OfferSlice.actions;

export default OfferSlice.reducer;
