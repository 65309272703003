import { createSlice, Slice } from "@reduxjs/toolkit";
import { authState } from "./authState";

export const AuthSlice: Slice<any> = createSlice({
    name: "auth",
    initialState: authState,
    reducers: {
        replaceUserAuth(state, action) {
            state.userAuth = action.payload.userAuth
        },
        replaceSelectedStructure(state, action) {
            state.selectedStructure = action.payload.selectedStructure
        }
    }
});

export const { replaceUserAuth, replaceSelectedStructure } = AuthSlice.actions;
export default AuthSlice.reducer;