import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { IBooking, PartialIBooking } from '../../../types/interfaces/booking.interface';
import { AscendingSortIcon, DefaultSortIcon, DescendingSortIcon } from '../../../utils/icons/SortingIcon';
import SelectForm from '../../../components/SelectForm';
import { bookingStatusBorderColorMapping, bookingStatusOptions } from '../../../types/enum/bookingStatus';
import HoverContent from '../../../components/HoverContent';
import { QuestionMarkIcon } from '../../../utils/icons/QuestionMarkIcon';
import { AddIcon } from '../../../utils/icons/AddIcon';
import { NoteIcon } from '../../../utils/icons/NoteIcon';
import { CakeIcon } from '../../../utils/icons/CakeIcon';
import { CAKE_TYPE, mapCakeType } from '../../../types/enum/cakeType';
import { NextPaginationIcon, PreviousPaginationIcon } from '../../../utils/icons/PaginationIcon';
import { FullStarIcon } from '../../../utils/icons/StarIcon';
import { ChildIcon } from '../../../utils/icons/ChildIcon';
import { ChildSeatsIcon } from '../../../utils/icons/ChildSeatsIcon';
import { IOffer } from '../../../types/interfaces/offer.interface';
import { IProposal } from '../../../types/interfaces/proposal.interface';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { IBookingRequest } from '../../../types/interfaces/bookingRequest.interface';
import { AutoBookingType } from '../../../types/enum/autoBookingType.enum';
import { colorPalette } from '../../../types/enum/colorPalette';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { replacePaginationData } from '../../../store/booking/bookingSlice';

export type SortDirection = 'ASC' | 'DESC';

export type TableHeader = {
    key: keyof PartialIBooking<{ proposals: IProposal, offer: IOffer, bookingRequest: IBookingRequest }>;
    label: string;
};

interface IProps {
    headers: TableHeader[];
    rows: PartialIBooking<{ proposals: IProposal, offer: IOffer, bookingRequest: IBookingRequest }>[];
    onHeaderClick: (...args: any[]) => void;
    onRowClick: (id?: string) => void;
    onStatusChange: (...args: any[]) => void;
    sorting: { [key: string]: SortDirection };
    totalPages: number;
    dataLoading: boolean;
    paginationOptions?: number[];
    indexRow?: boolean;
    showIcon?: boolean;
}

const BookingTable: React.FC<IProps> = ({ headers, rows, onHeaderClick, onRowClick, onStatusChange, sorting, totalPages, dataLoading, paginationOptions = [15, 30, 50, 70, 100], indexRow = true, showIcon = true }) => {
    const paginationData = useAppSelector(state => state.booking.paginationData);
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [headerLength, setHeaderLength] = useState(headers.length + 1);
    const pageSize = searchParams.has('pageSize') ? Number(searchParams.get('pageSize')) : paginationData.pageSize;
    const currentPage = searchParams.has('currentPage') ? Number(searchParams.get('currentPage')) : paginationData.currentPage;

    useEffect(() => {
        const index = !!indexRow ? 1 : 0;
        setHeaderLength(headers.length + index);
    }, [headers]);

    // Function to handle the rows to show per page
    const handleButtonClick = (size: number) => {
        if (searchParams.has('pageSize') || searchParams.has('currentPage')) {
            searchParams.set('pageSize', size.toString());
            searchParams.set('currentPage', '1');
            setSearchParams(searchParams);
        } else {
            dispatch(replacePaginationData({ field: 'pageSize', value: size }));
            dispatch(replacePaginationData({ field: 'currentPage', value: 1 }));
        }
    };

    // Functions to handle the pagination logics
    const handleNextPage = () => {
        if (searchParams.has('currentPage')) {
            searchParams.set('currentPage', (Number(searchParams.get('currentPage')) + 1).toString());
            setSearchParams(searchParams);
        } else {
            dispatch(replacePaginationData({ field: 'currentPage', value: paginationData.currentPage + 1 }));
        }
    };

    const handlePrevPage = () => {
        if (searchParams.has('currentPage')) {
            searchParams.set('currentPage', (Number(searchParams.get('currentPage')) - 1).toString());
            setSearchParams(searchParams);
        } else {
            dispatch(replacePaginationData({ field: 'currentPage', value: paginationData.currentPage - 1 }));
        }
    };

    const renderIconColor = (iconActive: boolean, isSelected: boolean) => {
        if (iconActive) {
            if (isSelected) {
                return colorPalette.White
            } else {
                return colorPalette.Primary
            }
        } else {
            if (isSelected) {
                return colorPalette.Primary
            } else {
                return colorPalette.MediumGray
            }
        }
    };

    return (
        <div className="flex-1">
            {/* Table Header */}
            <div className={`grid grid-cols-${headerLength} bg-white rounded-t py-4`}>

                {/* Index */}
                {indexRow &&
                    <div className="flex items-center justify-center">
                        <p title='Indice' className='font-semibold text-text text-base truncate select-none'>
                            Creata il
                        </p>
                    </div>
                }

                {/* Header's field */}
                {headers.map((header, index) => (
                    <div
                        key={index}
                        className={`flex items-center ${header.key !== 'proposals' && 'cursor-pointer'} justify-between mx-2 ml-2.5`}
                        onClick={() => {
                            if (header.key !== 'proposals')
                                onHeaderClick(header.key)
                        }}
                    >
                        <p title={header.label} className='font-bold text-text text-base truncate select-none'>
                            {header.label}
                        </p>

                        {/* Display the sorting indicator based on the sorting state */}
                        {header.key !== 'proposals'
                            ? (Object.keys(sorting)[0] === header.key)
                                ? sorting[Object.keys(sorting)[0]] === 'ASC'
                                    ? <AscendingSortIcon />
                                    : <DescendingSortIcon />
                                : <DefaultSortIcon />
                            : null
                        }
                    </div>
                ))}
            </div>

            {dataLoading
                ? <div className='h-96 flex items-center'>
                    <LoadingIndicator icon={true} />
                </div>
                : <div className='flex flex-col'>
                    {location.pathname.includes('/create')
                        ? <div className={`text-center bg-primary p-2 cursor-default select-none text-text_white`}>
                            <p className='font-semibold text-sm truncate'>
                                Inserisci i dati nella tabella di prenotazione
                            </p>
                        </div>
                        : null
                    }

                    {/* Table Rows */}
                    {rows.map((row, rowIndex) => (
                        <div
                            key={rowIndex}
                            onClick={() => onRowClick(row._id)}
                            style={{ borderTop: "2px solid #f2f2f2", borderBottom: "2px solid #f2f2f2" }}
                            className={`
                                grid grid-cols-${headerLength} items-center shadow h-16 cursor-pointer font-medium text-base 
                                ${(params.id === row._id) ? 'bg-primary text-text_white' : 'bg-white text-text'}  
                                ${(rowIndex === rows.length - 1) && 'rounded-b'}
                                ${bookingStatusBorderColorMapping[row.bookingStatus]} border-l-4
                            `}
                        >

                            {/* Row Booking index */}
                            {indexRow &&
                                <div className='flex flex-col items-center gap-1'>
                                    <div className='flex items-center gap-1 justify-center'>
                                        {!!row.isImportant && <FullStarIcon size='24' color={(params.id === row._id) ? 'white' : '#5B2FF6'} />}
                                        <p title={String(rowIndex + 1)} className='text-center truncate h-full flex items-end'>
                                            {(typeof row?.createdAt !== 'undefined') ? row?.createdAt?.split('T')[0].split('-').reverse().join('/') : 'N.D.'}
                                        </p>
                                    </div>
                                    {row.bookingRequest?.autoBookingType === AutoBookingType.AUTOMATIC && <p className={`text-xs font-semibold ${params.id === row._id ? 'text-text_white' : "text-info"}`}>Online</p>}
                                </div>
                            }

                            {/* Row Booking fields */}
                            {headers.map((header, index) => {
                                const cellValue = row[header.key as keyof IBooking];
                                return <div key={index} className='flex gap-2 mx-2 items-center'>
                                    {(header.key === 'bookingStatus')
                                        ? <SelectForm
                                            options={bookingStatusOptions.filter(status => status.value !== cellValue)}
                                            isColorChange={true}
                                            selectedOption={cellValue}
                                            setSelectedOption={(event) => onStatusChange(event.value, row._id)}
                                            arrow={false}
                                            containerClassName='w-full'
                                        />
                                        : (header.key === 'offer')
                                            ? <div className='flex flex-col w-full'>
                                                <p title={row.offer.name + ', ' + row.shift} className={`truncate capitalize ${showIcon && 'text-'}`}>{row.offer.name}{!!row.shift && ', ' + row.shift}</p>
                                                {!!showIcon && <div className='grid grid-cols-4 gap-1 items-center'>
                                                    <HoverContent isActive={!row.proposals.length} title='Offerte da definire' content={"Le opzioni di servizio per questa prenotazione non sono ancora state definite.\nTi invitiamo a definirle al più presto."} element={<QuestionMarkIcon color={renderIconColor(!row.proposals.length, params.id === row._id)} size='18' />} />
                                                    <HoverContent isActive={row.sharedBookingsNew.length > 0} title='Servizi extra' content={`In questa prenotazione sono inclusi anche ${row.sharedBookingsNew.length} servizi aggiuntivo/i oltre a quello corrente.`} element={<AddIcon color={renderIconColor(row.sharedBookingsNew.length > 0, params.id === row._id)} size='30' />} />
                                                    <HoverContent isActive={!!row.notes || !!row.clientNotes} title='Note' content={row.notes} element={<NoteIcon color={renderIconColor(!!row.notes || !!row.clientNotes, params.id === row._id)} size='18' />} />
                                                    <HoverContent isActive={row.cakeType !== CAKE_TYPE.NO_CAKE} title='Dolce' content={mapCakeType(row.cakeType) + '\n' + row.cakeNote} element={<CakeIcon color={renderIconColor(row.cakeType !== CAKE_TYPE.NO_CAKE, params.id === row._id)} height='18' width='18' />} />
                                                </div>}
                                            </div>
                                            : header.key === 'people'
                                                ? <div className='grid grid-cols-5 gap-4 w-full'>
                                                    <p>{row.people}</p>
                                                    {!!showIcon && <div className='col-span-3 grid grid-cols-3 gap-1 items-center'>
                                                        <HoverContent isActive={!!row.isPeopleVariable} title='Persone da definire' content={"I partecipanti per questa prenotazione possono variare.\nSi prega di contattare il prenotante per definire il numero esatto."} element={<QuestionMarkIcon color={renderIconColor(!!row.isPeopleVariable, params.id === row._id)} size='18' />} />
                                                        <HoverContent isActive={!!row.child} title='Bambini' content='In questa prenotazione sono presenti bambini.' element={<ChildIcon color={renderIconColor(!!row.child, params.id === row._id)} size='18' />} />
                                                        <HoverContent isActive={!!row.childSeats} title='Seggiolini' content={`Sono stati richiesti ${row.childSeats} dei seggiolini.`} element={<ChildSeatsIcon color={renderIconColor(!!row.childSeats, params.id === row._id)} size='18' />} />
                                                    </div>
                                                    }
                                                </div>
                                                : header.key === 'surname'
                                                    ? <p title={row.name + ' ' + row.surname} className='line-clamp-2 capitalize'>{row.name} {row.surname}</p>
                                                    : (header.key === 'proposals' && Array.isArray(cellValue))
                                                        ? <p className='whitespace-pre-wrap line-clamp-2 capitalize'>
                                                            {!!row.proposals?.length
                                                                ? row.proposals?.map(value => value.proposal.name).join(', ')
                                                                : 'Da definire.'
                                                            }
                                                        </p>
                                                        : !!cellValue && <p title={String(cellValue)} className='truncate capitalize w-full'>{String(cellValue)}</p>
                                    }
                                </div>
                            })}
                        </div>
                    ))}


                    {/* Pagination Buttons */}
                    <div className="flex justify-between items-center mt-4">
                        <div className="flex gap-2 items-center">
                            {paginationOptions.map((item, index) => (
                                <div key={index}
                                    onClick={() => handleButtonClick(item)}
                                    className={`px-4 py-1 rounded-full shadow font-semibold cursor-pointer ${item === pageSize ? 'bg-primary text-text_white' : 'bg-white text-text'}`}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>

                        <div className="flex gap-1 items-center whitespace-nowrap">
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className="rounded p-1 disabled:opacity-25"
                            >
                                <PreviousPaginationIcon />
                            </button>
                            <div className='px-2 py-1 bg-white rounded-full shadow'>
                                <p className='text-text font-semibold'>{currentPage} / {totalPages}</p>
                            </div>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="rounded p-1 disabled:opacity-25"
                            >

                                <NextPaginationIcon />
                            </button>
                        </div>
                    </div>
                </div>}

        </div >
    );
};

export default BookingTable;
