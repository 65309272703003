import { useNavigate } from 'react-router-dom';
import CustomButton from './CustomButton';

const NoPermissionPage = () => {
    const navigate = useNavigate();

    const goToHomePage = () => {
        navigate(-1);
    };

    return (
        <div className="flex flex-col h-full w-full gap-8 items-center justify-center p-8">
            <h2 className="text-xl font-medium mb-4 text-center">Non hai i permessi per accedere a questa pagina</h2>
            <CustomButton onClickHandler={goToHomePage} color='bg-info' label='Vai alla home' />
        </div>
    );
};

export default NoPermissionPage;
