import { useEffect, useState } from 'react';

function useScreenSize() {
    const [screenSize, setScreenSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl'>(getScreenSize());

    function getScreenSize() {
        const screenWidth = window.innerWidth;

        if (screenWidth < 576) {
            return 'xs';
        } else if (screenWidth < 768) {
            return 'sm';
        } else if (screenWidth < 992) {
            return 'md';
        } else if (screenWidth < 1200) {
            return 'lg';
        } else {
            return 'xl';
        }
    }

    useEffect(() => {
        function handleResize() {
            const newSize = getScreenSize();
            if (newSize !== screenSize) {
                setScreenSize(newSize);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenSize]);

    return screenSize;
}

export default useScreenSize;
