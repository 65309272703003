import moment from 'moment';
import { NAV_ACTIONS } from '../types/enum/navbarActions';
import { PluginIdentifier } from '../types/enum/pluginIdentifier';
import { isAction } from '@reduxjs/toolkit';

export const SidebarDataAdmin = [
    {
        title: 'HomePage',
        subNav: [
            {
                title: "Home",
                path: "/dashboard/home"
            },
            {
                title: "Prenotazioni",
                path: "/dashboard/bookings",
                key: PluginIdentifier.BOOKINGS
            },
            {
                title: "Richieste",
                path: `/dashboard/requests?page=0&date=${moment().utc(true).toISOString()}&status=pending`,
                key: PluginIdentifier.ONLINE_BOOKINGS
            },
            {
                title: "Servizi",
                path: "/dashboard/servizi",
                key: PluginIdentifier.OFFERS
            },
            {
                title: "Eventi",
                path: `/dashboard/events?startDate=${moment().utc(true).startOf('month').startOf('week').toISOString()}&endDate=${moment().utc(true).endOf('month').endOf('week').toISOString()}`,
                key: PluginIdentifier.EVENTS
            },
            {
                title: "Ingressi",
                path: "/dashboard/plugins/ingressi",
                key: PluginIdentifier.ENTRANCES
            },
            {
                title: "Cucina",
                path: "/dashboard/plugins/cucina",
                key: PluginIdentifier.KITCHEN
            },
            // {
            //     title: "Comande",
            //     path: "/dashboard/plugins/comande",
            //     key: SERVICE.ORDERS
            // }
        ],
        footerNav: [
            {
                title: "Impostazioni Struttura",
                isAction: NAV_ACTIONS.SETTINGS_STRUCTURE,
                path: "/dashboard/struttura",
                key: PluginIdentifier.STRUCTURE
            },
            {
                title: "Cambia Struttura",
                isAction: NAV_ACTIONS.CHANGE_STRUCTURE,
                path: "/structure"
            },
            {
                title: "Logout",
                isAction: NAV_ACTIONS.LOGOUT,
                path: "/"
            }
        ]
    }
]