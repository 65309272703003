import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { retrieveConfig } from '../config/config.environment';
import { API_ACTIONS } from '../types/enum/apiActions';

export interface IApiHandlerHelper {
    get: getHandler;
    post: postHandler;
    put: putHandler;
    del: delHandler;
}

export type apiRequestAction = () => Promise<getHandler | postHandler | putHandler | delHandler>;

const apiRequest = async <T>(method: API_ACTIONS, url: string, data?: any, params?: Record<string, any>, isMultipart: boolean = false): Promise<T | any | AxiosError> => {
    const headers: Record<string, string> = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
    };

    try {
        const response: AxiosResponse<T> = await axios({
            baseURL: retrieveConfig().apiBaseUrl,
            url,
            method,
            headers,
            data,
            params,
            withCredentials: true
        });

        return response.data;
    } catch (error: any) {
        // imin error
        if (error?.response?.data) {
            return error.response.data
        }

        // base error
        throw error
    }
};

// Helper function to make a GET API request
export type getHandler = <T>(path: string, params?: Record<string, any>, data?: any) => Promise<T | AxiosError>;
const get = <T>(path: string, params?: Record<string, any>, data?: any): Promise<AxiosError | T> => apiRequest<T>(API_ACTIONS.GET, path, data, params);

// Helper function to make a POST API request
export type postHandler = <T>(path: string, data?: any) => Promise<T | AxiosError>;
const post = <T>(path: string, data?: any): Promise<AxiosError | T> => apiRequest<T>(API_ACTIONS.POST, path, data);

// Helper function to make a PUT API request
export type putHandler = <T>(path: string, data?: any, isMultipart?: boolean) => Promise<T | AxiosError | any>;
const put = <T>(path: string, data?: any, isMultipart: boolean = false): Promise<AxiosError | T | any> => apiRequest<T>(API_ACTIONS.PUT, path, data, undefined, isMultipart);

// Helper function to make a DELETE API reques
export type delHandler = <T>(path: string, data?: any) => Promise<T | AxiosError>;
const del = <T>(path: string): Promise<AxiosError | T> => apiRequest<T>(API_ACTIONS.DELETE, path);

export const apiHandler: IApiHandlerHelper = {
    get,
    post,
    put,
    del
};
