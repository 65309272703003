import { createSlice, Slice } from "@reduxjs/toolkit";
import { accountState } from "./accountState";

export const AccountSlice: Slice<any> = createSlice({
    name: "account",
    initialState: accountState,
    reducers: {
        replaceUser(state, action) {
            state.user = action.payload.user
        },
        replaceUserStructure(state, action) {
            state.userStructure = action.payload.userStructure
        }
    }
});

export const { replaceUser, replaceUserStructure } = AccountSlice.actions;
export default AccountSlice.reducer;