export enum CAKE_TYPE {
    PERSONAL,
    DESSERT,
    NO_CAKE
};

const cakeTypeMapping = {
    PERSONAL: CAKE_TYPE.PERSONAL,
    DESSERT: CAKE_TYPE.DESSERT,
    NO_CAKE: CAKE_TYPE.NO_CAKE
};

export const cakeTypeTranslateMapping = {
    [CAKE_TYPE.PERSONAL]: 'Propria',
    [CAKE_TYPE.DESSERT]: 'Torta',
    [CAKE_TYPE.NO_CAKE]: 'Nessuna Torta'
};

export enum OLD_CAKE_TYPE {
    PERSONAL = 'Propria',
    DESSERT = 'Torta',
    NO_CAKE = 'Nessuna Torta'
};

export const mapCakeType = (value: any) => {
    if (Object.keys(OLD_CAKE_TYPE).includes(value as string)) {
        return OLD_CAKE_TYPE[value as keyof typeof OLD_CAKE_TYPE];
    }

    return cakeTypeTranslateMapping[value as CAKE_TYPE] || value
};

export const cakeTypeOptions: { label: string; value: CAKE_TYPE }[] = Object.values(cakeTypeMapping).map(key => ({
    label: mapCakeType(Number(key) as CAKE_TYPE) as string,
    value: Number(key) as CAKE_TYPE,
}));