import React from "react";
import '../utils/style/LoadingIndicator.css'

interface IProps {
    wFull?: boolean;
    additionalStyle?: string;
    icon?: boolean;
    label?: string;
    color?: string;
}
const LoadingIndicator: React.FC<IProps> = ({ wFull = true, additionalStyle, icon = false, label = 'Caricamento', color = 'text-primary' }) => {
    return (
        <div className={`flex flex-1 m-auto items-center justify-center  ${wFull ? 'w-full' : ''} ${additionalStyle}`}>
            {(icon)
                ? <svg
                    className={`animate-spin h-12 w-12 ${color}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.86 3.185 8.009l2-2.718z"
                    />
                </svg>
                : <p className="font-medium text-gray-500 text-sm flex items-center">{label}<span className="animate-loading-dots ml-2"></span></p>
            }
        </div>
    )
}

export default LoadingIndicator