import { useState } from "react";
import useCookie from "../../hooks/useCookie";
import InputForm from "../../components/InputForm";
import CustomButton from "../../components/CustomButton";
import { IminLogoSvg } from "../../utils/icons/IminLogoSvg";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { useNavigate } from "react-router-dom";
import { HttpMethod, apiService } from "../../services/apiService";

const SigninForm = () => {
    const navigate = useNavigate();
    const { setCookie } = useCookie({ userData: 'user_cookie' });
    const { showErrorModal } = useModalDispatcher();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        emailError: false,
        passwordError: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [validateAccount, setValidateAccount] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLogin = async () => {
        setIsLoading(true);
        const { email, password } = formData;
        const emailError = !emailRegex.test(email);
        const passwordError = !passwordRegex.test(password);

        setFormData({
            ...formData,
            emailError,
            passwordError,
        });

        if (emailError || passwordError) {
            setIsLoading(false);
            return;
        }

        try {

            const response = await apiService(HttpMethod.POST, '/user/login', { email, password });

            setCookie('userData', response.accessToken, response.exp);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                if (error.errorCode === "US005") {
                    setValidateAccount(true);
                }
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return (
        <div className="flex flex-col overflow-hidden justify-center items-center">
            <IminLogoSvg />
            <p className="text-3xl md:text-5xl font-bold text-primary">
                Bentornato!
            </p>

            <div className="w-full max-sm:mt-5 md:mt-10 flex flex-col gap-2" >
                <InputForm
                    label='Email'
                    value={formData.email}
                    setValue={(value) => handleInputChange('email', value)}
                    error={formData.emailError}
                    errorMessage='Inserire una email valida'
                    onSearchSubmit={handleLogin}
                />

                <InputForm
                    label='Password'
                    value={formData.password}
                    setValue={(value) => handleInputChange('password', value)}
                    error={formData.passwordError}
                    errorMessage='La password deve contenere almeno 8 caratteri, una lettera maiuscola e un numero.'
                    type='password'
                    onSearchSubmit={handleLogin}
                />
                {(validateAccount) &&
                    <p onClick={() => navigate("/auth/verify?email=" + formData.email)} className="mt-4 block text-sm text-center font-medium text-primary cursor-pointer hover:underline" >
                        Valida il tuo account prima di procedere
                    </p>}

                <CustomButton disabled={isLoading} label='Entra' color='bg-primary' className="max-sm:mt-5 md:mt-10 px-8 py-4 uppercase rounded-full" onClickHandler={handleLogin} />
            </div>

            <a href="/auth/reset-password" className="mt-4 block text-sm text-center font-medium text-primary hover:underline" >
                Password dimenticata?
            </a>
        </div>
    );
};

export default SigninForm;