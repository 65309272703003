import { useEffect, useRef, useState } from "react";
import { REQUEST_BOOKING_STATUS } from "../../../../types/enum/requestBookingStatus.enum";
import { IBookingRequest } from "../../../../types/interfaces/bookingRequest.interface";
import { IOffer } from "../../../../types/interfaces/offer.interface";
import { IProposal } from "../../../../types/interfaces/proposal.interface";
import { IStructure } from "../../../../types/interfaces/structure.interface";
import moment from "moment";
import Text from "../../../../components/Text";

interface IBookingRequestCardProps {
    bookingRequest: IBookingRequest<IStructure, IOffer, IProposal>;
    handleAcceptBookingRequest: (bookingRequest: IBookingRequest) => void;
    handleRejectBookingRequest: (bookingRequest: IBookingRequest) => void;
    handleModifyBookingRequest: (bookingRequest: IBookingRequest<IStructure, IOffer, IProposal>) => void;
    loading: boolean;
}

const BookingRequestCard: React.FC<IBookingRequestCardProps> = ({ bookingRequest, handleAcceptBookingRequest, handleRejectBookingRequest, handleModifyBookingRequest, loading }) => {

    const paragraphRef = useRef<HTMLParagraphElement>(null);
    const [lineCount, setLineCount] = useState<number>(0);
    const [extendNotes, setExtendNotes] = useState(false);

    useEffect(() => {
        if (paragraphRef.current) {
            const element = paragraphRef.current;
            const style = window.getComputedStyle(element);
            const lineHeight = parseFloat(style.lineHeight);
            const height = element.clientHeight;
            const numberOfLines = Math.round(height / lineHeight);
            setLineCount(numberOfLines);
            paragraphRef.current.classList.add('line-clamp-3');
        }
    }, []);

    const renderStatusName = (status: REQUEST_BOOKING_STATUS) => {
        switch (status) {
            case REQUEST_BOOKING_STATUS.ACCEPTED:
                return 'Accettato';
            case REQUEST_BOOKING_STATUS.REJECTED:
                return 'Rifiutato';
            case REQUEST_BOOKING_STATUS.CANCELLED:
                return 'Cancellato';
            case REQUEST_BOOKING_STATUS.UPDATED:
                return 'Modificato';
            case REQUEST_BOOKING_STATUS.PENDING:
                return 'In Attesa';
            default:
                return '';
        }
    };

    return <div className='shadow w-full p-4 rounded-lg mb-4 bg-white'>
        <div className="flex flex-col lg:grid lg:grid-cols-7 lg:flex-row justify-between gap-2 py-4 w-full">
            <div className="flex lg:flex-col 2xl:flex-row items-center justify-between lg:justify-around">
                <div className="h-full bg-white flex flex-col items-center justify-center mx-4">
                    <div className="text-gray-800 text-lg font-bold">{moment(bookingRequest.date).format('MMMM')}</div>
                    <div className="text-black text-2xl font-semibold">{moment(bookingRequest.date).format('DD')}</div>
                </div>

                <div className='h-full flex items-center'>
                    <div className='px-4 py-1 bg-blue-200 rounded-full w-fit text-center text-blue-500 flex items-center gap-2'>
                        <p className='capitalize'>{renderStatusName(bookingRequest.status)}</p>
                        <div className='w-2 h-2 rounded-full bg-blue-500'></div>
                    </div>
                </div>
            </div>

            <Text title="Nominativo" label={bookingRequest?.name + " " + bookingRequest?.surname + "\n" + bookingRequest?.phoneNumber} />

            <Text title="Persone" label={bookingRequest.people} />

            <div className="flex flex-col">
                <Text title="Servizio & Proposta" label={!!bookingRequest.proposal ? bookingRequest.offer.name + ", " + bookingRequest?.proposal?.name : bookingRequest.offer.name + ", " + "Proposta da Definire"} />
                {bookingRequest.shift && <Text title="" label={"Turno: " + bookingRequest.shift} />}
            </div>

            <div>
                <Text refs={paragraphRef} id={'#note-container'} title="Note" label={bookingRequest.notes} className={`break-words ${extendNotes ? '' : 'line-clamp-3'}`} />
                {lineCount >= 3 && <button onClick={() => setExtendNotes(!extendNotes)} className='text-primary font-medium text-sm '>{extendNotes ? 'Nascondi' : 'Visualizza'}</button>}
            </div>

            <Text title="Data" label={moment(bookingRequest.createdAt).format('DD/MM/YYYY')} />

            {bookingRequest.status === REQUEST_BOOKING_STATUS.PENDING &&
                <div className='h-full flex lg:flex-col 2xl:flex-row  items-center justify-between gap-2'>
                    <button
                        disabled={loading}
                        className='text-white font-medium bg-red-500 px-4 py-2 rounded w-28'
                        onClick={() => handleRejectBookingRequest(bookingRequest)}
                    >
                        Rifiuta
                    </button>
                    <button
                        className='text-white font-medium bg-primary px-4 py-2 rounded w-28'
                        onClick={() => handleAcceptBookingRequest(bookingRequest)}
                        disabled={loading}
                    >
                        Accetta
                    </button>
                </div>
            }

            {bookingRequest.status === REQUEST_BOOKING_STATUS.UPDATED &&
                <div className="h-full flex items-center">
                    <button
                        disabled={loading}
                        className='text-white font-medium bg-primary px-4 py-2 rounded md:w-58 h-fit w-full'
                        onClick={() => handleModifyBookingRequest(bookingRequest)}
                    >
                        Visualizza
                    </button>
                </div>
            }
        </div>

    </div >
}

export default BookingRequestCard