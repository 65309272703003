import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function formatItalianNumber(phoneInput: string): string | boolean {
    // Rimuovi spazi e trattini dal numero di telefono
    let cleanedNumber = phoneInput.replace(/\s|-/g, '');

    // Se il numero non inizia con '+39', aggiungi il prefisso internazionale
    if (!cleanedNumber.startsWith('+39')) {
        if (cleanedNumber.startsWith('39')) {
            cleanedNumber = '+' + cleanedNumber;
        } else {
            cleanedNumber = '+39' + cleanedNumber;
        }
    }

    const phoneNumber = parsePhoneNumberFromString(cleanedNumber, 'IT');

    if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'IT') {
        return phoneNumber.formatInternational();
    } else {
        return false;
    }
}
