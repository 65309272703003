import * as React from "react"
const AddServiceIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="#5B2FF6"
      d="M20.378 28.89H3.662c-1.101 0-1.987-.373-2.657-1.122C.335 27.02 0 26.128 0 25.094c0-1.111.335-2.012 1.005-2.702.67-.69 1.556-1.034 2.657-1.034h16.716V3.89c0-1.03.355-1.937 1.063-2.719C22.151.391 22.994 0 23.973 0c.979 0 1.831.39 2.558 1.172.727.782 1.09 1.688 1.09 2.719v17.467h16.717c1.043 0 1.914.35 2.613 1.049.7.7 1.049 1.591 1.049 2.675 0 1.084-.35 1.99-1.049 2.717-.699.728-1.57 1.092-2.613 1.092H27.622v17.384c0 1.014-.362 1.888-1.087 2.623-.724.735-1.587 1.102-2.589 1.102-.979 0-1.818-.367-2.518-1.102a3.672 3.672 0 0 1-1.05-2.623V28.89Z"
    />
  </svg>
)
export default AddServiceIcon 
