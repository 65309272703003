import { useState } from 'react'
import { Form, Navigate, useLoaderData, useNavigate, useNavigation, useSubmit } from 'react-router-dom';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { SquaringCircles } from '../../../../utils/icons/SquaringCircles';
import { IminDashboard } from '../../../../utils/icons/IminDashboard';
import Step1Update from './Step1Update';
import Step2Update from './Step2Update';
import Step3Update from './Step3Update';
import Step4Update from './Step4Update';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import moment from 'moment';
import CustomButton from '../../../../components/CustomButton';
import InputForm from '../../../../components/InputForm';
import { AutoBookingType } from '../../../../types/enum/autoBookingType.enum';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';

const BookingRequestUpdateWizard = () => {
    const bookingRequest = useLoaderData() as IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    const submit = useSubmit();
    const navigation = useNavigation();
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(4);
    const [data, setData] = useState<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>(bookingRequest as IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>);

    const handleNext = <T extends keyof IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>(fields: T[], value: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>[T], step: number) => {
        const updatedData = { ...data };

        if (step === 2) {
            updatedData.date = moment().utc(true).toDate();
            updatedData.people = 0;
            updatedData.proposal = {} as IProposal;
        } else if (step === 3) {
            updatedData.people = 0;
            updatedData.proposal = {} as IProposal;
        } else if (step === 4) {
            updatedData.proposal = {} as IProposal;
        }

        for (const field of fields) {
            updatedData[field] = value;
        }

        setData(updatedData);
        setCurrentStep(step);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const formElement = e.target;
        const form = new FormData(formElement);
        form.append("input", JSON.stringify(data));

        submit(form, { method: "put" });
    };

    if (bookingRequest.autoBookingType === AutoBookingType.SEMI_AUTOMATIC) {
        return Navigate({ to: `details/` + bookingRequest._id });
    };

    if (bookingRequest.deleted) {
        return Navigate({ to: `/${bookingRequest.structure.name}/${bookingRequest.structure._id}` });
    };

    return (
        <div className='flex flex-col justify-between h-full'>
            <div className='flex flex-col justify-between flex-1'>

                <div className='w-full bg-white shadow-md px-8 py-4 text-center'>
                    <p className='line-clamp-1 font-bold text-4xl text-primary'>{bookingRequest?.structure?.name}</p>
                    <p className='line-clamp-1 font-base text-sm text-text'>{bookingRequest.structure.address.city} ({bookingRequest.structure.address.state}), {bookingRequest.structure.address.street}</p>
                </div>

                <div className='flex flex-col h-full w-full'>
                    <div className='flex flex-col h-full w-full py-4 gap-6'>
                        <div className='flex flex-col px-4 gap-4'>
                            <p className='text-base font-semibold text-text'>Modifica la tue preferenze ed aggiorna la tua prenotazione in un solo click!</p>
                        </div>


                        <div className='px-4 flex flex-col gap-2'>
                            <InputForm isDisabled label='Nome' value={data.name} setValue={(e) => setData({ ...data, name: e })} backgroundColor='bg-white' />
                            <InputForm isDisabled label='Cognome' value={data.surname} setValue={(e) => setData({ ...data, surname: e })} backgroundColor='bg-white' />
                            <InputForm isDisabled label='Telefono' value={data.phoneNumber} setValue={(e) => setData({ ...data, phoneNumber: e })} backgroundColor='bg-white' />
                            <InputForm label='Note' multiline={true} multilineRows={6} type='textarea' value={data.notes} setValue={(e) => setData({ ...data, notes: e })} backgroundColor='bg-white' />
                        </div>

                        {currentStep >= 1 && <div className="flex flex-col gap-2">
                            <p className='px-4 font-semibold'>Turno</p>
                            <Step1Update newBookingRequest={data} handleNext={handleNext} />
                            <div className='flex flex-col px-4 gap-4 mt-4'>
                                <div className='w-full border-t border-darkGray' />
                            </div>
                        </div>}

                        {currentStep >= 2 && <div className="flex flex-col gap-2">
                            <p className='px-4 font-semibold'>Seleziona la data che preferisci</p>
                            <Step2Update newBookingRequest={data} handleNext={handleNext} />
                            <div className='flex flex-col px-4 gap-4 mt-4'>
                                <div className='w-full border-t border-darkGray' />
                            </div>
                        </div>}

                        {currentStep >= 3 && <div className="flex flex-col gap-2">
                            <p className='px-4 font-semibold'>Quate persone siete?</p>
                            <Step3Update newBookingRequest={data} handleNext={handleNext} />
                            <div className='flex flex-col px-4 gap-4 mt-4'>
                                <div className='w-full border-t border-darkGray' />
                            </div>
                        </div>}

                        {currentStep >= 4 && <div className="flex flex-col gap-2">
                            <p className='px-4 font-semibold'>Scegli una delle nostre proposte</p>
                            <Step4Update newBookingRequest={data} handleNext={handleNext} />
                        </div>}

                    </div>

                    <Form method='POST' className='flex flex-col md:flex-row-reverse md:justify-between gap-2 p-4' onSubmit={handleSubmit} >
                        <CustomButton type='submit' disabled={navigation.state !== 'idle'} label="Modifica" color='bg-primary' textColor='text-text_white' />
                        <CustomButton type='button' label="Indietro" onClickHandler={() => navigate(-1)} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />
                    </Form>


                    <div className='flex w-full items-center justify-between bg-primary p-4'>
                        <IminDashboard color='#FFF' />
                        <div className='flex flex-col gap-1 items-center'>
                            <p className='font-medium text-xs text-text_white'>Powered by</p>
                            <a href='https://thesquaringcircles.com/' target='_blank' rel='noreferrer'><SquaringCircles /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingRequestUpdateWizard