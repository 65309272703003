export const alphanumericSort = (arr: string[]): string[] => {
    return arr.slice().sort((a, b) => {
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
        }

        if (!isNaN(numA)) {
            return -1;
        }

        if (!isNaN(numB)) {
            return 1;
        }

        return a.localeCompare(b);
    });
};
