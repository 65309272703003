import { useLoaderData } from 'react-router-dom';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import { IminDashboard } from '../../../../utils/icons/IminDashboard';
import { SquaringCircles } from '../../../../utils/icons/SquaringCircles';
import { AutoBookingType } from '../../../../types/enum/autoBookingType.enum';
import AutomaticDetails from './details_pages/AutomaticDetails';
import SemiAutomaticDetails from './details_pages/SemiAutomaticDetails';
import { PartialIBooking } from '../../../../types/interfaces/booking.interface';
import { IProduct } from '../../../../types/interfaces/product.inteface';
import { validateLongString } from '../../../offer/templates/stepValidations';

const RequestInfo = () => {
    const bookingRequest = useLoaderData() as IBookingRequest<IStructure, IOffer, IProposal<string, string, IProduct>, PartialIBooking<{ proposals: IProposal<string, string, IProduct> }>>;

    return (
        <div className='h-full w-full flex flex-col gap-4'>
            {/* HEADER */}
            <div className='w-full bg-white shadow-md px-8 py-4 text-center'>
                <p className='line-clamp-1 font-bold text-4xl text-primary'>{bookingRequest?.structure?.name}</p>
                <p className='line-clamp-1 font-base text-sm text-text'>{bookingRequest.structure.address.city} ({bookingRequest.structure.address.state}), {bookingRequest.structure.address.street}</p>
            </div>

            {/* BODY */}
            {bookingRequest.autoBookingType === AutoBookingType.AUTOMATIC
                ? <AutomaticDetails bookingRequest={bookingRequest} />
                : <SemiAutomaticDetails bookingRequest={bookingRequest} />}

            {/* FOOTER */}
            <div className='flex w-full items-center justify-between bg-primary p-4'>
                <IminDashboard color='#FFF' />
                <div className='flex flex-col gap-1 items-center'>
                    <p className='font-medium text-xs text-text_white'>Powered by</p>
                    <a href='https://thesquaringcircles.com/' target='_blank' rel='noreferrer'><SquaringCircles /></a>
                </div>
            </div>
        </div>
    )
}

export default RequestInfo