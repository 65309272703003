import React, { useState } from 'react';

interface IProps {
    isOn: boolean;
    toggleSwitch: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    size?: 'sm' | 'md' | 'lg';
}

const Switch: React.FC<IProps> = ({ isOn, toggleSwitch, size = 'sm' }) => {
    const containerSize = {
        sm: 'w-8 h-4',
        md: 'w-12 h-6',
        lg: 'w-16 h-8'
    };

    const circleSize = {
        sm: 'w-3 h-3',
        md: 'w-5 h-5',
        lg: 'w-7 h-7'
    };

    const translate = {
        sm: 'translate-x-4',
        md: 'translate-x-6',
        lg: 'translate-x-8'
    };

    return (
        <div onClick={toggleSwitch} className={`relative inline-block rounded-full cursor-pointer ${isOn ? 'bg-secondary' : 'bg-error'} ${containerSize[size]}`} >
            <div className={`absolute inset-0.5 rounded-full bg-white ${circleSize[size]} transform ${isOn ? translate[size] : 'translate-x-0'} transition-transform`} />
        </div>
    );
};

export default Switch;
