import { io, Socket } from 'socket.io-client';
import { Dispatch } from 'redux';
import { connect, disconnect, handleSocketNotificationEvent } from '../../store/socket/socketSlice';
import { retrieveConfig } from '../../config/config.environment';
import { SOCKET_EVENTS } from '../../types/enum/socketEvents';
import { addNotifications } from '../../store/notification/notificationSlice';

class SocketManager {
    private static instance: SocketManager | null = null;

    private socket: Socket | null = null;
    private dispatch: Dispatch;

    public constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }

    public static getInstance(dispatch: Dispatch): SocketManager {
        if (!SocketManager.instance) {
            SocketManager.instance = new SocketManager(dispatch);
        }
        return SocketManager.instance;
    }

    public disconnect() {
        // Disconnect the socket when needed
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
            this.dispatch(disconnect({}));
        }
    }

    public initialize() {
        if (!this.socket) {
            const userCookie = document.cookie
                .split(';')
                .map((c) => c.trim())
                .find((c) => c.startsWith('structure_cookie='));

            const userToken = userCookie ? userCookie.split('=')[1] : null;

            if (!userToken) {
                console.log('Socket Manager: No user token found');
                return;
            }

            const socketEndpoint: string | undefined = retrieveConfig().socketEndpoint;

            if (!socketEndpoint) {
                console.log('No socket endpoint found');
                return;
            }

            // Initialize socket connection
            this.socket = io(socketEndpoint, {
                query: {
                    token: userToken.toString(),
                }
            });

            this.dispatch(connect({}));
            this.initializeEventListener();
        }
    }

    private initializeEventListener() {
        if (this.socket) {
            console.log('Socket event listener initialized')

            this.socket.on('connect', () => {
                console.log('Socket connected');
            });

            this.socket.on('disconnect', () => {
                console.log('Socket disconnected');
            });

            this.socket.on(SOCKET_EVENTS.BOOKING_ADDED, (data: any) => {
                console.log('booking added', data);
            });

            this.socket.on(SOCKET_EVENTS.BOOKING_UPDATED, (data: any) => {
                console.log('booking updated', data);
            });

            this.socket.on(SOCKET_EVENTS.BOOKING_DELETED, (data: any) => {
                console.log('booking deleted', data);
            });

            this.socket.on(SOCKET_EVENTS.BOOKING_VERIFIED, (data: any) => {
                this.dispatch(handleSocketNotificationEvent({
                    socketEvent: SOCKET_EVENTS.BOOKING_VERIFIED,
                    data: data
                }));

                // access the notifications slice and add the notification except the entity field inside the data object
                const notification = data;
                delete notification.entity;

                this.dispatch(addNotifications({
                    notifications: notification
                }));
            });

            this.socket.on(SOCKET_EVENTS.USER_STRUCUTURE_INVITATION, (data: any) => {
                console.log('user structure invitation', data);
            });
        }
    }

}

export default SocketManager;
