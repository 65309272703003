import React, { useState, useRef, ReactNode, useEffect } from 'react';
import { createPopper, Instance, Placement } from '@popperjs/core';

export interface Option {
    disabled?: boolean;
    closeOnClick?: boolean;
    label: ReactNode;
    onClick: () => void;
}

interface DropdownProps {
    toggleButton: ReactNode;
    title?: string;
    options: Option[];
    placement?: Placement;
    arrowDown?: boolean;
}

const DropdownMenu: React.FC<DropdownProps> = ({ toggleButton, title, options, placement = 'bottom-start', arrowDown = false }) => {
    const updatedOptions = options.map(option => ({
        ...option,
        closeOnClick: option.closeOnClick ?? true
    }));

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const referenceRef = useRef<HTMLButtonElement>(null);
    const popperInstanceRef = useRef<Instance | null>(null);

    const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option: Option, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        option.onClick();

        if (!!option.closeOnClick) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen && referenceRef.current && menuRef.current) {
            popperInstanceRef.current = createPopper(referenceRef.current, menuRef.current, {
                placement,
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'viewport'
                        }
                    },
                    {
                        name: 'flip',
                        options: {
                            boundary: 'viewport'
                        }
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 4]
                        }
                    }
                ],
            });

            const handleClickOutside = (event: MouseEvent) => {
                if (
                    isOpen &&
                    menuRef.current &&
                    referenceRef.current &&
                    !menuRef.current.contains(event.target as Node) &&
                    !referenceRef.current.contains(event.target as Node)
                ) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                if (popperInstanceRef.current) {
                    popperInstanceRef.current.destroy();
                }
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isOpen]);

    return (
        <div className="relative flex items-center">
            <button
                ref={referenceRef}
                type="button"
                onClick={toggleMenu}
                aria-haspopup="true"
                aria-expanded={isOpen}
                className='w-full flex gap-1 items-center'
            >
                {toggleButton}
                {arrowDown && <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transition-all duration-300 ease-in-out transform`}
                    style={{ transform: isOpen ? "rotateX(180deg)" : "" }}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>}
            </button>

            {isOpen && (
                <div
                    ref={menuRef}
                    className="origin-top-left absolute z-50 w-auto rounded-md shadow-lg bg-white focus:outline-none border-2 border-mediumGray"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div role="none" className='flex flex-col items-start'>
                        {title && <p className='w-full px-4 py-2 font-semibold text-left whitespace-nowrap border-b-2 border-mediumGray'>{title}</p>}
                        {updatedOptions.map((option, index) => (
                            <button
                                disabled={option.disabled}
                                title={option.disabled ? "Opzione disabilitata. Per ulteriori informazioni, contatta l'assistenza." : undefined}
                                key={index}
                                onClick={(e) => handleOptionSelect(option, e)}
                                className={`w-full px-4 py-1.5 text-sm font-medium text-left whitespace-nowrap disabled:opacity-30 disabled:bg-transparent disabled:text-text hover:bg-lightGray last:rounded-b-md ${!title && 'first:rounded-t-md'}`}
                                role="menuitem"
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default DropdownMenu;
