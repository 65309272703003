import { Outlet } from 'react-router-dom'
import { useAppSelector } from '../../store/store';
import NoPermissionPage from '../../components/NoPermissionPage';
import LoadingIndicator from '../../components/LoadingIndicator';
import { IUserStructure } from '../../types/interfaces/userStructure.interface';
import { IPlugin } from '../../types/interfaces/plugin.interface';
import { PluginIdentifier } from '../../types/enum/pluginIdentifier';
import { IStructure } from '../../types/interfaces/structure.interface';

const BookingLayout = () => {
    const { userStructure } = useAppSelector<{ userStructure: IUserStructure<IStructure<IPlugin, string>, string, IPlugin> }>(state => state.account.userStructure);

    if (!userStructure) {
        return <div className='flex w-full h-full'>
            <LoadingIndicator icon />
        </div>
    }

    if (!userStructure?.permissions?.some(permission => permission.plugin.identifier === PluginIdentifier.BOOKINGS)) {
        return <NoPermissionPage />
    }

    return (
        <Outlet />
    )
}

export default BookingLayout;