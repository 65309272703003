  
export const CrossIcon = ({
    fillColor = '#5B2FF6',
    width = '10',
    height = '10'
}) => {
 return (
<svg width={width} height={height} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.84203 5.50529L0.88696 2.55022C0.692301 2.35556 0.599252 2.13551 0.607812 1.89008C0.616364 1.64465 0.7084 1.43418 0.883922 1.25866C1.07255 1.07003 1.28461 0.976403 1.52012 0.97777C1.75563 0.979137 1.97071 1.07715 2.16537 1.27181L5.12045 4.22688L8.08467 1.26265C8.25958 1.08775 8.47601 0.996632 8.73397 0.9893C8.99192 0.981977 9.2074 1.06482 9.38041 1.23783C9.55341 1.41084 9.63786 1.62792 9.63375 1.88908C9.62962 2.15025 9.54011 2.36828 9.36521 2.54318L6.40098 5.50741L9.35605 8.46249C9.54039 8.64683 9.635 8.86015 9.63989 9.10246C9.64477 9.34477 9.55524 9.5579 9.3713 9.74184C9.18735 9.92579 8.97183 10.0177 8.72476 10.0176C8.47769 10.0175 8.26198 9.92524 8.07764 9.7409L5.12257 6.78583L2.17238 9.73601C2.00029 9.9081 1.78787 9.99246 1.53514 9.98909C1.2824 9.98571 1.0675 9.89548 0.890439 9.71842C0.717356 9.54534 0.631297 9.33461 0.632263 9.08622C0.633229 8.83782 0.719759 8.62757 0.891853 8.45547L3.84203 5.50529Z" fill={fillColor} />
</svg>
    )
}

