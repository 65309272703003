import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { SOCKET_EVENTS } from "../../types/enum/socketEvents";
import { ISocketState, socketState } from "./socketState";

export const SocketSlice: Slice<ISocketState> = createSlice({
    name: "socket",
    initialState: socketState,
    reducers: {
        connect: (state: ISocketState) => {
            state.isConnected = true;
        },
        disconnect: (state: ISocketState) => {
            state.isConnected = false;
        },
        
        handleSocketNotificationEvent: (state: ISocketState, action: PayloadAction<any>) => {
            console.log('socket event', action.payload.socketEvent);
            console.log('socket data', action.payload.data);
            
            // handle a case for each socket event
            switch (action.payload.socketEvent) {
                case SOCKET_EVENTS.BOOKING_ADDED:
                    console.log('booking added', action.payload.data);
                    break;
                case SOCKET_EVENTS.BOOKING_UPDATED:
                    console.log('booking updated', action.payload.data);
                    break;
                case SOCKET_EVENTS.BOOKING_DELETED:
                    console.log('booking deleted', action.payload.data);
                    break;
                case SOCKET_EVENTS.BOOKING_VERIFIED:
                    console.log('booking verified', action.payload.data.entity);
                    break;
                case SOCKET_EVENTS.USER_STRUCUTURE_INVITATION:
                    console.log('user structure invitation', action.payload.data);
                    break;
                default:
                    console.log('socket event not handled', action.payload.socketEvent);
                    break;
            }

        }
    }
});

export const { connect, disconnect, handleSocketNotificationEvent } = SocketSlice.actions;
export default SocketSlice.reducer;
