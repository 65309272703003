import { useState } from 'react';
import { Link, useLoaderData, useSearchParams } from 'react-router-dom';
import { IEvent } from '../../types/interfaces/event.interface';
import moment from 'moment';
import { ArrowLeft, ArrowRight } from '../../utils/icons/ArrowIcon';
import { AddIcon } from '../../utils/icons/AddIcon';
import { InfoIcon } from '../../utils/icons/InfoIcon';

const Events = () => {
    const response = useLoaderData() as { events: IEvent[], selectedDate: string };

    const [selectedDate, setSelectedDate] = useState(response.selectedDate);
    const [searchParams, setSearchParams] = useSearchParams();

    const firstDayOfMonth = moment(selectedDate).clone().utc(true).startOf('month');
    const lastDayOfMonth = moment(selectedDate).clone().utc(true).endOf('month');
    const weekdays = moment.weekdaysMin(true);

    const handleChangeMonth = (value: number) => {
        // Get the first and last day displayed in the calendar
        const firstDayOfCalendar = firstDayOfMonth.clone().utc(true).add(value, 'month').startOf('week').toISOString();
        const lastDayOfCalendar = lastDayOfMonth.clone().utc(true).add(value, 'month').endOf('week').toISOString();

        // Set query params for the loader
        searchParams.set('startDate', firstDayOfCalendar);
        searchParams.set('endDate', lastDayOfCalendar);
        setSearchParams(searchParams);

        // Update selected date
        setSelectedDate(moment(selectedDate).utc(true).add(value, 'month').toISOString());
    };

    const daysInPreviousMonth = firstDayOfMonth.weekday();
    const daysInNextMonth = 6 - lastDayOfMonth.weekday();

    const renderDailyEvents = (dailyEvents: IEvent[], isCurrentMonth: boolean = false) => {
        if (dailyEvents.length > 0)
            return <div className='flex flex-col gap-1 w-full px-1'>
                {dailyEvents.slice(0, 4).map((dailyEvent, index) => (
                    <p key={index} className={`text-xs md:text-base font-semibold ${isCurrentMonth ? "bg-mediumGray text-text" : "bg-lightGray text-mediumGray"} w-full rounded truncate p-0.5`}>
                        {dailyEvent.name}
                    </p>
                ))}
                {dailyEvents.length > 4 && <p className='text-lg font-bold w-full h-fit'>...</p>}
            </div>
    };

    return (
        <div className='flex flex-col h-full'>
            <div className='bg-white flex justify-between items-center p-4 border-b-2 border-background'>
                <div className='cursor-pointer px-2' onClick={() => handleChangeMonth(-1)}><ArrowLeft /></div>
                <p className='capitalize font-bold text-2xl text-text'>{moment(selectedDate).format('MMMM YYYY')}</p>
                <div className='cursor-pointer px-2' onClick={() => handleChangeMonth(1)}><ArrowRight /></div>
            </div>

            <div className={`grid grid-cols-7 gap-0.5 border-b-2 border-background`}>
                {weekdays.map((weekday) => (
                    <div key={weekday} className={"flex items-center justify-center font-bold text-xl py-2 text-darkGray bg-white"}>
                        {weekday.toUpperCase()}
                    </div>
                ))}
            </div>

            <div className={`grid grid-cols-7 h-full gap-0.5`}>
                {/* Giorni del mese precedente */}
                {Array.from({ length: daysInPreviousMonth }, (_, index) => {
                    const date = firstDayOfMonth.clone().subtract(daysInPreviousMonth - index, 'days').utc(true);
                    const isToday = moment().utc(true).isSame(date, 'day');
                    const dailyEvents = response.events.filter((event) => moment(event.date).isSame(date, 'day'));

                    return (
                        <div key={date.format()} className={`flex flex-col items-center text-sm md:text-lg text-center gap-1 py-1 truncate whitespace-nowrap bg-white font-semibold text-mediumGray`}>
                            <p className={`p-0.5 ${isToday && 'bg-primary text-text_white aspect-square rounded-full font-semibold'}`}>{date.format('D')}</p>
                            {dailyEvents.length > 0 && renderDailyEvents(dailyEvents)}
                        </div>
                    );
                })}

                {/* Giorni del mese corrente */}
                {Array.from({ length: lastDayOfMonth.date() }, (_, index) => {
                    const date = firstDayOfMonth.clone().add(index, 'days').utc(true);
                    const isSelected = date.isSame(selectedDate, 'day');
                    const isToday = moment().utc(true).isSame(date, 'day');
                    const dailyEvents = response.events.filter((event) => moment(event.date).isSame(date, 'day'));

                    return (
                        <div
                            key={date.format()}
                            onClick={() => setSelectedDate(date.toISOString())}
                            className={`flex flex-col items-center text-sm md:text-lg text-center transition-all duration-500 ease-in-out hover:text-primary gap-1 py-1 truncate whitespace-nowrap cursor-pointer
                                ${isSelected ? 'text-primary font-bold bg-background2' : 'text-text font-semibold bg-white'}`}
                        >
                            <p className={`p-0.5 ${isToday && 'bg-primary text-text_white aspect-square rounded-full font-semibold'}`}>{date.format('D')}</p>
                            {dailyEvents.length > 0 && renderDailyEvents(dailyEvents, true)}
                        </div>
                    );
                })}

                {/* Giorni del mese successivo */}
                {Array.from({ length: daysInNextMonth }, (_, index) => {
                    const date = lastDayOfMonth.clone().utc(true).startOf('day').add(index + 1, 'days');
                    const isToday = moment().utc(true).isSame(date, 'day');
                    const dailyEvents = response.events.filter((event) => moment(event.date).isSame(date, 'day'));

                    return (
                        <div key={date.format()} className={`flex flex-col items-center text-sm md:text-lg text-center gap-1 py-1 truncate whitespace-nowrap bg-white font-semibold text-mediumGray`}>
                            <p className={`p-0.5 ${isToday && 'bg-primary text-text_white aspect-square rounded-full font-semibold'}`}>{date.format('D')}</p>
                            {dailyEvents.length > 0 && renderDailyEvents(dailyEvents)}
                        </div>
                    );
                })}
            </div>

            <div className='absolute right-2 bottom-2 flex flex-col gap-2'>
                {response.events.some((event) => moment(event.date).isSame(selectedDate, 'day')) &&
                    <Link to={'/dashboard/events/info/' + selectedDate} className='bg-text p-2 rounded-full shadow'>
                        <InfoIcon size={24} />
                    </Link>
                }
                <Link to={'/dashboard/events/create?date=' + selectedDate} className='bg-text p-2 rounded-full shadow'>
                    <AddIcon />
                </Link>
            </div>
        </div >
    );
};

export default Events;
