export enum colorPalette {
    Primary = "#5B2FF6",
    Secondary = "#4928BE",
    Background = "#f5f5f5",
    Background2 = "#DDDDDD",
    Text = "#131313",
    Success = "#378C0D",
    Info = "#286F88",
    Warning = "#E6A23C",
    Error = "#E53935",
    DarkGray = "#555555",
    MediumGray = "#A9A9A9",
    LightGray = "#E0E0E0",
    PrimaryLight = "#EFEBFF",
    Dark = "#09090b",
    White = "#FFFFFF",
    Black = "#000000",
};