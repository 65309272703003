import { useEffect, useState } from 'react'
import PageTitle from '../../../../components/PageTitle'
import FilterTabs from './FilterTab'
import { REQUEST_BOOKING_STATUS } from '../../../../types/enum/requestBookingStatus.enum'
import { useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom'
import { Paginated } from '../../../../types/interfaces/paginated.interface'
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface'
import { IStructure } from '../../../../types/interfaces/structure.interface'
import { IOffer } from '../../../../types/interfaces/offer.interface'
import { IProposal } from '../../../../types/interfaces/proposal.interface'
import moment from 'moment'
import BookingRequestCard from './BookingRequestCard'
import { useModalDispatcher } from '../../../../hooks/useModalDispatcher'
import { apiHandler } from '../../../../services/apiHandler'
import Pagination from './Pagination'
import Modal from '../../../../components/Modal'
import { PartialIBooking } from '../../../../types/interfaces/booking.interface'
import { apiService, HttpMethod } from '../../../../services/apiService'
import Text from '../../../../components/Text'

const RequestsView = () => {
    const { paginatedResults, newBookingRequests } = useLoaderData() as { paginatedResults: Paginated<IBookingRequest<IStructure, IOffer, IProposal>>, newBookingRequests: { "PENDING": number, "UPDATED": number } };
    const [searchParams, setSearchParams] = useSearchParams();
    const revalidator = useRevalidator()

    const { showModal, showErrorModal } = useModalDispatcher();

    // pagination
    const [page, setPage] = useState(0);

    // FILTER BY STATUS
    const [statusFilters, setStatusFilters] = useState<string>((): string => {
        if (searchParams.get('status')) {
            const paramStatus = searchParams.get('status')
            if (paramStatus === null) return Object.keys(REQUEST_BOOKING_STATUS).filter(key => isNaN(Number(key)))[0]
            return paramStatus.toUpperCase()
        } else {
            return Object.keys(REQUEST_BOOKING_STATUS).filter(key => isNaN(Number(key)))[0]
        }
    });

    // bookings operations
    const [isRejecting, setIsRejecting] = useState(false);
    const [currentBookingReject, setCurrentBookingReject] = useState<IBookingRequest>({} as IBookingRequest);
    const [rejectMessage, setRejectMessage] = useState('');

    const [loading, setLoading] = useState(false);

    // modify request modal
    const [isModifying, setIsModifying] = useState(false);
    const [currentBookingModify, setCurrentBookingModify] = useState<IBookingRequest<IStructure, IOffer, IProposal>>();
    const [olderBookingVersion, setOlderBookingVersion] = useState<PartialIBooking<{ structure: IStructure, offer: IOffer, proposals: IProposal }>>();


    const setUrlParams = (page: number, date?: string, status?: string) => {
        // Copy the existing search parameters
        const newParams = new URLSearchParams(searchParams);

        // Set the page parameter
        if (page >= 0) {
            newParams.set('page', String(page));
        }

        // Set the date parameter if provided
        if (date) {
            newParams.set('date', date);
        }

        if (status) {
            switch (status) {
                case 'PENDING':
                    newParams.set('status', 'pending');
                    break;
                case 'ACCEPTED':
                    newParams.set('status', 'accepted');
                    break;
                case 'REJECTED':
                    newParams.set('status', 'rejected');
                    break;
                case 'CANCELLED':
                    newParams.set('status', 'cancelled');
                    break;
                case 'UPDATED':
                    newParams.set('status', 'updated');
                    break;
                default:
                    break;
            }
        }

        // Update the URL with the new parameters
        setSearchParams(newParams);
    }

    useEffect(() => {
        setUrlParams(page, moment().utc(true).toISOString(), statusFilters);
    }, [page, statusFilters]);

    useEffect(() => {
        setPage(0);
    }, [statusFilters]);

    // bookings operations
    // handle accept the booking request
    const handleAcceptBookingRequest = async (bookingRequest: IBookingRequest) => {
        try {
            setLoading(true)
            if (bookingRequest._id === undefined) {
                setLoading(false)
                return
            }

            const response = await apiService(HttpMethod.PUT, '/booking-request/accept/' + bookingRequest._id);

            if (response.errorCode) {
                throw response
            }

            revalidator.revalidate()
            showModal(200)
            setLoading(false)
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }

            setLoading(false)
            revalidator.revalidate()
        }
    }

    const handleRejectBookingRequest = async (bookingRequest: IBookingRequest) => {
        if (bookingRequest._id === undefined) return

        setIsRejecting(true)
        setCurrentBookingReject(bookingRequest)
        setRejectMessage('')
    }

    // handle reject the booking request
    const rejectBookingRequest = async (bookingRequest: IBookingRequest) => {
        try {
            setLoading(true)
            if (bookingRequest._id === undefined) {
                setLoading(false)
                return
            }

            const response = await apiService(HttpMethod.PUT, '/booking-request/reject/' + bookingRequest._id, {
                rejectedNotes: rejectMessage
            });

            if (response.errorCode) {
                throw response
            }

            revalidator.revalidate()
            setIsRejecting(false);
            showModal(200)
            setLoading(false)
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }

            setLoading(false)
            setIsRejecting(false)
            revalidator.revalidate()
        }
    }

    const handleModifyBookingRequest = async (bookingRequest: IBookingRequest<IStructure, IOffer, IProposal>) => {
        try {
            setLoading(true)

            if (bookingRequest._id === undefined) {
                setLoading(false)
                return
            }

            setIsModifying(true)

            const fetchOlderBooking: any = await apiHandler.get('/booking/get/' + bookingRequest.booking)

            if (fetchOlderBooking.errorCode) {
                throw fetchOlderBooking
            }

            setCurrentBookingModify(bookingRequest)
            setOlderBookingVersion(fetchOlderBooking)
            setLoading(false)
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }

            setLoading(false)
            revalidator.revalidate()
        }
    }

    const handleChangePage = (page: number) => {
        setPage(page - 1);
    };

    return (
        <div className='p-8 h-full w-full flex flex-col hidden-scrollbar overflow-auto'>
            <PageTitle title='Richieste di Prenotazione' goBackBtn={false} />

            <FilterTabs tabs={Object.keys(REQUEST_BOOKING_STATUS).filter(key => isNaN(Number(key)))} activeTab={statusFilters} setActiveTab={setStatusFilters} newBookingRequests={newBookingRequests} />

            {paginatedResults?.data?.map((bookingRequest: any, index: number) =>
                <BookingRequestCard
                    key={index}
                    bookingRequest={bookingRequest}
                    handleAcceptBookingRequest={handleAcceptBookingRequest}
                    handleRejectBookingRequest={handleRejectBookingRequest}
                    handleModifyBookingRequest={handleModifyBookingRequest}
                    loading={loading}
                />
            )}

            {paginatedResults?.data?.length === 0 && <p className='w-full mt-28 text-center text-mediumGray font-medium text-2xl'>Nessuna prenotazione disponibile</p>}

            {paginatedResults?.data?.length > 0 &&
                <Pagination currentPage={paginatedResults?.currentPage + 1} totalPages={paginatedResults?.totalPages} onPageChange={handleChangePage} />
            }

            {/* MODAL TO REJECT THE REQUEST */}
            <Modal isOpen={isRejecting} onClose={() => setIsRejecting(false)}>
                <div className='p-4 flex flex-col gap-8'>
                    <div className='fleex flex-col gap-2'>
                        <p className='text-2xl text-text font-bold'>Rifiuto Prenotazione</p>
                        <p className='text-base text-darkGray'>
                            Stai per rifiutare la prenotazione di <strong>{currentBookingReject?.name} {currentBookingReject?.surname}</strong> del <strong>{moment(currentBookingReject?.date).format('DD MMMM YYYY')}.</strong>
                        </p>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <textarea
                            className="w-full h-32 p-2 border border-mediumGray rounded-md focus:outline-none focus:border-info"
                            placeholder="Inserisci un messaggio per spiegare il motivo del rifiuto"
                            value={rejectMessage}
                            onChange={(event) => setRejectMessage(event.target.value)}
                        />
                        <p className='text-sm text-mediumGray'>
                            Questo messaggio è facoltativo, ma è utile per spiegare chiaramente al cliente il motivo del rifiuto.<br />
                            Puoi fornire dettagli o suggerimenti per future prenotazioni.
                        </p>
                    </div>


                    <div className='flex flex-col-reverse md:flex-row gap-2 justify-between'>
                        <button className="text-text bg-white border-2 border-darkGray px-4 py-2 rounded-md" onClick={() => setIsRejecting(false)} >
                            Annulla
                        </button>
                        <button className="bg-error text-white px-4 py-2 rounded-md" onClick={() => rejectBookingRequest(currentBookingReject)}>
                            Procedi
                        </button>
                    </div>
                </div>
            </Modal>

            {/* MODAL TO CHECK THE UPDATE MADE BY THE USER */}
            <Modal isOpen={isModifying} onClose={() => setIsModifying(false)}>
                <div className='p-4 flex flex-col gap-4'>
                    <div className='fleex flex-col gap-2'>
                        <p className='text-2xl text-text font-bold'>Modifica Prenotazione</p>
                        <p className='text-base text-darkGray'>
                            Stai visualizzando le modifiche apportate alla prenotazione di <strong>{currentBookingModify?.name} {currentBookingModify?.surname}.</strong>
                        </p>
                    </div>

                    <div className='flex gap-4 my-4'>
                        {/* Old booking */}
                        <div className='w-1/2 flex flex-col gap-2'>
                            <p className='text-xl font-bold'>Vecchia prenotazione</p>
                            <div className='w-full flex flex-col gap-1'>
                                <Text title='Persone' label={olderBookingVersion?.people} gap={0} />
                                <Text title='Data' label={moment(olderBookingVersion?.bookingDate).format('DD MMMM YYYY')} gap={0} />
                                <Text title='Servizio' label={olderBookingVersion?.offer?.name} gap={0} />
                                <Text title='Turno' label={olderBookingVersion?.shift} gap={0} />
                                <Text title='Proposta' label={olderBookingVersion?.proposals[0]?.proposal.name} gap={0} />
                            </div>
                        </div>


                        {/* New booking */}
                        <div className='w-1/2 flex flex-col gap-2'>
                            <p className='text-xl font-bold'>Nuova prenotazione</p>
                            <div className='w-full flex flex-col gap-1'>
                                <Text className={`${currentBookingModify?.people !== olderBookingVersion?.people ? "text-primary font-bold" : "text-text"}`} title='Persone' label={currentBookingModify?.people} />
                                <Text className={`${!moment(currentBookingModify?.date).isSame(olderBookingVersion?.bookingDate) ? "text-primary font-bold" : "text-text"}`} title='Data' label={moment(currentBookingModify?.date).format('DD MMMM YYYY')} />
                                <Text className={`${currentBookingModify?.offer._id !== olderBookingVersion?.offer._id ? "text-primary font-bold" : "text-text"}`} title='Servizio' label={currentBookingModify?.offer?.name} />
                                <Text className={`${currentBookingModify?.shift !== olderBookingVersion?.shift ? "text-primary font-bold" : "text-text"}`} title='Turno' label={currentBookingModify?.shift} />
                                <Text className={`${!olderBookingVersion?.proposals.find((proposal: any) => proposal._id === currentBookingModify?.proposal?._id) ? "text-primary font-bold" : "text-text"}`} title='Proposta' label={currentBookingModify?.proposal?.name} />
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col-reverse md:flex-row justify-between gap-2'>
                        <button className="text-text bg-white border-2 border-darkGray px-4 py-2 rounded-md" onClick={() => setIsModifying(false)}>
                            Annulla
                        </button>

                        <div className='flex flex-col-reverse md:flex-row gap-2'>
                            <button className="text-white bg-error px-4 py-2 rounded-md" onClick={() => {
                                if (!currentBookingModify) return
                                setIsModifying(false)
                                handleRejectBookingRequest(currentBookingModify)
                            }}>
                                Rifiuta
                            </button>
                            <button className="bg-primary text-white px-4 py-2 rounded-md" onClick={() => {
                                if (!currentBookingModify) return
                                setIsModifying(false)
                                handleAcceptBookingRequest(currentBookingModify)
                            }}>
                                Accetta
                            </button>
                        </div>
                    </div>

                </div>
            </Modal>

        </div>
    )
}

export default RequestsView