import { IminDashboard } from '../../utils/icons/IminDashboard'
import PageTamplate from '../../components/PageTamplate'
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { SquaringCircles } from '../../utils/icons/SquaringCircles'
import { privacyPolicy } from './iubenda'
import { useEffect } from 'react'

const Landing = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.innerHTML = `
          var _iub = _iub || [];
          _iub.csConfiguration = { "askConsentAtCookiePolicyUpdate": true, "floatingPreferencesButtonDisplay": "bottom-right", "perPurposeConsent": true, "siteId": 3392591, "whitelabel": false, "cookiePolicyId": 54121952, "lang": "it", "banner": { "acceptButtonDisplay": true, "closeButtonRejects": true, "customizeButtonDisplay": true, "explicitWithdrawal": true, "listPurposes": true, "position": "float-top-center", "showTitle": false } };
        `;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.src = 'https://cs.iubenda.com/autoblocking/3392591.js';
        script2.async = true;
        document.body.appendChild(script2);

        const script3 = document.createElement('script');
        script3.type = 'text/javascript';
        script3.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
        script3.async = true;
        document.body.appendChild(script3);

        return () => {
            const element = document.querySelector('.iubenda-tp-btn.iubenda-cs-preferences-link');

            document.body.removeChild(script1)
            document.body.removeChild(script2)
            document.body.removeChild(script3)

            if (element)
                document.body.removeChild(element)
        }
    }, []);

    return (
        <PageTamplate classname=' gap-0' style={{ padding: 0 }}>

            <img alt='' src='./images/landing/BG.png' className='w-full h-full top-0 fixed z-10' />
            <div className='flex flex-col gap-4 p-8 xl:gap-20 xl:p-32 xl:pt-8 z-10' >
                <div className='flex justify-between items-center'>
                    <img alt='logo-imin' src='/images/landing/logo-imin.svg' className='w-24' />
                    <div className='flex gap-2'>
                        <CustomButton textStyle="text-md" color='bg-primary' label="Login" onClickHandler={() => navigate("/auth")} className='shadow-none' />
                    </div>
                </div>

                <div className='flex flex-col-reverse gap-4 md:flex-row xl:flex-row w-full md:gap-10 xl:gap-10 md:items-center xl:items-center'>
                    <div className='md:w-1/2 xl:w-1/2 flex flex-col gap-4'>
                        <p className='text-4xl xl:text-6xl gap-1 font-semibold whitespace-pre-line'>
                            <span className='text-primary'>Riorganizza</span>
                            <br />da cima a fondo<br />
                            la tua <span className='text-primary'>Attività.</span>
                        </p>
                        <p className='font-medium text-md xl:text-xl'>
                            Il nostro obiettivo è semplificare il lavoro<br />
                            organizzativo e gestionale nel settore<br />
                            dell'intrattenimento e della ristorazione.
                        </p>
                        <CustomButton textStyle="text-md" color='bg-primary' textColor='text-text_white' label="Contattaci ora!" onClickHandler={() => window.location.href = "#contact"} className='w-fit' />
                    </div>
                    <div className='mt-16 md:w-1/2 xl:mt-0 xl:w-1/2 flex flex-col items-center'>
                        <img alt='' src='/images/landing/Hero.png' />
                    </div>
                </div>
            </div>

            <p className='text-4xl mt-16 xl:mt-0 xl:text-6xl text-primary font-semibold whitespace-pre-line text-center z-10  mb:10 xl:mb-20'>I nostri punti di forza</p>

            <div className='flex flex-col xl:flex-row'>
                <div className='w-1/6'></div>
                <div className='w-full xl:w-5/6 z-10'>
                    <div className='flex flex-col-reverse p-8 xl:flex-row xl:gap-10 xl:p-16 w-full z-10 xl:items-center'>
                        <div className='xl:w-1/2 flex flex-col gap-6'>
                            <p className='text-4xl xl:text-6xl text-primary font-semibold mt-8 xl:mt-0'><span className='text-text'>Una</span> gestione<br />dinamica <span className='text-text'>delle<br />prenotazioni.</span></p>
                            <p className='font-medium text-xl'>
                                Imin è il partner ideale per i locali desiderosi di semplificare <br className='hidden xl:visible' />
                                la gestione delle prenotazioni. Il software offre un sistema <br className='hidden xl:visible' />
                                flessibile e altamente personalizzabile, consentendo ai tuoi <br className='hidden xl:visible' />
                                servizi, sia di ristorazione che di intrattenimento, di brillare <br className='hidden xl:visible' />
                                con una pianificazione dinamica e dettagliata.
                            </p>
                        </div>
                        <div className='md:w-1/2 md:m-auto xl:w-1/2 flex flex-col items-center'>
                            <img alt='' src='/images/landing/Prenotazioni.png' />
                        </div>
                    </div>

                    <div className='flex flex-col p-8 xl:flex-row xl:gap-10 xl:p-16 w-full z-10 xl:items-center'>
                        <div className='md:w-1/2 md:m-auto xl:w-1/2 flex flex-col items-center mb-8'>
                            <img alt='' src='/images/landing/Sicurezza.png' />
                        </div>
                        <div className='xl:w-1/2 flex flex-col gap-6'>
                            <p className='text-4xl xl:text-6xl text-primary font-semibold'><span className='text-text'>Elevata</span> sicurezza<br /> e integrità.</p>
                            <p className='font-medium text-xl'>
                                Con Imin, prendi il controllo completo sulle prenotazioni. <br className='hidden xl:visible' />
                                Gestisci con facilità gli stati di ogni prenotazione, <br className='hidden xl:visible' />
                                identificando immediatamente le assenze e le <br className='hidden xl:visible' />
                                cancellazioni. Questa precisione ti permette di ottimizzare <br className='hidden xl:visible' />
                                le tue risorse e di evitare sprechi, con un occhio sempre <br className='hidden xl:visible' />
                                vigile sulla soddisfazione del cliente.
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-col-reverse p-8 xl:flex-row xl:gap-10 xl:p-16 w-full z-10 xl:items-center'>
                        <div className='xl:w-1/2 flex flex-col gap-6'>
                            <p className='text-4xl xl:text-6xl text-primary font-semibold mt-8 xl:mt-0'>Automazione<br /><span className='text-text'>per smaltire il<br />lavoro manuale.</span></p>
                            <p className='font-medium text-xl'>
                                Sfrutta il potere delle automazioni integrate di Imin per <br className='hidden xl:visible' />
                                inviare conferme tramite WhatsApp Business e gestire <br className='hidden xl:visible' />
                                pagamenti anticipati per eventi speciali. Ottimizza la <br className='hidden xl:visible' />
                                comunicazione con i tuoi clienti, consentendo loro di <br className='hidden xl:visible' />
                                modificare o confermare le prenotazioni direttamente dalla <br className='hidden xl:visible' />
                                piattaforma.
                            </p>
                        </div>
                        <div className='md:w-1/2 md:m-auto xl:w-1/2 flex flex-col items-center'>
                            <img alt='' src='/images/landing/Automazione.png' />
                        </div>
                    </div>

                    <div className='flex flex-col p-8 xl:flex-row xl:gap-10 xl:p-16 w-full z-10 xl:items-center'>
                        <div className='md:w-1/2 md:m-auto xl:w-1/2 flex flex-col'>
                            <img alt='' src='/images/landing/Tavoli.png' />
                        </div>
                        <div className='xl:w-1/2 flex flex-col justify-between gap-6'>
                            <p className='text-4xl xl:text-6xl text-primary font-semibold'><span className='text-text'>Funzione per <br />la</span> gestione di<br />ingressi e tavoli.</p>
                            <p className='font-medium text-xl'>
                                Per locali come discoteche o ristoranti, Imin offre una <br className='hidden xl:visible' />
                                visione intuitiva degli ingressi su tablet, semplificando la <br className='hidden xl:visible' />
                                gestione dei tavoli e delle prenotazioni. <br className='hidden xl:visible' />
                                Aggiorna facilmente lo stato dei booking e tavoli, <br className='hidden xl:visible' />
                                ottimizzando l'esperienza del cliente e migliorando il tuo <br className='hidden xl:visible' />
                                servizio.
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-col-reverse p-8 xl:flex-row xl:gap-10 xl:p-16 w-full z-10 xl:items-center'>
                        <div className='xl:w-1/2 flex flex-col justify-between gap-6'>
                            <p className='text-4xl xl:text-6xl text-primary font-semibold mt-8 xl:mt-0'>Servizio cucina<br /><span className='text-text'>in continua evoluzione.</span></p>
                            <p className='font-medium text-xl whitespace-pre-line break-words'>
                                Con il modulo cucina di Imin, il tuo staff ha tutto sotto <br className='hidden xl:visible' />
                                controllo. Visualizza in dettaglio i menu selezionati e le <br className='hidden xl:visible' />
                                relative prenotazioni, garantendo una gestione precisa <br className='hidden xl:visible' />
                                delle risorse e delle richieste particolari. <br className='hidden xl:visible' />
                                In futuro, potenzia l'esperienza del cliente permettendo <br className='hidden xl:visible' />
                                loro di effettuare ordini direttamente dai tavoli tramite <br className='hidden xl:visible' />
                                un'app intuitiva. <br className='hidden xl:visible' />
                                Con Imin, trasforma la gestione delle prenotazioni in <br className='hidden xl:visible' />
                                un'esperienza fluida e senza stress. Ottieni il massimo <br className='hidden xl:visible' />
                                controllo, efficienza e soddisfazione del cliente per il <br className='hidden xl:visible' />
                                successo continuo del tuo locale.
                            </p>
                        </div>
                        <div className='md:w-1/2 md:m-auto xl:first-letter:w-1/2 flex flex-col items-center'>
                            <img alt='' src='/images/landing/Cucina.png' />
                        </div>
                    </div>

                    {/* <p className='text-4xl text-primary font-semibold whitespace-pre-line text-center'>Parlano di noi</p>

            <div className='flex gap-20 p-16 py-14 w-full'>
                <div className='w-1/3 flex flex-col items-center bg-blue-200'>
                    Foto locale recensito
                </div>
                <div className='flex flex-col justify-between gap-6'>
                    <p className='text-xl text-primary font-semibold whitespace-pre-line'>Shada Beach & Food Club</p>
                    <p className='font-medium text-base whitespace-pre-line break-words'>
                        “Il modulo di prenotazioni e gestione delle tavolate ha semplificato il<br />
                        nostro servizio in sala, offrendo una visione chiara delle prenotazioni in<br />
                        arrivo e degli spazi disponibili. I nostri clienti hanno apprezzato la rapidità<br />
                        con cui possiamo ora accoglierli e offrire loro un'esperienza senza<br />
                        intoppi”.
                    </p>
                </div>
            </div>

            <p className='text-xl font-semibold whitespace-pre-line text-center'>01/08 {">"}</p>
 */}
                    {/* <div className='flex flex-col p-32 gap-20'>
                <p className='text-4xl text-primary font-semibold whitespace-pre-line text-center'>Cosa bolle in pentola?</p>

                <p className='text-xl font-semibold whitespace-pre-line text-center'>Sezione dove vengono mostrate le future features.</p>
            </div> */}

                    <section id="contact" className='flex flex-col py-16 xl:p-32'>
                        <p className='text-4xl text-primary font-semibold whitespace-pre-line text-center mb-4'>Vuoi saperne di più?</p>

                        <p className='text-xl px-8 font-semibold whitespace-pre-line text-center mb-8'>Compila il nostro form, ti ricontatteremo in un lampo!</p>

                        <CustomButton textStyle='text-md' color='bg-primary' textColor='text-text_white' label="Scrivici ora" onClickHandler={() => window.location.href = "mailto:info@thesquaringcircles.com"}
                            className='w-fit self-center' />
                    </section>
                </div>
                <div className='w-1/6'></div>
            </div>


            {/* footer */}
            <div className='flex md:flex-row flex-col gap-10 bg-primary p-16 py-10 justify-between items-center z-50'>

                <div className='flex flex-col items-center justify-between h-full gap-2'>

                    <div className='m-auto xl:m-0'>
                        <IminDashboard color='#FFF' />
                    </div>

                    <div className='flex flex-col items-center m-auto xl:m-0'>
                        <p className='font-medium text-xs text-text_white'>Powered by</p>
                        <a href='https://thesquaringcircles.com/' target='_blank'><SquaringCircles /></a>
                    </div>

                </div>


                <div className='flex flex-col items-center justify-between h-full gap-2'>
                    <a href="https://www.iubenda.com/privacy-policy/54121952" className="w-fit px-4 py-1 text-center font-semibold text-text_white" title="Privacy Policy ">Privacy Policy</a>
                    <PrivacyPolicy />

                    <a href="https://www.iubenda.com/privacy-policy/54121952/cookie-policy" className="w-fit px-4 py-1 text-center font-semibold text-text_white" title="Cookie Policy ">Cookie Policy</a>
                    <CookiePolicy />

                    <a href="https://www.iubenda.com/termini-e-condizioni/54121952" className="w-fit px-4 py-1 text-center font-semibold text-text_white" title="Termini e Condizioni ">Termini e Condizioni</a>
                    <TermsAndConditions />
                </div>
            </div>

        </PageTamplate>
    )
}

const PrivacyPolicy: React.FC = () => {

    useEffect(() => {
        privacyPolicy()
    }, [])

    return <></>
}

const CookiePolicy: React.FC = () => {

    useEffect(() => {
        privacyPolicy()
    }, [])



    return <></>
}

const TermsAndConditions: React.FC = () => {

    useEffect(() => {
        privacyPolicy()
    }, [])

    return <></>
}

export default Landing