import React, { useEffect, useState } from 'react';
import { IBooking } from '../../types/interfaces/booking.interface';
import useFetch from '../../hooks/useFetch';
import { apiHandler, getHandler, putHandler } from '../../services/apiHandler';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { IOffer, IRoom } from '../../types/interfaces/offer.interface';
import { replaceOffers } from '../../store/offer/offerSlice';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import { BOOKING_STATUS, bookingStatusColorMapping, bookingStatusDbMapping } from '../../types/enum/bookingStatus';
import PageTamplate from '../../components/PageTamplate';
import InputForm from '../../components/InputForm';
import CustomButton from '../../components/CustomButton';
import Modal from '../../components/Modal';
import Autocomplete from '../../components/Autocomplete';
import LoadingIndicator from '../../components/LoadingIndicator';
import { SuccessIcon } from '../../utils/icons/SuccessIcon';
import { NoShowIcon } from '../../utils/icons/NoShowIcon';
import { CancelIcon } from '../../utils/icons/CancelIcon';
import { AddIcon } from '../../utils/icons/AddIcon';
import { useSearchParams } from 'react-router-dom';
import { PendingIcon } from '../../utils/icons/PendingIcon';
import { AcceptedIcon } from '../../utils/icons/AcceptedIcon';
import QuickBookingForm from '../booking/QuickBookingForm';
import useScreenSize from '../../hooks/useScreenSize';
import { MergeIcon } from '../../utils/icons/MergeIcon';
import { BlockIcon } from '../../utils/icons/BlockIcon';
import { CheckIcon } from '../../utils/icons/CheckIcon';
import { Paginated } from '../../types/interfaces/paginated.interface';

const EntryManagement = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { offers } = useAppSelector(state => state.offer);
    const { globalDate } = useAppSelector(state => state.booking);
    const { showModal, showErrorModal } = useModalDispatcher();
    const screenSize = useScreenSize();

    const [isModal, setIsModal] = useState<{ open: boolean, action: 'CLEAN' | 'SPLIT' | 'CHANGE', data: { destinationPos: string, sourcePos: string, source: IBooking, block: IBooking } | undefined }>({ open: false, action: 'CHANGE', data: undefined });
    const [bookingId, setBookingId] = useState<string | undefined>(undefined);
    const [isDragging, setIsDragging] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [bookingsNoLoc, setBookingsNoLoc] = useState<IBooking[]>([]);
    const [selectedOffer, setSelectedOffer] = useState<IOffer>();
    const [selectedRoom, setSelectedroom] = useState<IRoom>();
    const [searchTerm, setSearchTerm] = useState<string>('');

    const { loading: loadingOffers } = useFetch<IOffer[]>(
        () => apiHandler.post('/offer/list'),
        (fetchedData) => {
            setSelectedOffer(fetchedData[0]);
            setSelectedroom(fetchedData[0]?.rooms[0]);
            dispatch(replaceOffers(fetchedData));
        }
    );

    const { data, loading } = useFetch<{ positionsData: { pos: string; booking?: IBooking }[], closedTable: string[] }>(
        () => apiHandler.post<{ pos: string; booking?: IBooking }[]>('/offer/get/bookings/position', { offerId: selectedOffer?._id, date: globalDate, room: selectedRoom }),
        undefined,
        [selectedOffer, isModal, globalDate, selectedRoom],
        !!selectedOffer
    );

    const { data: bookingData } = useFetch<{ pagination: Paginated<IBooking> }>(
        () => apiHandler.post('/booking/list', { pagination: { pageSize: 10, currentPage: 1, filters: { bookingDate: globalDate, offer: selectedOffer?._id, bookingStatus: [0, 1, 2], surname: searchTerm || '^(?!.*\\S)' } } }),
        undefined,
        [searchTerm, selectedOffer, searchParams],
        !!selectedOffer
    );

    useEffect(() => {
        if (!!selectedOffer) {
            if (!!selectedOffer.rooms.length) {
                if (!!selectedRoom)
                    handleFilterOptions('');
            } else {
                handleFilterOptions('');
            }
        }
    }, [selectedOffer, selectedRoom, globalDate, isModal, searchParams])

    // Function to manage the dragStart and store the firts id
    const handleDragStart = (e: React.DragEvent, id: string) => {
        e.dataTransfer.setData('text/plain', id);
        setIsDragging(id);
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    // Function to manage the drop of the booking and get the first and second id
    const handleDrop = (e: React.DragEvent, destinationTable: string, destinationId: string) => {
        e.preventDefault();
        const sourceId = e.dataTransfer.getData('text/plain');

        if (sourceId !== destinationId)
            handleChangeTable(sourceId, destinationTable, false, 'CHANGE');
    };

    // onButtonClick={(id, action) => handleChangeTable(id, pos, false, action)}

    const handleChangeTable = async (sourceId: string, destinationPos: string, switchTable: boolean = false, action: 'CLEAN' | 'SPLIT' | 'CHANGE' = 'CHANGE', sourcePos: string = '') => {
        try {
            if (!sourceId) return

            setIsLoading(true);
            const response = await apiHandler.get<getHandler | any>('/booking/get/' + sourceId);
            const booking = response as IBooking;

            let newLoc: string[] = [];
            let newIsAggregate = booking.isAggregate;
            let newMainTable = booking.mainTable;

            // Rimuovi il tavolo da una prenotazione
            if (action === 'CLEAN') {
                if (!booking.isAggregate && booking.locationIdentifier.length > 1) {
                    newLoc = booking.locationIdentifier.filter(pos => pos !== sourcePos);
                } else {
                    newLoc = []
                }
                newIsAggregate = false;
                newMainTable = '';
            }

            // Assegna un tavolo ad una prenotazione
            if (action === 'CHANGE') {
                newLoc = [destinationPos];
                newIsAggregate = false;
                newMainTable = '';
            }

            // Dividi la prenotazione su piu tavoli
            if (action === 'SPLIT') {
                newLoc = booking.locationIdentifier.filter(loc => loc !== '');
                newLoc.push(destinationPos)
            }

            const data: IBooking = { ...booking, locationIdentifier: newLoc, roomId: selectedRoom?._id, isAggregate: newIsAggregate, mainTable: newMainTable }
            const updatedBooking = await apiHandler.put('/booking/update/single/' + sourceId, { switchTable, data, sourcePos });

            if (updatedBooking.isAvailable === false) {
                setIsModal({ open: true, action, data: { destinationPos: destinationPos, sourcePos: sourcePos, source: booking, block: response.blockingEntities[0] } });
            } else {
                showModal(200)
                setIsModal({ open: false, action, data: undefined });
            }

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleCombineTables = async (booking: IBooking, mainPos: string, isAggregate: boolean) => {
        try {
            setIsLoading(true);
            let data: IBooking
            if (isAggregate) {
                data = { ...booking, mainTable: mainPos, isAggregate: true };
            } else {
                data = { ...booking, mainTable: '', isAggregate: false };
            }
            await apiHandler.put('/booking/update/single/' + booking._id, { data });
            setIsModal({ open: false, action: 'CHANGE', data: undefined }); //JUST FOR REFETCH
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleFilterOptions = async (value: string) => {
        try {
            const data = await apiHandler.post<any>('/booking/list', { pagination: { pageSize: 30, currentPage: 1, filters: { bookingDate: globalDate, offer: selectedOffer?._id, bookingStatus: [0, 1, 2], surname: value, locationIdentifier: [] } } });
            setBookingsNoLoc(data?.pagination?.data);
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleUpdateBookingStatus = async (value: BOOKING_STATUS, id: string) => {
        try {
            const response = await apiHandler.put<putHandler>('/booking/update-status/' + id, { status: value });
            if (response) {
                if (value !== BOOKING_STATUS.SUCCESS && value !== BOOKING_STATUS.PENDING && value !== BOOKING_STATUS.ACCEPTED) {
                    handleChangeTable(id, '', false, 'CLEAN');
                }
                setSearchTerm('');
                showModal(200);
                setIsModal({ open: false, action: 'CHANGE', data: undefined }); //JUST FOR REFETCH
                setBookingId(undefined)
            }
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleManageClosedPos = async (pos: string, command: "ADD" | "REMOVE") => {
        try {
            setIsLoading(true);
            await apiHandler.put<putHandler>('/daily-info/update/closed-pos/' + globalDate, { pos, offerId: selectedOffer?._id, command });

            showModal(200);
            setIsModal({ open: false, action: 'CHANGE', data: undefined }); //JUST FOR REFETCH
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const renderOffersFilter = () => {
        return <div className='flex  gap-2'>
            {offers.map((offer: IOffer, index: number) => {
                const isOfferSelected = selectedOffer && selectedOffer._id === offer._id;
                return (
                    <div key={index}
                        onClick={() => {
                            setSearchTerm('');
                            setSelectedOffer(offer);
                            setSelectedroom(offer?.rooms[0] || undefined);
                        }}
                        className={`font-medium text-xl px-6 py-2 flex items-center shadow rounded-full capitalize cursor-pointer select-none ${isOfferSelected ? 'bg-primary text-text_white' : 'bg-white text-text'}`}
                    >
                        {offer.name}
                    </div>
                );
            })}
        </div >
    };

    const renderOffersRooms = () => {
        return <div className='flex  gap-2'>
            {selectedOffer?.rooms.map((room, index) => {
                return <div
                    key={index}
                    onClick={() => {
                        setSelectedroom(room);
                        setSearchTerm('');
                    }}
                    className={`font-medium px-4 py-1 flex items-center shadow rounded-full capitalize cursor-pointer select-none ${selectedRoom?._id === room._id ? 'bg-info text-text_white' : 'bg-white text-text'}`}
                >
                    {room.name}
                </div>
            })}
        </div >
    };


    const openCreateBooking = () => {
        if (searchParams.has('create')) {
            searchParams.delete('create');
        } else {
            searchParams.set('create', 'OPEN');
        }

        setSearchParams(searchParams);
    };

    return <PageTamplate>
        <div className='flex flex-col flex-wrap gap-4 mb-2 z-10 w-full'>

            {(!loadingOffers) && renderOffersFilter()}
            {(!loadingOffers && !!selectedOffer?.rooms?.length) && renderOffersRooms()}

            <div className='flex w-full justify-between gap-2'>
                <InputForm
                    value={searchTerm}
                    setValue={(value) => setSearchTerm(value)}
                    onSearch={() => setSearchTerm(searchTerm)}
                    backgroundColor='bg-white'
                    placeholder='Cerca per cognome...'
                    fullWidth
                    customStyle='h-8 rounded-3xl shadow'
                />
                <div onClick={openCreateBooking} className='flex justify-center items-center h-8 px-4 rounded-md cursor-pointer shadow bg-text'>
                    <AddIcon />
                </div>
            </div>

            {!!bookingData?.pagination?.data?.length &&
                <div className='p-2 bg-white w-full flex flex-col gap-2'>
                    <div className="grid grid-cols-5 md:grid-cols-4 items-center justify-between font-semibold dium whitespace-nowrap text-text select-none">
                        <p>Nome</p>
                        <p>Pax</p>
                        <p>Pst</p>
                        <p className='col-span-2 md:col-span-1'>Azioni stato</p>
                    </div>

                    {bookingData.pagination.data.map((booking: IBooking, index: number) => {
                        return <div key={index} className="z-40 grid grid-cols-5 md:grid-cols-4 items-center justify-between text-sm capitalize text-text">
                            <p className='truncate'>{booking.surname} {booking.name}</p>
                            <p>{booking.people}</p>
                            <p>{booking.locationIdentifier}</p>
                            <div className='flex gap-2 col-span-2 md:col-span-1'>
                                {(booking.bookingStatus !== BOOKING_STATUS.PENDING) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.PENDING, booking._id!)} title="In Corso"><PendingIcon /></div>}
                                {(booking.bookingStatus !== BOOKING_STATUS.ACCEPTED) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.ACCEPTED, booking._id!)} title="Accettata"><AcceptedIcon /></div>}
                                {(booking.bookingStatus !== BOOKING_STATUS.SUCCESS) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.SUCCESS, booking._id!)} title="Completata"><SuccessIcon /></div>}
                                {(booking.bookingStatus !== BOOKING_STATUS.NOSHOW) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.NOSHOW, booking._id!)} title="No Show"><NoShowIcon /></div>}
                                {(booking.bookingStatus !== BOOKING_STATUS.CANCELLED) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.CANCELLED, booking._id!)} title="Disdetta"><CancelIcon /></div>}
                            </div>
                        </div>
                    })}
                </div>
            }
        </div>

        <p className="font-semibold text-text text-lg">Tavoli Attivi</p>

        {(searchParams.has('create'))
            ? selectedOffer && <QuickBookingForm selectedOffer={selectedOffer} />
            : (!loading)
                ? !!data?.positionsData?.length
                    ? <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
                        {data.positionsData.map((result, index) => {
                            const { pos, booking } = result;
                            if (booking?.isAggregate && booking.mainTable !== pos && booking.locationIdentifier.some(loc => loc === pos)) {
                                return null
                            }
                            const isMultiple = booking ? booking?.locationIdentifier?.length > 1 : false;
                            const aggregateTables = booking?.isAggregate ? booking?.locationIdentifier?.filter(loc => loc !== booking.mainTable) : undefined;
                            return <div key={index} className={`rounded flex items-center gap-1 ${isDragging === booking?._id ? 'bg-mediumGray' : 'bg-white'}`}>
                                <p onClick={() => (bookingId === booking?._id) ? setBookingId(undefined) : setBookingId(booking?._id)} title={booking && bookingStatusDbMapping[booking.bookingStatus]} className={`h-full aspect-square p-2 flex justify-center items-center text-sm font-bold ${booking && 'text-text_white cursor-pointer ' + bookingStatusColorMapping[booking.bookingStatus]} rounded-l`}>{pos}</p>
                                {!!bookingId && bookingId === booking?._id
                                    ? <div className='flex gap-2 justify-center w-full'>
                                        {(booking.bookingStatus !== BOOKING_STATUS.PENDING) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.PENDING, booking._id!)} title="In Corso"><PendingIcon /></div>}
                                        {(booking.bookingStatus !== BOOKING_STATUS.ACCEPTED) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.ACCEPTED, booking._id!)} title="Accettata"><AcceptedIcon /></div>}
                                        {(booking.bookingStatus !== BOOKING_STATUS.SUCCESS) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.SUCCESS, booking._id!)} title="Completata"><SuccessIcon /></div>}
                                        {(booking.bookingStatus !== BOOKING_STATUS.NOSHOW) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.NOSHOW, booking._id!)} title="No Show"><NoShowIcon /></div>}
                                        {(booking.bookingStatus !== BOOKING_STATUS.CANCELLED) && <div className='cursor-pointer' onClick={() => handleUpdateBookingStatus(BOOKING_STATUS.CANCELLED, booking._id!)} title="Disdetta"><CancelIcon /></div>}
                                    </div>
                                    : booking
                                        ? <div
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, booking._id!)}
                                            onDragOver={handleDragOver}
                                            onDragEnd={() => setIsDragging('')}
                                            onDrop={(e) => handleDrop(e, pos, booking._id!)}
                                            className='flex gap-1.5 justify-between w-full cursor-grab items-center p-1'>

                                            <p className='font-medium text-text max-sm:text-sm whitespace-nowrap'>{booking.surname} {booking.name} | {booking.people}</p>

                                            <Autocomplete
                                                options={data.positionsData.filter(loc => !booking.locationIdentifier.some(location => location === loc.pos))}
                                                label="Scambia con tavolo..."
                                                fieldToSearch='pos'
                                                rightButton
                                                freeButton
                                                onButtonClick={(newPos, action) => handleChangeTable(booking._id!, newPos, false, action, pos)}
                                            />

                                            {isMultiple
                                                ? (screenSize !== 'xs')
                                                    ? <CustomButton className='whitespace-nowrap' disabled={isLoading} label={booking?.isAggregate ? 'Separa ' + String(aggregateTables) : 'Unisci'} color='bg-info' onClickHandler={() => handleCombineTables(booking, pos, !booking.isAggregate)} />
                                                    : <div onClick={() => !isLoading && handleCombineTables(booking, pos, !booking.isAggregate)}><MergeIcon /></div>
                                                : null}
                                        </div>
                                        : <div onDrop={(e) => handleDrop(e, pos, '')} className='flex items-center w-full gap-2 p-1'>
                                            <Autocomplete
                                                options={bookingsNoLoc}
                                                optionsFilterCallback={(value) => handleFilterOptions(value)}
                                                label="Cerca cognome..."
                                                fieldToSearch='surname'
                                                fieldToShow2='name'
                                                fieldToShow3='people'
                                                bookingStatus rightButton
                                                onButtonClick={(id, action) => handleChangeTable(id, pos, false, action)}
                                            />
                                            {(screenSize !== 'xs')
                                                ? <CustomButton color='bg-darkGray' disabled={isLoading} label='Disattiva' onClickHandler={() => handleManageClosedPos(pos, "ADD")} />
                                                : <div onClick={() => !isLoading && handleManageClosedPos(pos, "ADD")}><BlockIcon /></div>}
                                        </div>
                                }
                            </div>
                        })}
                    </div>
                    : <p className='font-medium text-gray-500 text-sm flex flex-1 justify-center items-center'>Nessun postazione inserita per questo servizio</p>
                : <LoadingIndicator label='Caricamento tavoli' />
        }

        {/* CLOSED POS */}
        {!!data?.closedTable?.length &&
            <>
                <div className='border-b border-mediumGray mt-4' />

                <p className="font-semibold text-text text-lg">Tavoli Disattivati</p>

                <div className='flex flex-wrap gap-2'>
                    {data.closedTable.sort((a, b) => Number(a) - Number(b)).map((table: string, index: number) => {
                        return <div onClick={() => !isLoading && handleManageClosedPos(table, "REMOVE")} key={index} className='flex items-center justify-between gap-2 bg-white rounded-md p-1'>
                            <p className='text-xl font-medium text-text px-2'>{table}</p>
                            {(screenSize !== 'xs')
                                ? <CustomButton disabled={isLoading} popoverTitle='Attiva' fullWidth color='bg-primary' label='Attiva' onClickHandler={() => false} />
                                : <CheckIcon />
                            }
                        </div>
                    })}
                </div>
            </>
        }


        <Modal isOpen={isModal.open} onClose={() => setIsModal({ open: false, action: 'CHANGE', data: undefined })}>
            {isModal.action === 'SPLIT'
                ? <div className='flex flex-col gap-2'>
                    <p>Libera il tavolo {isModal.data?.destinationPos} prima di poter dividere la prenotazione {isModal.data?.source.surname} {isModal.data?.source.name}</p>
                    <CustomButton label='Ok' color='bg-primary' textColor='text-text_white' onClickHandler={() => {
                        setIsModal({ open: false, action: 'CHANGE', data: undefined })
                    }} />
                </div>
                : <div>
                    <p>Tavolo <span className='font-semibold capitalize'>{isModal.data?.destinationPos}</span> già occupato dalla prenotazione <span className='font-semibold capitalize'>{isModal.data?.block.surname} {isModal.data?.block.name}</span>!</p>
                    <p>Vuoi scambiare i tavoli assegnando a <span className='font-semibold capitalize'>{isModal.data?.block.surname} {isModal.data?.block.name}</span> il tavolo <span className='font-semibold capitalize'>{isModal.data?.sourcePos}</span>?</p>
                    <div className='flex flex-col justify-center items-center font-semibold capitalize'>
                        <p>{isModal.data?.block.surname} {isModal.data?.block.name} {'->'} {isModal.data?.sourcePos}</p>
                        <p>{isModal.data?.source.surname} {isModal.data?.source.name} {'->'} {isModal.data?.destinationPos}</p>
                    </div>
                    <div className='flex justify-between mt-2'>
                        <CustomButton label='Annulla' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => {
                            setIsModal({ open: false, action: 'CHANGE', data: undefined })
                        }} />
                        <CustomButton
                            disabled={isLoading}
                            color='bg-primary'
                            label='Scambia'
                            onClickHandler={() => (typeof isModal.data !== 'undefined') && handleChangeTable(isModal.data.source._id!, isModal.data?.destinationPos, true, 'CHANGE', isModal.data.sourcePos)}
                        />
                    </div>
                </div>
            }
        </Modal>
    </PageTamplate >
}

export default EntryManagement