import { useEffect, useRef } from "react";
import { IBookingRequest } from "../../../../types/interfaces/bookingRequest.interface";
import { IOffer } from "../../../../types/interfaces/offer.interface";
import { IProposal } from "../../../../types/interfaces/proposal.interface";
import { IStructure } from "../../../../types/interfaces/structure.interface";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import { IAutoBookingOption } from "../../../../types/interfaces/autoBookingOptions.interface";

interface IProps {
    newBookingRequest: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    handleNext: (fields: (keyof IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>)[], value: any, step: number) => void;
};

const Step1Update: React.FC<IProps> = ({ newBookingRequest, handleNext }) => {
    const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    useHorizontalScroll(containerRef);

    useEffect(() => {
        if (newBookingRequest.shift) {
            const selectedIndex = newBookingRequest.offer.shifts.indexOf(newBookingRequest.shift);

            if (selectedIndex !== -1 && buttonRefs.current[selectedIndex]) {
                setTimeout(() => {
                    buttonRefs.current[selectedIndex]!.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
                }, 0);
            }
        }
    }, [newBookingRequest.shift]);

    return (
        <div ref={containerRef} className='flex w-full overflow-x-auto hidden-scrollbar px-4 gap-2'>
            {newBookingRequest.offer.shifts.map((shift, index) => {
                const isSelected = newBookingRequest.shift === shift;
                return (
                    <button
                        key={index}
                        ref={(el) => (buttonRefs.current[index] = el)}
                        disabled={isSelected}
                        onClick={() => handleNext(["shift"], shift, 2)}
                        className={`aspect-video h-12 font-semibold text-lg hover:border-2 rounded-md shadow bg-white ${isSelected ? 'border-2 border-primary text-primary' : 'text-darkGray'}`}
                    >
                        {shift}
                    </button>
                );
            })}
        </div>
    )
}

export default Step1Update