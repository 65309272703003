import { setSideBarState } from "../store/UI/uiSlice";
import { useAppDispatch, useAppSelector } from "../store/store";

interface IProps {
  navContent?: JSX.Element;
  burgerMenu?: boolean;
}

const Navbar = ({ navContent, burgerMenu = true }: IProps) => {
  const dispatch = useAppDispatch();

  const { sideBarState } = useAppSelector(state => state.ui);

  return <div className='flex w-full py-2 px-4 justify-between items-center text-text select-none shadow bg-white z-40'>

    <div className="cursor-pointer select-none p-2 pl-0 z-40" onClick={() => dispatch(setSideBarState(!sideBarState))}>
      {burgerMenu &&
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="8" width="20" height="4" rx="0.5" fill="#131313" />
          <rect width="20" height="4" rx="0.5" fill="#131313" />
          <rect y="16" width="20" height="4" rx="0.5" fill="#131313" />
        </svg>}
    </div>
    {navContent}
  </div>
}

export default Navbar;
