export const ArrowUp = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4722 7.24707e-07C13.8062 7.53908e-07 14.1402 0.0740746 14.4742 0.222223C14.8082 0.370372 15.068 0.574075 15.2536 0.833334L26.2206 11.7778C26.7402 12.2222 27 12.7963 27 13.5C27 14.2037 26.7588 14.7963 26.2763 15.2778C25.7938 15.7593 25.1907 16 24.467 16C23.7433 16 23.1402 15.7593 22.6577 15.2778L13.4722 6.11111L4.2866 15.2778C3.80412 15.7593 3.21959 16 2.53299 16C1.84639 16 1.2433 15.7593 0.723709 15.2778C0.241234 14.7963 -1.7495e-06 14.1944 -1.68636e-06 13.4722C-1.62322e-06 12.75 0.241234 12.1481 0.723709 11.6667L11.6351 0.833334C11.8577 0.574074 12.1361 0.370371 12.4701 0.222223C12.8041 0.0740745 13.1381 6.95506e-07 13.4722 7.24707e-07Z" fill="#5B2FF6" />
        </svg>
    )
}

export const ArrowDown = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6576 15C12.3451 15 12.0326 14.9306 11.72 14.7917C11.4075 14.6528 11.1644 14.4618 10.9908 14.2188L0.729246 3.95833C0.243082 3.54167 0 3.00347 0 2.34375C0 1.68403 0.225719 1.12847 0.677157 0.677083C1.12859 0.225694 1.69289 0 2.37005 0C3.04721 0 3.6115 0.225694 4.06294 0.677083L12.6576 9.27083L21.2523 0.677083C21.7037 0.225694 22.2507 0 22.8931 0C23.5355 0 24.0998 0.225694 24.586 0.677083C25.0374 1.12847 25.2632 1.69271 25.2632 2.36979C25.2632 3.04688 25.0374 3.61111 24.586 4.0625L14.3766 14.2188C14.1682 14.4618 13.9078 14.6528 13.5952 14.7917C13.2827 14.9306 12.9702 15 12.6576 15Z" fill="#5B2FF6" />
        </svg>
    )
}

export const ArrowLeft = ({ color = '#5B2FF6' }) => {
    return (
        <svg width="10" height="30" viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={color} d="M0.499999 15.1891C0.499999 14.8141 0.583331 14.4391 0.749997 14.064C0.916664 13.689 1.14583 13.3973 1.4375 13.1889L13.75 0.875095C14.25 0.291698 14.8958 0 15.6875 0C16.4792 0 17.1458 0.270863 17.6875 0.812588C18.2292 1.35431 18.5 2.03147 18.5 2.84406C18.5 3.65665 18.2292 4.3338 17.6875 4.87553L7.375 15.1891L17.6875 25.5028C18.2292 26.0445 18.5 26.7008 18.5 27.4717C18.5 28.2426 18.2292 28.9198 17.6875 29.5032C17.1458 30.0449 16.4687 30.3158 15.6562 30.3158C14.8437 30.3158 14.1667 30.0449 13.625 29.5032L1.4375 17.2519C1.14583 17.0018 0.916664 16.6893 0.749997 16.3143C0.583331 15.9392 0.499999 15.5642 0.499999 15.1891Z" />
        </svg>
    );
}

export const ArrowRight = ({ color = '#5B2FF6', width = "10", height = "30" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 15.1267C18 15.5017 17.9167 15.8767 17.75 16.2518C17.5833 16.6268 17.3542 16.9185 17.0625 17.1269L4.75 29.4407C4.25 30.0241 3.60417 30.3158 2.8125 30.3158C2.02083 30.3158 1.35417 30.0449 0.8125 29.5032C0.270833 28.9615 0 28.2843 0 27.4717C0 26.6591 0.270833 25.982 0.8125 25.4403L11.125 15.1267L0.8125 4.81303C0.270833 4.27131 0 3.61499 0 2.84407C0 2.07315 0.270833 1.396 0.8125 0.812599C1.35417 0.270874 2.03125 1.11982e-05 2.84375 1.11982e-05C3.65625 1.11982e-05 4.33333 0.270874 4.875 0.812599L17.0625 13.0639C17.3542 13.314 17.5833 13.6265 17.75 14.0015C17.9167 14.3766 18 14.7516 18 15.1267Z" fill={color} />
        </svg>
    );
};

export const ArrowRightForward = ({ color = '#FFFFFF', size = '24' }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill={color}>
        <path d="M633.85-434.5H151.87v-91h481.98L415.11-744.24 480-808.13 808.13-480 480-151.87l-64.89-63.89L633.85-434.5Z" />
    </svg>
};
