import { useParams, useSearchParams } from "react-router-dom";
import { useModalDispatcher } from "../../../hooks/useModalDispatcher";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import InputForm from "../../../components/InputForm";
import CustomButton from "../../../components/CustomButton";
import { apiHandler } from "../../../services/apiHandler";
import { ICategory } from "../../../types/interfaces/category.interface";
import { IProduct } from "../../../types/interfaces/product.inteface";
import { IProposal } from "../../../types/interfaces/proposal.interface";
import { IStructure } from "../../../types/interfaces/structure.interface";
import { IOffer } from "../../../types/interfaces/offer.interface";

interface ProductState {
    name: string;
    description?: string;
    price?: number;
}

interface IProps {
    isOpen: boolean;
    setIsOpen: (e: boolean) => void;
    category?: ICategory | undefined;
    proposal?: IProposal<IStructure, IOffer, IProduct> | undefined;
    element?: IProduct;
    setElement?: React.Dispatch<React.SetStateAction<IProduct | undefined>>
};

const ManageProduct: React.FC<IProps> = ({ isOpen, setIsOpen, category, proposal, element, setElement }) => {
    const { showErrorModal } = useModalDispatcher();
    const [searchParams, setSearchParams] = useSearchParams();


    const ref = useRef<HTMLInputElement | null>(null);

    const [product, setProduct] = useState<ProductState>({ name: '', description: '', price: 0 });
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (element) {
            setProduct(element)
        } else {
            setProduct({ name: '', description: '', price: 0 })
        }
    }, [element])

    const handleManageProduct = async () => {
        try {
            setError('');

            if (!element
                && !!category
                && category?.products.find(prod => { if (typeof prod !== 'string') return prod.name.toLowerCase() === product.name.toLowerCase() })) {
                setError('Nome del prodotto gia inserito.');
                return;
            }

            if (!element
                && !!proposal
                && proposal?.products?.find(prod => { if (typeof prod !== 'string') return prod.name.toLowerCase() === product.name.toLowerCase() })) {
                setError('Nome del prodotto gia inserito.');
                return;
            }

            if (!product?.name.length) {
                setError('Inserisci il nome del prodotto.');
                return;
            }

            setIsLoading(true);

            if (!!element) {
                await apiHandler.put('product/update/' + element._id, product);
            } else {
                await apiHandler.post('product/create', { ...product, category: category?._id, proposal: proposal?._id });
            }

            setSearchParams(searchParams);
            setProduct({ name: '', description: '', price: 0 });
            setIsLoading(false);

            ref?.current?.focus();

            if (!!element)
                handleClose();

        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleDeleteProduct = async () => {
        try {
            setIsLoading(true);

            await apiHandler.del('product/delete/' + element?._id);

            setSearchParams(searchParams);
            setProduct({ name: '', description: '', price: 0 });
            handleClose();
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    }

    const handleProductChange = (field: keyof ProductState, value: string): void => {
        setProduct(prevProduct => ({
            ...prevProduct,
            [field]: value
        }));
    };

    const handleClose = () => {
        if (setElement) { setElement(undefined) }
        setIsOpen(false);
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <h2 className="text-xl font-semibold text-text text-center mb-4">{!!element ? 'Modifica' : 'Nuovo'} Prodotto</h2>
            <div className='flex flex-col gap-8 md:w-96'>

                <InputForm inputRef={ref} backgroundColor='bg-background' label='Nome *' value={product?.name} setValue={(e) => handleProductChange('name', e)} onSearchSubmit={handleManageProduct} />
                {
                    !!category && <div className="flex flex-col gap-2">
                        <p className="font-semibold text-text text-lg">Prezzo</p>
                        <div className="flex gap-4 items-center">
                            <InputForm backgroundColor='bg-background' type="number" fullWidth value={product?.price} setValue={(e) => handleProductChange('price', e)} onSearchSubmit={handleManageProduct} />
                            <p className="font-semibold text-2xl text-darkGray">€</p>
                        </div>
                    </div>
                }
                <InputForm backgroundColor='bg-background' label='Descrizione' value={product?.description} setValue={(e) => handleProductChange('description', e)} onSearchSubmit={handleManageProduct} />

                {!!error && <p className="text-error text-sm text-center font-medium">{error}</p>}

                <div className={`flex flex-row-reverse gap-2 justify-between`}>
                    <CustomButton disabled={isLoading} onClickHandler={handleManageProduct} color='bg-primary' label={!!element ? 'Modifica' : 'Crea'} />
                    {!element
                        ? <CustomButton onClickHandler={handleClose} color='bg-success' label='Fine' />
                        : <CustomButton onClickHandler={handleDeleteProduct} color='bg-error' label='Elimina' />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ManageProduct