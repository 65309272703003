import React, { useEffect, useState } from 'react';
import { IOffer, IOfferCapacity, IProposalCapacity } from '../types/interfaces/offer.interface';
import LoadingIndicator from './LoadingIndicator';
import moment from 'moment';
import useFetch from '../hooks/useFetch';
import DropdownMenu, { Option } from './DropdownMenu';
import ProgressBar from './ProgressBar';
import { apiService, HttpMethod } from '../services/apiService';
import { AddIcon } from '../utils/icons/AddIcon';
import ProposalCapacityTable from './ProposalCapacityTable';
import { useSearchParams } from 'react-router-dom';

interface IProps {
    offer: IOffer;
    date?: string;
    addBookingBtn?: (id: IOffer) => void;
    refetch: boolean
}

export const getColorForPercentage = (percentage: number): string => {
    const colorMap: Record<string, boolean> = {
        'bg-success': percentage > 40,
        'bg-warning': percentage <= 60 && percentage > 0,
        'bg-error': percentage <= 0
    };
    return Object.keys(colorMap).find((color) => colorMap[color]) || '';
}

const OfferCapacityInfo: React.FC<IProps> = ({ offer, date = moment().utc(true).startOf('day').toISOString(), addBookingBtn, refetch }) => {
    const [showCapacity, setShowCapacity] = useState<"free" | "busy">("busy");
    const [searchParams] = useSearchParams();

    const { data, loading: loadingCapacity } = useFetch<IOfferCapacity>(
        () => apiService(HttpMethod.GET, '/offer/disponibility', undefined, { offerId: offer._id, date: date, numberOfPeople: 0, }),
        undefined,
        [offer._id, date, searchParams, refetch],
        !!offer._id
    );

    const { data: proposalsCapacity } = useFetch<IProposalCapacity[]>(
        () => apiService(HttpMethod.GET, '/offer/proposal/disponibility', undefined, { offersId: [offer._id], date: date }),
        undefined,
        [offer._id, date, searchParams, refetch],
        !!offer._id
    );

    const totalProposalsCapacity = !!proposalsCapacity?.length ? proposalsCapacity?.reduce((acc, curr) => acc + curr.totCapacity, 0) : 0;
    const totalProposalsBooked = !!proposalsCapacity?.length ? proposalsCapacity?.reduce((acc, curr) => acc + curr.currentCapacity, 0)! : 0;

    const generateOptions = (): Option[] => {
        return [
            {
                label: <p>Disponibili</p>,
                onClick: () => setShowCapacity("free")
            },
            {
                label: <p>Occupati</p>,
                onClick: () => setShowCapacity("busy")
            }
        ]
    };

    return !loadingCapacity
        ? !!data
            ? <div className={`flex flex-col pb-4 gap-4 rounded shadow-md bg-white w-full sm:w-96 flex-shrink-0`}>

                <div className='w-full rounded-t px-6 pt-3 pb-2 flex justify-between items-center bg-background2'>
                    <p className='capitalize text-3xl font-bold w-full truncate'>{offer.name}</p>
                    {/* {!!addBookingBtn &&
                        <button type='button' onClick={() => addBookingBtn(data.offer)} className='bg-text py-2 px-4 rounded-lg shadow'>
                            <AddIcon size='28' />
                        </button>
                    } */}
                </div>

                {data.offer.capacity > 0
                    ? <div className='flex justify-between items-baseline gap-2 px-6'>
                        <p>
                            <span className={`text-4xl ${showCapacity === "free" ? 'text-success' : 'text-error'} font-bold`}>{showCapacity === "free" ? data.freeCapacity : data.peopleBooked}</span>
                            <span className='text-2xl font-bold'>/{data.offer.capacity}</span>
                        </p>

                        <DropdownMenu placement='auto' arrowDown toggleButton={<p className='cursor-pointer text-lg text-darkGray font-semibold'>{showCapacity === "free" ? "Disponibili" : "Occupati"}</p>} options={generateOptions()} />
                    </div>
                    : <div className='flex justify-between items-baseline gap-2 px-6'>
                        <p className={`text-4xl text-text font-bold`}>{data.peopleBooked}</p>
                        <p className='cursor-pointer text-lg text-darkGray font-semibold'>Persone</p>
                    </div>
                }

                <div className='flex flex-col gap-2 px-6'>
                    {data.offer.capacity > 0
                        ? showCapacity === 'free'
                            ? <ProgressBar goodPerc={Number(data.goodPercent)} />
                            : <ProgressBar goodPerc={Number(data.badPercent)} />
                        : <ProgressBar goodPerc={((totalProposalsCapacity ?? 0 - totalProposalsBooked) * 100 / (totalProposalsCapacity ?? 0))} />
                    }
                    <p className='text-right text-sm font-medium'>{data.nBookings} Prenotazioni</p>
                </div>

                {!!proposalsCapacity?.length &&
                    <div className='flex flex-col px-6 gap-2'>
                        <p className='select-none text-xl font-semibold'>Proposte</p>

                        <ProposalCapacityTable proposalsCapacity={proposalsCapacity} />
                    </div>
                }
            </div>
            : <p className='flex h-full items-center justify-center font-medium text-sm text-darkGray'>Nessuna statistica da mostrare</p>
        : <div className={`flex flex-col items-center h-72 pb-4 gap-4 rounded shadow-md bg-white w-full sm:w-96 flex-shrink-0`}>
            <LoadingIndicator icon />
        </div>
};

export default OfferCapacityInfo