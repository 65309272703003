export enum PERMISSION_LEVEL {
    READ,
    MANAGE
};

const permissionLevelMapping = {
    READ: PERMISSION_LEVEL.READ,
    MANAGE: PERMISSION_LEVEL.MANAGE
};

export const permissionLevelLabels = {
    [PERMISSION_LEVEL.READ]: 'Visualizza',
    [PERMISSION_LEVEL.MANAGE]: 'Gestisci',
};

export const mapPermissionLevel = (value: any) => {
    return permissionLevelLabels[value as PERMISSION_LEVEL] || value;
}

export const permissionLevelOptions: { label: string; value: PERMISSION_LEVEL }[] = Object.values(permissionLevelMapping).map(key => ({
    label: mapPermissionLevel(Number(key) as PERMISSION_LEVEL) as string,
    value: Number(key) as PERMISSION_LEVEL,
}));