import React, { Key } from 'react'

interface IProps {
    label: string;
    checked: boolean;
    classname?: string;
    labelStyle?: string;
    onChange?: () => void;
    disabled?: boolean;
}

const RadioButton: React.FC<IProps> = ({ label, checked, classname, onChange, labelStyle = 'text-sm', disabled = false }) => {
    return (
        <button type='button' disabled={disabled} className={`flex items-center gap-4 pl-1 ${classname}`} onClick={onChange}>
            <div className={`h-4 rounded-full aspect-square shadow border border-primary ${(checked) ? 'bg-primary' : 'bg-background'}`} />
            <p className={`text-text font-medium capitalize ${labelStyle} truncate`}>{label}</p>
        </button>
    )
}

export default RadioButton
