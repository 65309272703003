import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../src/utils/style/scrollbar.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorkerRegistration.register();

// Send a message to the service worker to skip waiting and activate the new version
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.controller?.postMessage({ type: 'SKIP_WAITING' });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
