import Routing from './routes/Routing';
import ApiResponseModal from './components/ApiResponseModal';
import { useAppDispatch } from './store/store';
import SocketManager from './services/socket/SocketManager';
import moment from 'moment';
import 'moment/locale/it';

moment.locale('it');

function App() {
  const dispatch = useAppDispatch();

  // initialize socket manager 
  const socketManager = new SocketManager(dispatch);
  socketManager.initialize();

  return (
    <>
      <Routing />
      <ApiResponseModal />
    </>
  );
}

export default App;
