export const IminDashboard = ({ color = "#5B2FF6" }) => {
    return (
        <svg width="60" height="24" viewBox="0 0 131 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_441_13)">
                <path d="M55.6163 0.363098H20.144V30.5392C20.144 31.6832 20.6752 32.7804 21.6206 33.5893C22.5661 34.3982 23.8483 34.8527 25.1854 34.8527H30.216V13.2944C30.216 12.1504 30.7471 11.0532 31.6926 10.2443C32.638 9.43536 33.9203 8.9809 35.2574 8.9809C36.5944 8.9809 37.8767 9.43536 38.8221 10.2443C39.7676 11.0532 40.2987 12.1504 40.2987 13.2944V30.5392C40.2987 31.6832 40.8299 32.7804 41.7753 33.5893C42.7207 34.3982 44.003 34.8527 45.3401 34.8527H50.3707V13.4416C50.3574 12.3289 50.8346 11.2529 51.7071 10.4279C52.5797 9.60288 53.7836 9.08951 55.0788 8.9901C55.7671 8.95243 56.4571 9.03573 57.1063 9.23488C57.7555 9.43403 58.3502 9.7448 58.8537 10.148C59.3572 10.5513 59.7589 11.0385 60.034 11.5796C60.3091 12.1207 60.4519 12.7043 60.4534 13.2944V30.392C60.4348 31.5044 60.9088 32.5816 61.7801 33.4073C62.6514 34.233 63.8556 34.7461 65.1508 34.8435H70.5254V13.1288C70.5254 9.74475 68.9549 6.49913 66.1592 4.10535C63.3636 1.71157 59.5714 0.365536 55.6163 0.363098Z" fill={color} />
                <path d="M116.091 0.363098H100.773V30.4288C100.756 31.417 101.13 32.3811 101.835 33.1643C102.539 33.9475 103.533 34.5037 104.654 34.7423C105.031 34.8149 105.417 34.8519 105.804 34.8527H110.845V13.4416C110.832 12.3289 111.309 11.2529 112.182 10.4279C113.054 9.60288 114.258 9.08951 115.553 8.9901C116.242 8.95243 116.932 9.03573 117.581 9.23488C118.23 9.43403 118.825 9.7448 119.328 10.148C119.832 10.5513 120.233 11.0385 120.509 11.5796C120.784 12.1207 120.926 12.7043 120.928 13.2944V30.5392C120.927 31.1052 121.056 31.6659 121.308 32.1892C121.56 32.7125 121.93 33.1881 122.398 33.5887C122.865 33.9894 123.42 34.3073 124.031 34.5242C124.642 34.7411 125.297 34.8527 125.959 34.8527H131V13.1288C131 9.74475 129.429 6.49913 126.634 4.10535C123.838 1.71157 120.046 0.365536 116.091 0.363098Z" fill={color} />
                <path d="M6.29901 0.363098H0V31.6337C0.00284556 32.4882 0.401608 33.307 1.10887 33.9104C1.81612 34.5138 2.77416 34.8527 3.77295 34.8527H10.072V3.58213C10.0691 2.72754 9.67035 1.90879 8.9631 1.30537C8.25584 0.701948 7.2978 0.363095 6.29901 0.363098Z" fill={color} />
                <path d="M86.9177 0.363098H80.6187V31.6337C80.6187 32.4898 81.0162 33.311 81.7237 33.9164C82.4313 34.5218 83.391 34.8619 84.3916 34.8619H90.6906V3.59132C90.6906 2.73515 90.2931 1.91403 89.5855 1.30862C88.878 0.703214 87.9183 0.363098 86.9177 0.363098Z" fill={color} />
                <path d="M3.57947 51.2233H0V43.4796H3.62247C4.21927 43.4735 4.81156 43.569 5.36508 43.7604C5.9186 43.9519 6.42236 44.2356 6.84721 44.5951C7.70054 45.3295 8.17968 46.3239 8.17968 47.3607C8.17968 48.3974 7.70054 49.3919 6.84721 50.1263C6.41373 50.4848 5.90187 50.7663 5.34106 50.9546C4.78025 51.1429 4.18158 51.2342 3.57947 51.2233ZM3.57947 50.4028C4.51055 50.4169 5.41052 50.1154 6.08402 49.5639C6.76668 48.9764 7.15 48.1809 7.15 47.3515C7.15 46.5221 6.76668 45.7265 6.08402 45.139C5.40203 44.5872 4.49541 44.2861 3.55797 44.3001H1.07492V50.4028H3.57947Z" fill={color} />
                <path d="M23.6482 51.2232H22.5733L21.4984 49.2412H16.6613L15.6723 51.2232H14.5974L18.6176 43.4796H19.6925L23.6482 51.2232ZM21.1222 48.4577L19.0798 44.4752L17.0912 48.43L21.1222 48.4577Z" fill={color} />
                <path d="M33.4514 51.2324C32.8174 51.2434 32.1874 51.1436 31.5998 50.9391C31.0122 50.7347 30.4792 50.4299 30.0332 50.0432L30.4847 49.3058C31.2881 49.9711 32.3562 50.35 33.4729 50.3659C33.9952 50.3713 34.5101 50.2602 34.9671 50.0432C35.159 49.9544 35.3175 49.8205 35.4241 49.6573C35.5307 49.494 35.5809 49.3082 35.569 49.1214C35.579 48.9148 35.5208 48.71 35.401 48.5306C35.2811 48.3512 35.1047 48.2045 34.8918 48.1073C34.39 47.9026 33.8638 47.7449 33.3224 47.6372C32.7481 47.5058 32.1901 47.3267 31.6563 47.1025C31.5166 47.0317 31.3836 46.9516 31.2586 46.8628C30.8497 46.5488 30.5906 46.1162 30.5311 45.6482C30.4716 45.1803 30.6158 44.71 30.9361 44.3277C31.2471 44.0237 31.6483 43.7975 32.097 43.6732C32.5875 43.5418 33.0988 43.4765 33.6127 43.4796C34.6206 43.4738 35.608 43.724 36.4505 44.1986L36.0205 45.0191C35.2956 44.5829 34.4337 44.345 33.5482 44.3369C33.0872 44.329 32.6306 44.4142 32.2153 44.5858C32.0362 44.6587 31.8863 44.7756 31.7849 44.9217C31.6835 45.0678 31.635 45.2365 31.6456 45.4063C31.6365 45.5875 31.6821 45.7676 31.7782 45.9291C31.8743 46.0906 32.0174 46.2281 32.1938 46.3281C32.6993 46.6093 33.2602 46.8092 33.8492 46.9181C34.5274 47.0643 35.1761 47.2974 35.7733 47.6095C36.056 47.78 36.2868 48.0064 36.4458 48.2696C36.6049 48.5328 36.6876 48.825 36.6869 49.1214C36.7085 49.54 36.5561 49.9516 36.257 50.2829C35.9365 50.6114 35.516 50.8571 35.0423 50.9928C34.5316 51.1492 33.9937 51.2303 33.4514 51.2324Z" fill={color} />
                <path d="M52.241 51.2232H51.166V47.7939H45.7915V51.2232H44.7166V43.4796H45.7915V46.9919H51.166V43.4796H52.241V51.2232Z" fill={color} />
                <path d="M64.7852 51.2232H60.9692V43.4796H64.7422C65.4542 43.4505 66.1606 43.6012 66.7738 43.9129C67.0641 44.0767 67.2979 44.3041 67.451 44.5716C67.6041 44.8391 67.6709 45.1368 67.6445 45.434C67.6616 45.8495 67.5021 46.2563 67.1956 46.5785C66.8891 46.9007 66.4565 47.1165 65.9783 47.1855C66.5715 47.2569 67.118 47.501 67.5262 47.8769C67.8553 48.2296 68.0302 48.6698 68.0207 49.1214C68.0443 49.428 67.9744 49.7345 67.818 50.0109C67.6616 50.2873 67.424 50.524 67.1285 50.6978C66.4268 51.071 65.6101 51.2541 64.7852 51.2232ZM64.6884 46.8352C65.9138 46.8352 66.5481 46.4019 66.591 45.5354C66.591 44.7333 65.9568 44.3277 64.6884 44.3277H61.9904V46.8352H64.6884ZM64.6884 50.4028C65.2679 50.4127 65.8397 50.2885 66.3438 50.0433C66.555 49.9348 66.727 49.7784 66.8408 49.5913C66.9547 49.4042 67.0059 49.1937 66.9888 48.9831C67.0037 48.7756 66.9501 48.5686 66.8341 48.386C66.7181 48.2034 66.5445 48.0525 66.3331 47.9506C65.8291 47.7173 65.2606 47.6057 64.6884 47.628H61.9904V50.3936L64.6884 50.4028Z" fill={color} />
                <path d="M80.0383 51.2325H79.9308C79.3194 51.2421 78.712 51.1458 78.145 50.9494C77.578 50.753 77.063 50.4605 76.6308 50.0894C76.2088 49.7325 75.8696 49.3102 75.6312 48.8449C75.384 48.3719 75.2523 47.8606 75.2442 47.3423C75.2327 46.825 75.3484 46.3114 75.5836 45.8349C75.8188 45.3584 76.1683 44.9297 76.6093 44.5767C77.0593 44.2076 77.5904 43.9189 78.1717 43.7274C78.753 43.5359 79.3729 43.4454 79.9953 43.4612C80.6028 43.4503 81.2065 43.5452 81.77 43.7401C82.3336 43.9349 82.8451 44.2258 83.2738 44.5951C83.7114 44.9339 84.0609 45.3478 84.2998 45.8099C84.5387 46.2721 84.6616 46.7722 84.6604 47.2777V47.3699C84.6788 47.8881 84.5663 48.4035 84.3307 48.8808C84.0951 49.3582 83.7419 49.7862 83.2953 50.1355C82.8634 50.4934 82.3534 50.7745 81.7944 50.9628C81.2355 51.151 80.6387 51.2427 80.0383 51.2325ZM80.0383 50.4213C80.5169 50.4242 80.9912 50.344 81.4327 50.1856C81.8742 50.0272 82.2738 49.7938 82.6074 49.4994C82.953 49.2189 83.227 48.8798 83.4121 48.5037C83.5971 48.1277 83.6891 47.7228 83.6823 47.3146C83.6877 46.9121 83.5949 46.513 83.4098 46.1431C83.2247 45.7732 82.9513 45.4406 82.6074 45.1667C82.2746 44.8715 81.8751 44.6375 81.4335 44.479C80.9918 44.3205 80.5171 44.2408 80.0383 44.2448C79.5581 44.2417 79.0821 44.3218 78.6388 44.4802C78.1955 44.6386 77.7941 44.8721 77.4585 45.1667C77.1107 45.442 76.8351 45.7774 76.6498 46.1509C76.4645 46.5243 76.3738 46.9273 76.3836 47.3331C76.3734 47.7416 76.4639 48.1474 76.6492 48.5238C76.8344 48.9003 77.1102 49.2391 77.4585 49.5179C77.7895 49.8036 78.1831 50.0305 78.6165 50.1855C79.05 50.3405 79.5149 50.4207 79.9846 50.4213H80.0383Z" fill={color} />
                <path d="M100.096 51.2232H99.0213L98.0109 49.2412H93.1093L92.1096 51.2232H91.0347L95.0441 43.4796H96.119L100.096 51.2232ZM97.5701 48.4577L95.5386 44.4752L93.5177 48.43L97.5701 48.4577Z" fill={color} />
                <path d="M114.543 51.2233H113.371L111.501 48.7619H108.567V51.2233H107.492V43.4796H110.888C111.528 43.4654 112.164 43.5626 112.759 43.7654C113.262 43.9336 113.691 44.2336 113.984 44.6227C114.291 45.0616 114.44 45.5681 114.414 46.0793C114.466 46.6319 114.293 47.1829 113.925 47.6384C113.556 48.0939 113.015 48.4258 112.393 48.5775L114.543 51.2233ZM110.921 47.9507C111.571 47.9738 112.214 47.8223 112.759 47.5174C113.006 47.3505 113.201 47.1331 113.324 46.8851C113.448 46.6371 113.498 46.3664 113.468 46.0977C113.468 44.8993 112.619 44.3093 110.921 44.3093H108.567V47.9507H110.921Z" fill={color} />
                <path d="M126.356 51.2233H122.777V43.4796H126.399C126.996 43.4735 127.588 43.569 128.142 43.7604C128.695 43.9519 129.199 44.2356 129.624 44.5951C130.477 45.3295 130.957 46.3239 130.957 47.3607C130.957 48.3974 130.477 49.3919 129.624 50.1263C129.191 50.4848 128.679 50.7663 128.118 50.9546C127.557 51.1429 126.958 51.2342 126.356 51.2233ZM126.356 50.4028C127.287 50.4169 128.187 50.1154 128.861 49.5639C129.212 49.283 129.489 48.9413 129.675 48.5615C129.86 48.1816 129.949 47.7723 129.936 47.3607C129.956 46.9456 129.87 46.5318 129.684 46.1481C129.499 45.7644 129.218 45.4201 128.861 45.139C128.179 44.5872 127.272 44.2861 126.335 44.3001H123.766V50.4028H126.356Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_441_13">
                    <rect width="131" height="50.8514" fill="white" transform="translate(0 0.363098)" />
                </clipPath>
            </defs>
        </svg>
    )
}