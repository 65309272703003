import React, { useState } from 'react';
import { useAppSelector } from '../../store/store';
import { IOffer, IProposalCapacity } from '../../types/interfaces/offer.interface';
import { IBooking, IProposalItem, PartialIBooking } from '../../types/interfaces/booking.interface';
import { useSearchParams } from 'react-router-dom';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import InputForm from '../../components/InputForm';
import Modal from '../../components/Modal';
import CustomButton from '../../components/CustomButton';
import RadioButton from '../../components/RadioButton';
import SelectForm from '../../components/SelectForm';
import useFetch from '../../hooks/useFetch';
import { HttpMethod, apiService } from '../../services/apiService';
import { IBookingManage } from './booking/BookingCreate';
import { IProposal } from '../../types/interfaces/proposal.interface';
import BookingRecap from '../../components/BookingRecap';
import { IEvent } from '../../types/interfaces/event.interface';
import { EventProposalOptions } from '../../types/enum/eventProposalsOptions.enum';
import ProposalCapacityTable from '../../components/ProposalCapacityTable';

interface IProps {
    selectedOffer: IOffer;
}

const QuickBookingForm: React.FC<IProps> = ({ selectedOffer }) => {
    const globalDate = useAppSelector(state => state.booking.globalDate);
    const { showErrorModal } = useModalDispatcher();
    const [searchParams, setSearchParams] = useSearchParams();

    const [booking, setBooking] = useState<IBookingManage>({ bookingDate: globalDate, bookedOffers: [{ offer: selectedOffer, proposals: [] as any }] } as IBookingManage);
    const [isLoading, setIsLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({});
    const [isModalOpen, setIsModalOpen] = useState<{ type: 'checkName' | 'confirm' | null, state: boolean, data?: any }>({ type: null, state: false });

    const { data: proposalsCapacity } = useFetch<IProposalCapacity[]>(
        () => apiService(HttpMethod.GET, '/offer/proposal/disponibility', undefined, { offersId: [selectedOffer._id], date: globalDate }),
        undefined,
        [selectedOffer, globalDate],
        !!selectedOffer
    );

    const { data: dailyEvents } = useFetch<IEvent<string, IProposal, IOffer>[]>(
        () => apiService<IEvent[] & { errorCode: string }>(HttpMethod.GET, `/event/list`, undefined, { date: globalDate }),
        undefined,
        [globalDate],
        !!globalDate
    );

    const validateBooking = () => {
        const errors: { [key: string]: boolean } = {};

        const requiredFields: (keyof IBookingManage)[] = ['surname', 'phoneNumber'];

        for (const key of requiredFields) {
            const value = booking[key];
            errors[key] = value === undefined || value === null || value === 0 || value.toString().trim() === '';
        }

        if (booking.bookedOffers?.length !== 0 && !!booking.bookedOffers?.length) {
            for (let index = 0; index < booking?.bookedOffers?.length; index++) {
                const bookedOffer = booking.bookedOffers[index];
                if (!bookedOffer.people) {
                    errors['people'] = true;
                    break;
                }
            }
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (booking.email && !emailRegex.test(booking.email)) {
            errors['email'] = true;
        }

        setFieldErrors(errors);
        return errors;
    };

    const handleCheckName = async () => {
        try {
            setIsLoading(true);

            const errors = validateBooking();

            if (Object.values(errors).some((value) => value)) {
                setIsLoading(false);
                return;
            }

            const response = await apiService<IBooking & { errorCode: string }>(HttpMethod.GET, '/booking/check-booking-name', undefined, { name: booking.name, surname: booking.surname, date: booking.bookingDate });

            if (!!response.errorCode) {
                throw response;
            }

            if (response) {
                setIsModalOpen({ type: 'checkName', state: true, data: response });
                setIsLoading(false);
            } else {
                setIsModalOpen({ type: 'confirm', state: true });
                setIsLoading(false);
            }
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleCreateBooking = async () => {
        try {
            const body = { booking };

            const response = await apiService<IBooking[] & { errorCode: string }>(HttpMethod.POST, '/booking/create', body);

            if (!!response.errorCode) {
                throw response;
            }

            searchParams.delete('create');
            setSearchParams(searchParams);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleManageBooking = <T extends keyof IBookingManage>(key: T, value: IBookingManage[T]) => {
        setBooking(prev => ({ ...prev, [key]: value }));
    };

    const updateBookedOffer = <T extends keyof PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>>(key: T, value: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>[T]) => {
        setBooking(prev => {
            const newBookedOffers = [...prev.bookedOffers ?? []];
            if (newBookedOffers[0]) {
                (newBookedOffers[0] as any)[key] = value;
            }
            return { ...prev, bookedOffers: newBookedOffers };
        });
    };

    const proposalOnClick = (isSelected: IProposalItem<IProposal<string, string, string>>[] | undefined, proposal: IProposal<string, string, string>, bookedOffer: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>) => {
        if (!!isSelected?.length) {
            updateBookedOffer('proposals', bookedOffer.proposals?.filter((prop: IProposalItem<IProposal<string, string, string>>) => prop.proposal._id !== proposal._id));
        } else {
            const newProposal = {
                proposal: proposal,
                quantity: 1
            };
            updateBookedOffer('proposals', [...bookedOffer.proposals ?? [], newProposal]);
        }
    };

    const handleQuantityChange = (newQuantity: number | null, proposalId: string, bookedOffer: PartialIBooking<{ offer: IOffer, proposals: IProposal<string, string, string> }>) => {
        if (newQuantity !== null && newQuantity >= 0) {
            const updatedProposals = (bookedOffer.proposals ?? []).map(prop =>
                prop.proposal._id === proposalId ? { ...prop, quantity: newQuantity } : prop
            );
            updateBookedOffer('proposals', updatedProposals);
        }
    };

    const proposalToRender = () => {
        const dailyEvent = dailyEvents?.find(dailyEvent => dailyEvent.offer?._id === selectedOffer._id)
        let proposalsToRender: IProposal[] = [];

        switch (dailyEvent?.proposalOption) {
            case EventProposalOptions.COMBINED:
                proposalsToRender = (selectedOffer.proposals ?? []).concat(dailyEvent?.proposals ?? []);
                break;
            case EventProposalOptions.CUSTOM:
                proposalsToRender = dailyEvent?.proposals ?? [];
                break;
            case EventProposalOptions.SERVICE:
                proposalsToRender = selectedOffer.proposals ?? [];
                break;
            default:
                proposalsToRender = selectedOffer.proposals ?? [];
                break;
        };

        return proposalsToRender;
    }

    return (
        selectedOffer
            ? <div className='bg-white p-4 flex flex-col gap-4 rounded-md shadow-md'>

                <div className='flex flex-col gap-1'>
                    <p className='text-text text-xl md:text-2xl font-bold'>Prenota il tuo servizio</p>
                    <p className='text-text text-sm md:text-base font-medium'>Questa pagina ti permette di creare una prenotazione in modo rapido, con solo i campi più importanti. Facile e veloce!</p>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-2 justify-between w-full'>
                    {/* COGNOME */}
                    <InputForm paddingY={1} label='Cognome' placeholder='Cognome' value={booking.surname} setValue={(value) => handleManageBooking('surname', value)} error={!!fieldErrors.surname} errorMessage='Campo obbligatorio.' customStyle='shadow' />
                    {/* NOME */}
                    <InputForm paddingY={1} label='Nome' placeholder='Nome' value={booking.name} setValue={(value) => handleManageBooking('name', value)} customStyle='shadow' />
                    {/* TELEFONO */}
                    <InputForm paddingY={1} label='Telefono' placeholder='+39' value={booking.phoneNumber} setValue={(value) => handleManageBooking('phoneNumber', value)} error={!!fieldErrors.phoneNumber} errorMessage='Campo obbligatorio.' customStyle='shadow' />
                </div>


                <div className='flex items-start justify-between gap-4 w-full'>
                    <InputForm fullWidth type='number' customStyle='w-full h-8' label='Persone' value={booking.bookedOffers[0]?.people} placeholder='00' setValue={(e) => updateBookedOffer('people', Number(e))} error={!!fieldErrors.people} errorMessage='Campo obbligatorio.' />
                    {selectedOffer.shifts.length > 0
                        ? <SelectForm options={selectedOffer.shifts.map(shift => ({ label: shift, value: shift }))} selectedOption={booking.bookedOffers[0]?.shift} setSelectedOption={(e) => updateBookedOffer('shift', e.value)} containerClassName='w-full' arrow={false} title='Turno' />
                        : <InputForm fullWidth customStyle='w-full h-8' label='Turno' value={booking.bookedOffers[0]?.shift} placeholder='00:00' setValue={(e) => updateBookedOffer('shift', e)} />
                    }
                </div>

                <div className='flex flex-col md:flex-row-reverse justify-between gap-4'>

                    {/* PROPOSALS INFO TABLE */}
                    <div className='flex md:w-1/2'>
                        {!!proposalsCapacity && <ProposalCapacityTable proposalsCapacity={proposalsCapacity} />}
                    </div>

                    {/* LISTA DELLE PROPOSTE */}
                    <div className='flex flex-col gap-1 select-none md:w-1/2'>
                        <div className='grid grid-cols-3'>
                            <p className="font-semibold text-text text-lg col-span-2">Proposte</p>
                            <p className="font-semibold text-text text-lg">Quantità</p>
                        </div>

                        {proposalToRender()?.map((proposal, proposalIndex) => {
                            const isSelected = booking.bookedOffers[0]?.proposals?.filter(prop => prop.proposal._id === proposal._id);

                            return <div key={proposalIndex} className='grid grid-cols-3'>
                                {/* PROPOSTA */}
                                <RadioButton
                                    label={proposal?.name}
                                    classname='col-span-2'
                                    checked={!!isSelected?.length}
                                    onChange={() => proposalOnClick(isSelected, proposal, booking.bookedOffers[0])}
                                />

                                {/* QUANTITA PROPOSTA */}
                                {(!!isSelected?.length) &&
                                    <InputForm
                                        fullWidth
                                        containerClassName={'col-span-1'}
                                        type='number'
                                        value={booking.bookedOffers[0]?.proposals?.find(prop => prop.proposal._id === proposal._id)?.quantity}
                                        setValue={(e) => handleQuantityChange(Number(e), proposal._id!, booking.bookedOffers[0])}
                                        placeholder='00'
                                        paddingY={1}
                                        isDisabled={!isSelected}
                                    />
                                }
                            </div>
                        })}
                        {/* PROPOSTA DEFAULT (DA DEFINIRE) */}
                        <div className='grid grid-cols-3'>
                            <RadioButton label='Da definire' classname='col-span-2'
                                checked={booking.bookedOffers[0]?.proposals?.length === 0}
                                onChange={() => updateBookedOffer('proposals', [])} />
                        </div>
                    </div>

                </div>


                {/* NOTE */}
                <InputForm paddingY={1} label='Note' placeholder='Scrivi una nota' value={booking.bookedOffers[0]?.notes} setValue={(value) => updateBookedOffer('notes', value)} multilineRows={4} multiline />

                {/* <div className='flex flex-col gap-2'>
                    TORTA
                    <SelectForm title='Torta' options={cakeTypeOptions} selectedOption={mapCakeType(booking.bookedOffers[0]?.cakeType)} setSelectedOption={(value) => updateBookedOffer('cakeType', value.value)} />
                    NOTE TORTA
                    {(booking.bookedOffers[0]?.cakeType !== 2) && <InputForm paddingY={1} placeholder='Note torta' value={booking.bookedOffers[0]?.cakeNote} setValue={(value) => updateBookedOffer('cakeNote', value)} multilineRows={4} multiline />}
                </div> */}


                <div className='flex justify-between mt-2'>
                    <CustomButton label='Annulla' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => {
                        searchParams.delete('create');
                        setSearchParams(searchParams);
                    }} />
                    <CustomButton
                        disabled={isLoading}
                        color='bg-primary'
                        label='Conferma'
                        onClickHandler={handleCheckName}
                    />
                </div>

                <Modal isOpen={isModalOpen.state} onClose={() => setIsModalOpen({ type: null, state: false })}>

                    {(isModalOpen.type === 'confirm') && <BookingRecap booking={booking} onClose={() => setIsModalOpen({ type: null, state: false })} onAction={handleCreateBooking} isLoading={isLoading} />}

                    {(isModalOpen.type === 'checkName') && <div className='flex flex-col gap-4'>
                        <p className='text-text font-medium text-center capitalize'>Prenotazione esistente per {isModalOpen?.data?.surname} {isModalOpen?.data?.name}</p>
                        <p className='text-sm text-darkGray font-medium'>Per favore, scegli un nome diverso per la prenotazione per evitare confusione.</p>
                        <div className='flex justify-between gap-2'>
                            <CustomButton color='bg-primary' label='Chiudi' fullWidth onClickHandler={() => setIsModalOpen({ type: null, state: false })} />
                        </div>
                    </div>}

                </Modal>
            </div>
            : null
    )
}

export default QuickBookingForm