import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { BookingSlice } from './booking/bookingSlice';
import { AuthSlice } from './auth/authSlice';
import { UISlice } from './UI/uiSlice';
import { OfferSlice } from './offer/offerSlice';
import { AccountSlice } from './account/accountSlice';
import { NotificationSlice } from './notification/notificationSlice';
import { SocketSlice } from './socket/socketSlice';
import { EventSlice } from './event/eventSlice';

export const store = configureStore({
    reducer: {
        booking: BookingSlice.reducer,
        offer: OfferSlice.reducer,
        event: EventSlice.reducer,
        auth: AuthSlice.reducer,
        ui: UISlice.reducer,
        account: AccountSlice.reducer,
        notification: NotificationSlice.reducer,
        socket: SocketSlice.reducer
    }
});

// To use useAppDispatch & useAppSelector correctly with typescript you need this two line of code
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
