import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import useCookie from '../../hooks/useCookie';
import CustomButton from '../../components/CustomButton';
import { LoginIcon } from '../../utils/icons/LoginIcon';
import { UsersIcon } from '../../utils/icons/UsersIcon';
import { OfferIcon } from '../../utils/icons/OfferIcon';
import { PluginsIcon } from '../../utils/icons/PluginsIcon';
import PageTamplate from '../../components/PageTamplate';
import { apiHandler, postHandler } from '../../services/apiHandler';
import useFetch from '../../hooks/useFetch';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import CardContainer from '../../components/CardContainer';
import LoadingIndicator from '../../components/LoadingIndicator';
import { USER_ROLES, getRolesEnum } from '../../types/enum/userRoles.enum';
import { replaceUserStructure } from '../../store/account/accountSlice';

const StructureView: React.FC = () => {
    const dispatch = useAppDispatch();
    const { userStructure } = useAppSelector(state => state.account);
    const { setCookie } = useCookie({ structureData: 'structure_cookie' });
    const { showErrorModal } = useModalDispatcher();

    const { loading } = useFetch(
        () => apiHandler.get('/user/get-current-user/false', undefined, { isUserCookie: true }),
        (data) => dispatch(replaceUserStructure({ userStructure: data }))
    )

    const handleStructureLogin = async (id: string) => {
        try {
            const response = await apiHandler.post<postHandler | any>('/user/login/structure', { structureId: id, isUserCookie: true });
            setCookie('structureData', response.accessToken, response.exp);
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return (
        <PageTamplate>
            <p className='text-3xl font-bold text-text'>Le tue strutture</p>
            <div className='flex gap-4 h-full w-full overflow-auto'>
                {(!loading)
                    ? (userStructure?.structures?.length > 0)
                        ? userStructure?.structures?.map((structure: any, index: number) => (
                            <CardContainer key={index} additionalStyle='h-fit flex-none max-sm:w-full w-96' >
                                <div>
                                    <p className='text-lg font-bold text-text'>{structure.name}</p>
                                    {/* <p className='text-xs font-medium text-darkGray truncate'>Indirizzo</p> */}
                                </div>
                                {structure.role === USER_ROLES.ADMIN &&
                                    <div className='flex flex-col gap-2'>
                                        <p className='text-base font-medium text-text'>Riepilogo</p>
                                        <div className='flex justify-evenly items-end text-sm font-medium text-text'>
                                            <div className='flex flex-col items-center'>
                                                <UsersIcon />
                                                {structure.users} Utenti
                                            </div>

                                            <div className='flex flex-col items-center'>
                                                <OfferIcon />
                                                {structure.offers} Servizi
                                            </div>

                                            <div className='flex flex-col items-center'>
                                                <PluginsIcon />
                                                {structure?.services?.length} Plugins
                                            </div>
                                        </div>
                                    </div>}

                                <div className='flex justify-between items-center'>
                                    <p className='text-base font-medium text-text truncate'>{getRolesEnum([structure.role])[0]}</p>
                                    <CustomButton onClickHandler={() => handleStructureLogin(structure.id)} label='Accedi' color='bg-secondary' leftIcon={<LoginIcon />} />
                                </div>
                            </CardContainer>
                        ))
                        : <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
                            <p className='text-xs font-medium text-darkGray text-center'>Al momento, non hai ancora collegato nessuna struttura al tuo account.<br />Non appena sarai stato invitato a partecipare a una, potrai iniziare a usufruire dei nostri servizi.</p>
                        </div>
                    : <LoadingIndicator icon />
                }
            </div>
        </PageTamplate>
    )
}

export default StructureView
