export const SquaringCircles = () => {
    return (
        <svg width="150" height="17" viewBox="0 0 219 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_427_33)">
                <path d="M12.444 3.44147e-07C10.8097 -0.000384925 9.19137 0.322712 7.68142 0.950833C6.17147 1.57895 4.79951 2.49979 3.6439 3.66074C2.4883 4.82168 1.57169 6.19999 0.946461 7.71691C0.321228 9.23383 -0.000383155 10.8596 3.42565e-07 12.5015V25H12.444C14.0778 25 15.6955 24.6767 17.205 24.0486C18.7144 23.4205 20.0859 22.4999 21.2411 21.3393C22.3964 20.1787 23.3128 18.8008 23.938 17.2844C24.5632 15.7681 24.885 14.1428 24.885 12.5015V3.44147e-07H12.444Z" fill="white" />
                <path d="M37.329 8.87605e-06C36.0749 -0.00149595 34.8279 0.188352 33.6305 0.563059C33.2421 0.682621 32.8601 0.822599 32.4863 0.982414C32.2994 1.06159 32.1155 1.1437 31.9346 1.23168C29.8227 2.25305 28.0407 3.85258 26.7936 5.84626C25.5465 7.83995 24.8849 10.1468 24.885 12.5015C24.8845 14.8559 25.5459 17.1625 26.793 19.1558C28.0402 21.1492 29.8225 22.748 31.9346 23.7683C32.1155 23.8563 32.2994 23.9384 32.4863 24.0176C32.857 24.176 33.2394 24.3109 33.6305 24.4399C33.8232 24.5015 34.0217 24.5572 34.2143 24.607C34.6145 24.7112 35.0199 24.7944 35.4287 24.8563C36.0555 24.9533 36.6889 25.0013 37.3231 25H49.7671V8.87605e-06H37.329Z" fill="white" />
                <path d="M64.2866 18.9555C62.074 18.9555 60.5541 17.9887 59.7271 16.0552L61.966 15.0258C62.1569 15.4866 62.4813 15.8789 62.8972 16.1519C63.317 16.4303 63.8099 16.5763 64.3129 16.5713C64.8519 16.6042 65.3883 16.4723 65.8512 16.193C66.0233 16.0771 66.1632 15.9191 66.2577 15.7339C66.3521 15.5487 66.3981 15.3423 66.3913 15.1343C66.3913 14.3406 65.681 13.7991 64.2604 13.5097C61.5087 13.0034 60.1328 11.7111 60.1328 9.63288C60.13 9.15027 60.2332 8.67299 60.435 8.23503C60.6368 7.79708 60.9323 7.40924 61.3004 7.09915C62.0788 6.40511 63.1132 6.0581 64.4034 6.0581C65.3418 6.02774 66.2675 6.28221 67.0597 6.7883C67.7697 7.24965 68.306 7.936 68.5835 8.73845L66.4526 9.71499C66.34 9.28087 66.0705 8.90447 65.6965 8.65927C65.3221 8.40694 64.8805 8.27406 64.4297 8.27804C63.9759 8.25172 63.5274 8.38669 63.1628 8.65927C63.0182 8.77034 62.9015 8.91384 62.8219 9.0783C62.7423 9.24276 62.7021 9.42364 62.7045 9.60648C62.7045 10.4198 63.3963 10.9437 64.78 11.1783C67.5861 11.6866 68.9892 12.9877 68.9892 15.0816C68.995 15.6033 68.8869 16.1199 68.6726 16.5951C68.4582 17.0703 68.1429 17.4926 67.7486 17.8323C66.9138 18.5791 65.7598 18.9535 64.2866 18.9555Z" fill="white" />
                <path d="M79.9065 6V7.49267C78.7894 6.54964 77.3723 6.04078 75.9133 6.05865C75.0609 6.03822 74.2134 6.19255 73.4224 6.51222C72.6314 6.83189 71.9136 7.31018 71.3128 7.91789C70.7043 8.51135 70.2239 9.2243 69.9014 10.0123C69.579 10.8004 69.4215 11.6467 69.4388 12.4985C69.4219 13.3503 69.5795 14.1965 69.9019 14.9845C70.2243 15.7725 70.7046 16.4855 71.3128 17.0792C71.9129 17.6885 72.6304 18.1684 73.4214 18.4896C74.2124 18.8108 75.0603 18.9665 75.9133 18.9472C77.3729 18.9656 78.7904 18.4555 79.9065 17.5103V25H82.4666V6H79.9065ZM78.8148 15.3372C78.0708 16.0597 77.0765 16.4635 76.0417 16.4635C75.0069 16.4635 74.0126 16.0597 73.2686 15.3372C72.9023 14.9684 72.6151 14.528 72.4248 14.0433C72.2345 13.5586 72.1452 13.0398 72.1623 12.5191C72.1623 12.0065 72.2628 11.4989 72.458 11.0254C72.6533 10.5518 72.9395 10.1215 73.3002 9.75906C73.661 9.39662 74.0893 9.10911 74.5607 8.91295C75.0321 8.7168 75.5373 8.61584 76.0475 8.61584C76.5578 8.61584 77.063 8.7168 77.5344 8.91295C78.0058 9.10911 78.4341 9.39662 78.7948 9.75906C79.1556 10.1215 79.4418 10.5518 79.6371 11.0254C79.8323 11.4989 79.9328 12.0065 79.9328 12.5191C79.9453 13.0403 79.8527 13.5586 79.6605 14.043C79.4684 14.5273 79.1807 14.9675 78.8148 15.3372Z" fill="white" />
                <path d="M89.6446 18.956C87.8815 18.956 86.5367 18.4282 85.6104 17.3724C84.6841 16.3167 84.2209 14.9658 84.2209 13.3197V5.98828H86.7839V13.3754C86.7401 14.2047 87.0149 15.0194 87.5516 15.651C88.1162 16.1892 88.8648 16.4892 89.6431 16.4892C90.4214 16.4892 91.17 16.1892 91.7346 15.651C92.2714 15.0194 92.5462 14.2047 92.5023 13.3754V6.00001H95.0653V13.3314C95.0653 14.958 94.5982 16.304 93.6641 17.3695C92.73 18.435 91.3902 18.9638 89.6446 18.956Z" fill="white" />
                <path d="M105.641 7.48063C104.687 6.53049 103.384 6.05639 101.729 6.05835C100.627 6.0237 99.5403 6.32531 98.6119 6.92345C97.7743 7.48441 97.1147 8.27531 96.7116 9.20204L98.6265 10.4484C99.2103 9.07985 100.245 8.39559 101.729 8.39559C102.451 8.35409 103.164 8.56099 103.752 8.9821C103.964 9.12465 104.14 9.31458 104.266 9.53696C104.392 9.75933 104.465 10.0081 104.479 10.2636C104.486 10.4176 104.459 10.5712 104.401 10.714C104.343 10.8567 104.255 10.9852 104.144 11.0906C103.884 11.3106 103.551 11.422 103.212 11.4015H100.416C99.3458 11.3559 98.2958 11.703 97.4618 12.378C97.0858 12.6903 96.786 13.085 96.5856 13.5319C96.3853 13.9788 96.2895 14.4659 96.3059 14.9557C96.2971 15.4986 96.4088 16.0367 96.633 16.5308C96.8572 17.0249 97.1882 17.4626 97.6019 17.812C98.466 18.5803 99.6083 18.9645 101.029 18.9645C101.818 18.9818 102.6 18.8057 103.306 18.4513C103.859 18.1936 104.337 17.7979 104.695 17.3017V18.9997H107.071V10.8795C107.078 10.2446 106.955 9.61518 106.709 9.03041C106.462 8.44565 106.099 7.91809 105.641 7.48063ZM104.535 13.5627C104.551 13.9855 104.481 14.4071 104.327 14.8013C104.174 15.1954 103.942 15.5534 103.644 15.8531C103.323 16.1539 102.945 16.3868 102.532 16.538C102.119 16.6891 101.681 16.7554 101.242 16.7328C100.648 16.7662 100.061 16.5887 99.584 16.2314C99.3925 16.0834 99.238 15.8927 99.1326 15.6743C99.0272 15.456 98.9739 15.216 98.9768 14.9733C98.9722 14.7614 99.0134 14.5509 99.0975 14.3565C99.1817 14.1621 99.3068 13.9883 99.4643 13.8472C99.805 13.5413 100.251 13.3814 100.708 13.4015H104.538L104.535 13.5627Z" fill="white" />
                <path d="M111.765 19H109.202V6H111.602V8.05279C111.91 7.43474 112.405 6.93138 113.017 6.61584C113.684 6.25228 114.431 6.06554 115.189 6.07331H116.08V8.59238H115.011C114.57 8.56894 114.13 8.64174 113.719 8.80576C113.309 8.96978 112.939 9.22114 112.635 9.54252C112.037 10.24 111.729 11.142 111.774 12.0616L111.765 19Z" fill="white" />
                <path d="M118.453 3.43948C117.992 3.44767 117.546 3.27274 117.212 2.95268C117.046 2.79743 116.914 2.60834 116.826 2.39799C116.738 2.18764 116.695 1.96086 116.701 1.73274C116.697 1.50303 116.739 1.27483 116.827 1.06275C116.915 0.850661 117.046 0.659355 117.212 0.501064C117.375 0.338198 117.569 0.209875 117.782 0.123728C117.995 0.0375807 118.223 -0.00463284 118.453 -0.000402475C118.687 -0.00641619 118.92 0.0348684 119.138 0.121006C119.356 0.207143 119.555 0.336381 119.723 0.501064C119.888 0.659355 120.019 0.850661 120.107 1.06275C120.195 1.27483 120.238 1.50303 120.233 1.73274C120.239 1.96086 120.197 2.18764 120.109 2.39799C120.021 2.60834 119.889 2.79743 119.723 2.95268C119.553 3.11378 119.354 3.23972 119.136 3.32325C118.918 3.40679 118.686 3.44629 118.453 3.43948Z" fill="white" />
                <path d="M119.749 6H117.186V19H119.749V6Z" fill="white" />
                <path d="M124.469 18.9999H121.906V5.99992H124.308V7.75945C124.659 7.25621 125.132 6.8522 125.683 6.58643C126.422 6.17375 127.256 5.9643 128.101 5.97926C128.947 5.99423 129.773 6.23307 130.497 6.67165C131.221 7.11024 131.816 7.73302 132.224 8.47705C132.631 9.22108 132.836 10.06 132.817 10.909V18.9999H130.255V11.4515C130.29 10.6619 130.019 9.8893 129.498 9.29611C129.237 9.01883 128.919 8.80142 128.567 8.65871C128.214 8.516 127.835 8.45134 127.455 8.46913C127.058 8.45662 126.663 8.52853 126.296 8.68015C125.929 8.83177 125.598 9.05966 125.324 9.34889C124.747 9.98878 124.446 10.8324 124.486 11.6949L124.469 18.9999Z" fill="white" />
                <path d="M144.762 6V7.49267C143.647 6.54915 142.23 6.04018 140.772 6.05865C139.92 6.03822 139.072 6.19255 138.281 6.51222C137.49 6.83189 136.772 7.31018 136.171 7.91789C135.563 8.51135 135.082 9.2243 134.76 10.0123C134.438 10.8004 134.28 11.6467 134.297 12.4985C134.281 13.3503 134.438 14.1965 134.761 14.9845C135.083 15.7725 135.563 16.4855 136.171 17.0792C136.772 17.6885 137.489 18.1684 138.28 18.4896C139.071 18.8108 139.919 18.9665 140.772 18.9472C142.231 18.9659 143.647 18.4558 144.762 17.5103V18.5425C144.787 19.0696 144.7 19.5958 144.509 20.0872C144.317 20.5785 144.025 21.0239 143.652 21.3945C143.278 21.7651 142.831 22.0525 142.339 22.2382C141.847 22.4238 141.322 22.5035 140.798 22.4721C140.057 22.476 139.327 22.2849 138.682 21.9179C138.066 21.5784 137.56 21.0698 137.222 20.4516L135.118 21.8886C135.674 22.8412 136.482 23.6199 137.453 24.1378C138.486 24.7041 139.648 24.993 140.824 24.9765C142.77 24.9765 144.344 24.3715 145.545 23.1613C146.745 21.9511 147.348 20.4066 147.351 18.5279V6H144.762ZM143.659 15.3372C142.915 16.0597 141.921 16.4635 140.886 16.4635C139.851 16.4635 138.857 16.0597 138.113 15.3372C137.746 14.9684 137.459 14.528 137.269 14.0433C137.079 13.5586 136.989 13.0398 137.006 12.5191C136.992 11.9977 137.082 11.4787 137.271 10.9928C137.46 10.5069 137.743 10.064 138.105 9.69017C138.467 9.31634 138.9 9.01919 139.379 8.81629C139.857 8.6134 140.371 8.50886 140.89 8.50886C141.409 8.50886 141.923 8.6134 142.402 8.81629C142.88 9.01919 143.313 9.31634 143.675 9.69017C144.037 10.064 144.321 10.5069 144.509 10.9928C144.698 11.4787 144.788 11.9977 144.774 12.5191C144.787 13.0401 144.695 13.5584 144.503 14.0427C144.312 14.527 144.024 14.9674 143.659 15.3372Z" fill="white" />
                <path d="M159.88 18.9553C159.014 18.9785 158.152 18.8261 157.345 18.5072C156.539 18.1883 155.804 17.7093 155.186 17.099C154.572 16.5071 154.087 15.793 153.762 15.0023C153.437 14.2117 153.28 13.3617 153.301 12.5066C153.281 11.6521 153.438 10.8027 153.763 10.0125C154.087 9.22241 154.572 8.50882 155.186 7.9172C155.804 7.30689 156.539 6.82797 157.345 6.50903C158.152 6.1901 159.014 6.03768 159.88 6.0609C161.04 6.04436 162.185 6.33353 163.199 6.89961C164.165 7.44005 164.965 8.2363 165.511 9.20166L163.325 10.6093C163.014 9.98845 162.532 9.47108 161.935 9.11955C161.312 8.74149 160.599 8.5389 159.871 8.53304C159.353 8.52765 158.839 8.62759 158.36 8.82686C157.881 9.02614 157.448 9.32063 157.085 9.69263C156.722 10.0646 156.438 10.5065 156.25 10.9916C156.062 11.4767 155.973 11.995 155.989 12.5154C155.976 13.0358 156.067 13.5535 156.257 14.0377C156.447 14.5219 156.732 14.9626 157.095 15.3336C157.454 15.7069 157.886 16.0009 158.364 16.1968C158.842 16.3927 159.355 16.4863 159.871 16.4715C160.577 16.4799 161.272 16.2976 161.883 15.9436C162.48 15.5946 162.969 15.0873 163.298 14.4773L165.456 15.8879C164.899 16.8259 164.103 17.5974 163.15 18.1225C162.15 18.6801 161.023 18.9671 159.88 18.9553Z" fill="white" />
                <path d="M167.934 3.43941C167.473 3.4476 167.027 3.27267 166.693 2.95261C166.526 2.7975 166.394 2.60851 166.306 2.39818C166.217 2.18784 166.174 1.96098 166.18 1.73266C166.175 1.50277 166.219 1.27449 166.307 1.06242C166.396 0.85035 166.527 0.659138 166.693 0.500993C166.856 0.338127 167.05 0.209805 167.263 0.123657C167.476 0.03751 167.704 -0.0047036 167.934 -0.000473233C168.168 -0.00609229 168.4 0.0353847 168.618 0.121511C168.835 0.207637 169.034 0.336669 169.201 0.500993C169.367 0.659138 169.498 0.85035 169.587 1.06242C169.675 1.27449 169.719 1.50277 169.715 1.73266C169.72 1.96098 169.677 2.18784 169.588 2.39818C169.5 2.60851 169.368 2.7975 169.201 2.95261C169.032 3.11336 168.833 3.23909 168.616 3.32261C168.398 3.40614 168.167 3.44583 167.934 3.43941Z" fill="white" />
                <path d="M169.23 6H166.667V19H169.23V6Z" fill="white" />
                <path d="M173.95 19H171.387V6H173.787V8.05279C174.094 7.43474 174.59 6.93138 175.202 6.61584C175.868 6.25228 176.616 6.06554 177.374 6.07331H178.264V8.59238H177.187C176.746 8.56933 176.306 8.64231 175.896 8.80631C175.486 8.97031 175.116 9.22145 174.811 9.54252C174.208 10.2378 173.894 11.14 173.935 12.0616L173.95 19Z" fill="white" />
                <path d="M184.777 18.9555C183.91 18.979 183.047 18.8267 182.24 18.5077C181.433 18.1888 180.699 17.7098 180.08 17.0992C179.466 16.5073 178.981 15.7932 178.656 15.0025C178.331 14.2119 178.174 13.3619 178.194 12.5068C178.173 11.6529 178.329 10.8039 178.652 10.0138C178.976 9.22372 179.459 8.50979 180.071 7.9174C180.691 7.30526 181.427 6.82505 182.235 6.50557C183.044 6.18609 183.908 6.03392 184.777 6.05817C185.936 6.042 187.079 6.33117 188.093 6.89688C189.062 7.43684 189.865 8.23425 190.414 9.20186L188.227 10.6095C187.912 9.98624 187.423 9.46861 186.82 9.11975C186.198 8.7422 185.486 8.53962 184.759 8.53324C184.241 8.52745 183.727 8.6271 183.247 8.8262C182.768 9.02529 182.334 9.31972 181.971 9.69176C181.608 10.0638 181.324 10.5058 181.135 10.9911C180.947 11.4764 180.858 11.995 180.874 12.5156C180.861 13.036 180.952 13.5537 181.142 14.0379C181.332 14.5221 181.617 14.9628 181.98 15.3338C182.342 15.708 182.778 16.0017 183.259 16.1962C183.741 16.3907 184.258 16.4815 184.777 16.4629C185.481 16.4714 186.175 16.2891 186.785 15.935C187.382 15.586 187.872 15.0787 188.201 14.4687L190.358 15.8793C189.801 16.8173 189.005 17.5888 188.052 18.1139C187.052 18.6757 185.923 18.9658 184.777 18.9555Z" fill="white" />
                <path d="M196.231 19H195.826C194.463 19 193.416 18.6256 192.685 17.8768C191.953 17.1281 191.589 16.0215 191.593 14.5572V0H194.156V14.5308C194.156 15.868 194.848 16.5367 196.231 16.5367V19Z" fill="white" />
                <path d="M209.262 12.3724C209.262 10.7474 208.648 9.18296 207.546 7.99408C206.403 6.70376 204.852 6.05859 202.893 6.05859C200.985 6.05859 199.429 6.68616 198.222 7.94129C197.63 8.5411 197.163 9.25432 196.85 10.0387C196.537 10.823 196.384 11.6625 196.401 12.5073C196.389 13.3553 196.551 14.1967 196.876 14.9795C197.2 15.7623 197.682 16.4699 198.289 17.0586C198.901 17.6801 199.633 18.169 200.441 18.4953C201.248 18.8217 202.113 18.9784 202.983 18.956C204.107 18.9734 205.217 18.7072 206.212 18.1818C207.172 17.6752 207.979 16.9173 208.547 15.9882L206.524 14.604C206.159 15.1884 205.648 15.6659 205.041 15.9882C204.414 16.3294 203.711 16.5059 202.998 16.5014C202.092 16.5119 201.211 16.2061 200.505 15.6363C199.777 15.0551 199.298 14.2154 199.168 13.2903H209.23C209.258 12.9852 209.269 12.6787 209.262 12.3724ZM199.226 11.2873C199.423 10.4827 199.872 9.76299 200.508 9.23455C201.183 8.68987 202.03 8.40681 202.895 8.43689C203.785 8.41495 204.652 8.71819 205.336 9.29027C205.962 9.78119 206.38 10.493 206.503 11.2815L199.226 11.2873Z" fill="white" />
                <path d="M214.306 18.9553C212.094 18.9553 210.574 17.9885 209.747 16.055L211.986 15.0256C212.176 15.4864 212.501 15.8788 212.917 16.1517C213.336 16.4304 213.829 16.5765 214.332 16.5711C214.871 16.604 215.408 16.4721 215.871 16.1928C216.043 16.0768 216.182 15.9187 216.276 15.7334C216.37 15.5482 216.415 15.3419 216.408 15.1342C216.408 14.3404 215.698 13.7989 214.277 13.5095C211.527 13.0032 210.151 11.7109 210.149 9.63269C210.147 9.15012 210.25 8.6729 210.452 8.23497C210.654 7.79705 210.949 7.40918 211.317 7.09896C212.095 6.40492 213.13 6.05791 214.42 6.05791C215.358 6.02882 216.284 6.28319 217.076 6.78811C217.785 7.25145 218.32 7.93711 218.6 8.73826L216.469 9.7148C216.357 9.28068 216.087 8.90428 215.713 8.65908C215.339 8.40675 214.897 8.27387 214.446 8.27785C213.992 8.25209 213.542 8.38697 213.176 8.65908C213.032 8.77015 212.915 8.91365 212.836 9.07811C212.756 9.24257 212.716 9.42345 212.718 9.60629C212.718 10.4196 213.411 10.9435 214.797 11.1781C217.601 11.6865 219.004 12.9875 219.006 15.0814C219.011 15.6033 218.903 16.1201 218.688 16.5953C218.473 17.0706 218.157 17.4927 217.762 17.8321C216.931 18.5789 215.779 18.9533 214.306 18.9553Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_427_33">
                    <rect width="219" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}