import { useState, useEffect } from 'react';
import { replaceSelectedStructure, replaceUserAuth } from '../store/auth/authSlice';
import { useAppDispatch } from '../store/store';
import jwt_decode from "jwt-decode";
import moment from 'moment';

const useCookie = (cookieNames: Record<string, string>) => {
    const dispatch = useAppDispatch();

    const getCookie = (name: string): string | null => {
        const cookies = document.cookie.split(';').map((c) => c.trim());
        const cookie = cookies.find((c) => c.startsWith(name + '='));
        return cookie ? cookie.split('=')[1] : null;
    };

    const optionsToString = (options?: any): string => {
        if (!options) return '';
        return Object.entries(options)
            .map(([key, value]) => `${key}=${value}`)
            .join('; ');
    };


    const [cookieData, setCookieData] = useState<Record<string, string | null>>(() => {
        const initialData: Record<string, string | null> = {};
        Object.keys(cookieNames).forEach((name) => {
            const cookieValue = getCookie(cookieNames[name]);
            initialData[name] = (cookieValue) ? cookieValue : null;
        });
        return initialData;
    });

    useEffect(() => {
        if (cookieData) {
            Object.entries(cookieData).forEach(([name, data]) => {
                if (name === 'userData') {
                    if (data) {
                        const userData = jwt_decode(data);
                        dispatch(replaceUserAuth({ userAuth: userData }));
                    } else {
                        dispatch(replaceUserAuth({ userAuth: undefined }));
                    }
                } else if (name === 'structureData') {
                    dispatch(replaceSelectedStructure({ selectedStructure: data || undefined }));
                }
            });
        }
    }, [cookieData, dispatch]);


    const setCookie = (name: string, data: any, expirationTimeToken?: number) => {

        let expiresDate: undefined | string;
        if (expirationTimeToken) {
            const cookieExpirationTime = (expirationTimeToken - Math.floor(Date.now() / 1000))
                ? expirationTimeToken
                : expirationTimeToken - 3600 // 3600 seconds = 1 hour

            expiresDate = moment(cookieExpirationTime * 1000).toDate().toUTCString();
        }

        setCookieData((prevData) => ({
            ...prevData,
            [name]: data,
        }));


        if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
            document.cookie = `${cookieNames[name]}=${data}; path=/; ${!!expiresDate && "expires=" + expiresDate + ';'}`
        } else {
            document.cookie = `${cookieNames[name]}=${data}; path=/; domain=.imin-dashboard.com; ${!!expiresDate && "expires=" + expiresDate + ';'}`
        }
    };

    const removeCookie = (name: string, options?: any) => {
        setCookieData((prevData) => ({
            ...prevData,
            [name]: null,
        }));

        if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
            document.cookie = `${cookieNames[name]}=; path=/; ${optionsToString(options)} expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        } else {
            document.cookie = `${cookieNames[name]}=; path=/; domain=.imin-dashboard.com; ${optionsToString(options)} expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        }
    };

    return { cookieData, setCookie, removeCookie };
};

export default useCookie;
