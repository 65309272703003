import { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import PageTamplate from '../../../components/PageTamplate';
import CustomButton from '../../../components/CustomButton';
import SelectForm from '../../../components/SelectForm';
import useComponentVisible from '../../../hooks/useComponentVisible';
import useScreenSize from '../../../hooks/useScreenSize';
import { IProposal } from '../../../types/interfaces/proposal.interface';
import { IProduct } from '../../../types/interfaces/product.inteface';
import { colorPalette } from '../../../types/enum/colorPalette';
import { AddIcon } from '../../../utils/icons/AddIcon';
import { InfoIcon } from '../../../utils/icons/InfoIcon';
import { apiHandler } from '../../../services/apiHandler';
import { useModalDispatcher } from '../../../hooks/useModalDispatcher';
import ManageProduct from '../menu/ManageProduct';
import { IStructure } from '../../../types/interfaces/structure.interface';
import { IOffer } from '../../../types/interfaces/offer.interface';
import ManageProposal from './ManageProposal';
import PageTitle from '../../../components/PageTitle';

const Proposals = () => {
    const proposals = useLoaderData() as IProposal<IStructure, IOffer, IProduct>[];
    const screenSize = useScreenSize();
    const { showErrorModal } = useModalDispatcher();
    const [searchParams, setSearchParams] = useSearchParams();

    const { isComponentVisible: createProposal, setIsComponentVisible: setCreateProposal } = useComponentVisible(false);
    const { isComponentVisible: createProduct, setIsComponentVisible: setCreateProduct } = useComponentVisible(false);

    const [product, setProduct] = useState<IProduct>();
    const [proposal, setProposal] = useState<IProposal<IStructure, IOffer, IProduct>>();

    const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
    const [selectedProposal, setSelectedProposal] = useState<IProposal<IStructure, IOffer, IProduct>>();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!!proposals?.length) {
            const index = proposals.findIndex(proposal => proposal._id === selectedProposal?._id);
            if (index !== -1) {
                setSelectedProposal(proposals[index]);
            } else {
                setSelectedProposal(proposals[0]);
            }
        }
    }, [proposals]);

    const openModal = (type: 'PROPOSAL' | "PRODUCT") => {
        if (type === 'PROPOSAL') {
            setProposal(undefined);
            setCreateProposal(true);
        } else {
            setCreateProduct(true);
        }
    }

    const handleManageProduct = (product: IProduct) => {
        setProduct(product);
        setCreateProduct(true);
    };

    const handleManageProposal = () => {
        setProposal(selectedProposal);
        setCreateProposal(true);
    };

    const handleSelectAll = () => {
        const allProductsSelected = selectedProducts.length === proposals.find(proposal => proposal._id === selectedProposal?._id)?.products?.length;
        if (allProductsSelected) {
            setSelectedProducts([]);
        } else {
            const allProducts = proposals.find(proposal => proposal._id === selectedProposal?._id)?.products || [];
            setSelectedProducts(allProducts.filter(product => typeof product !== 'string'));
        }
    };

    const handleProductSelect = (product: IProduct) => {
        const isSelected = selectedProducts.some(selectedProduct => selectedProduct._id === product._id);
        if (isSelected) {
            setSelectedProducts(selectedProducts.filter(selectedProduct => selectedProduct._id !== product._id));
        } else {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleDeleteProducts = async () => {
        try {
            setIsLoading(true);

            await apiHandler.post('product/delete/many', { ids: selectedProducts.map(prod => prod._id) });

            setSearchParams(searchParams);
            setSelectedProducts([]);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return (
        <PageTamplate>
            {!!proposals?.length
                ? <>
                    <PageTitle
                        title='Proposte'
                        goBackBtn={false}
                        rightBtn={<button type='button' className='bg-text py-2 px-4 rounded-lg shadow' onClick={() => openModal('PROPOSAL')}><AddIcon size='28' /></button>}
                    />

                    {screenSize === 'xs' || screenSize === 'sm'
                        ? <div className='flex gap-4 items-center w-full'>
                            <SelectForm
                                containerClassName='w-full'
                                arrow={false}
                                options={proposals
                                    .filter(proposal => proposal._id !== selectedProposal?._id)
                                    .map(proposal => ({ label: proposal.name, value: proposal }))
                                }
                                selectedOption={proposals.find(proposal => proposal._id === selectedProposal?._id)?.name}
                                setSelectedOption={(e) => {
                                    setSelectedProposal(e.value);
                                }}
                            />

                            {!!selectedProposal && <div onClick={handleManageProposal}>
                                <InfoIcon color={colorPalette.Text} size={28} />
                            </div>}
                        </div>
                        : <div className='flex justify-center'>
                            {proposals.map((proposal, index) => {
                                return <div key={index}>
                                    <button onClick={() => setSelectedProposal(proposal)} className={`px-4 py-1 whitespace-nowrap font-medium text-xl ${index === 0 && 'rounded-l-md'} ${index === proposals.length - 1 && 'rounded-r-md'} shadow ${selectedProposal?._id === proposal._id ? 'bg-primary text-white' : 'bg-white text-text'}`}>
                                        <p>{proposal.name}</p>
                                    </button>
                                    {selectedProposal?._id === proposal._id && <div onClick={handleManageProposal} className='w-full text-text py-1 font-medium text-center cursor-pointer hover:underline'>Modifica</div>}
                                </div>
                            })}
                        </div>
                    }

                    <div className='flex justify-between items-center'>
                        <PageTitle
                            title='Prodotti'
                            goBackBtn={false}
                            rightBtn={
                                !!selectedProposal && selectedProducts.length > 0
                                    ? <div className='bg-white flex items-center gap-6 shadow rounded-md w-fit self-center pl-4'>
                                        <p className='text-sm font-medium'>{selectedProducts.length} selezionati</p>
                                        <CustomButton disabled={isLoading} color='bg-error' label='Elimina' onClickHandler={handleDeleteProducts} />
                                    </div>
                                    : <button type='button' className='bg-text py-2 px-4 rounded-lg shadow' onClick={() => openModal('PRODUCT')}><AddIcon size='28' /></button>

                            }
                        />
                    </div>

                    {!!proposals.find(proposal => proposal._id === selectedProposal?._id)?.products?.length
                        ? <div>
                            <div className='flex w-full items-center justify-between gap-4 p-4'>
                                <input type='checkbox' className='w-6 h-6 accent-primary' onChange={handleSelectAll} checked={selectedProducts.length === proposals.find(proposal => proposal._id === selectedProposal?._id)?.products?.length} />
                                <div className='grid grid-cols-5 w-full rounded-t-md items-center font-semibold text-text text-lg'>
                                    <p className='col-span-1'>Nome</p>
                                    <p className='col-span-4 md:col-span-4'>Descrizione</p>
                                </div>
                                <p className='col-span-1 text-center w-10 text-2xl'>#</p>
                            </div>

                            <div className='border-b border-mediumGray w-full' />

                            {proposals
                                .find(proposal => proposal._id === selectedProposal?._id)?.products?.sort((a, b) => {
                                    if (typeof a !== 'string' && typeof b !== 'string') {
                                        return a.name.localeCompare(b.name);
                                    } else {
                                        return typeof a === 'string' ? 1 : -1;
                                    }
                                })
                                .map((product, index) => {
                                    if (typeof product !== 'string') {
                                        return <div key={index} className={`${selectedProducts.some(selectedProduct => selectedProduct._id === product._id) && 'bg-primary bg-opacity-5 border-l-4 border-primary'}`}>
                                            <div className="flex items-center w-full last:rounded-b-md p-4 gap-4 font-medium text-darkGray text-opacity-60 text-sm">
                                                <input type='checkbox' className='w-6 h-6 accent-primary' onChange={() => handleProductSelect(product)} checked={selectedProducts.some(selectedProduct => selectedProduct._id === product._id)} />
                                                <div className='grid grid-cols-5 w-full items-center'>
                                                    <p className='col-span-1 line-clamp-2'>{product.name}</p>
                                                    <p className='col-span-4 md:col-span-4 line-clamp-2'>{product.description}</p>
                                                </div>
                                                <button className='col-span-1 flex justify-center w-10' onClick={() => handleManageProduct(product)}><InfoIcon color={colorPalette.Primary} size={28} /></button>
                                            </div>
                                            {(index !== proposals?.find(proposal => proposal._id === selectedProposal?._id)?.products?.length! - 1) && <div className='border-b border-mediumGray w-full' />}
                                        </div>
                                    }
                                    return null
                                })
                            }
                        </div>
                        : <p className='flex h-full items-center justify-center font-medium text-sm text-darkGray whitespace-nowrap'>Nessun prodotto inserito.</p>}
                </>
                : <div className='flex flex-col h-full w-full justify-center gap-8'>
                    <p className='font-medium text-center text-4xl text-darkGray'>Nessun prodotto presente</p>
                    <p className='font-medium text-center text-base text-darkGray'>Al momento, non hai ancora inserito nessuna proposta di menu.<br />Per iniziare a organizzare il tuo servizio, è necessario creare la prima proposta di prodotti.</p>
                    <CustomButton color='bg-primary' label='Aggiungi Proposta' onClickHandler={() => openModal('PROPOSAL')} className='w-fit self-center' />
                    <ManageProposal isOpen={createProposal} setIsOpen={setCreateProposal} />
                </div>
            }

            <ManageProposal isOpen={createProposal} setIsOpen={setCreateProposal} element={proposal} setElement={setProposal} />
            <ManageProduct isOpen={createProduct} setIsOpen={setCreateProduct} proposal={selectedProposal} element={product} setElement={setProduct} />

        </PageTamplate >
    )
}

export default Proposals