import React from 'react';
import { NextPaginationIcon, PreviousPaginationIcon } from '../../../../utils/icons/PaginationIcon';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        let pages = [];
        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages = [1, 2, 3, '...', totalPages - 1, totalPages];
            if (currentPage > 3 && currentPage < totalPages - 2) {
                pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
            } else if (currentPage >= totalPages - 2) {
                pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
            }
        }
        return pages;
    };

    return (
        <nav className="flex justify-center items-center space-x-2 mt-4">
            {/* Pagination Buttons */}
            <button
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
                className="rounded p-1 disabled:opacity-25"
            >
                <PreviousPaginationIcon />
            </button>
            {getPageNumbers().map((page, index) => (
                <button
                    key={index}
                    onClick={() => typeof page === 'number' && onPageChange(page)}
                    className={`w-8 h-8 flex items-center justify-center border ${currentPage === page
                        ? 'bg-black text-white font-semibold'
                        : 'text-black hover:bg-lightGray'
                        } ${typeof page !== 'number' ? 'border-none' : ''}`}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
                className="rounded p-1 disabled:opacity-25"
            >

                <NextPaginationIcon />
            </button>
        </nav>
    );
};

export default Pagination;