import React from 'react'
import { CalendarIcon } from '../utils/icons/CalendarIcon';
import Calendar from './Calendar';
import moment from 'moment';
import { ArrowRight } from '../utils/icons/ArrowIcon';

interface IProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    selectedDate: string | Date;
    onDatePick: (value: string) => void;
    title?: string;
    bgColor?: string;
    disabledDays?: number[];
    minDate?: string;
    maxDate?: string;
}

const DatePicker: React.FC<IProps> = ({ isOpen, setIsOpen, selectedDate, onDatePick, title, bgColor = 'bg-white', disabledDays, minDate = moment().utc(true).toISOString(), maxDate }) => {
    return (
        <div className='flex flex-col gap-4'>
            {!!title && <p className="font-semibold text-text text-lg">{title}</p>}
            <div className={`flex items-center ${bgColor} rounded-lg justify-between relative md:w-96 shadow`}>
                <div className='flex items-center gap-4 pl-4'>
                    <div className={`ease-in-out duration-300 transition-all ${isOpen ? 'rotate-90' : ''}`}><ArrowRight /></div>
                    <p className='font-semibold capitalize text-primary text-xl'>{moment(selectedDate).format('dddd')}</p>
                </div>
                <p className='font-semibold text-text'>{moment(selectedDate).format('DD/MM/YYYY')}</p>
                <div onClick={() => setIsOpen(!isOpen)} className=' bg-primary aspect-square rounded-lg p-1 shadow'>
                    <CalendarIcon color='#FFF' size='42' />
                </div>
                {isOpen &&
                    <div className="absolute z-50 top-14 right-0 translate shadow rounded-lg w-full flex justify-center items-center overflow-auto p-2 bg-white">
                        <Calendar
                            selectedDate={moment(selectedDate).utc(true).toISOString()}
                            fullWidth
                            handlePickDate={() => setIsOpen(false)}
                            setSelectedDate={onDatePick}
                            minDate={moment().utc(true).toISOString()}
                            disabledDays={disabledDays}
                            maxDate={maxDate}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default DatePicker