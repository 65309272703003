import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { uiState } from "./uiState";

export const UISlice: Slice<any> = createSlice({
    name: "uiSlice",
    initialState: uiState,
    reducers: {
        setModal: (state, action) => {
            const { message, severity } = action.payload;
            state.modalState.isOpen = true;
            state.modalState.message = message;
            state.modalState.severity = severity;
        },
        removeModal: (state) => {
            state.modalState.isOpen = false;
        },
        toggleNotificationCenter: (state) => {
            state.notificationPopoverState.isOpen = !state.notificationPopoverState.isOpen;
        },
        setSideBarState: (state, action: PayloadAction<boolean>) => {
            state.sideBarState = action.payload
        }
    }
});

export const { setModal, removeModal, toggleNotificationCenter, setSideBarState } = UISlice.actions;
export default UISlice.reducer;
