import React from 'react';
import 'tailwindcss/tailwind.css';
import { useAppDispatch, useAppSelector } from '../store/store';
import { IUserStructure } from '../types/interfaces/userStructure.interface';
import { IStructure } from '../types/interfaces/structure.interface';
import { IPlugin } from '../types/interfaces/plugin.interface';

const TopBar: React.FC = () => {
    const { userStructure } = useAppSelector<{ userStructure: IUserStructure<IStructure<IPlugin, string>, string, IPlugin> }>(state => state.account.userStructure);

    return (
        <div className="bg-black text-white p-1 text-center text-xs">
            {userStructure?.structureId?.name}
        </div>
    );
};

export default TopBar;