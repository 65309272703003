export const IminLogoSvg = () => {
    return (
        <svg width="80" height="40" viewBox="0 0 174 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_107_474)">
                <path d="M22.71 80.54H20.71V72.61C20.7609 72.0605 20.6896 71.5066 20.5011 70.9879C20.3127 70.4692 20.0118 69.9987 19.62 69.61C18.8658 68.9613 17.8941 68.6219 16.9 68.66C16.246 68.6845 15.6042 68.8447 15.0155 69.1305C14.4267 69.4163 13.9038 69.8213 13.48 70.32C12.7529 71.3202 12.3799 72.5341 12.42 73.77V80.52H10.33V72.61C10.3669 71.5844 10.0333 70.5797 9.39 69.78C9.02418 69.3939 8.57648 69.0947 8.07983 68.9043C7.58317 68.714 7.05017 68.6373 6.52 68.68C5.8643 68.7031 5.22062 68.8627 4.63004 69.1485C4.03946 69.4343 3.51495 69.8401 3.09 70.34C2.34869 71.3345 1.96464 72.5501 2 73.79V80.54H0V66.82H2V69.93C2.4462 68.9346 3.1892 68.1015 4.12724 67.5448C5.06529 66.988 6.15251 66.7349 7.24 66.82C8.34369 66.722 9.44617 67.0206 10.3497 67.662C11.2531 68.3034 11.8985 69.2458 12.17 70.32C12.5623 69.2215 13.3061 68.2831 14.2861 67.6503C15.266 67.0175 16.4273 66.7256 17.59 66.82C18.3009 66.7668 19.0147 66.875 19.6779 67.1365C20.3411 67.3979 20.9367 67.806 21.42 68.33C22.2968 69.4016 22.7519 70.7565 22.7 72.14L22.71 80.54Z" fill="#5B2FF6" />
                <path d="M30.72 80.55C29.8104 80.5979 28.9041 80.4084 28.09 80C27.4411 79.6509 26.909 79.1189 26.56 78.47C26.2217 77.8363 26.0465 77.1284 26.05 76.41C26.0399 75.8869 26.1458 75.3681 26.3602 74.8909C26.5747 74.4136 26.8922 73.9898 27.29 73.65C28.4112 72.8254 29.793 72.4347 31.18 72.55H35.7V71.71C35.7151 71.2887 35.6449 70.8687 35.4937 70.4752C35.3425 70.0817 35.1133 69.7228 34.82 69.42C34.4352 69.0945 33.9894 68.8491 33.5086 68.698C33.0278 68.5469 32.5218 68.4932 32.02 68.54C30.4293 68.556 28.8916 69.1133 27.66 70.12L26.8 68.75C28.4191 67.5327 30.3844 66.8636 32.41 66.84C33.1298 66.7775 33.8549 66.8604 34.542 67.0838C35.2292 67.3071 35.8644 67.6663 36.41 68.14C36.8338 68.5952 37.1627 69.1304 37.3773 69.7142C37.5919 70.2979 37.688 70.9187 37.66 71.54V80.54H35.66V78.34C35.0672 79.0696 34.3114 79.6497 33.4533 80.0336C32.5952 80.4175 31.659 80.5944 30.72 80.55ZM31.11 78.86C32.1247 78.8871 33.1265 78.6272 34 78.11C34.8653 77.5757 35.4947 76.7318 35.76 75.75V74.09H31.45C29.15 74.09 27.99 74.83 27.95 76.33C27.948 76.6667 28.0184 76.9999 28.1565 77.307C28.2945 77.6141 28.4969 77.8879 28.75 78.11C29.41 78.6559 30.2559 78.9248 31.11 78.86Z" fill="#5B2FF6" />
                <path d="M54.8298 80.5402H52.8298V72.6602C52.8724 72.1211 52.7959 71.5791 52.6058 71.0728C52.4157 70.5665 52.1167 70.1082 51.7298 69.7302C50.958 69.0589 49.9623 68.702 48.9398 68.7302C48.3148 68.6819 47.6868 68.7705 47.0996 68.9899C46.5124 69.2092 45.9801 69.554 45.5398 70.0002C44.7706 70.8767 44.332 71.9945 44.2998 73.1602V80.5502H42.2998V66.8202H44.2998V69.8202C44.7877 68.8478 45.5545 68.043 46.5021 67.5086C47.4498 66.9742 48.5352 66.7345 49.6198 66.8202C50.6637 66.7753 51.7004 67.0137 52.6198 67.5102C53.3624 67.9506 53.9495 68.6112 54.2998 69.4002C54.6677 70.266 54.8483 71.1997 54.8298 72.1402V80.5402Z" fill="#5B2FF6" />
                <path d="M62.8599 80.5502C61.9537 80.5968 61.0509 80.4073 60.2399 80.0002C59.5866 79.6571 59.053 79.1235 58.7099 78.4702C58.3716 77.8365 58.1964 77.1286 58.1999 76.4102C58.1898 75.8871 58.2957 75.3683 58.5101 74.8911C58.7246 74.4138 59.0421 73.9901 59.4399 73.6502C60.5611 72.8256 61.9429 72.4349 63.3299 72.5502H67.8399V71.7102C67.8649 71.2867 67.8034 70.8626 67.659 70.4637C67.5145 70.0648 67.2903 69.6995 66.9999 69.3902C66.6145 69.0657 66.1686 68.8208 65.688 68.6698C65.2074 68.5187 64.7016 68.4645 64.1999 68.5102C62.6092 68.5262 61.0715 69.0835 59.8399 70.0902L58.9999 68.7202C60.6184 67.5017 62.5841 66.8324 64.6099 66.8102C65.3297 66.7478 66.0548 66.8306 66.7419 67.054C67.4291 67.2773 68.0643 67.6365 68.6099 68.1102C69.0337 68.5655 69.3626 69.1006 69.5772 69.6844C69.7918 70.2682 69.8879 70.8889 69.8599 71.5102V80.5102H67.8599V78.3402C67.2574 79.074 66.4911 79.6561 65.6228 80.04C64.7544 80.4238 63.8081 80.5986 62.8599 80.5502ZM63.2599 78.8602C64.261 78.8788 65.2476 78.6191 66.1099 78.1102C66.9752 77.5759 67.6046 76.732 67.8699 75.7502V74.0902H63.5899C61.2999 74.0902 60.1399 74.8302 60.0999 76.3302C60.0979 76.6669 60.1684 77.0001 60.3064 77.3072C60.4444 77.6143 60.6468 77.8882 60.8999 78.1102C61.5599 78.6562 62.4058 78.925 63.2599 78.8602Z" fill="#5B2FF6" />
                <path d="M79.4699 85.6098C77.4209 85.5997 75.436 84.8946 73.8399 83.6098L74.7799 82.1298C76.0501 83.2638 77.6971 83.8842 79.3999 83.8698C80.6764 83.9092 81.925 83.4907 82.9199 82.6898C83.4131 82.2462 83.7984 81.6958 84.0463 81.0805C84.2941 80.4652 84.398 79.8014 84.3499 79.1398V77.0498C83.8714 77.9624 83.139 78.7171 82.2412 79.2228C81.3433 79.7285 80.3184 79.9636 79.2899 79.8998C78.4599 79.9277 77.6332 79.783 76.862 79.4749C76.0909 79.1668 75.3921 78.7019 74.8099 78.1098C74.1954 77.4661 73.7182 76.7043 73.4071 75.8706C73.0959 75.0368 72.9574 74.1486 72.9999 73.2598C72.9466 72.3942 73.0698 71.5267 73.3621 70.7102C73.6543 69.8937 74.1095 69.145 74.6999 68.5098C75.2986 67.9382 76.0068 67.4937 76.7819 67.2033C77.5571 66.9128 78.383 66.7824 79.2099 66.8198C80.2506 66.7509 81.2888 66.9878 82.1967 67.5013C83.1046 68.0147 83.8426 68.7824 84.3199 69.7098V66.8198H86.3199V79.0798C86.3703 80.3519 86.0544 81.6118 85.4099 82.7098C84.791 83.6847 83.8965 84.4539 82.8399 84.9198C81.7758 85.3787 80.6287 85.6136 79.4699 85.6098ZM79.6599 78.2298C80.3121 78.2698 80.9649 78.1599 81.5681 77.9086C82.1713 77.6572 82.709 77.2711 83.1399 76.7798C83.9437 75.8198 84.3735 74.6016 84.3499 73.3498C84.3899 72.0522 83.9238 70.7898 83.0499 69.8298C82.6167 69.3697 82.09 69.0076 81.5053 68.7679C80.9206 68.5281 80.2914 68.4163 79.6599 68.4398C79.0203 68.4003 78.3799 68.5016 77.7838 68.7366C77.1877 68.9716 76.6504 69.3345 76.2099 69.7998C75.3792 70.7952 74.9291 72.0533 74.9399 73.3498C74.9358 74.63 75.3858 75.8701 76.2099 76.8498C76.6465 77.3214 77.1826 77.69 77.7793 77.9287C78.3761 78.1674 79.0184 78.2702 79.6599 78.2298Z" fill="#5B2FF6" />
                <path d="M96.4501 80.55C95.2586 80.5677 94.0809 80.293 93.0201 79.75C91.986 79.2289 91.1246 78.4197 90.5402 77.42C89.8926 76.2754 89.5742 74.9742 89.6202 73.66C89.5691 72.3414 89.8804 71.034 90.5201 69.88C91.1091 68.8913 91.97 68.0929 93.0002 67.58C94.0647 67.0616 95.2363 66.8013 96.4202 66.82C97.7011 66.7636 98.9714 67.0759 100.08 67.72C101.041 68.3245 101.786 69.2169 102.21 70.27C102.653 71.3864 102.874 72.5788 102.86 73.78V74.37H91.6202C91.7629 75.5929 92.3041 76.735 93.1602 77.62C93.6219 78.0455 94.1641 78.3745 94.7549 78.5874C95.3457 78.8004 95.973 78.893 96.6002 78.86C97.3921 78.8862 98.1806 78.7437 98.9133 78.442C99.646 78.1403 100.306 77.6862 100.85 77.11L102 78.28C101.295 79.0376 100.433 79.6329 99.4746 80.0249C98.5164 80.4168 97.4844 80.596 96.4501 80.55ZM101 72.76C100.961 71.6177 100.518 70.5264 99.7502 69.68C99.3082 69.2582 98.7824 68.9343 98.2069 68.7292C97.6314 68.5242 97.0192 68.4427 96.4102 68.49C95.8011 68.4508 95.1904 68.5352 94.6147 68.738C94.039 68.9407 93.5102 69.2577 93.0601 69.67C92.2455 70.5093 91.7351 71.597 91.6102 72.76H101Z" fill="#5B2FF6" />
                <path d="M129 80.54H127V72.61C127.051 72.0605 126.98 71.5066 126.791 70.9879C126.603 70.4692 126.302 69.9987 125.91 69.61C125.157 68.9596 124.184 68.6199 123.19 68.66C122.536 68.6845 121.894 68.8447 121.306 69.1305C120.717 69.4163 120.194 69.8213 119.77 70.32C119.043 71.3202 118.67 72.5341 118.71 73.77V80.52H116.65V72.61C116.687 71.5844 116.353 70.5797 115.71 69.78C115.344 69.3939 114.897 69.0947 114.4 68.9043C113.903 68.714 113.37 68.6373 112.84 68.68C112.184 68.7031 111.541 68.8627 110.95 69.1485C110.36 69.4343 109.835 69.8401 109.41 70.34C108.672 71.3358 108.292 72.5513 108.33 73.79V80.54H106.33V66.82H108.33V69.93C108.776 68.9346 109.519 68.1015 110.457 67.5448C111.395 66.988 112.483 66.7349 113.57 66.82C114.674 66.722 115.776 67.0206 116.68 67.662C117.583 68.3034 118.229 69.2458 118.5 70.32C118.892 69.2215 119.636 68.2831 120.616 67.6503C121.596 67.0175 122.757 66.7256 123.92 66.82C124.631 66.7668 125.345 66.875 126.008 67.1365C126.671 67.3979 127.267 67.806 127.75 68.33C128.616 69.4058 129.06 70.7603 129 72.14V80.54Z" fill="#5B2FF6" />
                <path d="M139 80.5502C137.809 80.567 136.631 80.2924 135.57 79.7502C134.539 79.2245 133.679 78.4163 133.09 77.4201C132.492 76.2536 132.18 74.9613 132.18 73.6502C132.18 72.339 132.492 71.0467 133.09 69.8801C133.679 68.8915 134.54 68.0931 135.57 67.5802C136.635 67.0618 137.806 66.8014 138.99 66.8202C140.274 66.7652 141.547 67.0774 142.66 67.7202C143.615 68.3283 144.357 69.2199 144.78 70.2701C145.229 71.385 145.45 72.5785 145.43 73.7802V74.3702H134.18C134.314 75.5957 134.857 76.7405 135.72 77.6202C136.181 78.0467 136.723 78.3762 137.314 78.5892C137.905 78.8022 138.533 78.8944 139.16 78.8602C139.952 78.8844 140.74 78.7411 141.472 78.4395C142.205 78.1379 142.865 77.6849 143.41 77.1102L144.53 78.2802C143.828 79.0368 142.97 79.6316 142.015 80.0236C141.06 80.4156 140.031 80.5952 139 80.5502ZM143.56 72.7602C143.525 71.617 143.082 70.5242 142.31 69.6802C141.872 69.2623 141.351 68.9406 140.781 68.7357C140.211 68.5308 139.604 68.4471 139 68.4901C138.391 68.451 137.78 68.5354 137.205 68.7381C136.629 68.9409 136.1 69.2579 135.65 69.6701C134.835 70.5077 134.327 71.597 134.21 72.7602H143.56Z" fill="#5B2FF6" />
                <path d="M161.45 80.5402H159.45V72.6602C159.492 72.1211 159.416 71.5791 159.226 71.0728C159.036 70.5665 158.737 70.1082 158.35 69.7302C157.578 69.0589 156.582 68.702 155.56 68.7302C154.935 68.6806 154.306 68.7685 153.719 68.988C153.132 69.2074 152.599 69.5529 152.16 70.0002C151.391 70.8767 150.952 71.9945 150.92 73.1602V80.5502H148.92V66.8202H150.92V69.8202C151.408 68.8478 152.175 68.043 153.122 67.5086C154.07 66.9742 155.155 66.7345 156.24 66.8202C157.284 66.7723 158.322 67.0109 159.24 67.5102C159.987 67.9475 160.579 68.6086 160.93 69.4002C161.293 70.2673 161.474 71.2001 161.46 72.1402L161.45 80.5402Z" fill="#5B2FF6" />
                <path d="M170 80.5501C169.091 80.5775 168.205 80.2595 167.52 79.6601C167.147 79.2615 166.865 78.7871 166.692 78.2694C166.52 77.7516 166.461 77.2026 166.52 76.6601V68.7601H164.52V67.1801H166.52V63.5801H168.52V67.1801H172.87V68.7701H168.52V76.3301C168.52 77.8901 169.1 78.6701 170.27 78.6701C171.075 78.6381 171.857 78.3969 172.54 77.9701L173.11 79.5301C172.209 80.1928 171.119 80.5502 170 80.5501Z" fill="#5B2FF6" />
                <path d="M73.4799 0H26.6299V46.6C26.6299 48.3646 27.3302 50.0571 28.577 51.3058C29.8238 52.5545 31.5153 53.2573 33.2799 53.26H39.9399V20C39.9399 18.2337 40.6416 16.5397 41.8906 15.2907C43.1395 14.0417 44.8335 13.34 46.5999 13.34C48.3662 13.34 50.0602 14.0417 51.3092 15.2907C52.5582 16.5397 53.2599 18.2337 53.2599 20V46.6C53.2599 48.3646 53.9602 50.0571 55.207 51.3058C56.4538 52.5545 58.1453 53.2573 59.9099 53.26H66.5699V20.2C66.5457 18.4822 67.1727 16.819 68.3247 15.5445C69.4767 14.2701 71.0684 13.4789 72.7799 13.33C73.6916 13.2696 74.606 13.3975 75.4662 13.7057C76.3264 14.0139 77.114 14.4958 77.78 15.1214C78.4459 15.7471 78.976 16.503 79.3373 17.3423C79.6986 18.1816 79.8833 19.0863 79.8799 20V46.4C79.8583 48.1187 80.4874 49.782 81.6411 51.0562C82.7947 52.3304 84.3875 53.1212 86.0999 53.27H93.1999V19.71C93.1972 14.4817 91.1184 9.46837 87.4205 5.77232C83.7226 2.07626 78.7082 -6.72233e-07 73.4799 0Z" fill="#5B2FF6" />
                <path d="M153.37 0H133.14V46.43C133.116 47.9541 133.609 49.4414 134.539 50.6492C135.469 51.8569 136.78 52.7138 138.26 53.08C138.762 53.1929 139.275 53.2532 139.79 53.26H146.45V20.2C146.428 18.4813 147.058 16.818 148.211 15.5438C149.365 14.2696 150.958 13.4788 152.67 13.33C153.579 13.2698 154.491 13.3968 155.349 13.7032C156.207 14.0096 156.994 14.4888 157.659 15.1112C158.325 15.7336 158.855 16.4859 159.218 17.3217C159.582 18.1574 159.769 19.0588 159.77 19.97V46.6C159.77 48.3646 160.47 50.0571 161.717 51.3058C162.964 52.5545 164.655 53.2573 166.42 53.26H173.08V19.71C173.077 14.4834 171 9.47162 167.304 5.77585C163.608 2.08008 158.597 0.00264974 153.37 0Z" fill="#5B2FF6" />
                <path d="M8.33 0H0V48.27C0 49.5961 0.526784 50.8679 1.46447 51.8055C2.40215 52.7432 3.67392 53.27 5 53.27H13.32V5C13.32 3.67565 12.7946 2.40539 11.8591 1.468C10.9236 0.530602 9.65435 0.0026487 8.33 0Z" fill="#5B2FF6" />
                <path d="M114.84 0H106.51V48.27C106.51 49.5961 107.037 50.8679 107.974 51.8055C108.912 52.7432 110.184 53.27 111.51 53.27H119.83V5C119.83 3.67565 119.304 2.40539 118.369 1.468C117.433 0.530602 116.164 0.0026487 114.84 0Z" fill="#5B2FF6" />
            </g>
            <defs>
                <clipPath id="clip0_107_474">
                    <rect width="173.08" height="85.61" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}