import moment from "moment"
import { REQUEST_BOOKING_STATUS, requestBookingStatusBgColorMapping, requestBookingStatusOptions, requestBookingStatusTextColorMapping } from "../../../../../types/enum/requestBookingStatus.enum"
import { IBookingRequest } from "../../../../../types/interfaces/bookingRequest.interface"
import { IOffer } from "../../../../../types/interfaces/offer.interface"
import { IProposal } from "../../../../../types/interfaces/proposal.interface"
import { IStructure } from "../../../../../types/interfaces/structure.interface"
import CustomButton from "../../../../../components/CustomButton"
import { Form, useNavigate, useNavigation } from "react-router-dom"
import Text from "../../../../../components/Text"
import Modal from "../../../../../components/Modal"
import useComponentVisible from "../../../../../hooks/useComponentVisible"
import { colorPalette } from "../../../../../types/enum/colorPalette"
import { PartialIBooking } from "../../../../../types/interfaces/booking.interface"
import { ArrowRight } from "../../../../../utils/icons/ArrowIcon"
import { IProduct } from "../../../../../types/interfaces/product.inteface"
import MenuProposalAccordion from "./MenuProposalAccordion"

interface IProps {
    bookingRequest: IBookingRequest<IStructure, IOffer, IProposal<string, string, IProduct>, PartialIBooking<{ proposals: IProposal<string, string, IProduct> }>>
}

const AutomaticDetails: React.FC<IProps> = ({ bookingRequest }) => {
    const navigate = useNavigate();
    const navigation = useNavigation();

    const { isComponentVisible, setIsComponentVisible } = useComponentVisible();

    const rejected_canceled_updated = bookingRequest.status === REQUEST_BOOKING_STATUS.REJECTED || bookingRequest.status === REQUEST_BOOKING_STATUS.CANCELLED || bookingRequest.status === REQUEST_BOOKING_STATUS.UPDATED;
    let sliderStyle: React.CSSProperties = {};

    switch (bookingRequest.status) {
        case REQUEST_BOOKING_STATUS.PENDING:
            sliderStyle.width = '50%';
            break;
        case REQUEST_BOOKING_STATUS.ACCEPTED:
            sliderStyle.width = '100%';
            break;
        case REQUEST_BOOKING_STATUS.UPDATED:
            sliderStyle.width = '100%';
            break;
        case REQUEST_BOOKING_STATUS.REJECTED:
            sliderStyle.width = '100%';
            break;
        case REQUEST_BOOKING_STATUS.CANCELLED:
            sliderStyle.width = '100%';
            break;
        default:
            sliderStyle.width = '50%';
            sliderStyle.backgroundColor = colorPalette.Primary;
            break;
    };

    return (
        bookingRequest.deleted || bookingRequest.status === REQUEST_BOOKING_STATUS.CANCELLED
            ? <div className='flex flex-col gap-4 p-8 py-4 justify-between flex-1'>
                <p className='text-xl font-semibold text-left'>
                    Caro {bookingRequest?.name} {bookingRequest.surname},<br />
                    hai già eliminato questa prenotazione per {moment(bookingRequest.date).format('dddd DD MMMM YYYY')}.
                </p>
                <p className='text-base font-medium text-center'>Per poterne creare una nuova visita questo link, o contatta la struttura per maggiori informazioni.</p>
                <CustomButton label="Crea nuova prenotazione" onClickHandler={() => navigate(`/${bookingRequest?.structure?.name}/${bookingRequest.structure._id}`)} color='bg-primary' className='mt-4' />
            </div>
            : bookingRequest.status === REQUEST_BOOKING_STATUS.REJECTED && !bookingRequest.booking
                ? <div className='flex flex-col gap-4 px-8 flex-1 justify-center text-center'>
                    <div className='flex flex-col gap-2'>
                        <p className='text-3xl font-bold'>Ci dispiace!</p>
                        <p className='text-sm font-medium text-darkGray'>
                            La struttura <span className='font-semibold'>{bookingRequest?.structure?.name}</span> non ha approvato la tua richiesta di prenotazione.
                        </p>
                    </div>

                    <div className={`flex flex-col gap-2`}>
                        {bookingRequest.rejectedNotes
                            ? <>
                                <p className='font-semibold text-warning text-xl'>Motivazione del Rifiuto</p>
                                <p className={`text-text text-sm truncate whitespace-pre-line font-medium`}>{bookingRequest.rejectedNotes}</p>
                            </>
                            : <>
                                <p className='font-semibold text-warning text-xl'>Cosa fare ora?</p>
                                <p className={`text-text text-sm truncate whitespace-pre-line font-medium`}>
                                    Puoi provare a creare una nuova prenotazione o contattare direttamente la struttura per maggiori informazioni.
                                </p>
                            </>}
                    </div>

                    {bookingRequest.structure.contact.phone &&
                        <a href={`tel:${bookingRequest.structure.contact.phone}`} className='bg-primary text-white font-medium text-xl px-4 py-2 rounded-lg focus:outline-none w-fit self-center'>
                            Contatta Struttura
                        </a>}
                </div>
                : <div className='flex flex-col gap-4 px-8 justify-between flex-1'>
                    <div className='flex flex-col gap-2'>
                        <p className='text-3xl font-bold'>Dettagli</p>
                        <p className='text-sm font-medium text-darkGray'>
                            Qui troverai i dettagli della tua richiesta di prenotazione, controlla attentamente tutte le informazioni per assicurarti che siano corrette.
                        </p>
                    </div>


                    {bookingRequest.rejectedBy && <div className={`flex flex-col gap-2 border-y-4 border-warning border-dashed py-4`}>
                        <p className='text-sm font-semibold text-darkGray underline underline-offset-2'>
                            La struttura ha rifiutato la tua richiesta di modifica. Ora puoi scegliere se mantenere la prenotazione, fare una nuova richiesta di modifica o eliminarla.
                        </p>
                        {bookingRequest.rejectedNotes &&
                            <>
                                <p className='font-bold text-text text-lg'>Motivazione del Rifiuto</p>
                                <p className={`text-text text-sm truncate whitespace-pre-line font-normal`}>{bookingRequest.rejectedNotes}</p>
                            </>
                        }
                    </div>}

                    <div className='grid grid-cols-2 gap-4'>
                        <Text title='Data' label={moment(bookingRequest.date).format('DD-MM-YYYY')} />
                        {bookingRequest.shift && <Text title='Turno' label={bookingRequest.shift} />}
                        <Text title='Nome' label={bookingRequest?.name} />
                        <Text title='Cognome' label={bookingRequest.surname} />
                        <Text title='Telefono' label={bookingRequest.phoneNumber} />
                        <Text title='Persone' label={bookingRequest.people} />
                    </div>

                    {bookingRequest.notes && <Text title='Note' label={bookingRequest.notes} className='normal-case' />}

                    <div className='flex flex-col gap-2 w-full'>
                        <p className="font-semibold text-text">Proposta</p>

                        <div className='bg-white px-4 py-2 rounded-md shadow'>
                            <p className='text-xl font-semibold'>{bookingRequest.proposal?.name}</p>
                            <p className='text-sm'>{bookingRequest.proposal.description}</p>
                            <MenuProposalAccordion proposal={bookingRequest.proposal} />
                        </div>
                    </div>

                    <div className='flex flex-col gap-2 w-full'>
                        <p className="font-semibold text-text">Stato</p>
                        <div className='relative w-full h-2 bg-white rounded-full'>
                            <div className={`absolute w-full h-2 rounded-full ${requestBookingStatusBgColorMapping[bookingRequest.status]}`} style={sliderStyle}>
                            </div>
                        </div>
                        {rejected_canceled_updated
                            ? <div className='flex justify-center'>
                                <p className={`text-sm font-semibold ${requestBookingStatusTextColorMapping[bookingRequest.status]}`}>
                                    {requestBookingStatusOptions.find(option => option.value === bookingRequest.status)?.label}
                                </p>
                            </div>
                            : <div className='grid grid-cols-2'>
                                {requestBookingStatusOptions
                                    .filter(option => option.value === REQUEST_BOOKING_STATUS.PENDING || option.value === REQUEST_BOOKING_STATUS.ACCEPTED)
                                    .map(status => (
                                        <p className={`${(bookingRequest.status === status.value) ? requestBookingStatusTextColorMapping[bookingRequest.status] : 'text-darkGray'} font-semibold text-base text-center`} key={status.value}>
                                            {status.label}
                                        </p>
                                    ))}
                            </div>
                        }
                        {bookingRequest.status === REQUEST_BOOKING_STATUS.UPDATED && <p className='text-mediumGray text-sm font-semibold text-center'>In attesa che venga accettata la modifica</p>}
                    </div>

                    <div className='border-2 border-dashed border-primary my-4' />

                    <p className='text-sm font-medium text-darkGray'>Se hai bisogno di assistenza o hai domande sulla tua prenotazione, non esitare a contattarci. Siamo qui per aiutarti!</p>

                    <div className='flex flex-col md:flex-row-reverse md:justify-between gap-2' >
                        <div className="flex flex-col gap-2 md:flex-row ">
                            <CustomButton type='button' label="Modifica" color='bg-primary' textColor='text-text_white' onClickHandler={() => navigate(`/request/${bookingRequest._id}/update`)} />
                            <CustomButton type='button' label="Elimina" color='bg-error' textColor='text-text_white' onClickHandler={() => setIsComponentVisible(true)} />
                        </div>
                        <CustomButton type='button' label="Indietro" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => navigate(-1)} />
                    </div>

                    {/* MODAL FOR DELETE REQUEST */}
                    <Modal isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)}>
                        <div className='flex flex-col gap-2'>
                            <p className='text-center text-primary font-bold text-2xl'>Elimina Prenotazione</p>
                            <p className='text-center font-medium text-lg'>Sei sicuro di voler eliminare questa prenotazione?</p>

                            <Form method='put' className='flex justify-between mt-6'>
                                <CustomButton type='button' label="Annulla" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => setIsComponentVisible(false)} />
                                <CustomButton type='submit' disabled={navigation.state !== 'idle'} label="Procedi" color='bg-error' textColor='text-text_white' className='border-2 border-error' />
                            </Form>
                        </div>
                    </Modal>
                </div>
    )
}

export default AutomaticDetails