import React from 'react';

interface TextProps {
    title: string;
    label?: string | number;
    className?: string;
    containerClassName?: string;
    gap?: number;
    refs?: React.LegacyRef<HTMLParagraphElement>;
    id?: string;
    reverseFontStyle?: boolean;
}

const Text: React.FC<TextProps> = ({ title, label, className, containerClassName, gap = 0, refs, id, reverseFontStyle = false }) => {

    if (!!label) {
        return <div className={`flex flex-col gap-${gap} ${containerClassName}`}>
            <p className={`${reverseFontStyle ? 'font-normal text-sm' : 'font-semibold text-lg'} text-text truncate`}>{title}</p>
            <p ref={refs} id={id} title={String(label)} className={`text-black truncate whitespace-pre-line ${reverseFontStyle ? 'font-semibold' : 'font-normal text-sm'} ${className}`}>{label}</p>
        </div>
    } else {
        return null
    }

};

export default Text;
