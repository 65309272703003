import React from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import CustomButton from '../../../../components/CustomButton';
import { apiHandler } from '../../../../services/apiHandler';
import useFetch from '../../../../hooks/useFetch';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';

interface Step3Props {
    data: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    setData: React.Dispatch<React.SetStateAction<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>>;
    nextStep: () => void;
    prevStep: () => void;
}

const Step3Create: React.FC<Step3Props> = ({ data, setData, nextStep, prevStep }) => {

    const { data: response } = useFetch<{ occupiedSeats: number }>(
        () => apiHandler.post(`/booking-request/get/occupied-seats`, { shift: data.shift, date: data.date, structureId: data.structure._id, offerId: data.offer._id }),
        undefined,
        [],
        !!data.date
    );

    const handleNext = (people: number) => {
        setData({ ...data, people });
        nextStep();
    };

    const renderPeopleBtn = () => {
        let availableSeats = 0
        if (!!data?.offer?.autoBookingOptions?.nBookingsAvailable && response?.occupiedSeats !== undefined) {
            availableSeats = data?.offer?.autoBookingOptions?.nBookingsAvailable - response?.occupiedSeats
        }

        const maxPeople = data?.offer?.autoBookingOptions?.maxPeoplePerBooking ?? 12;

        const numPeopleOptions = Array.from({ length: maxPeople }, (_, index) => index + 1);

        const peopleButtons = numPeopleOptions.map((numPeople) => (
            <button disabled={availableSeats < numPeople} key={numPeople} onClick={() => handleNext(numPeople)} className={`bg-white text-darkGray font-semibold text-lg hover:bg-primary hover:text-text_white rounded-md shadow aspect-video disabled:opacity-40`}>
                {numPeople}
            </button>
        ));

        return (
            <div className='grid grid-cols-4 sm:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 2xl:grid-cols-12 gap-2'>
                {peopleButtons}
            </div>
        );
    };

    return (
        <>
            <div className='flex flex-col gap-2'>
                <p className='font-bold text-text text-2xl'>Personalizza la Tua Esperienza</p>
                <p className='text-darkGray font-semibold md:text-center'>
                    Scegli il numero di partecipanti e preparati a vivere un'esperienza unica con noi.
                </p>
            </div>

            {renderPeopleBtn()}

            <p className='text-xs font-semibold text-mediumGray'>
                La presenza di numeri oscurati indica che la struttura non può garantire la disponibilità per quel numero di persone.<br />
                Ti consigliamo di contattare la struttura direttamente per maggiori informazioni.
            </p>

            <div className='flex flex-col gap-2 h-full justify-end md:w-fit'>
                <CustomButton type='button' label="Indietro" onClickHandler={prevStep} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />
            </div>
        </>
    );
};

export default Step3Create;
