import { useDispatch } from 'react-redux';
import { setModal, removeModal } from '../store/UI/uiSlice';
import { getSeverityStringFromNumber, getMessageFromResponseStatus } from '../types/enum/apiResponseSeverity';
import { getErrorMessage } from '../utils/getErrorMessage';

export function useModalDispatcher() {
    const dispatch = useDispatch();

    const showModal = (status: number, customMessage?: string) => {
        dispatch(
            setModal({
                severity: getSeverityStringFromNumber(status as 100 | 101 | 200 | 201 | 202 | 300 | 400 | 401 | 403 | 404 | 429 | 500 | 503),
                message: customMessage ?? getMessageFromResponseStatus(status as 100 | 101 | 200 | 201 | 202 | 300 | 400 | 401 | 403 | 404 | 429 | 500 | 503),
                isOpen: true,
            })
        );
    };

    const showErrorModal = (status: number, code: string, message?: string) => {
        dispatch(
            setModal({
                severity: getSeverityStringFromNumber(status as 100 | 101 | 200 | 201 | 202 | 300 | 400 | 401 | 403 | 404 | 429 | 500 | 503),
                message: message ?? getErrorMessage(code),
                isOpen: true,
            })
        );
    };


    const hideModal = () => {
        dispatch(removeModal({}))
    }

    return { showModal, showErrorModal, hideModal };
}
