import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { getHandler, postHandler, putHandler, delHandler } from '../services/apiHandler';
import { useModalDispatcher } from './useModalDispatcher';

const useFetch = <T>(
    apiActionCallback: () => Promise<getHandler | postHandler | putHandler | delHandler | AxiosError | any>,
    reduxActionCallback?: (data: T) => void,
    refetch: any[] = [],
    shouldFetch: boolean = true
) => {
    const { showModal, showErrorModal, hideModal } = useModalDispatcher();

    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (shouldFetch) {
            callApiRequest();
        }
    }, refetch);

    const callApiRequest = async () => {
        setLoading(true);
        hideModal();
        setError(undefined);
        try {
            const response = await apiActionCallback() as T & { errorCode: string };

            if (!!response.errorCode) {
                throw response;
            }

            if (!response) {
                throw new Error();
            }

            setData(response);
            reduxActionCallback && reduxActionCallback(response);
            setLoading(false);
            // showModal(response.status);
        } catch (error: any) {
            setLoading(false);
            if (error?.errorCode) {
                setError({ message: error.message });
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return { data, loading, error };
};

export default useFetch;
