export const FullStarIcon = ({ size = '40', color = '#5B2FF6' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size}>
            <path d="m480-252.463-165.358 99.794q-7.205 4-14.577 3.526-7.371-.475-13.371-4.731t-9.102-11.166q-3.103-6.91-.898-15.346l43.615-187.922-145.64-126.769q-6.615-5.615-8.32-12.935-1.705-7.321.705-14.397 2.154-6.821 7.692-11.346 5.538-4.526 14.179-5.474l192.538-16.923 74.845-177.666q3.436-7.564 9.962-11.41 6.525-3.846 13.73-3.846t13.73 3.846q6.526 3.846 9.962 11.41l74.845 177.666 192.538 16.923q8.641.948 14.179 5.474 5.538 4.525 7.949 11.346 2.153 7.076.448 14.397-1.705 7.32-8.32 12.935l-145.64 126.769 43.871 187.922q1.949 8.436-1.153 15.346-3.103 6.91-9.103 11.166t-13.371 4.731q-7.372.474-14.577-3.526L480-252.463Z" fill={color} />
        </svg>
    );
};

export const EmptyStarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
            <path d="M338.974-226.436 480-311.359l141.026 85.923-37.205-160.769 124.128-107.641-163.59-14.487L480-659.769l-64.359 150.436-163.59 14.487 124.128 108.231-37.205 160.179ZM480-252.463l-165.358 99.794q-7.205 4-14.577 3.526-7.371-.475-13.371-4.731t-9.102-11.166q-3.103-6.91-.898-15.346l43.615-187.922-145.64-126.769q-6.615-5.615-8.32-12.935-1.705-7.321.705-14.397 2.154-6.821 7.692-11.346 5.538-4.526 14.179-5.474l192.538-16.923 74.845-177.666q3.436-7.564 9.962-11.41 6.525-3.846 13.73-3.846t13.73 3.846q6.526 3.846 9.962 11.41l74.845 177.666 192.538 16.923q8.641.948 14.179 5.474 5.538 4.525 7.949 11.346 2.153 7.076.448 14.397-1.705 7.32-8.32 12.935l-145.64 126.769 43.871 187.922q1.949 8.436-1.153 15.346-3.103 6.91-9.103 11.166t-13.371 4.731q-7.372.474-14.577-3.526L480-252.463Zm0-180.101Z" fill="#5B2FF6" />
        </svg>
    );
};

export const EmptyStarIconSmall = ({ color = '#5B2FF6' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m354-247 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143Zm126-5.463-155.615 93.845q-8.692 5.077-17.422 4.27-8.731-.808-15.808-5.885-7.076-5.077-10.922-13.269-3.847-8.192-1.231-18.115l41.307-176.691-137.384-118.923q-7.692-6.692-9.807-15.499-2.115-8.808 1.115-17.115 3.231-8.308 9.308-13.577t16.615-6.884l181.307-15.846 70.384-166.846q3.846-9.307 11.653-13.769 7.808-4.461 16.5-4.461t16.5 4.461q7.807 4.462 11.653 13.769l70.384 166.846 181.307 15.846q10.538 1.615 16.615 6.884 6.077 5.269 9.308 13.577 3.23 8.307 1.115 17.115-2.115 8.807-9.807 15.499L639.691-368.308l41.307 176.691q2.616 9.923-1.231 18.115-3.846 8.192-10.922 13.269-7.077 5.077-15.808 5.885-8.73.807-17.422-4.27L480-252.463ZM480-430Z" fill={color} />
        </svg>
    )
}