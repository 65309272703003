import React, { ReactNode } from "react";

interface CardContainerProps {
    children: ReactNode;
    title?: string;
    additionalStyle?: string;
    titleCenter?: boolean;
    headerElement?: JSX.Element;
}

const CardContainer: React.FC<CardContainerProps> = ({
    children,
    title,
    additionalStyle,
    titleCenter = false,
    headerElement
}) => {
    return (
        <div className={`flex-1 flex flex-col p-4 gap-2 rounded shadow-md bg-white justify-between ${additionalStyle}`}>
            {title && (
                <div className={`flex items-center gap-4 ${titleCenter ? 'justify-center' : 'justify-between'}`}>
                    <p className="text-text font-bold text-lg md:text-2xl">{title}</p>
                    {headerElement}
                </div>
            )}
            {children}
        </div>
    );
};

export default CardContainer;
