export enum PluginIdentifier {
    BOOKINGS = 'bookings',
    OFFERS = 'services',
    EVENTS = 'events',
    KITCHEN = 'kitchen',
    ENTRANCES = 'entrances',
    STRUCTURE = 'structure',
    ONLINE_BOOKINGS = 'online-bookings',
    WHATSAPP_REMINDERS = 'whatsapp-reminders',
    WHATSAPP_BOOKING_CONFIRMATION = 'whatsapp-booking-confirmation'
};