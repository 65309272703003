import React, { useState, useRef, useEffect } from 'react';
import { createPopper, Instance } from '@popperjs/core';

interface IProps {
    title?: string;
    content?: React.ReactNode;
    element: React.ReactNode;
    isActive: boolean;
}

const HoverContent: React.FC<IProps> = ({ title, content, element, isActive }) => {
    const [isHovered, setIsHovered] = useState(false);
    const popperInstanceRef = useRef<Instance | null>(null);
    const referenceRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isHovered && referenceRef.current && popperRef.current) {
            popperInstanceRef.current = createPopper(referenceRef.current, popperRef.current, {
                placement: 'top-start',
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'viewport'
                        }
                    },
                    {
                        name: 'flip',
                        options: {
                            boundary: 'viewport'
                        }
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8]
                        }
                    }
                ],
            });

            return () => {
                if (popperInstanceRef.current) {
                    popperInstanceRef.current.destroy();
                }
            };
        }
    }, [isHovered]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className="relative flex justify-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={referenceRef}
        >
            {element}

            {isHovered && isActive && (
                <div
                    ref={popperRef}
                    className="z-10 w-44 bg-white p-2 rounded-md shadow-md border-2 border-mediumGray flex flex-col items-start gap-1"
                >
                    {title && <p className='font-semibold text-text text-lg'>{title}</p>}
                    {content && <p className='font-medium text-darkGray text-sm whitespace-pre-line'>{content}</p>}
                </div>
            )}
        </div>
    );
};

export default HoverContent;