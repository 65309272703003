import React, { useRef, useEffect } from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { useHorizontalScroll } from '../../../../hooks/useHorizontalScroll';

interface IFilterTabProps {
    tabs: string[];
    activeTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    newBookingRequests: { "PENDING": number, "UPDATED": number }
}

const FilterTabs: React.FC<IFilterTabProps> = ({ tabs, activeTab, setActiveTab, newBookingRequests }) => {
    const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    useHorizontalScroll(scrollContainerRef);

    const renderName = (tab: string) => {
        switch (tab) {
            case 'PENDING':
                return 'In Attesa';
            case 'ACCEPTED':
                return 'Accettato';
            case 'REJECTED':
                return 'Rifiutato';
            case 'CANCELLED':
                return 'Cancellato';
            case 'UPDATED':
                return 'Modifica';
            default:
                return tab;
        }
    };

    useEffect(() => {
        const selectedIndex = tabs.indexOf(activeTab);
        if (selectedIndex !== -1 && buttonRefs.current[selectedIndex] && !!buttonRefs) {
            setTimeout(() => {
                buttonRefs?.current[selectedIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }, 500);
        }
    }, [activeTab]);

    return (
        <div className="relative">
            <div
                ref={scrollContainerRef}
                className="flex bg-white w-full p-2 overflow-x-auto whitespace-nowrap rounded my-4 shadow hidden-scrollbar"
            >
                {tabs.map((tab: string, index: number) => (
                    <button
                        key={tab}
                        ref={(el) => (buttonRefs.current[index] = el)}
                        onClick={() => setActiveTab(tab)}
                        className={`px-4 py-2 text-sm rounded flex-shrink-0 transition-colors duration-200 relative 
                            ${activeTab === tab
                                ? 'bg-primary text-white font-semibold'
                                : 'text-text hover:bg-mediumGray'
                            }`}
                    >
                        {renderName(tab)}
                        {
                            newBookingRequests[tab as keyof typeof newBookingRequests] > 0 &&
                            <div className='h-3 w-3 aspect-square rounded-full bg-secondary absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 z-20' />
                        }
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FilterTabs;