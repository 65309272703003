import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputForm from '../../components/InputForm';
import CustomButton from '../../components/CustomButton';
import { apiHandler, putHandler } from '../../services/apiHandler';
import useCookie from '../../hooks/useCookie';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { setCookie, removeCookie } = useCookie({ sessionToken: 'session_token_reset' });
    const { showErrorModal } = useModalDispatcher();

    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [step, setStep] = useState(1);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    useEffect(() => {
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [countdown]);

    const handleRequestOtp = async () => {
        setErrors({});
        if (!emailRegex.test(email)) {
            setErrors({ email: true })
            return;
        }

        try {
            setCountdown(60);
            await apiHandler.put<putHandler | any>('/user/otp/send-reset-password', { email: email.trim() });
        } catch (error: any) {
            setCountdown(0);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleCheckToken = async () => {
        setErrors({});
        setIsLoading(true);

        if (otp.length <= 0) {
            setErrors({ otp: true });
            setIsLoading(false);
            return;
        }
        try {
            const response = await apiHandler.put<putHandler | any>('/user/otp/check-token', { otp: otp.trim().toUpperCase(), email: email });

            if (response.sessionToken) {
                setCookie('sessionToken', response.sessionToken, response.exp);
                setStep(2);
                setOtp('');
            } else {
                setErrors({ otpValid: true });
            }

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleResetPassword = async () => {
        setIsLoading(true);
        setErrors({});


        if (!emailRegex.test(email)) {
            setErrors({ email: true });
            setIsLoading(false);
            return;
        }
        if (!passwordRegex.test(newPassword)) {
            setErrors({ newPassword: true });
            setIsLoading(false);
            return;
        }
        if (newPassword !== matchPassword) {
            setErrors({ matchPassword: true });
            setIsLoading(false);
            return;
        }

        try {
            await apiHandler.put<putHandler | any>('/user/otp/reset-password', { email: email, newPassword: newPassword });

            removeCookie('sessionToken');
            setIsLoading(false);
            navigate('/', { replace: true });
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return (
        <div className='h-screen w-full flex justify-center items-center bg-gradient-to-t lg:bg-gradient-to-r to-primary from-primaryLight'>
            <div className="w-11/12 lg:w-1/2 flex flex-col justify-between overflow-hidden bg-white rounded-xl p-4 gap-2">

                <p className="text-2xl font-bold text-text">
                    Inserisci codice OTP
                </p>
                {(errors.server) && <p className="text-error text-sm md:text-xxs  font-medium">Errore, prova a richiedere un nuovo codice.</p>}

                <div className='flex gap-8 items-end'>
                    <InputForm
                        fullWidth
                        backgroundColor='bg-background'
                        label='Email'
                        value={email}
                        error={errors.email}
                        errorMessage='Inserisci una email valida'
                        setValue={(value) => setEmail(value)}
                    />
                    {(step === 1) && <CustomButton disabled={countdown !== 0} label={`Richiedi codice ${(countdown !== 0 ? countdown : '')}`} color='bg-primary' className="uppercase" onClickHandler={handleRequestOtp} />}
                </div>

                {(step === 1)
                    ? <InputForm
                        backgroundColor='bg-background'
                        label='Codice'
                        value={otp}
                        error={errors.otp}
                        errorMessage={'Inserire un codice'}
                        setValue={(value) => setOtp(value)}
                    />
                    : <>
                        <InputForm
                            backgroundColor='bg-background'
                            label='Nuova password'
                            value={newPassword}
                            error={errors.newPassword}
                            errorMessage='La nuova password deve contenere almeno 8 caratteri, una lettera maiuscola e un numero.'
                            setValue={(value) => setNewPassword(value)}
                        />

                        <InputForm
                            backgroundColor='bg-background'
                            label='Ripeti password'
                            value={matchPassword}
                            error={errors.matchPassword}
                            errorMessage={'La password non corrisponde a quella inserita sopra'}
                            setValue={(value) => setMatchPassword(value)}
                        />
                    </>
                }

                {(errors.otpValid) && <p className="text-error text-xxs font-medium">Codice non valido.</p>}

                <div className={`flex ${(step === 1) ? 'justify-between' : 'justify-end'} items-center`}>
                    {(step === 1) && <CustomButton label='Indietro' color='bg-white' textColor='text-darkGray' className="border-2 border-darkGray mt-10 uppercase" onClickHandler={() => navigate(-1)} />}
                    <CustomButton disabled={isLoading} label={(step === 1) ? 'Invia' : 'Salva'} color='bg-primary' className="mt-10 uppercase border-2 border-primary" onClickHandler={(step === 1) ? handleCheckToken : handleResetPassword} />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword