import React, { useState, useEffect } from 'react';
import useComponentVisible from '../hooks/useComponentVisible';
import { bookingStatusColorMapping, bookingStatusDbMapping } from '../types/enum/bookingStatus';
import CustomButton from './CustomButton';
import { SwitchIcon } from '../utils/icons/SwitchIcon';
import useScreenSize from '../hooks/useScreenSize';
import { ClearIcon } from '../utils/icons/ClearIcon';
import { SplitIcon } from '../utils/icons/SplitIcon';

interface AutocompleteProps {
    options: any[];
    optionsFilterCallback?: (value: string) => void;
    label?: string;
    fieldToSearch: string;
    fieldToShow2?: string;
    fieldToShow3?: string;
    bookingStatus?: boolean;
    rightButton?: boolean;
    freeButton?: boolean;
    onButtonClick?: (id: string, action: 'CLEAN' | 'SPLIT' | 'CHANGE') => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ options, optionsFilterCallback, label = "Cerca...", fieldToSearch, fieldToShow2, fieldToShow3, bookingStatus = false, rightButton = false, freeButton = false, onButtonClick }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
    const [elementId, setElementId] = useState('');
    const screenSize = useScreenSize();

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setElementId('');
        const inputValue = event.target.value;
        setSearchValue(inputValue);

        // Filter options based on the input value
        optionsFilterCallback && optionsFilterCallback(inputValue.toLowerCase());
    };

    const toggleDropdown = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    const selectOption = (option: any) => {
        setElementId(option['_id'] || option['pos'])
        setSearchValue(option[fieldToSearch]);
        setIsComponentVisible(false);
    };
    return (
        <div className='flex w-full items-center gap-2'>
            <div ref={ref} onClick={toggleDropdown} className="relative text-text w-full">
                <input
                    type="text"
                    placeholder={label}
                    value={searchValue}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 text-sm font-medium focus:outline-none border ${!!elementId && 'border-primary border-2'} ${((isComponentVisible && options.length > 0) ? 'rounded-t-lg' : 'rounded-lg')}`}
                />
                {(isComponentVisible && options.length > 0) && (
                    <div className="absolute left-0 w-full z-50 max-h-48 overflow-auto bg-white border border-t-0 rounded-b-lg shadow-lg">
                        {filteredOptions.map((option, index) => (
                            <div
                                key={index}
                                className="relative px-4 py-2 hover:bg-background cursor-pointer capitalize flex gap-4 items-center whitespace-nowrap truncate"
                                onClick={() => selectOption(option)}
                            >
                                <div className='flex gap-1 truncate'>
                                    <p className='font-medium text-text max-sm:truncate'>{option[fieldToSearch]}</p>
                                    <p className='font-medium text-text max-sm:truncate'>{fieldToShow2 && option[fieldToShow2]}</p>
                                </div>
                                <p className='font-bold text-primary'>{fieldToShow3 && option[fieldToShow3]}</p>
                                {bookingStatus && <div title={bookingStatusDbMapping[option['bookingStatus'] as keyof typeof bookingStatusDbMapping]} className={`absolute right-3 top-3.5 font-medium h-3 w-3 rounded-full ${bookingStatusColorMapping[option['bookingStatus'] as keyof typeof bookingStatusDbMapping]}`} />}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {rightButton &&
                (screenSize !== 'xs')
                ? (freeButton && !elementId)
                    ? <CustomButton color='bg-info' label='Libera' onClickHandler={() => onButtonClick && onButtonClick('', 'CLEAN')} />
                    : <div className='flex gap-1'>
                        {(!!elementId && !!freeButton) && <CustomButton color='bg-info' label='Duplica' disabled={!(!!elementId)} onClickHandler={() => {
                            if (!!onButtonClick) {
                                onButtonClick(elementId, 'SPLIT');
                                setSearchValue('');
                                setElementId('');
                            }
                        }} />}
                        <CustomButton color='bg-success' label='Assegna' disabled={!(!!elementId)} onClickHandler={() => onButtonClick && onButtonClick(elementId, 'CHANGE')} />
                    </div>
                : (freeButton && !elementId)
                    ? <div onClick={() => onButtonClick && onButtonClick('', 'CLEAN')}><ClearIcon /></div>
                    : <div className='flex gap-1'>
                        {(!!elementId && !!freeButton) && <div onClick={() => {
                            if (!!onButtonClick && !!elementId) {
                                onButtonClick(elementId, 'SPLIT');
                                setSearchValue('');
                                setElementId('');
                            }
                        }}><SplitIcon /></div>}
                        <div onClick={() => (onButtonClick && !!elementId) && onButtonClick(elementId, 'CHANGE')}><SwitchIcon /></div>
                    </div>
            }
        </div >
    );
};

export default Autocomplete;