import React, { useState } from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import CustomButton from '../../../../components/CustomButton';
import { Form, Link, useActionData, useNavigation, useSubmit } from 'react-router-dom';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import InputForm from '../../../../components/InputForm';
import PhoneInput from '../../../../components/PhoneInput';
import { formatItalianNumber } from '../../../../utils/phoneNumberUtils';
import moment from 'moment';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import useFetch from '../../../../hooks/useFetch';
import { IEvent } from '../../../../types/interfaces/event.interface';
import { apiService, HttpMethod } from '../../../../services/apiService';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';

interface IProps {
    data: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    setData: React.Dispatch<React.SetStateAction<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>>;
    prevStep: () => void;
}

const Step5Create: React.FC<IProps> = ({ data, setData, prevStep }) => {
    const response = useActionData() as { errorCode: string, message: string, errorData: string };
    const submit = useSubmit();
    const navigation = useNavigation();

    const [errorMessage, setErrorMessage] = useState('');

    const { data: events, loading } = useFetch<{ offerEvent: IEvent | undefined, dailyEvent: IEvent | undefined }>(
        () => apiService(HttpMethod.POST, `/event/public/get/dailyEvent`, { date: data.date, structureId: data.structure, offer: data.offer._id }),
        undefined,
        [data.date],
        !!data.date && !!data.structure && !!data.offer
    );

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setErrorMessage("");
        const formData = data as any;

        const hasMissingRequiredField = formFields.some(formField => {
            const value = formData[formField.name];
            return !value && formField.isRequired;
        });

        const phoneFormatted = formatItalianNumber(data.phoneNumber);

        if (hasMissingRequiredField) {
            const missingField = formFields.find(formField => !formData[formField.name] && formField.isRequired)?.label;
            setErrorMessage(`${missingField} obbligatorio!`);
            return;
        }

        if (!phoneFormatted) {
            setErrorMessage('Numero di telefono non valido!');
            return;
        } else {
            formData.phoneNumber = phoneFormatted
            setData({ ...data, phoneNumber: phoneFormatted as string });
        }

        const formElement = e.target;
        const form = new FormData(formElement);
        form.append("data", JSON.stringify(formData));

        submit(form, { method: "post" });
    };

    const formFields = [
        {
            name: "name",
            label: "Nome",
            isRequired: true,
            placeholder: 'Es. Mario',
            type: 'text'
        },
        {
            name: "surname",
            label: "Cognome",
            isRequired: true,
            placeholder: 'Es. Rossi',
            type: 'text'
        },
        {
            name: "phoneNumber",
            label: "Telefono",
            isRequired: true,
            placeholder: 'Es. 333 1234567',
            type: 'text'
        },
        {
            name: "notes",
            label: "Note",
            isRequired: false,
            placeholder: 'Scrivi qui le tue note...',
            type: 'textarea'
        }
    ];

    return (
        <>
            <div className='flex flex-col gap-2'>
                <p className='font-bold text-text text-2xl'>Ci siamo quasi!</p>
                <p className='text-darkGray font-semibold md:text-center'>
                    Completa la tua prenotazione inserendo le informazioni personali e, se desideri, lascia una nota per l'organizzatore per assicurarti un'esperienza perfetta durante la tua visita.
                </p>
            </div>

            <div className="flex flex-col gap-8">

                <div className="flex flex-col gap-3">
                    {formFields.map((formField, index) => {
                        if (formField.name === 'phoneNumber') {
                            return <div className='flex flex-col gap-1'>
                                <PhoneInput key={index} setValue={(value) => setData((prev) => ({ ...prev, [formField.name]: value }))} />
                                <p className='text-xs font-semibold text-gray-400 px-2'>Il tuo numero di telefono ci aiuta a inviarti informazioni e promemoria sulla tua prenotazione. Promettiamo di non inviarti pubblicità! <br /> Assicurati di inserire un numero valido, altrimenti non possiamo garantire che la prenotazione vada a buon fine.</p>
                            </div>
                        }

                        return (
                            <InputForm
                                key={index}
                                name={formField.name}
                                label={formField.label + ` ${formField.isRequired ? '*' : ''}`}
                                backgroundColor='bg-white'
                                placeholder={formField.placeholder}
                                type={formField.type}
                                multiline={formField.type === 'textarea'}
                                multilineRows={formField.type === 'textarea' ? 6 : undefined}
                                value={data[formField.name as keyof IBookingRequest<IStructure, IOffer, IProposal>]}
                                setValue={(value) => setData((prev) => ({ ...prev, [formField.name]: value }))}
                            />
                        )
                    })}
                </div>
            </div>

            <div className="bg-primary -mx-8 px-8 py-4 gap-8 flex flex-col">
                {/* Structure Info */}
                <div className='flex flex-col gap-2'>
                    <p className="text-2xl font-bold text-white">Informazioni</p>
                    <p className="text-sm text-justify text-lightGray">{data.offer?.autoBookingOptions?.info}</p>
                </div>

                {/* Daily Event */}

                {!!events?.offerEvent || !!events?.dailyEvent
                    ? <div className={`bg-primary -mx-8 px-8 py-4 gap-2 flex flex-col md:max-w-md md:mx-auto md:rounded-b-md relative ${loading && 'bg-opacity-50'}`}>
                        <p className="text-2xl font-bold text-white">{events?.offerEvent?.name}</p>
                        <p className="text-sm text-justify text-mediumGray">{events?.offerEvent?.info}</p>

                        <p className="text-2xl font-bold text-white">{events?.dailyEvent?.name}</p>
                        <p className="text-sm text-justify text-mediumGray">{events?.dailyEvent?.info}</p>
                    </div>
                    : null
                }
            </div>

            {errorMessage && <p className='text-center text-red-700 font-medium text-sm underline'>{errorMessage}</p>}

            {!!response?.errorCode
                ? response.errorCode === 'BR007'
                    ? <div className='flex flex-col justify-center items-center gap-2'>
                        <p className='text-center text-red-700 font-medium text-sm'>
                            Esiste già una prenotazione collegata a questo numero di telefono <span className='font-bold capitalize'>( {data.phoneNumber} )</span> per <span className='font-bold capitalize'>{moment(data.date).format('ddd DD MMMM YYYY')}.</span><br />
                            Visita questo link per controllare e magari modificare la prenotazione o contatta la struttura per maggiori chiarimenti.
                        </p>
                        <Link to={'/request/' + response.errorData} replace={true} className='bg-primary rounded-md shadow px-4 py-1 font-medium text-text_white text-lg'>Prenotazioni</Link>
                    </div>
                    : <p className='text-center text-red-700 font-medium text-sm'>{response.message}</p>
                : null}

            <div className='h-full flex flex-col gap-2 justify-end'>
                <p className='text-xs font-medium text-darkGray text-center'>
                    Preparati a gustare l'attesa!<br />
                    Appena confermata, riceverai un promemoria direttamente sul tuo telefono tramite WhatsApp.
                </p>
                <Form method='POST' className='flex flex-col md:flex-row-reverse md:justify-between gap-2' onSubmit={handleSubmit}>
                    <CustomButton type='submit' disabled={navigation.state !== 'idle'} label="Prenota" color='bg-primary' textColor='text-text_white' />
                    <CustomButton type='button' disabled={navigation.state !== 'idle'} label="Indietro" onClickHandler={prevStep} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />
                </Form>
            </div>


        </>
    );
};
export default Step5Create