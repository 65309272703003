import { Link, useLoaderData, useParams } from "react-router-dom";
import { IBookingRequest } from "../../../../types/interfaces/bookingRequest.interface";
import { IStructure } from "../../../../types/interfaces/structure.interface";
import { IProposal } from "../../../../types/interfaces/proposal.interface";
import { IOffer } from "../../../../types/interfaces/offer.interface";
import { PartialIBooking } from "../../../../types/interfaces/booking.interface";
import { IminDashboard } from "../../../../utils/icons/IminDashboard";
import { SquaringCircles } from "../../../../utils/icons/SquaringCircles";
import Text from "../../../../components/Text";
import moment from "moment";
import { requestBookingStatusBgColorMapping } from "../../../../types/enum/requestBookingStatus.enum";
import { AutoBookingType } from "../../../../types/enum/autoBookingType.enum";
import { useEffect, useRef } from "react";
import { AddIcon } from "../../../../utils/icons/AddIcon";
import { IAutoBookingOption } from "../../../../types/interfaces/autoBookingOptions.interface";

const BookingRequests = () => {
    const response = useLoaderData() as {
        bookingRequest: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal, PartialIBooking<{ proposals: IProposal }>>,
        bookingRequests: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal, PartialIBooking<{ proposals: IProposal }>>[]
    };

    const bookingRequestRefs = useRef<{ [key: string]: HTMLAnchorElement | null }>({});


    const sortedBookingRequests = response.bookingRequests.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());


    const groupedByDate = sortedBookingRequests.reduce((acc, bookingRequest) => {
        const date = moment(bookingRequest.date).format('YYYY-MM-DD');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(bookingRequest);
        return acc;
    }, {} as { [date: string]: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal, PartialIBooking<{ proposals: IProposal }>>[] });

    useEffect(() => {
        const bookingRequestId = response.bookingRequest._id;
        if (bookingRequestId && bookingRequestRefs.current[bookingRequestId]) {
            bookingRequestRefs.current[bookingRequestId]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            bookingRequestRefs.current[bookingRequestId]?.focus();
        }
    }, []);

    const bookingRequestCard = (bookingRequest: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal, PartialIBooking<{ proposals: IProposal }>>) => {
        return <Link
            to={'/details/' + bookingRequest._id}
            key={bookingRequest._id}
            className="bg-white rounded shadow p-4 pb-6 gap-2 cursor-pointer grid grid-cols-2 relative hover:shadow-primary-custom focus:shadow-primary-custom focus:outline-none"
            ref={(el) => {
                if (el) {
                    bookingRequestRefs.current[bookingRequest._id] = el;
                }
            }}
        >

            <div title="Stato Prenotazione" className={`absolute h-6 aspect-square rounded-full top-2 right-2 shadow ${requestBookingStatusBgColorMapping[bookingRequest.status]}`} />

            <Text label={bookingRequest?.name} title="Nome" gap={0} />
            <Text label={bookingRequest?.surname} title="Cognome" gap={0} />
            <Text label={moment(bookingRequest?.createdAt).format('DD-MM-YYYY')} title="Creata il" gap={0} />
            <Text label={bookingRequest?.people} title="Persone" gap={0} />
            <Text label={bookingRequest?.offer?.name} title="Servizio" gap={0} />
            <Text label={bookingRequest?.shift} title="Ora" gap={0} />

            {
                bookingRequest.autoBookingType === AutoBookingType.AUTOMATIC
                    ? !!bookingRequest?.proposal && <div className="border-b-2 border-darkGray col-span-2" />
                    : !!bookingRequest?.booking?.proposals.length && <div className="border-b-2 border-darkGray col-span-2" />
            }

            {
                bookingRequest.autoBookingType === AutoBookingType.AUTOMATIC
                    ? !!bookingRequest?.proposal && <div className="col-span-2 grid grid-cols-2">
                        <Text label={bookingRequest?.proposal.name} title="Proposta" gap={0} />
                    </div>
                    : bookingRequest?.booking?.proposals?.map((proposal, index) =>
                        <div key={index} className="col-span-2 grid grid-cols-2">
                            <Text label={proposal.proposal.name} title="Proposta" gap={0} />
                            <Text label={proposal.quantity} title="Persone" gap={0} />
                        </div>
                    )
            }

            <p className="text-xs text-mediumGray absolute bottom-1 right-2">
                {
                    bookingRequest.autoBookingType === AutoBookingType.AUTOMATIC
                        ? "Creata da te"
                        : "Creata dalla struttura"
                }
            </p>
        </Link>
    };

    return (
        <div className='h-full w-full flex flex-col gap-4'>
            {/* HEADER */}
            <div className='w-full bg-white shadow-md px-8 py-4 text-center'>
                <p className='line-clamp-1 font-bold text-4xl text-primary'>{response.bookingRequest?.structure?.name}</p>
                <p className='line-clamp-1 font-base text-sm text-text'>{response.bookingRequest.structure.address.city} ({response.bookingRequest.structure.address.state}), {response.bookingRequest.structure.address.street}</p>
            </div>

            {/* BODY */}
            <div className='flex flex-col gap-4 px-8 justify-between flex-1'>
                {response.bookingRequests.length > 0
                    ? (
                        <div className='flex flex-col gap-2'>
                            <div className="flex justify-between items-center">
                                <p className='text-3xl font-bold'>Le tue prenotazioni</p>
                                {(!!response.bookingRequest?.offer.autoBookingOptions && !response.bookingRequest?.offer.autoBookingOptions?.disabled) && (
                                    <Link to={`/${response.bookingRequest?.structure?.name}/${response.bookingRequest?.structure?._id}`} className="bg-text py-2 px-4 rounded-lg shadow">
                                        <AddIcon />
                                    </Link>
                                )}

                            </div>
                            <p className='text-sm font-medium text-darkGray'>
                                Qui puoi vedere tutte le tue prenotazioni future per questa struttura. Seleziona una prenotazione per visualizzare i dettagli e le informazioni complete.
                            </p>

                            <div className="flex flex-col  gap-6 mt-4">
                                {
                                    Object.keys(groupedByDate).map(date => (
                                        <div key={date} className='flex flex-col gap-4'>
                                            <p className='text-xl font-semibold capitalize'>{moment(date).format('ddd DD MMMM YYYY')}</p>
                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                                                {groupedByDate[date].map(bookingRequest => bookingRequestCard(bookingRequest))}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    ) : (
                        <div className='flex flex-col gap-2'>
                            <p className='text-3xl font-bold'>Nessuna prenotazione futura</p>
                            <p className='text-sm font-medium text-darkGray'>
                                Al momento non hai prenotazioni future per questa struttura. Torna presto per vedere eventuali aggiornamenti!
                            </p>
                        </div>
                    )
                }
            </div>
            {/* BODY */}

            {/* FOOTER */}
            <div className='flex w-full items-center justify-between bg-primary p-4'>
                <IminDashboard color='#FFF' />
                <div className='flex flex-col gap-1 items-center'>
                    <p className='font-medium text-xs text-text_white'>Powered by</p>
                    <a href='https://thesquaringcircles.com/' target='_blank' rel='noreferrer'><SquaringCircles /></a>
                </div>
            </div>
        </div>
    )
}

export default BookingRequests