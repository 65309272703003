import { useEffect, useRef } from 'react';

export const useHorizontalScroll = (containerRef: React.RefObject<HTMLDivElement>) => {
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const handleMouseDown = (e: MouseEvent) => {
                isDragging.current = true;
                startX.current = e.pageX - container.offsetLeft;
                scrollLeft.current = container.scrollLeft;
            };

            const handleMouseLeaveOrUp = () => {
                isDragging.current = false;
            };

            const handleMouseMove = (e: MouseEvent) => {
                if (!isDragging.current) return;
                e.preventDefault();
                const x = e.pageX - container.offsetLeft;
                const walk = (x - startX.current) * 2;
                container.scrollLeft = scrollLeft.current - walk;
            };

            container.addEventListener('mousedown', handleMouseDown);
            container.addEventListener('mouseleave', handleMouseLeaveOrUp);
            container.addEventListener('mouseup', handleMouseLeaveOrUp);
            container.addEventListener('mousemove', handleMouseMove);

            return () => {
                container.removeEventListener('mousedown', handleMouseDown);
                container.removeEventListener('mouseleave', handleMouseLeaveOrUp);
                container.removeEventListener('mouseup', handleMouseLeaveOrUp);
                container.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [containerRef]);
};