import React, { useRef, useState, useEffect } from 'react';

interface ExpandableTextProps {
    text: string;
    initialClampLines?: number;
    labelClassName?: string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ text, initialClampLines = 3, labelClassName }) => {
    const paragraphRef = useRef<HTMLParagraphElement>(null);
    const [lineCount, setLineCount] = useState<number>(0);
    const [extendNotes, setExtendNotes] = useState(true);

    useEffect(() => {
        if (paragraphRef.current) {
            const element = paragraphRef.current;
            const style = window.getComputedStyle(element);
            const lineHeight = parseFloat(style.lineHeight);
            const height = element.clientHeight;
            const numberOfLines = Math.round(height / lineHeight);
            setLineCount(numberOfLines);
            setExtendNotes(false);
        }
    }, [text]);

    return (
        <div>
            <p
                id={'#note-container'}
                ref={paragraphRef}
                className={`${labelClassName} break-words whitespace-pre-line truncate ${extendNotes ? '' : `line-clamp-${initialClampLines}`}`}
            >
                {text}
            </p>
            {lineCount > initialClampLines && (
                <button onClick={() => setExtendNotes(!extendNotes)} className='text-primary font-medium text-sm'>
                    {extendNotes ? 'Nascondi' : 'Visualizza'}
                </button>
            )}
        </div>
    );
};

export default ExpandableText;
