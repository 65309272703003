import PageTamplate from '../../components/PageTamplate';
import { IUser } from '../../types/interfaces/auth.interface';
import { IStructure } from '../../types/interfaces/structure.interface';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/store';
import { AddIcon } from '../../utils/icons/AddIcon';
import { EditIcon } from '../../utils/icons/EditIcon';
import Text from '../../components/Text';
import PageTitle from '../../components/PageTitle';

const Structure = () => {
    const response = useLoaderData() as { structure: IStructure, users: IUser[] };
    const navigate = useNavigate();
    const { userAuth } = useAppSelector(state => state.auth);

    const navigateToUserStructure = (user: IUser) => {
        if (userAuth?.id !== user._id)
            navigate('user/' + user._id);
    }

    return <div className='flex-1 w-full flex flex-col p-8 gap-8 select-none'>
        <div className='flex flex-col gap-2'>
            <PageTitle
                title={"Struttura"}
                goBackBtn={false}
                rightBtn={<div onClick={() => navigate('manage/' + response.structure._id)} className='flex items-center justify-center py-2 px-4 rounded-md cursor-pointer shadow bg-text'>
                    <EditIcon size='28' />
                </div>}
            />


            <div className='grid grid-cols-2 gap-2'>
                <Text gap={0} title={'Nome'} label={response?.structure?.name} />
                <Text gap={0} title={'Telefono'} label={response?.structure?.contact?.phone} />
                <Text gap={0} title={'Email'} label={response?.structure?.contact?.email} />
                <Text gap={0} title={'Città'} label={response?.structure?.address?.city} />
                <Text gap={0} title={'Stato/Regione'} label={response?.structure?.address?.state} />
                <Text gap={0} title={'Codice postale'} label={response?.structure?.address?.zip} />
                <Text gap={0} title={'Indirizzo'} label={response?.structure?.address?.street} />
                <Text gap={0} title={'Descrizione'} label={response?.structure?.description} />
            </div>
        </div >

        <div className='flex flex-col gap-2'>
            <PageTitle
                title={"Utenti " + response?.users?.length}
                goBackBtn={false}
                rightBtn={<div onClick={() => navigate('user')} className='flex items-center justify-center py-2 px-4 rounded-md cursor-pointer shadow bg-text'>
                    <AddIcon size='28' />
                </div>}
            />

            <div className='flex flex-col rounded-md shadow'>
                <div className='grid grid-cols-2 gap-2 items-center p-2 font-bold text-text text-base truncate bg-white rounded-t'>
                    <p className='truncate'>Nome</p>
                    <p className='truncate'>Email</p>
                </div>
                <div className='bg-white flex flex-col'>
                    {response?.users.map((user: IUser, index: number) => {
                        if (!user) return null

                        return <div
                            key={index}
                            onClick={() => navigateToUserStructure(user)}
                            style={{ borderTop: "2px solid #f2f2f2", borderBottom: "2px solid #f2f2f2" }}
                            className={`
                                grid grid-cols-2 gap-2 items-center py-4 px-2 text-text font-medium text-base border-l-4
                                ${userAuth?.id !== user._id && 'hover:bg-primaryLight cursor-pointer'}
                                ${user.isVerified ? 'border-success' : 'border-error'} 
                                last:rounded-b 
                            `}
                        >
                            <p className='truncate'>{user.name} {user.surname} {userAuth?.id === user._id && <span className='font-light'>(tu)</span>}</p>
                            <p className='truncate'>{user.email}</p>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default Structure