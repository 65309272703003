import React from 'react';
import SigninForm from './SigninForm';
import SignupForm from './SignupForm';
import LeftOverlayContent from './LeftOverlayContent';
import RightOverlayContent from './RightOverlayContent';
import { useSearchParams } from 'react-router-dom';

const LoginView: React.FC = () => {
    const [searchParams] = useSearchParams();
    const overlayBg = "bg-gradient-to-r from-primary via-purple-800 to-primary";

    return (
        <div className="flex items-center z-10 justify-center h-screen w-screen bg-purple-200">
            <div className="h-full w-full bg-white relative max-sm:overflow-auto md:overflow-hidden rounded-lg">
                <div className={`absolute top-0 md:h-full md:w-1/2 max-sm:h-1/2 max-sm:w-full flex justify-center items-center transition-all duration-700 ease-in-out ${searchParams.has('register') ? "max-sm:translate-y-full md:translate-x-full opacity-0" : "z-20"}`} >
                    <SigninForm />
                </div>

                <div className={`absolute top-0 md:h-full md:w-1/2 max-sm:h-1/2 max-sm:w-full flex justify-center items-center transition-all duration-700 ease-in-out ${searchParams.has('register') ? "max-sm:translate-y-full md:translate-x-full opacity-100 z-20 animate-show" : "opacity-0"}`} >
                    <SignupForm />
                </div>

                <div className={`absolute md:top-0 max-sm:bottom-0 z-20 md:left-1/2 md:w-1/2 md:h-full max-sm:h-1/2 max-sm:w-full overflow-hidden transition-transform duration-700 ease-in-out  ${searchParams.has('register') ? "max-sm:-translate-y-full md:-translate-x-full" : ""}`} >
                    <div className={`${overlayBg} relative -left-full h-full w-[200%] transform transition-transform duration-700 ease-in-out ${searchParams.has('register') ? "translate-x-1/2" : "translate-x-0"}`} >
                        <div className={`w-1/2 h-full absolute flex justify-center items-center top-0 left-0 transform transition-transform duration-700 ease-in-out ${searchParams.has('register') ? " translate-x-0" : "-translate-x-[20%]"}`} >
                            <LeftOverlayContent />
                        </div>
                        <div className={`w-1/2 h-full absolute flex justify-center items-center top-0 right-0 transform transition-transform duration-700 ease-in-out ${searchParams.has('register') ? "translate-x-[20%]" : "translate-x-0"}`} >
                            <RightOverlayContent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginView;
