export const shortStringErrorMessage = "Il campo deve contenere almeno 1 carattere e massimo 25 caratteri";
export const longStringErrorMessage = "Il campo deve contenere almeno 1 carattere e massimo 1000 caratteri";
export const numberErrorMessage = "Il campo deve contenere un numero compreso tra 0 e 999999";
export const timetableErrorMessage = "Il campo deve essere nel formato HH:mm (esempio: 08:00)";


/* input validations */
const validateShortString = (input: any, required: boolean = true) => {
    // check if input is type of string 
    if (typeof input !== 'string') {
        return false;
    }

    if (required) {
        return (input.length > 0 && input.length <= 25);
    }

    return (input.length <= 25);
}

const validateLongString = (input: any, required: boolean = true) => {
    // check if input is type of string 
    if (typeof input !== 'string') {
        return false;
    }

    if (required) {
        return (input.length > 0 && input.length <= 1000);
    }

    return (input.length <= 1000);
}

const validateNumber = (input: any, required: boolean = true) => {
    // check if input type is number 
    if (isNaN(input)) {
        return false;
    }

    if (required) {
        return input > 0 && input <= 999999;
    }

    return input >= 0 && input <= 999999;
}

const validateTimetable = (input: any, required: boolean = true): boolean => {
    const opening = input.openingAt;
    const closing = input.closedAt;

    // check if input is type of string 
    if (typeof opening !== 'string' || typeof closing !== 'string') {
        return false;
    }

    // check if string is in format HH:mm
    if (required) {
        return !!(opening.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/) && closing.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/));
    }

    return !!((opening.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/) && closing.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) || opening === '' || closing === '');
}

// export all validations
export {
    validateShortString,
    validateLongString,
    validateNumber,
    validateTimetable
}
