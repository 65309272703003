export enum BOOKING_STATUS {
    PENDING,
    ACCEPTED,
    DRAW,
    SUCCESS,
    REJECTED,
    NOSHOW,
    CANCELLED
};

const bookingStatusMapping = {
    PENDING: BOOKING_STATUS.PENDING,
    ACCEPTED: BOOKING_STATUS.ACCEPTED,
    DRAW: BOOKING_STATUS.DRAW,
    SUCCESS: BOOKING_STATUS.SUCCESS,
    REJECTED: BOOKING_STATUS.REJECTED,
    NOSHOW: BOOKING_STATUS.NOSHOW,
    CANCELLED: BOOKING_STATUS.CANCELLED
};

export const bookingStatusDbMapping = {
    [BOOKING_STATUS.PENDING]: 'In Corso',
    [BOOKING_STATUS.ACCEPTED]: 'Accettata',
    [BOOKING_STATUS.DRAW]: 'Disegnato',
    [BOOKING_STATUS.SUCCESS]: 'Completata',
    [BOOKING_STATUS.REJECTED]: 'Rifiutata',
    [BOOKING_STATUS.NOSHOW]: 'No show',
    [BOOKING_STATUS.CANCELLED]: 'Disdetta'
};

export const mapBookingStatus = (value: any) => {
    return bookingStatusDbMapping[value as BOOKING_STATUS] || value;
};

export const bookingStatusOptions: { label: string; value: BOOKING_STATUS }[] = Object.values(bookingStatusMapping).map(key => ({
    label: mapBookingStatus(Number(key) as BOOKING_STATUS) as string,
    value: Number(key) as BOOKING_STATUS,
}));

export const bookingStatusColorMapping = {
    0: 'bg-info',
    1: 'bg-primary',
    2: 'bg-success',
    3: 'bg-success',
    4: 'bg-warning',
    5: 'bg-darkGray',
    6: 'bg-error'
};

export const bookingStatusBorderColorMapping = {
    0: 'border-info',
    1: 'border-primary',
    2: 'border-success',
    3: 'border-success',
    4: 'border-warning',
    5: 'border-darkGray',
    6: 'border-error'
};

export const bookingStatusTextColorMapping = {
    0: 'text-info',
    1: 'text-primary',
    2: 'text-success',
    3: 'text-success',
    4: 'text-warning',
    5: 'text-darkGray',
    6: 'text-error'
};