import { SEVERITY_TYPE } from '../../types/enum/apiResponseSeverity';

type SeverityTypeValues = typeof SEVERITY_TYPE[keyof typeof SEVERITY_TYPE];

export const uiState: IUiState = {
    modalState: { isOpen: false, message: '', severity: 'info' },
    notificationPopoverState: { isOpen: false },
    sideBarState: false
};

interface IUiState {
    modalState: {
        isOpen: boolean;
        message: string;
        severity: SeverityTypeValues
    },
    notificationPopoverState: {
        isOpen: boolean;
    },
    sideBarState: boolean
};