import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTamplate from '../../components/PageTamplate';
import Switch from '../../components/Switch';
import useFetch from '../../hooks/useFetch';
import { apiHandler } from '../../services/apiHandler';
import { replaceOffers, resetOfferManage } from '../../store/offer/offerSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { IOffer } from '../../types/interfaces/offer.interface';
import AddServiceIcon from '../../utils/icons/AddServiceIcon';
import { DeleteBucketIcon } from '../../utils/icons/DeleteBucketIcon';
import { v4 as uuidv4 } from 'uuid';
import CustomButton from '../../components/CustomButton';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import { ITimeTable } from '../../types/interfaces/timeTable.interface';
import { SettingIcon } from '../../utils/icons/SettingIcon';
import DropdownMenu, { Option } from '../../components/DropdownMenu';
import { HttpMethod, apiService } from '../../services/apiService';
import PageTitle from '../../components/PageTitle';
import { IUserStructure } from '../../types/interfaces/userStructure.interface';
import { IPlugin } from '../../types/interfaces/plugin.interface';
import { PluginIdentifier } from '../../types/enum/pluginIdentifier';
import { IStructure } from '../../types/interfaces/structure.interface';

const OfferView: React.FC = () => {
    // utils
    const navigate = useNavigate();
    const { showModal, showErrorModal } = useModalDispatcher();

    // redux
    const dispatch = useAppDispatch();
    const offers = useAppSelector(state => state.offer.offers);
    const { userStructure } = useAppSelector<{ userStructure: IUserStructure<IStructure<IPlugin>, string, IPlugin> }>(state => state.account.userStructure);

    // local state 
    const [isDeleteCardVisible, setIsDeleteCardVisible] = useState({
        offerId: '',
        isVisible: false
    });

    // fetching offers
    useFetch(
        () => apiHandler.post('/offer/list'),
        (fetchedData) => dispatch(replaceOffers(fetchedData))
    );

    useEffect(() => {
        dispatch(resetOfferManage({}))
    }, []);

    const deleteHandler = async (id: string) => {
        // delete offer by calling api
        try {
            const response: any = await apiHandler.del(`/offer/delete/${id}`)

            if (response.data) {
                showModal(200)
                const refetch: any = await apiHandler.post('/offer/list')
                dispatch(replaceOffers(refetch))
            }

        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleOfferPress = (id: string | undefined) => {
        const sessionId = uuidv4()
        if (id) {
            navigate(`/dashboard/servizi/manage?offerSession=${sessionId}&id=${id}&isCreate=false`);
        }
    };

    const handleAddOfferPress = () => {
        const sessionId = uuidv4()
        navigate(`/dashboard/servizi/manage?offerSession=${sessionId}&isCreate=true`);
    };

    const handleSwitchChange = async (e: any, offer: IOffer) => {
        try {
            e.stopPropagation();

            if (!offer._id) return;

            const response = await apiService<IOffer & { errorCode: string }>(HttpMethod.PUT, `/offer/update/${offer._id}`, { ...offer, isEnabled: !offer.isEnabled });

            if (!!response.errorCode) {
                throw response;
            }

            showModal(200)

            const refetch = await apiService<IOffer[] & { errorCode: string }>(HttpMethod.POST, '/offer/list')

            if (!!refetch.errorCode) {
                throw refetch;
            }

            dispatch(replaceOffers(refetch))
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleDeleteOfferPress = (event: any, id: string | undefined) => {
        event.stopPropagation();
        // show delete card on press delete button
        if (id) {
            setIsDeleteCardVisible({
                offerId: id,
                isVisible: true
            })
        }
    };

    const handleDeleteCardPress = (action: string) => {
        switch (action) {
            case 'cancel':
                setIsDeleteCardVisible({
                    offerId: '',
                    isVisible: false
                })
                break;

            case 'delete':
                deleteHandler(isDeleteCardVisible.offerId);
                setIsDeleteCardVisible({
                    offerId: '',
                    isVisible: false
                })
                break;

            default:
                break;
        }
    };

    const renderAddCard = () => {
        return (
            <div onClick={handleAddOfferPress} className="p-4 bg-white rounded-md shadow-md flex flex-col justify-between items-center cursor-pointer">
                <div className="flex flex-col justify-center items-center h-full py-16">
                    <AddServiceIcon />
                </div>
            </div>
        )
    };

    const renderDeleteCard = (index: number) => {
        return (
            <div key={index} className="p-4 bg-white rounded-md shadow-md flex flex-col items-center justify-between">
                <p className='text-text text-center font-medium my-6'>Vuoi procedere con l'eliminazione definitiva di questo servizio?</p>
                <div className="flex flex-col justify-between w-full gap-y-2">
                    <CustomButton label="Annulla" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => handleDeleteCardPress('cancel')} />
                    <CustomButton label="Elimina" color='bg-error' onClickHandler={() => handleDeleteCardPress('delete')} />
                </div>
            </div>
        )
    };

    const generateOptions = (id: string): Option[] => {
        return [
            {
                disabled: false,
                label: <p>Proposte</p>,
                onClick: () => navigate(`/dashboard/servizi/proposte/` + id)
            },
            // {
            //     disabled: !userStructure?.permissions?.includes(SERVICE.ORDERS),
            //     label: <p>Menu</p>,
            //     onClick: () => navigate(`/dashboard/servizi/menu/` + id)
            // },
            // {
            //     disabled: !userStructure?.permissions?.includes(SERVICE.ORDERS),
            //     label: <p>Variazioni</p>,
            //     onClick: () => navigate(`/dashboard/servizi/variazioni/` + id)
            // },
            {
                disabled: (
                    !userStructure.structureId.plugins.some(plugin => plugin.identifier === PluginIdentifier.ONLINE_BOOKINGS) ||
                    !userStructure?.permissions?.some(permission => permission.plugin.identifier === PluginIdentifier.BOOKINGS)
                ),
                label: <p>Prenotazione automatica</p>,
                onClick: () => navigate(`/dashboard/servizi/auto-booking/` + id)
            }
        ]
    };

    // render cards
    const renderOfferCards = offers.map((offer: IOffer, index: number) => {
        return (isDeleteCardVisible.offerId === offer?._id && isDeleteCardVisible.isVisible)
            ? renderDeleteCard(index)
            : <div key={index} onClick={() => handleOfferPress(offer._id)} className="flex flex-col bg-white rounded-md shadow-md cursor-pointer overflow-hiddenz-40">
                <div onClick={(e) => e.stopPropagation()} className="flex flex-row justify-between items-center w-full cursor-default p-4">
                    <DropdownMenu
                        toggleButton={<div className='cursor-pointer'><SettingIcon /></div>}
                        title='Impostazioni'
                        options={generateOptions(offer._id!)}
                    />

                    <Switch isOn={offer.isEnabled} toggleSwitch={(e) => handleSwitchChange(e, offer)} />

                    <div className='cursor-pointer' onClick={(e) => handleDeleteOfferPress(e, offer._id)}>
                        <DeleteBucketIcon />
                    </div>
                </div>

                <p className="font-black text-center capitalize text-3xl text-primary py-12 border-b-2 border-mediumGray">{offer.name}</p>

                <div className="flex justify-center items-center gap-4 w-full p-4">
                    {offer.timetable?.map((timetable: ITimeTable, index: number) => {
                        const isUnavailable = timetable?.isAvailable ? 'opacity-100' : 'opacity-20';
                        return <p key={index} className={`uppercase font-semibold text-lg text-text ${isUnavailable}`}>{timetable.day.toString()[0]}</p>
                    })}

                </div>
            </div>
    });

    return (
        <PageTamplate>
            <PageTitle title='Servizi' goBackBtn={false} />
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {renderOfferCards}
                {renderAddCard()}
            </div>
        </PageTamplate>
    )
}

export default OfferView
