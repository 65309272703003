import React, { ReactNode } from 'react';

type IProps = {
    children: ReactNode;
    classname?: string;
    style?: React.CSSProperties | undefined
};

/**
 * PageTamplate component.
 * @param props - The props of the PageTamplate component.
 * @returns Rendered component.
 */
const PageTamplate: React.FC<IProps> = ({ children, classname, style }) => {
    return (
        <div className={`h-full w-full flex flex-col p-4 md:p-8 gap-4 ${classname}`} style={style}>
            {children}
        </div>
    );
};

export default PageTamplate;
