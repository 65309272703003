import React from 'react';

interface CustomButtonProps {
    color: 'bg-primary' | 'bg-secondary' | 'bg-background' | 'bg-background2' | 'bg-text' | 'bg-success' | 'bg-info' | 'bg-warning' | 'bg-error' | 'bg-darkGray' | 'bg-mediumGray' | 'bg-lightGray' | 'bg-transparent' | 'bg-white';
    textColor?: 'text-primary' | 'text-secondary' | 'text-background' | 'text-background2' | 'text-text' | 'text-success' | 'text-info' | 'text-warning' | 'text-error' | 'text-darkGray' | 'text-mediumGray' | 'text-lightGray' | 'text-transparent' | 'text-text_white' | 'text-text';
    label?: string | React.ReactNode;
    title?: string;
    padding?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    onClickHandler?: (e: React.MouseEvent<any>) => void;
    className?: string;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    textStyle?: string;
    popoverTitle?: string;
    type?: "button" | "submit" | "reset" | undefined;
    containerClassName?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ color, textColor = 'text-text_white', label, title, padding = 'py-2 px-6', fullWidth, disabled, onClickHandler, className, leftIcon, rightIcon, textStyle = 'text-base', popoverTitle, type, containerClassName }) => {
    return (
        <div className={`flex flex-col gap-2 select-none ${fullWidth ? 'w-full' : ''} ${containerClassName}`}>
            {title && <label className="font-medium text-text">
                {title}
            </label>}
            <button
                type={type}
                title={popoverTitle}
                onClick={onClickHandler}
                className={`${className} rounded-md shadow flex justify-center items-center gap-2 cursor-pointer ${disabled ? 'bg-mediumGray pointer-events-none cursor-default' : `${color}`} ${padding} hover:opacity-80`}
                disabled={disabled}
            >
                {leftIcon}
                {label && <p className={`text-center ${textColor} ${textStyle} font-semibold ${!title ? 'cursor-pointer' : 'cursor-default'}`}>
                    {label}
                </p>}
                {rightIcon}
            </button>
        </div>
    );
};

export default CustomButton;
