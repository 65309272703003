import { useState } from "react";
import InputForm from "../../components/InputForm";
import CustomButton from "../../components/CustomButton";
import { apiHandler, postHandler } from "../../services/apiHandler";
import { useNavigate } from "react-router-dom";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";

const SignupForm = () => {
    const navigate = useNavigate();
    const { showErrorModal } = useModalDispatcher();
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        nameError: false,
        surnameError: false,
        emailError: false,
        passwordError: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSignUp = async () => {
        setIsLoading(true);
        const { name, surname, email, password } = formData;
        const nameError = name.length <= 0;
        const surnameError = surname.length <= 0;
        const emailError = !emailRegex.test(email);
        const passwordError = !passwordRegex.test(password);

        setFormData({
            ...formData,
            nameError,
            surnameError,
            emailError,
            passwordError,
        });

        if (nameError || surnameError || emailError || passwordError) {
            setIsLoading(false);
            return;
        }

        try {
            const response = await apiHandler.post<postHandler | any>('/user/register', { name, surname, email, password });
            const { user } = response;
            if (user._id) {
                navigate('/auth/verify?id=' + user._id + '&email=' + user.email)
            }
            setIsLoading(false);

        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return (
        <div className="flex flex-col w-4/5 overflow-hidden justify-center items-center">

            <p className="text-3xl md:text-5xl font-bold text-primary">
                Crea un account
            </p>

            <div className="w-full max-sm:mt-5 md:mt-10 flex flex-col gap-2" >
                <div className="flex items-center gap-2">
                    <InputForm
                        label='Nome'
                        value={formData.name}
                        setValue={(value) => handleInputChange('name', value)}
                        error={formData.surnameError}
                        errorMessage='Inserire un nome valido'
                        onSearchSubmit={handleSignUp}
                        fullWidth
                    />

                    <InputForm
                        label='Cognome'
                        value={formData.surname}
                        setValue={(value) => handleInputChange('surname', value)}
                        error={formData.surnameError}
                        errorMessage='Inserire un cognome valido'
                        onSearchSubmit={handleSignUp}
                        fullWidth
                    />
                </div>
                <InputForm
                    label='Email'
                    value={formData.email}
                    setValue={(value) => handleInputChange('email', value)}
                    error={formData.emailError}
                    errorMessage='Inserire una email valida'
                    onSearchSubmit={handleSignUp}
                />

                <InputForm
                    label='Password'
                    value={formData.password}
                    setValue={(value) => handleInputChange('password', value)}
                    error={formData.passwordError}
                    errorMessage='La password deve contenere almeno 8 caratteri, una lettera maiuscola e un numero.'
                    type='password'
                    onSearchSubmit={handleSignUp}
                />

                <CustomButton disabled={isLoading} label='Registrati' color='bg-primary' className="mt-10 px-8 py-4 uppercase" onClickHandler={handleSignUp} />
            </div>
        </div>
    );
};

export default SignupForm;