import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store/store';
import { addFilter, clearFilters, removeFilter, updateFilters } from '../store/booking/bookingSlice';
import { IBooking, PartialIBooking } from '../types/interfaces/booking.interface';
import { IOffer } from '../types/interfaces/offer.interface';
import { IProposal } from '../types/interfaces/proposal.interface';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function useFilter() {

    const dispatch = useDispatch();
    const filters = useAppSelector(state => state.booking.filters);
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedFilters = JSON.parse(searchParams.get('filters') || '{}');

    const handleAddMultipleFilter = (isSelected: boolean, key: keyof PartialIBooking<{ offer: IOffer, proposals: IProposal }>, value: string | number | boolean | any) => {
        const updatedState = filters[key] ? [...filters[key]] : [];
        if (isSelected) {
            const indexToRemove = updatedState.indexOf(value);
            if (indexToRemove !== -1) {
                updatedState.splice(indexToRemove, 1);
            }
        } else {
            updatedState.push(value);
        }

        if (updatedState.length === 0) {
            dispatch(removeFilter(key));
        } else {
            dispatch(addFilter({ field: key, value: updatedState }));
        }
    };

    const handleAddSingleFilter = (key: keyof IBooking, value: string | number | boolean | null | any) => {
        dispatch(addFilter({ field: key, value: value }));
    };

    const handleRemoveFilter = (key: keyof IBooking, key2?: keyof IBooking) => {
        const updatedFilters = { ...filters };
        delete updatedFilters[key];
        !!key2 && delete updatedFilters[key2];
        dispatch(updateFilters(updatedFilters));
    };

    const handleResetFilters = () => {
        dispatch(clearFilters(filters));
    };

    const handleAddMultipleURLFilter = (isSelected: boolean, key: keyof PartialIBooking<{ offer: IOffer, proposals: IProposal }>, value: string | number | boolean | any) => {
        if (!parsedFilters[key]) {
            parsedFilters[key] = [];
        }

        // Convert to Set for easier manipulation
        const filterSet = new Set(parsedFilters[key]);

        if (isSelected) {
            // If the value is already selected, remove it
            filterSet.delete(value);
        } else {
            // Otherwise, add it to the filter set
            filterSet.add(value);
        }

        // Update filters object
        parsedFilters[key] = Array.from(filterSet);

        // Convert filters back to JSON and update URL
        if (parsedFilters[key].length === 0) {
            delete parsedFilters[key]; // Remove key if empty
        }

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('filters', JSON.stringify(parsedFilters));

        if (newSearchParams.has('currentPage')) {
            newSearchParams.set('currentPage', '1');
        }

        setSearchParams(newSearchParams);
    };

    const handleAddSingleURLFilter = (key: keyof PartialIBooking<{ offer: IOffer, proposals: IProposal }>, value: string | number | boolean | any) => {
        // Set the single filter value
        parsedFilters[key] = value;

        // Convert filters back to JSON and update URL
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('filters', JSON.stringify(parsedFilters));

        if (newSearchParams.has('currentPage')) {
            newSearchParams.set('currentPage', '1');
        }

        setSearchParams(newSearchParams);
    };

    const handleRemoveURLfilter = (key: keyof PartialIBooking<{ offer: IOffer, proposals: IProposal }>) => {
        if (parsedFilters[key]) {
            delete parsedFilters[key];

            const newSearchParams = new URLSearchParams(searchParams);
            if (Object.keys(parsedFilters).length === 0) {
                newSearchParams.delete('filters');
            } else {
                newSearchParams.set('filters', JSON.stringify(parsedFilters));
            }

            setSearchParams(newSearchParams);

        }
    }

    const handleResetURLFilters = () => {
        searchParams.delete('filters');
        setSearchParams(searchParams);
    };

    useEffect(() => {
        handleResetFilters();
    }, []);

    return { filters, handleAddMultipleFilter, handleAddSingleFilter, handleRemoveFilter, handleResetFilters, parsedFilters, handleAddMultipleURLFilter, handleResetURLFilters, handleAddSingleURLFilter, handleRemoveURLfilter };
}

export default useFilter;
