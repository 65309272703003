import { Outlet, useLoaderData, useLocation, useNavigate, useNavigation, useParams, useSearchParams } from "react-router-dom";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { useModalDispatcher } from "../../../hooks/useModalDispatcher";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import useScreenSize from "../../../hooks/useScreenSize";
import { useEffect, useState } from "react";
import useFilter from "../../../hooks/useFilters";
import BookingTable, { SortDirection, TableHeader } from "./BookingTable";
import useFetch from "../../../hooks/useFetch";
import { HttpMethod, apiService } from "../../../services/apiService";
import { Paginated } from "../../../types/interfaces/paginated.interface";
import { PartialIBooking } from "../../../types/interfaces/booking.interface";
import { IOffer } from "../../../types/interfaces/offer.interface";
import { replaceOffers } from "../../../store/offer/offerSlice";
import moment from "moment";
import downloadCsv from "../../../utils/downloadCsv";
import { BOOKING_STATUS } from "../../../types/enum/bookingStatus";
import PageTamplate from "../../../components/PageTamplate";
import { FilterIcon } from "../../../utils/icons/FilterIcon";
import { colorPalette } from "../../../types/enum/colorPalette";
import InputForm from "../../../components/InputForm";
import { AddIcon } from "../../../utils/icons/AddIcon";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { DownloadIcon } from "../../../utils/icons/DownloadIcon";
import BookingFilter from "./BookingFilter";
import BookingTableMobile from "./BookingTableMobile";
import { IProposal } from "../../../types/interfaces/proposal.interface";
import { IBookingRequest } from "../../../types/interfaces/bookingRequest.interface";

export interface IBookingPagination {
    pagination: Paginated<PartialIBooking<{ offer: IOffer; proposals: IProposal, bookingRequest: IBookingRequest }>>
}

const Bookings = () => {
    const response = useLoaderData() as IBookingPagination;
    const dispatch = useAppDispatch();
    const { paginationData } = useAppSelector(state => state.booking);
    const { offers } = useAppSelector(state => state.offer);
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible();
    const { parsedFilters: filters, handleAddMultipleURLFilter, handleAddSingleURLFilter } = useFilter();
    const { showErrorModal } = useModalDispatcher();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const location = useLocation();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const screenSize = useScreenSize();

    const [searchTerm, setSearchTerm] = useState<string>('');
    let sortData = JSON.parse(searchParams.get('sort') || '{}');
    const [bookingData, setBookingData] = useState<IBookingPagination | undefined>(response);

    const { loading: loadingOffers } = useFetch<IOffer[]>(
        () => apiService(HttpMethod.POST, '/offer/list'),
        (fetchedData) => dispatch(replaceOffers(fetchedData))
    );

    useEffect(() => {
        let check = false;
        if (!searchParams.has('bookingDate')) {
            searchParams.set('bookingDate', moment().utc(true).toISOString());
            check = true;
        }
        if (!searchParams.has('pageSize')) {
            searchParams.set('pageSize', '15');
            check = true;
        }
        if (!searchParams.has('currentPage')) {
            searchParams.set('currentPage', '1');
            check = true;
        }
        if (!searchParams.has('sort')) {
            searchParams.set('sort', JSON.stringify({ createdAt: 'DESC' }));
            check = true;
        }

        if (check) {
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    useEffect(() => {
        if (!!response)
            setBookingData(response);
    }, [response]);

    const headers: TableHeader[] = (location.pathname.includes('details') || location.pathname.includes('create') || location.pathname.includes('update')) || isComponentVisible
        ? [
            { key: 'surname', label: 'Nome' },
            { key: 'phoneNumber', label: 'Telefono' },
            { key: 'offer', label: 'Servizio' },
            { key: 'people', label: 'Persone' }
        ]
        : [
            { key: 'surname', label: 'Nome' },
            { key: 'phoneNumber', label: 'Telefono' },
            { key: 'people', label: 'Persone' },
            { key: 'offer', label: 'Servizio' },
            { key: 'proposals', label: 'Proposte' },
            { key: 'notes', label: 'Note' },
            { key: 'bookingStatus', label: 'Stato' }
        ]

    const handleExportCsv = async () => {
        try {
            const response: any = await apiService(HttpMethod.POST, '/booking/export-csv', { pagination: { pageSize: paginationData.pageSize, currentPage: paginationData.currentPage, filters: { ...filters, bookingDate: searchParams.get('bookingDate') } } });

            if (response.errorCode) {
                throw response;
            }

            if (response) {
                downloadCsv(response, `prenotazioni-${moment(searchParams.get('bookingDate')).format('DD-MM-YYYY')}.csv`)
            }
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleOpenDetails = (id?: string) => {
        let currentFilters;

        if (searchParams.get('filters'))
            currentFilters = JSON.parse(searchParams.get('filters') || '{}');

        if (location.pathname.includes('details')) {
            if (params.id === id) {
                // if filters is not null preserve the filters
                currentFilters
                    ? navigate('details/' + id + '?bookingDate=' + searchParams.get('bookingDate') + '&filters=' + JSON.stringify(currentFilters))
                    : navigate('details/' + id + '?bookingDate=' + searchParams.get('bookingDate'));
            } else {
                setIsComponentVisible(false);

                // if filters is not null preserve the filters
                currentFilters
                    ? navigate('details/' + id + '?bookingDate=' + searchParams.get('bookingDate') + '&filters=' + JSON.stringify(currentFilters))
                    : navigate('details/' + id + '?bookingDate=' + searchParams.get('bookingDate'));
            }
        } else {
            setIsComponentVisible(false);

            // if filters is not null preserve the filters
            currentFilters
                ? navigate('details/' + id + '?bookingDate=' + searchParams.get('bookingDate') + '&filters=' + JSON.stringify(currentFilters))
                : navigate('details/' + id + '?bookingDate=' + searchParams.get('bookingDate'));
        }
    };

    const handleOpenCreate = () => {
        if (location.pathname.endsWith('/create')) {
            navigate('/dashboard/bookings?bookingDate=' + searchParams.get('bookingDate'), { replace: true });
        } else {
            setIsComponentVisible(false);
            navigate('/dashboard/bookings/create' + '?bookingDate=' + searchParams.get('bookingDate'), { replace: true });
        }
    };

    const handleUpdateBookingStatus = async (value: BOOKING_STATUS, id: string) => {
        try {
            const response = await apiService(HttpMethod.PUT, '/booking/update-status/' + id, { status: value });

            if (response.errorCode) {
                throw response;
            }

            if (response) {
                const updatedBooking = response;
                const updatedBookings = bookingData?.pagination.data?.map((booking) => booking._id === updatedBooking._id ? { ...booking, bookingStatus: updatedBooking.bookingStatus } : booking);

                if (!!updatedBookings) {
                    setBookingData((prev) => ({ ...prev, pagination: { ...prev?.pagination!, data: updatedBookings } }));
                }
            }
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    // Function to handle the header click event and update the sorting state
    const handleSortBooking = (sortKey: string) => {

        if (sortData[sortKey] !== undefined) {
            // Toggle the sort direction if the key already exists
            sortData[sortKey] = sortData[sortKey] === 'ASC' ? 'DESC' : 'ASC';
        } else {
            // Set the new sort key and default sort direction to 'ASC'
            sortData = { [sortKey]: 'ASC' };
        }

        // Update the URL with the new sort data
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.set('sort', JSON.stringify(sortData));
        setSearchParams(newSearchParams);
    };

    const renderOffersFilter = () => {
        return offers.map((offer: IOffer, index: number) => {
            if (offer.isEnabled === false) return null;

            const isOfferSelected = filters.offer && filters.offer.some((o: any) => o === offer._id!);
            return (
                <div key={index} onClick={() => handleAddMultipleURLFilter(isOfferSelected, 'offer', offer._id!)} className={`h-8 font-semibold px-4 flex items-center shadow rounded-2xl capitalize cursor-pointer select-none whitespace-nowrap ${isOfferSelected ? 'bg-primary text-text_white' : 'bg-white text-text'}`}>
                    {offer?.name}
                </div>
            );
        })
    };

    return (
        <PageTamplate classname="overflow-auto">
            <div className='flex max-sm:flex-wrap max-md:flex-col gap-4 w-full md:items-center'>
                <div className='w-full md:w-3/12 flex items-center gap-2 h-8'>
                    <div
                        className={`flex items-center justify-center h-8 px-3 rounded-md cursor-pointer shadow ${isComponentVisible ? 'bg-text' : 'bg-white'}`}
                        onClick={() => {
                            if (location.pathname.includes('details') || location.pathname.endsWith('/create')) {
                                navigate('/dashboard/bookings?bookingDate=' + searchParams.get('bookingDate'), { replace: true });
                            }
                            setIsComponentVisible(!isComponentVisible);
                        }}
                    >
                        <FilterIcon color={isComponentVisible ? colorPalette.White : colorPalette.Black} />
                    </div>

                    <InputForm
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onSearch={() => {
                            if (!!searchTerm || !!filters.surname) {
                                handleAddSingleURLFilter('surname', searchTerm || null)
                            }
                        }}
                        backgroundColor='bg-white'
                        placeholder='Cerca per cognome...'
                        fullWidth
                        customStyle='h-8 rounded-3xl shadow'
                    />

                    {(screenSize === 'sm' || screenSize === 'xs') &&
                        <>
                            <div onClick={handleExportCsv} className='flex items-center justify-center h-8 px-3 rounded-md cursor-pointer shadow bg-text'>
                                <DownloadIcon />
                            </div>
                            <div
                                className='flex items-center justify-center h-8 px-3 rounded-md cursor-pointer shadow bg-text'
                                onClick={handleOpenCreate}
                            >
                                <AddIcon />
                            </div>
                        </>
                    }
                </div>

                {(screenSize === 'sm' || screenSize === 'xs') &&
                    <div className='flex flex-wrap gap-2'>
                        {(loadingOffers) ? <LoadingIndicator wFull={false} label='Caricamento servizi' /> : renderOffersFilter()}
                    </div>
                }

                {(screenSize !== 'sm' && screenSize !== 'xs') &&
                    <div className='flex-1 flex items-center justify-between w-full gap-4'>
                        <div className='flex gap-2'>
                            {(loadingOffers) ? <LoadingIndicator wFull={false} label='Caricamento servizi' /> : renderOffersFilter()}
                        </div>


                        <div className='flex flex-row gap-x-4 items-center'>
                            <div onClick={handleExportCsv} className='text-text_white gap-x-2 font-medium flex justify-center items-center h-8 px-3 rounded-md cursor-pointer shadow bg-text'>
                                <p>Esporta</p>
                                <DownloadIcon />
                            </div>
                            <div
                                onClick={handleOpenCreate}
                                className='flex justify-center items-center h-8 px-3 rounded-md cursor-pointer shadow bg-text'
                            >
                                <AddIcon />
                            </div>

                        </div>
                    </div>}
            </div>

            <div className='flex-1 flex relative'>

                <BookingFilter isOpen={isComponentVisible} />

                {(bookingData?.pagination.data && bookingData?.pagination.data.length > 0)
                    ? (screenSize === 'sm' || screenSize === 'xs')
                        ? !(location.pathname.includes('/create') || location.pathname.includes('details') || location.pathname.includes('update') || isComponentVisible) &&
                        <BookingTableMobile
                            bookingsData={bookingData.pagination.data}
                            onRowClick={handleOpenDetails}
                            totalPages={bookingData?.pagination?.totalPages!}
                        />
                        : <BookingTable
                            headers={headers}
                            rows={bookingData.pagination.data}
                            onHeaderClick={handleSortBooking}
                            onRowClick={handleOpenDetails}
                            onStatusChange={handleUpdateBookingStatus}
                            sorting={sortData}
                            totalPages={bookingData?.pagination?.totalPages!}
                            dataLoading={navigation.state === 'loading'}
                        />
                    : (screenSize === 'sm' || screenSize === 'xs') && (location.pathname.includes('/create') || location.pathname.includes('details') || location.pathname.includes('update') || isComponentVisible)
                        ? null
                        : <p className='flex m-auto items-center justify-center font-medium text-sm text-darkGray'> Nessuna prenotazione da mostrare</p>}

                <div
                    className={`h-full bg-white rounded-lg shadow-sm flex flex-col justify-between gap-4 transition-all duration-300 ease-in-out 
                        ${(location.pathname.includes('/create') || location.pathname.includes('details') || location.pathname.includes('update'))
                            ? `max-md:w-full md:w-1/3 opacity-100 px-6 py-4 ${(screenSize !== 'sm' && screenSize !== 'xs') && 'ml-4'}`
                            : 'w-0 opacity-0 overflow-hidden'}`}
                >
                    <Outlet />
                </div>
            </div>
        </PageTamplate>
    );
}

export default Bookings