import React from 'react'
import { IProduct } from '../../../../../types/interfaces/product.inteface';
import { IProposal } from '../../../../../types/interfaces/proposal.interface';
import useComponentVisible from '../../../../../hooks/useComponentVisible';
import { ArrowRight } from '../../../../../utils/icons/ArrowIcon';

interface IProps {
    proposal: IProposal<string, string, IProduct>
}

const MenuProposalAccordion: React.FC<IProps> = ({ proposal }) => {
    const { ref, isComponentVisible: menuOpen, setIsComponentVisible: setMenuOpen } = useComponentVisible(false);

    if (!proposal.products?.length) return null

    return (
        <div className='flex flex-col w-full mt-4'>
            <div
                ref={ref}
                className='flex gap-4 w-full whitespace-nowrap'
                onClick={(e) => {
                    e.stopPropagation();
                    setMenuOpen(!menuOpen);
                }}
            >
                <p className='text-text text-base font-semibold cursor-pointer'>
                    Menù
                </p>
                <div className={`ease-in-out duration-300 transition-all ${menuOpen ? 'rotate-90' : ''}`}>
                    <ArrowRight />
                </div>
            </div>


            {(menuOpen) &&
                <div className='flex flex-col w-full'>
                    {proposal.products?.map((product, index) => (
                        <p key={index} className='text-text text-xs'>{product.name}</p>
                    ))}
                </div>
            }
        </div>
    )
}

export default MenuProposalAccordion