import { IUser } from "../../types/interfaces/auth.interface";
import { IStructure } from "../../types/interfaces/structure.interface";
import { IUserStructure } from "../../types/interfaces/userStructure.interface";

export const accountState: IAccountState = {
    user: undefined,
    userStructure: { structures: [], user: undefined, userStructure: undefined }
};

interface IAccountState {
    user: IUser | undefined;
    userStructure: { structures: IStructure[], user: IUser | undefined, userStructure: IUserStructure | undefined }
}