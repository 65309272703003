import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import useCookie from "../../hooks/useCookie";

export const LoginLayout = () => {
    const { userAuth } = useAppSelector(state => state.auth);
    useCookie({ userData: 'user_cookie' });

    if (userAuth) {
        return <Navigate to="/structure" />;
    }

    return <Outlet />
};