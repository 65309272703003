export const SEVERITY_TYPE = {
    100: 'info',                         // Informational message
    101: 'additional_details',           // Informational message with additional details
    200: 'success',                      // Successful operation
    201: 'created',                      // Successful operation resulting in the creation of a new resource
    202: 'accepted',                     // Successful request has been accepted for processing
    300: 'warning',                      // Warning or non-critical issue
    400: 'bad_request',                  // Bad request or invalid input provided by the user
    401: 'unauthorized',                 // Unauthorized access or authentication-related issue
    403: 'forbidden',                    // Forbidden request, server refuses to authorize it
    404: 'not_found',                    // Resource not found
    429: 'too_many_requests',            // Too many requests within a given amount of time
    500: 'critical',                     // Critical error or server issue
    503: 'service_unavailable',          // Critical error, the server is currently unavailable
} as const;

// Helper function to translate the number to the corresponding SEVERITY_TYPE string
export const getSeverityStringFromNumber = (severityNumber: keyof typeof SEVERITY_TYPE): typeof SEVERITY_TYPE[keyof typeof SEVERITY_TYPE] => {
    if (SEVERITY_TYPE[severityNumber]) {
        return SEVERITY_TYPE[severityNumber];
    }

    return 'info';
};

export const getMessageFromResponseStatus = (status: keyof typeof SEVERITY_TYPE): string => {
    switch (status) {
        case 100:
            return 'Messaggio informativo: La risposta contiene informazioni.';
        case 101:
            return 'Messaggio informativo con dettagli aggiuntivi: La risposta contiene informazioni e dettagli aggiuntivi.';
        case 200:
            return 'Operazione riuscita: L\'azione è stata completata con successo.';
        case 201:
            return 'Operazione riuscita: Creazione completata con successo.';
        case 202:
            return 'Richiesta accettata e in elaborazione: La richiesta è stata accettata e il server sta elaborando l\'azione.';
        case 300:
            return 'Avviso o problema non critico: La risposta contiene un avviso o un problema non critico.';
        case 400:
            return 'Richiesta errata o input non valido: La richiesta inviata al server è errata o contiene dati non validi.';
        case 401:
            return 'Accesso non autorizzato o problema di autenticazione: L\'utente non è autorizzato ad accedere all\'azione o è necessaria l\'autenticazione.';
        case 403:
            return 'Richiesta vietata: Il server rifiuta di autorizzare la richiesta, l\'azione è vietata.';
        case 404:
            return 'Elemento non trovato: L\'elemento richiesto non è stato trovato.';
        case 429:
            return 'Troppe richieste in un dato intervallo di tempo: Il server ha ricevuto troppe richieste dalla stessa origine in un breve periodo di tempo.';
        case 500:
            return 'Errore critico o problema del server: Si è verificato un errore critico o un problema interno del server.';
        case 503:
            return 'Errore critico, il server è attualmente non disponibile: Il server è momentaneamente non disponibile, riprova più tardi.';
        default:
            return 'Errore sconosciuto, riprova più tardi.';
    }
};

export const getModalColor = (severity: typeof SEVERITY_TYPE[keyof typeof SEVERITY_TYPE]) => {
    switch (severity) {
        case "success":
            return "bg-green-400";
        case "warning":
            return "bg-yellow-400";
        case "bad_request":
            return "bg-red-400";
        case "info":
            return "bg-blue-400";
        case "additional_details":
            return "bg-purple-400";
        case "created":
            return "bg-indigo-400";
        case "accepted":
            return "bg-orange-400";
        case "critical":
            return "bg-red-800";
        case "forbidden":
            return "bg-red-600";
        case "not_found":
            return "bg-red-500";
        case "service_unavailable":
            return "bg-red-300";
        case "too_many_requests":
            return "bg-yellow-600";
        case "unauthorized":
            return "bg-yellow-400";
        default:
            return "bg-gray-400";
    }
};
