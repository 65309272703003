export enum REQUEST_BOOKING_STATUS {
    PENDING,
    ACCEPTED,
    UPDATED,
    REJECTED,
    CANCELLED
};

const requestBookingStatusMapping = {
    PENDING: REQUEST_BOOKING_STATUS.PENDING,
    ACCEPTED: REQUEST_BOOKING_STATUS.ACCEPTED,
    UPDATED: REQUEST_BOOKING_STATUS.UPDATED,
    REJECTED: REQUEST_BOOKING_STATUS.REJECTED,
    CANCELLED: REQUEST_BOOKING_STATUS.CANCELLED
};

export const requestBookingStatusDbMapping = {
    [REQUEST_BOOKING_STATUS.PENDING]: 'In Corso',
    [REQUEST_BOOKING_STATUS.ACCEPTED]: 'Accettata',
    [REQUEST_BOOKING_STATUS.UPDATED]: 'Modificata',
    [REQUEST_BOOKING_STATUS.REJECTED]: 'Rifiutata',
    [REQUEST_BOOKING_STATUS.CANCELLED]: 'Cancellata',
};

export const mapRequestBookingStatus = (value: any) => {
    return requestBookingStatusDbMapping[value as REQUEST_BOOKING_STATUS] || value;
};

export const requestBookingStatusOptions: { label: string; value: REQUEST_BOOKING_STATUS }[] = Object.values(requestBookingStatusMapping).map(key => ({
    label: mapRequestBookingStatus(Number(key) as REQUEST_BOOKING_STATUS) as string,
    value: Number(key) as REQUEST_BOOKING_STATUS,
}));

export const requestBookingStatusBgColorMapping = {
    0: 'bg-primary',
    1: 'bg-primary',
    2: 'bg-warning',
    3: 'bg-error',
    4: 'bg-error'
};

export const requestBookingStatusTextColorMapping = {
    0: 'text-primary',
    1: 'text-primary',
    2: 'text-warning',
    3: 'text-error',
    4: 'text-error',
};