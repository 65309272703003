import React, { useEffect, useState } from 'react';
import useFilter from '../../../hooks/useFilters';
import { DeleteBucketIcon } from '../../../utils/icons/DeleteBucketIcon';
import { bookingStatusOptions } from '../../../types/enum/bookingStatus';
import RadioButton from '../../../components/RadioButton';
import { cakeTypeOptions } from '../../../types/enum/cakeType';
import { apiHandler, getHandler } from '../../../services/apiHandler';
import useFetch from '../../../hooks/useFetch';
import { IOffer } from '../../../types/interfaces/offer.interface';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Text from '../../../components/Text';

interface IProps {
    isOpen: boolean;
}

const BookingFilter: React.FC<IProps> = ({ isOpen }) => {
    const { parsedFilters: filters, handleAddMultipleURLFilter, handleAddSingleURLFilter, handleResetURLFilters, handleRemoveURLfilter } = useFilter();
    const [selectedOffer, setSelectedOffer] = useState('');

    const { data: offer, loading: offerLoading } = useFetch<IOffer>(
        () => apiHandler.get<getHandler>('/offer/get/' + selectedOffer),
        undefined,
        [selectedOffer],
        !!selectedOffer
    );

    useEffect(() => {
        if (filters.offer?.length === 1 && selectedOffer !== filters.offer[0]) {
            setSelectedOffer(filters.offer[0]);
        } else if (filters.offer?.length !== 1) {
            setSelectedOffer('');
        }
    }, [filters]);

    useEffect(() => {
        if (!selectedOffer && !!filters.proposals) {
            handleRemoveURLfilter('proposals');
        }
    }, [selectedOffer]);

    return (
        <div
            className={`
                h-full bg-white rounded-lg shadow-sm flex flex-col gap-4 transition-all duration-300 ease-in-out select-none 
                ${isOpen
                    ? 'max-md:w-full md:w-1/4 opacity-100 px-6 py-4 mr-4'
                    : 'w-0 opacity-0 overflow-hidden'
                }
            `}
        >
            <div className='flex items-center justify-between'>
                <p className='text-primary text-2xl font-bold md:whitespace-nowrap'>Filtri</p>
                <div title='Reset filtri' className='cursor-pointer' onClick={handleResetURLFilters}>
                    <DeleteBucketIcon />
                </div>
            </div>

            <div className='flex flex-col gap-1'>
                <Text title='Stato' label=" " />
                {bookingStatusOptions.map((status, index) => {
                    const isSelected = filters.bookingStatus && filters.bookingStatus.some((filterStatus: any) => filterStatus === status.value);
                    return <RadioButton key={index} label={status.label} checked={isSelected} onChange={() => handleAddMultipleURLFilter(isSelected, 'bookingStatus', status.value)} />
                })}
            </div>

            <div className='flex flex-col gap-1'>
                <Text title='Torta' label=" " />
                {cakeTypeOptions.map((cake, index) => {
                    const isSelected = filters.cakeType && filters.cakeType.some((filterCake: any) => filterCake === cake.value);
                    return <RadioButton key={index} label={cake.label} checked={isSelected} onChange={() => handleAddMultipleURLFilter(isSelected, 'cakeType', cake.value)} />
                })}
            </div>

            <div className='flex flex-col gap-1'>
                <Text title='Filtri speciali' label=" " />
                <RadioButton label='Da definire' checked={!!filters?.proposals && filters?.proposals?.length === 0} onChange={() => {
                    if (!!filters?.proposals && filters?.proposals?.length === 0) {
                        handleAddMultipleURLFilter(true, 'proposals', []);
                    } else {
                        handleAddSingleURLFilter('proposals', []);
                    }
                }} />

                <RadioButton label='Importanti' checked={!!filters.isImportant} onChange={() => handleAddMultipleURLFilter(!!filters.isImportant, 'isImportant', true)} />
            </div>

            {!!offer?.proposals?.length
                ? offerLoading
                    ? <LoadingIndicator label='Caricamento filtri' />
                    : filters?.offer?.length === 1 &&
                    <div className='flex flex-col gap-1'>
                        <Text title='Proposte' label=" " />
                        {offer?.proposals?.filter((proposal) => !proposal.deleted).map((proposal, index) => {
                            const isSelected = filters.proposals && filters.proposals.some((filterSetting: any) => filterSetting === proposal._id);
                            return <RadioButton key={index} label={proposal.name} checked={isSelected} onChange={() => handleAddMultipleURLFilter(isSelected, 'proposals', proposal?._id!)} />;
                        })}
                    </div>
                : null
            }
        </div>
    )
};

export default BookingFilter;
