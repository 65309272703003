export enum USER_ROLES {
    NOROLE,
    KITCHEN,
    USER,
    ADMIN
}

const rolesTranslated = [
    "Nessun Ruolo",
    "Cucina",
    "Utente",
    "Admin"
];

export function getRolesEnum(roles: number[]): string[] {
    return roles.map((index) => rolesTranslated[index]);
}