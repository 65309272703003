import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { BOOKING_STATUS, bookingStatusColorMapping, bookingStatusOptions } from '../../../types/enum/bookingStatus';
import { HttpMethod, apiService } from '../../../services/apiService';
import { PartialIBooking } from '../../../types/interfaces/booking.interface';
import { IOffer } from '../../../types/interfaces/offer.interface';
import { IProposal } from '../../../types/interfaces/proposal.interface';
import HoverContent from '../../../components/HoverContent';
import { AddIcon } from '../../../utils/icons/AddIcon';
import { QuestionMarkIcon } from '../../../utils/icons/QuestionMarkIcon';
import { NoteIcon } from '../../../utils/icons/NoteIcon';
import { CakeIcon } from '../../../utils/icons/CakeIcon';
import { CAKE_TYPE, mapCakeType } from '../../../types/enum/cakeType';
import { NextPaginationIcon, PreviousPaginationIcon } from '../../../utils/icons/PaginationIcon';
import { IBookingRequest } from '../../../types/interfaces/bookingRequest.interface';
import DropdownMenu, { Option } from '../../../components/DropdownMenu';
import { AutoBookingType } from '../../../types/enum/autoBookingType.enum';
import { colorPalette } from '../../../types/enum/colorPalette';
import { useSearchParams } from 'react-router-dom';


interface IProps {
    bookingsData: PartialIBooking<{ proposals: IProposal, offer: IOffer, bookingRequest: IBookingRequest }>[];
    onRowClick: (id?: string) => void;
    totalPages: number;
    bgColor?: string;
}

const BookingTableMobile: React.FC<IProps> = ({ bookingsData, onRowClick, totalPages, bgColor = 'bg-white' }) => {
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const paginationOptions = [15, 30, 50];

    const [bookings, setBookings] = useState<PartialIBooking<{ proposals: IProposal, offer: IOffer, bookingRequest: IBookingRequest }>[]>(bookingsData);

    useEffect(() => {
        if (bookingsData) {
            setBookings(bookingsData);
        }
    }, [bookingsData]);

    const handleUpdateBookingStatus = async (value: BOOKING_STATUS, id: string) => {
        const response = await apiService(HttpMethod.PUT, '/booking/update-status/' + id, { status: value });
        if (response) {
            const updatedBooking = response;

            const updatedBookings = bookings?.map((booking) => booking._id === updatedBooking._id ? { ...booking, bookingStatus: updatedBooking.bookingStatus } : booking);

            setBookings(updatedBookings);

            setIsComponentVisible(false);
        }
    };

    // Function to handle the rows to show per page
    const handleButtonClick = (size: number) => {
        searchParams.set('pageSize', size.toString());
        searchParams.set('currentPage', '1');
        setSearchParams(searchParams);
    };

    // Functions to handle the pagination logics
    const handleNextPage = () => {
        searchParams.set('currentPage', (Number(searchParams.get('currentPage')) + 1).toString());
        setSearchParams(searchParams);
    };

    const handlePrevPage = () => {
        searchParams.set('currentPage', (Number(searchParams.get('currentPage')) - 1).toString());
        setSearchParams(searchParams);
    };

    const generateOptions = (id: string): Option[] => {
        return [
            {
                disabled: false,
                label: <p>Cambia stato</p>,
                onClick: () => {
                    setIsComponentVisible(true);
                },
                closeOnClick: false
            },
            {
                disabled: false,
                label: <p>Dettagli</p>,
                onClick: () => onRowClick(id)
            }
        ]
    };

    const statusOption = (booking: PartialIBooking<{ proposals: IProposal, offer: IOffer, bookingRequest: IBookingRequest }>): Option[] => {
        const options: Option[] = bookingStatusOptions
            .filter(status => status.value !== booking.bookingStatus)
            .map((option: { label: string, value: BOOKING_STATUS }) => (
                {
                    label: <p>{option.label}</p>,
                    disabled: false,
                    onClick: () => handleUpdateBookingStatus(option.value, booking._id!)
                }
            ));

        return options;
    };

    return (
        <div className='flex flex-col h-full w-full gap-3 select-none'>
            <div className='grid grid-cols-5 gap-1 uppercase text-sm px-2'>
                <p className='col-span-2 text-darkGray font-semibold whitespace-nowrap text-'>Cliente</p>
                <p className='col-span-2 text-darkGray font-semibold whitespace-nowrap text-center'>Servizio</p>
                <p className='text-darkGray font-semibold whitespace-nowrap text-center'>Turno-Pax</p>
            </div>
            {
                bookings?.map((booking: PartialIBooking<{ offer: IOffer, proposals: IProposal, bookingRequest: IBookingRequest }>, index: number) => {
                    const statusColor = bookingStatusColorMapping[booking.bookingStatus];
                    const isOnlineBooking = booking.bookingRequest?.autoBookingType === AutoBookingType.AUTOMATIC

                    return (<DropdownMenu
                        key={index}
                        options={isComponentVisible ? statusOption(booking) : generateOptions(booking._id!)}
                        toggleButton={
                            <div onClick={() => setIsComponentVisible(false)} className={`w-full relative grid grid-cols-5 gap-1 gap-x-4 shadow text-base md:text-xs ${bgColor} p-2 py-1 rounded-md cursor-pointer`}>
                                <div className='flex items-center gap-2 absolute top-0 left-0 -translate-y-1/2 -translate-x-2'>
                                    <div className={`w-4 h-4 rounded-full ${statusColor}`} />
                                    {!!isOnlineBooking && <p style={{ fontSize: "10px", lineHeight: "16px" }} className='bg-info rounded shadow font-medium text-text_white px-1 flex items-center'>Online</p>}
                                </div>

                                <p className='col-span-2 text-darkGray font-bold capitalize w-full truncate text-left'>{booking.surname} {booking.name}</p>
                                <p className='col-span-2 text-darkGray font-medium capitalize w-full truncate text-center'>{booking.offer.name}</p>
                                <p className='text-darkGray font-medium capitalize w-full truncate text-center'>{booking.shift}</p>

                                <div className='col-span-5 border-b border-dashed w-full border-darkGray' />

                                <p className='col-span-2 text-darkGray font-medium w-full truncate text-left'>{booking.phoneNumber}</p>

                                <div className='col-span-2 grid grid-cols-4 gap-1 items-center'>
                                    <HoverContent isActive={!booking.proposals.length} title='Offerte da definire' content={"Le opzioni di servizio per questa prenotazione non sono ancora state definite.\nTi invitiamo a definirle al più presto."} element={<QuestionMarkIcon color={!booking.proposals.length ? colorPalette.Primary : colorPalette.MediumGray} size='18' />} />
                                    <HoverContent isActive={booking.sharedBookingsNew.length > 0} title='Servizi extra' content={`In questa prenotazione sono inclusi anche ${booking.sharedBookingsNew.length} servizi aggiuntivo/i oltre a quello corrente.`} element={<AddIcon color={booking.sharedBookingsNew.length > 0 ? colorPalette.Primary : colorPalette.MediumGray} size='30' />} />
                                    <HoverContent isActive={!!booking.notes || !!booking.clientNotes} title='Note' content={booking.notes} element={<NoteIcon color={!!booking.notes || !!booking.clientNotes ? colorPalette.Primary : colorPalette.MediumGray} size='18' />} />
                                    <HoverContent isActive={booking.cakeType !== CAKE_TYPE.NO_CAKE} title='Dolce' content={mapCakeType(booking.cakeType) + '\n' + booking.cakeNote} element={<CakeIcon color={booking.cakeType !== CAKE_TYPE.NO_CAKE ? colorPalette.Primary : colorPalette.MediumGray} height='18' width='18' />} />
                                </div>

                                <div className='text-darkGray font-medium flex items-center justify-center w-full gap-1'>
                                    <p>{booking.people}</p>
                                    <HoverContent isActive={!!booking.isPeopleVariable} title='Persone da definire' content={"I partecipanti per questa prenotazione possono variare.\nSi prega di contattare il prenotante per definire il numero esatto."} element={<QuestionMarkIcon color={!!booking.isPeopleVariable ? colorPalette.Primary : colorPalette.MediumGray} />} />
                                </div>
                            </div>
                        }
                    />)
                })
            }

            {/* Pagination Buttons */}
            <div className="flex gap-8 justify-between items-center mt-4">
                <div className="flex gap-2 items-center">
                    {paginationOptions.map((item, index) => (
                        <div key={index}
                            onClick={() => handleButtonClick(item)}
                            className={`px-4 py-1 rounded-full shadow font-semibold cursor-pointer ${item === Number(searchParams.get('pageSize')) ? 'bg-primary text-text_white' : 'bg-white text-text'}`}
                        >
                            {item}
                        </div>
                    ))}
                </div>

                <div className="flex gap-1 items-center whitespace-nowrap">
                    <button
                        onClick={handlePrevPage}
                        disabled={Number(searchParams.get('currentPage')) === 1}
                        className="rounded p-1 disabled:opacity-25"
                    >
                        <PreviousPaginationIcon />
                    </button>
                    <div className='px-2 py-1 bg-white rounded-full shadow'>
                        <p className='text-text font-semibold'>{Number(searchParams.get('currentPage'))} / {totalPages}</p>
                    </div>
                    <button
                        onClick={handleNextPage}
                        disabled={Number(searchParams.get('currentPage')) === totalPages}
                        className="rounded p-1 disabled:opacity-25"
                    >

                        <NextPaginationIcon />
                    </button>
                </div>
            </div>
        </div >
    )
}

export default BookingTableMobile