import React, { useEffect, useState } from 'react';
import PageTamplate from '../../components/PageTamplate';
import { useAppSelector } from '../../store/store';
import useFetch from '../../hooks/useFetch';
import useFilter from '../../hooks/useFilters';
import CardContainer from '../../components/CardContainer';
import LoadingIndicator from '../../components/LoadingIndicator';
import { PartialIBooking } from '../../types/interfaces/booking.interface';
import { IOffer, IOfferCapacity, IProposalCapacity } from '../../types/interfaces/offer.interface';
import { CAKE_TYPE, cakeTypeOptions, mapCakeType } from '../../types/enum/cakeType';
import Modal from '../../components/Modal';
import CustomButton from '../../components/CustomButton';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import { colorPalette } from '../../types/enum/colorPalette';
import useComponentVisible from '../../hooks/useComponentVisible';
import RadioButton from '../../components/RadioButton';
import { DeleteBucketIcon } from '../../utils/icons/DeleteBucketIcon';
import { CakeIcon } from '../../utils/icons/CakeIcon';
import { Paginated } from '../../types/interfaces/paginated.interface';
import { HttpMethod, apiService } from '../../services/apiService';
import { IProposal } from '../../types/interfaces/proposal.interface';
import { useLoaderData } from 'react-router-dom';
import { IEvent } from '../../types/interfaces/event.interface';
import { FilterIcon } from '../../utils/icons/FilterIcon';
import SelectForm from '../../components/SelectForm';

const KitchenView: React.FC = () => {
    const offers = useLoaderData() as IOffer[];
    const { globalDate } = useAppSelector(state => state.booking);
    const { filters, handleAddMultipleFilter, handleAddSingleFilter, handleRemoveFilter } = useFilter();
    const { showErrorModal } = useModalDispatcher();
    const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible();

    const [bookings, setBookings] = useState<PartialIBooking<{ offer: IOffer, proposals: IProposal }>[]>([]);
    const [dataPage, setDataPage] = useState(1);
    const [selectedOffer, setSelectedOffer] = useState<IOffer>();
    const [isModal, setIsModal] = useState<{ state: boolean, source: PartialIBooking<{ offer: IOffer, proposals: IProposal }> | undefined, content: string | undefined }>({ state: false, source: undefined, content: undefined });

    useEffect(() => {
        if (!!offers.length) {
            setSelectedOffer(offers[0]);
            handleAddSingleFilter('offer', offers[0]?._id!);
        }
    }, []);

    const { data, loading: loadingBooking } = useFetch<{ pagination: Paginated<PartialIBooking<{ offer: IOffer, proposals: IProposal }>> }>(
        () => apiService(HttpMethod.POST, '/booking/list', { pagination: { pageSize: 15, currentPage: 1, filters: { ...filters, bookingStatus: [0, 1, 2], bookingDate: globalDate }, sort: { createdAt: 'DESC' } } }),
        (data) => {
            setDataPage(1);
            setBookings(data.pagination.data);
        },
        [globalDate, selectedOffer, filters],
        !!globalDate && !!selectedOffer
    );

    const { data: offerCapacity } = useFetch<IOfferCapacity>(
        () => apiService(HttpMethod.GET, '/offer/disponibility', undefined, { offerId: selectedOffer?._id, date: globalDate, numberOfPeople: 0 }),
        undefined,
        [selectedOffer, globalDate],
        !!selectedOffer
    );

    const { data: proposalsCapacity, loading: loadingProposals } = useFetch<IProposalCapacity[]>(
        () => apiService(HttpMethod.GET, '/offer/proposal/disponibility', undefined, { offersId: [selectedOffer?._id], date: globalDate }),
        undefined,
        [selectedOffer, globalDate],
        !!selectedOffer
    );

    const { data: events, loading } = useFetch<{ offerEvent: IEvent | undefined, dailyEvent: IEvent | undefined }>(
        () => apiService(HttpMethod.POST, `/event/public/get/dailyEvent`, { date: globalDate, structureId: selectedOffer?.structureId, offer: selectedOffer?._id }),
        undefined,
        [globalDate],
        !!globalDate && !!selectedOffer
    );

    const fetchMoreData = async () => {
        try {
            const requestPayload = {
                pagination: {
                    pageSize: 15,
                    currentPage: dataPage + 1,
                    filters: { ...filters, bookingStatus: [0, 1, 2], bookingDate: globalDate },
                    sort: { createdAt: 'DESC' },
                },
            };

            setDataPage(dataPage + 1);
            const response = await apiService<{ pagination: Paginated<PartialIBooking<{ offer: IOffer, proposals: IProposal }>> } & { errorCode: string }>(HttpMethod.POST, '/booking/list', requestPayload);

            if (response.errorCode) {
                throw response;
            }

            if (response?.pagination?.data) {
                const moreBookings = bookings.concat(response.pagination.data);
                setBookings(moreBookings);
            }
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleSelectProposal = (isSelected: boolean, proposal?: IProposal) => {
        if (!!proposal) {
            handleAddMultipleFilter(isSelected, 'proposals', proposal._id);
        } else {
            if (isSelected) {
                handleRemoveFilter('proposals')
            } else {
                handleAddSingleFilter('proposals', []);
            }
        }
    };

    return (
        <PageTamplate classname='overflow-auto'>
            {!offers.length
                ? <div className='h-full w-full flex flex-col gap-8 items-center justify-center text-center'>
                    <p className='text-4xl font-semibold'>Oops! Nessun servizio disponibile.</p>
                    <p className='text-xl font-medium'>Crea un nuovo servizio o prova a riattivare quelli disattivati!<br /> Vedrai, appariranno qui in un batter d'occhio!</p>
                </div>
                : <>
                    <div className='flex flex-col gap-2 select-none'>
                        <div className=' w-full md:hidden'>
                            <SelectForm
                                containerClassName='w-full'
                                arrow={false}
                                options={offers
                                    .filter(offer => offer._id !== selectedOffer?._id)
                                    .map(offer => ({ label: offer.name, value: offer }))
                                }
                                selectedOption={offers.find(offer => offer._id === selectedOffer?._id)?.name}
                                setSelectedOption={(e) => {
                                    handleRemoveFilter('proposals');
                                    setSelectedOffer(e.value);
                                    handleAddSingleFilter('offer', e.value._id!);
                                }}
                            />
                        </div>

                        <div className='hidden md:flex overflow-auto -mx-4 px-4 py-2'>
                            {offers.map((offer: IOffer, index: number) => {
                                const isSelected = filters?.offer && String(filters.offer) === offer._id!;

                                return <div key={index}
                                    className={`w-full p-2 text-xl text-center font-semibold cursor-pointer ${isSelected ? 'bg-primary text-white' : 'bg-white text-text'} last:rounded-r-md first:rounded-l-md shadow`}
                                    onClick={() => {
                                        handleRemoveFilter('proposals');
                                        setSelectedOffer(offer);
                                        handleAddSingleFilter('offer', offer._id!);
                                    }}
                                >
                                    {offer.name} <span className='text-sm'>{events?.offerEvent?.offer === offer._id ? "( " + events?.offerEvent?.name + " )" : ''}</span>
                                </div>
                            })}
                        </div>

                        {loadingProposals
                            ? <LoadingIndicator label='Caricamento offerte di servizio' />
                            : <div className='flex gap-2 overflow-auto -mx-4 px-4 py-2'>
                                {proposalsCapacity?.map((proposal, index) => {
                                    const isSelected = !!proposal?.proposal
                                        ? filters.proposals && filters.proposals.some(filter => filter === proposal?.proposal?._id)
                                        : filters.proposals && filters.proposals.length === 0;

                                    return <div key={index} onClick={() => handleSelectProposal(isSelected, proposal.proposal)}
                                        className={`w-full p-1 text-sm text-center font-medium cursor-pointer ${isSelected ? 'bg-primary text-white' : 'bg-white text-text'} rounded-md shadow`}
                                    >
                                        <p className='truncate'>{!!proposal.proposal ? proposal?.proposal?.name : 'Da definire'}</p>
                                        <p className={`${isSelected ? 'text-lightGray' : 'text-darkGray'} text-xs truncate`}>Prenotati {proposal.currentCapacity}</p>
                                    </div>
                                })}
                            </div>
                        }
                    </div>

                    <CardContainer additionalStyle='select-none flex-none gap-4'>
                        {!!offerCapacity &&
                            <div className='flex justify-between md:justify-center h-full font-medium items-center relative'>
                                <p className='font-bold'>Prenotazioni {offerCapacity?.nBookings} | Persone totali {offerCapacity?.peopleBooked}</p>

                                <div onClick={() => setIsComponentVisible(!isComponentVisible)} className={`flex absolute aspect-square right-0 gap-1 items-center justify-center p-2 rounded-md cursor-pointer ${isComponentVisible ? 'bg-primary' : 'bg-white'}`}>
                                    <FilterIcon color={isComponentVisible ? colorPalette.White : colorPalette.Primary} />
                                </div>

                                {isComponentVisible &&
                                    <div ref={ref} className="absolute z-50 top-8 right-0 rounded-md shadow-lg flex justify-center items-center bg-background p-4">
                                        <div className='flex flex-col gap-4'>
                                            <div className='flex w-full justify-between gap-2 items-center'>
                                                <p className="font-semibold text-text text-xl">Torta</p>
                                                <div className='cursor-pointer' onClick={() => handleRemoveFilter('cakeType')}><DeleteBucketIcon width={24} /></div>
                                            </div>
                                            <div className='flex gap-2 flex-col'>
                                                {cakeTypeOptions.map((cake, index) => {
                                                    const isSelected = filters.cakeType && filters.cakeType.some(filterCake => filterCake === cake.value);
                                                    return <RadioButton labelStyle='text-lg' key={index} label={cake.label} checked={isSelected} onChange={() => handleAddMultipleFilter(isSelected, 'cakeType', cake.value)} />
                                                })}
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        }

                        {!loadingBooking
                            ? bookings?.length
                                ? <div>
                                    <div className='md:grid hidden grid-cols-2 md:grid-cols-5 gap-2 p-2 font-semibold text-text text-lg bg-background rounded-t-md'>
                                        <p>Nome</p>
                                        <p>Pax</p>
                                        <p>Proposta/e</p>
                                        <p>Note</p>
                                        <p>Torta</p>
                                    </div>

                                    {bookings.map((booking, index) => {
                                        return <div key={index} className={`grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-y-0 items-center p-2 border-t-2 py-4 md:py-2 border-mediumGray text-text relative `}>
                                            <p className='truncate font-bold'>{booking.surname} {booking.name}</p>
                                            <p className='truncate font-medium'>{booking.people} <span className='md:hidden'>PAX</span></p>
                                            <div className='flex flex-col'>
                                                {!booking.proposals.length
                                                    ? <p>Da Definire</p>
                                                    : booking.proposals.map((proposal, index) => {
                                                        return <div key={index} className='flex gap-2 items-center'>
                                                            <p className='font-medium'>{proposal.proposal.name}</p>
                                                            {!!proposal.quantity && <p className='text-darkGray'>x {proposal.quantity}</p>}
                                                        </div>
                                                    })}
                                            </div>
                                            <p className='text-sm line-clamp-3 cursor-pointer whitespace-pre-line' onClick={() => setIsModal({ state: true, source: booking, content: "Note:\n" + booking.notes })}>{booking.notes}</p>
                                            <p className='text-lg font-medium cursor-pointer hidden md:flex' onClick={() => setIsModal({ state: true, source: booking, content: "Note della torta:\n" + booking.cakeNote })}>
                                                {mapCakeType(booking.cakeType)}<br />
                                                <span className='text-sm font-normal line-clamp-2 whitespace-pre-line'>{booking.cakeNote}</span>
                                            </p>

                                            {booking.cakeType !== CAKE_TYPE.NO_CAKE &&
                                                <div className='absolute md:hidden right-2 top-2' onClick={() => setIsModal({ state: true, source: booking, content: mapCakeType(booking.cakeType) + "\nNote della torta:\n" + booking.cakeNote })}>
                                                    <CakeIcon color={colorPalette.Primary} height='24' width='24' />
                                                </div>
                                            }
                                        </div>
                                    })}

                                    {!!data && (data.pagination.totalItems > bookings.length) && <div className='flex w-full justify-center'>
                                        <CustomButton color='bg-info' label='Carica Altro' className='mt-5' fullWidth={false} onClickHandler={fetchMoreData} />
                                    </div>}

                                </div>
                                : <p className='flex h-full items-center justify-center font-medium text-sm text-darkGray'>Nessuna prenotazione da mostrare</p>
                            : <LoadingIndicator label='Caricamento prenotazioni' />}
                    </CardContainer>

                    <Modal isOpen={isModal.state} onClose={() => setIsModal({ state: false, source: undefined, content: undefined })} className='w-96'>
                        <p className='font-bold text-lg text-center'>{isModal.source?.name} {isModal.source?.surname}</p>
                        <p className='font-medium text-sm text-text whitespace-pre-line'>{isModal.content}</p>
                    </Modal>
                </>}
        </PageTamplate >
    )
}

export default KitchenView