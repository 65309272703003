import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { ArrowDown, ArrowUp } from '../utils/icons/ArrowIcon';

interface CalendarProps {
    setSelectedDate: (...args: any[]) => void;
    handlePickDate?: (...args: any[]) => void;
    selectedDate: string;
    fullHeight?: boolean;
    fullWidth?: boolean;
    minDate?: string;
    maxDate?: string;
    disabledDays?: number[]
};

const Calendar: React.FC<CalendarProps> = ({ setSelectedDate, handlePickDate, selectedDate, fullHeight = true, fullWidth = false, minDate, maxDate, disabledDays }) => {
    const [cloneDate, setCloneDate] = useState(selectedDate)
    const firstDayOfMonth = moment(cloneDate).clone().startOf('month');
    const lastDayOfMonth = moment(cloneDate).clone().endOf('month');
    const weekdays = moment.weekdaysShort(true);

    const handleDateClick = (date: Moment) => {
        if (handlePickDate) {
            handlePickDate(date.utc(true).toISOString());
        }
        setSelectedDate(date.utc(true).toISOString());
    };

    const handlePrevious = () => {
        // If showed month on calendar is equal to month of today, set the selected day as today
        if (moment(cloneDate).clone().subtract(1, 'month').isSame(moment().startOf('day'), 'month')) {
            setCloneDate(moment().startOf('day').utc(true).toISOString());
        } else {
            setCloneDate(moment(cloneDate).clone().subtract(1, 'month').utc(true).toISOString());
        }
    };

    const handleNext = () => {
        // If showed month on calendar is equal to month of today, set the selected day as today
        if (moment(cloneDate).clone().add(1, 'month').isSame(moment().startOf('day'), 'month')) {
            setCloneDate(moment().startOf('day').utc(true).toISOString());
        } else {
            setCloneDate(moment(cloneDate).clone().add(1, 'month').utc(true).toISOString());
        }
    };

    return (
        <div className={`flex flex-col select-none ${(fullHeight) ? 'h-full' : ''} ${(fullWidth) ? 'w-full' : ''} text-text`}>
            <div className="grid grid-cols-7 items-center py-2">
                <p className="col-span-5 font-semibold text-text text-lg capitalize">{moment(cloneDate).format('MMMM YYYY')}</p>
                <div className='flex justify-center text-center cursor-pointer' onClick={handlePrevious}><ArrowDown /></div>
                <div className='flex justify-center text-center cursor-pointer' onClick={handleNext}><ArrowUp /></div>
            </div>

            <div className="grid grid-cols-7 py-1">
                {/* Render day names */}
                {weekdays.map((weekday) => (
                    <div key={weekday} className={"text-base text-center font-bold text-text"}>
                        {weekday.charAt(0).toUpperCase()}
                    </div>
                ))}
            </div>

            <div className="grid grid-cols-7 h-fit gap-2">

                {/* Render empty cells before the start of the month */}
                {Array.from({ length: firstDayOfMonth.weekday() }, (_, index) => (
                    <div key={`empty-${index}`} />
                ))}

                {/* Render date cells */}
                {Array.from({ length: lastDayOfMonth.date() }, (_, index) => {
                    const date = firstDayOfMonth.clone().add(index, 'day');
                    const isBeforeLimit = !!minDate ? date.isBefore(moment(minDate), 'day') : false;
                    const isAfterLimit = !!maxDate ? date.isAfter(moment(maxDate), 'day') : false;
                    const isDisabled = !!disabledDays ? disabledDays?.includes(moment(date).day()) : false;

                    return <button
                        key={date.format()}
                        disabled={isBeforeLimit || isAfterLimit || isDisabled}
                        onClick={() => handleDateClick(date)}
                        className={
                            `flex justify-center items-center aspect-square rounded-full disabled:cursor-not-allowed disabled:text-gray-300 p-1

                            ${!(isBeforeLimit || isAfterLimit)
                            && "hover:bg-primary hover:text-text_white"}

                            ${moment(selectedDate).isSame(date, 'day')
                                ? 'bg-primary text-text_white'
                                : 'bg-transparent text-darkGray'}
                        `}
                    >
                        <p className='text-base font-semibold'>{date.format('D')}</p>
                    </button>
                })}
            </div>
        </div >
    );
};

export default Calendar
