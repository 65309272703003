import React from 'react';
import CustomButton from './CustomButton';
import ProgressBar from './ProgressBar';

export interface IUserReliability {
    pending: number,
    accepted: number,
    success: number,
    cancelled: number,
    noshow: number,
    rejected: number,
    unhandle: number,
    tot: number,
    good: number,
    bad: number,
    badPerc: number,
    isReliable: boolean
}

interface IProps {
    data: IUserReliability;
    onConfirm: () => void;
    onCancel: () => void;
}

const UserReliability: React.FC<IProps> = ({ data, onConfirm, onCancel }) => {
    const goodPerc = ((data.good / data.tot) * 100).toFixed(2);
    const badPerc = ((data.bad / data.tot) * 100).toFixed(2);

    const bookingCategories = [
        { label: 'In corso', count: data.pending, className: 'text-darkGray' },
        { label: 'Accettate', count: data.accepted, className: 'text-success' },
        { label: 'Completate', count: data.success, className: 'text-success' },
        { label: 'Rifiutate', count: data.rejected, className: 'text-error' },
        { label: 'No Show', count: data.noshow, className: 'text-error' },
        { label: 'Cancellate', count: data.cancelled, className: 'text-error' },
        { label: 'Altro', count: data.unhandle, className: 'text-darkGray' },
    ];

    bookingCategories.sort((a, b) => b.count - a.count);

    return (
        <div className="flex flex-col gap-4 p-4 bg-white rounded-lg shadow-md">
            <div className="flex flex-col items-center gap-2">
                <p className="text-2xl font-bold text-darkGray text-center">Storico Prenotazioni Utente</p>
                <p className="text-xs md:text-sm text-darkGray text-center">
                    Abbiamo individuato delle informazioni che potrebbero richiedere la tua attenzione.<br />
                    Qui trovi un riepilogo delle prenotazioni passate di questo utente, dove vengono evidenziate alcune criticità.
                    Esamina i dettagli per valutare attentamente come procedere.
                </p>
            </div>

            {
                data && (
                    <>
                        <div className="w-full h-px bg-mediumGray" />

                        <p className="text-darkGray font-semibold text-center">Prenotazioni Totali: {data.tot}</p>

                        <ProgressBar badPerc={Number(badPerc)} goodPerc={Number(goodPerc)} />

                        <div className="flex flex-col">
                            {bookingCategories.map((category, index) => (
                                category.count > 0 && (
                                    <div key={index} className="flex gap-2 items-center">
                                        <p className={`text-lg font-medium ${category.className}`}>{category.label} :</p>
                                        <p className={`text-lg font-medium ${category.className}`}>{category.count}</p>
                                    </div>
                                )
                            ))}
                        </div>
                    </>
                )
            }

            <div className="flex justify-between">
                <CustomButton
                    color='bg-white'
                    textColor='text-darkGray'
                    className='border-2 border-darkGray'
                    label='Annulla'
                    onClickHandler={onCancel}
                />
                <CustomButton
                    color='bg-primary'
                    textColor='text-background'
                    label='Crea'
                    onClickHandler={onConfirm}
                />
            </div>
        </div>
    )
}

export default UserReliability